<template>
  <b-container class="quiz-body">
    <b-row>
      <b-col>
        <basicHeader>
          <template v-slot:icon>
            <img
              src="https://motivateforms.co.za/public
/img/frontend_assets/patient.png"
              alt
              srcset />
          </template>
          <template v-slot:title>TO BE COMPLETED BY THE PATIENT</template>
          <template v-slot:header> PSORIASIS SIGN AND SYMPTOMS DIARY </template>
          <template v-slot:content>
            <p>
              Please answer each question to the best of your ability. There are no right or wrong answers. Please pay close attention to the time period of interest. These questions ask you to think about the <strong>PAST 24 HOURS</strong>. Please complete
              the diary at the same time every day.
            </p>
            <p>
              Individuals with psoriasis may experience a range of symptoms. Please indicate how severe each of the following skin symptoms was in the past 24 hours. Please select only one number for each item on the 0 to 10 scale (0 = Absent and 10 = Worst
              imaginable)
            </p>
          </template>
        </basicHeader>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <p>The PSSD includes 11 items covering symptoms and visible signs using 0-10 numerical rating scales. <sup>1</sup></p>

        <p>Overall scores range from 0-100 with a higher score indicating more severe symptoms/signs.<sup>1</sup></p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <basicQuiz :quizDetails="quiz"></basicQuiz>
      </b-col>
    </b-row>
    <b-row class="pt-3 pb-3">
      <b-col class="d-none d-md-block"></b-col>
      <b-col>
        <b-container fluid>
          <b-row>
            <b-col md="6" sm="12" cols="12">{{ checkCompleted }} of {{ quiz.length }} questions completed</b-col>
            <b-col md="3" sm="12" cols="12">
              <b-button class="btn-style btn-reset-quiz" pill @click="reset()">RESET</b-button>
            </b-col>
            <b-col md="3" sm="12" cols="12">
              <b-button pill class="primary-button btn-style" @click="complete()" :disabled="checkCompleted != quiz.length">COMPLETE</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card header="FOR CLINICIAN USE ONLY" header-tag="header" :header-class="['header-color-card', { 'header-color-card-aval': avaliable }]" header-text-variant="white" :body-class="['body-card-aval', { 'body-card-aval-false': !avaliable }]">
          <template v-slot:header>
            <b-container>
              <b-row>
                <b-col md="7" sm="7" :class="!avaliable ? 'textColor' : 'text-view'">FOR CLINICIAN USE ONLY</b-col>
                <b-col md="5" sm="5" :class="['second-text-size', !avaliable ? 'textColor' : 'text-view']">PSSD TOTAL SCORE</b-col>
              </b-row>
            </b-container>
          </template>
          <b-card-text class="card-background">
            <b-container :class="['quiz-title', 'parent-color', { 'changed-acitve-color': !avaliable }]">
              <b-row>
                <b-col md="7" sm="7"> </b-col>
                <b-col md="5" sm="5" class="my-auto">
                  <div :class="['mx-auto total-score', { 'total-score-available': !avaliable }]">
                    <p>{{ total() }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-container fluid>
          <b-row>
            <b-col>
              <p><strong>If two or more questions are left unanswered the questionnaire is not scored</strong></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                <strong>REFERENCES:</strong> 1. Feldman Sr <em>et al.</em> Development of a patient-reported outcome questionnaire for use in adults with moderate-to-severe plaque psoriasis: The Psoriasis Symptoms and Signs Diary. Journal of Dematology &
                Dermatologic Surgery. 2016;20:19-26.
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>
                <strong><span style="border: 1px solid black">S4</span> TREMFYA® 100 mg solution for subcutaneous injection;</strong> Each pre-filled syringe contains 100 mg of guselkumab per 1 mL. Registration number: 52/30.1/0400.
                <strong>JANSSEN PHARMACEUTICA (PTY) LTY/ (EDMS) BPK; (REG. NO./REGNR. 1980/011122/07)</strong>, No. 2, Medical Road, Halfway House, Midrand ,1685. www.janssen.com.
              </p>
              <p>Medical info Line: 0860 11 11 17. For full prescribing information refer to approved TREMFYA® Professional Information (May 2022)</p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import basicQuiz from "@/components/Quiz/BasicQuiz.vue";
import basicHeader from "@/components/Quiz/BasicHeader.vue";
import basicFooter from "@/components/Quiz/BasicFooter.vue";
import moment from "moment";
import axios from "axios";
import Input from "@/components/Fields/Input";
export default {
  components: {
    Input,
    basicQuiz,
    basicHeader,
    basicFooter,
  },
  computed: {
    checkCompleted() {
      let arraySize = this.quiz.filter((val) => {
        return val.value != null;
      });
      return arraySize.length;
    },
  },
  methods: {
    async download() {
      try {
        let headSection = [];
        if (this.patients_name) {
          headSection.push({
            classes: "",
            element: "p",
            text: "Patient name: " + this.patients_name,
          });
        }
        headSection.push({
          classes: "",
          element: "p",
          text: "Date: " + moment().format("DD/MM/YYYY"),
        });
        let dataRequest = {
          data: {
            headSection: headSection,
            questions: this.quiz,
            scores: [
              {
                item_code: "A",
                text: "Add scores of questions 1 - 4",
                score: this.calculatedValues.a,
                is_total: false,
              },
              {
                item_code: "B",
                text: "Add the scores from questions 5 + 6 and divide by 2",
                score: this.calculatedValues.b,
                is_total: false,
              },
              {
                item_code: "C",
                text: "Add A and B and divide by 5",
                score: this.calculatedValues.c,
                is_total: true,
              },
            ],
            total_score: this.calculatedValues.c,
          },
          document_name: "BASDAI-form-" + (this.patients_name ? this.patients_name : "unknown"),
        };

        let response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/quiz-report/basdai`, dataRequest);
        if (response) {
          window.open(response.data.data.url, "_blank");
        }
      } catch (error) {}
    },
    reset() {
      let quizTemp = this.quiz.map((val) => {
        val.value = null;
        return val;
      });
      this.quiz = quizTemp;
      this.calculatedValues = {
        a: null,
        b: null,
        c: null,
      };
      this.avaliable = true;
    },
    complete() {
      let total = 0;
      this.quiz.forEach((val) => {
        total += val.value;
      });
      //   let A_value = 0;
      //   let B_value = 0;
      //   let temp_b = 0;
      //   let C_value = 0;

      //   this.quiz.forEach(val => {
      //     if (val.id <= 4) {
      //       A_value += val.value;
      //     }
      //     if (val.id == 5 || val.id == 6) {
      //       temp_b += val.value;
      //     }
      //   });
      //   B_value = temp_b / 2;
      //   C_value = this.calculateC(A_value, B_value);
      //   this.calculatedValues = {
      //     a: A_value,
      //     b: B_value,
      //     c: C_value
      //   };
      this.avaliable = false;
      this.$emit("response", parseInt(total));
    },
    total() {
      let total = 0;
      this.quiz.forEach((val) => {
        total += val.value;
      });
      return total;
    },
  },

  data() {
    return {
      patients_name: "",
      avaliable: true,
      calculatedValues: {
        a: null,
        b: null,
        c: null,
      },
      quiz: [
        {
          id: 1,
          question: "Rate the severity of <strong>itch</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 2,
          question: "Rate the severity of <strong>dryness</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 3,
          question: "Rate the severity of <strong>cracking</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 4,
          question: "Rate the severity of <strong>skin tightness</strong> om the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 5,
          question: "Rate the severity of <strong>scaling (build-up of skin)</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 6,
          question: "Rate the severity of <strong>shedding or flaking</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 7,
          question: "Rate the severity of <strong>redness</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 8,
          question: "Rate the severity of <strong>bleeding</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 9,
          question: "Rate the severity of <strong>burning</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 10,
          question: "Rate the severity of <strong>stinging</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
        {
          id: 11,
          question: "Rate the severity of the <strong>pain from the psoriasis lesions</strong> in the past 24 hours",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 0, description: "" },
            { value: 1, description: "" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "" },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables";
.card {
  border-radius: 1rem;
  margin-bottom: 1rem;
}
.card-header {
  border-radius: 1rem 1rem 0 0 !important;
}
.card-body {
  border-radius: 0 0 1rem 1rem;
}

.card-background {
  font-size: 0.8rem;
}
</style>
