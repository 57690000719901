<template>
  <b-container fluid>
       <b-row class="pt-2 pb-2">
      <b-col md="2" sm="1" cols="2" class="padding-apply"><slot name="icon"></slot></b-col>
      <b-col md="9" sm="9"  cols="10" class="my-auto small-screen-style">
        <h2 class="quiz-title text-left">
            <slot name="title"></slot>
            </h2>
      </b-col>
    </b-row>
    <b-row class="alert-box-information">
      <b-col sm="2" md="2" class="my-auto"><img src="https://motivateforms.co.za/public
/img/frontend_assets/skin.png" alt="" srcset="" class="image-style"></b-col>
      <b-col sm="10" md="10" class="my-auto">
        <p class="text-white font-weight-bold">
            <slot name="header"></slot>
        </p>
        <p class="text-white">
            <slot name="content"></slot>
        
        </p>
      </b-col>
    </b-row>
    
  </b-container>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.image-style{
  height:8rem;
}


@media (min-width:850px) {

.padding-apply{
  padding-left:3rem;

}

}
@media (max-width:850px) {
    .image-style{
      height: 4rem;
    }
  .small-screen-style{
    padding-left:3rem;
    h2{
    font-size:1.5rem !important;

    }
  
  }
}

</style>

