<template>
  <Layout>
    <template v-slot:required-header>
      <b-row>
        <b-col md="2" sm="12">
          <h6>HCP Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input type="text" :binding="form.hcp_full_name" placeholder="Enter Dr Name" label="HCP Name"
            :required="validateErrors.hcp_full_name" @changeValue="
              form.hcp_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :binding="form.date" name="date" label="Date" :required="validateErrors.date"
            placeholder="Enter Date" @changeValue="
              form.date = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row class="mb-0">
        <b-col md="2" sm="12">
          <h6>Patient's Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input type="text" :binding="form.patient_detail_full_name" placeholder="Enter Patient's full name"
            label="Full Name" :required="validateErrors.patient_detail_full_name" @changeValue="
              form.patient_detail_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :binding="form.date_of_birth" name="date_of_birth" :required="validateErrors.date_of_birth"
            placeholder="Enter Date of birth" label="Date of Birth" @changeValue="
              form.date_of_birth = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <!-- <b-row>
        <b-col md="10" offset="2" sm="12">
          <SelectInput
            :binding="form.chosen_product"
            @changeValue="
              form.chosen_product = $event;
              setProductLogo();
            "
            :options="options.chosen_product"
            label="Product motivating for"
            placeholder
            name="chosen_product"></SelectInput>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col md="10" offset="2" sm="12">
          <SelectInput :binding="form.diagnosis" @changeValue="form.diagnosis = $event" :options="options.diagnosis"
            label="Diagnosis" placeholder name="diagnosis"></SelectInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" offset="2" sm="12">
          <p>PMB DTP Code</p>
          <p>901E – Hypertension – acute life-threatening complications and malignant hypertension; renal artery stenosis
            and other curable hypertension</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input type="text" :binding="form.medical_aid_name" placeholder="Enter Medical Aid Name "
            label="Medical Aid Name" @changeValue="form.medical_aid_name = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="text" :binding="form.plan_option" placeholder="Enter option here" label="Plan Option"
            @changeValue="form.plan_option = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input type="text" :binding="form.medical_aid_membership_number" placeholder="Enter Membership Number"
            label="Membership Number" @changeValue="form.medical_aid_membership_number = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput :binding="form.idc_10_code" @changeValue="form.idc_10_code = $event" :options="options.idc_10_code"
            label="ICD 10 Codes" placeholder name="icd_10_codes"></SelectInput>
        </b-col>
      </b-row>
    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Motivation</h6>
        </b-col>
        <b-col md="10" sm="12">
          <p>
            <span class="red-text">
              It is advisable that the motivational letter includes the clinical presentation of the patient, previous
              treatment history and the patient's co-morbidity Index.
              Please view the relevant additional documentation you may need to support your motivational letter. Medical
              scheme information to support the motivation is also
              available.
            </span>
          </p>
          <Input type="textarea" :binding="form.motivation" placeholder="Motivate here"
            @changeValue="form.motivation = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Medical Scheme Information</h6>
        </b-col>
        <b-col md="10" sm="12">
          <p>
            <span class="red-text"> Text from medical scheme information, found under additional information can be pasted
              below should you require.</span>
          </p>
          <HtmlEditor :inputData="form.medical_scheme_info" @changeValue="form.medical_scheme_info = $event"></HtmlEditor>
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton :tabs="options.tabs" :selectedTab="selectedTab" @changeTab="
        selectedTab = $event;
      changeActive($event);
      "></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="selectedTab == 'material'">
        <b-col md="12" sm="12">
          <!-- <Accordion v-if="form.chosen_product == 'Opsumit'" :info="form.opsumit"></Accordion> -->
          <Accordion :info="form.uptravi"></Accordion>
        </b-col>
        <b-col md="12" sm="12">
          <!-- <Accordion v-if="form.chosen_product == 'Opsumit'" :info="form.clinical_trial_opsumit"></Accordion> -->
          <Accordion :info="form.clinical_trial_uptravi"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'additional'">
        <b-col md="12" sm="12">
          <Accordion :info="form.additional_information"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'checklist'">
        <b-col md="12" sm="12">
          <Checklist>
            <template v-slot:pre-title>
              <b-alert variant="danger" show>
                <b-container fluid>
                  <b-row>
                    <b-col md="1" sm="1" class="pr-0 icon-col">
                      <img src="https://motivateforms.co.za/public/img/frontend_assets/danger-info.png" alt />
                    </b-col>
                    <b-col md="11" sm="11">Please remember to add / attach the below checklist documentation when
                      submitting your motivational letter.</b-col>
                  </b-row>
                </b-container>
              </b-alert>
            </template>

            <template v-slot:title>
              <h4 class="section-title section-title-spacing">CHECKLIST OF DOCUMENTS TO INCLUDE IN YOUR MOTIVATION FOR
                UPTRAVI</h4>
            </template>
            <template v-slot:content>
              <ul>
                <li>Motivation Letter</li>
                <li>Script</li>
                <li>Laboratory results</li>
                <li>Supporting investigations as listed in the form</li>
                <li>Additional investigations as listed in the form</li>
              </ul>
            </template>
          </Checklist>
        </b-col>
      </b-row>
    </template>
    <template v-slot:modal-preview>
      <ModalComponent :name="previewModal.data" @closing="closeModal()" :response="true"
        :modalName="previewModal.modalName" :showModal="previewModal.show"></ModalComponent>
    </template>
    <template v-slot:modal-type>
      <ModalComponent size="md" :defaultModal="true" @closing="exportModal.show = false" :response="true"
        :DocumentType="exportModal.type" :modalName="exportModal.modalName" :showModal="exportModal.show" :footer="false"
        @saveExport="processType($event, 'save')" @exportReset="processType($event, 'reset')"></ModalComponent>
    </template>
    <template v-slot:formControl>
      <FormControl :alert="alertMessage" @alertClose="alertMessage.show = false" @preview="preview()" @save="save()"
        @reset="reset()" @exportPDF="showModalExports('pdf')" @exportWord="showModalExports('Word')"></FormControl>
    </template>
  </Layout>
</template>

<script>
import Input from "@/components/Fields/Input";
import moment from "moment";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import pahJson from "@/assets/json/pah.json";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import Layout from "./Layouts";
import HtmlEditor from "../components/HtmlEditor.vue";
import FormControl from "../components/FormControl.vue";
export default {
  components: {
    Layout,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent,
    HtmlEditor,
    FormControl,
  },
  mixins: [GeneralUsage, ModalInformation],
  data() {
    return {
      options: {
        tabs: [
          { value: "material", text: "Supporting Material" },
          {
            value: "additional",
            text: "Additional Information",
          },
          {
            value: "checklist",
            text: "Checklist",
          },
        ],
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Clinical Trials", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional",
          },
          { value: false, text: "Checklist", relation: "checklist" },
        ],
        idc_10_code: [
          { value: null, text: "Select an option" },
          { value: "I27.0 Primary Pulmonary Hypertension", text: "I27.0 Primary Pulmonary Hypertension" },
          { value: "I27.2 Other secondary Pulmonary Hypertension", text: "I27.2 Other secondary Pulmonary Hypertension" },
        ],
        // chosen_product: [
        //   { value: null, text: "Select an option" },
        //   { value: "Opsumit", text: "Opsumit" },
        //   { value: "Uptravi", text: "Uptravi" },
        // ],
        diagnosis: [
          { value: null, text: "Select an option" },
          { value: "1.1. Idiopathic PAH", text: "1.1. Idiopathic PAH" },
          { value: "1.2. Heritable PAH", text: "1.2. Heritable PAH" },
          { value: "1.3. Drug- and toxin-induced PAH", text: "1.3. Drug- and toxin-induced PAH" },
          {
            label: "1.4. PAH associated with",
            options: [
              { value: "1.4.1. PAH associated with Connective tissue disease", html: "1.4.1. Connective tissue disease" },
              { value: "1.4.2. PAH associated with HIV infection", html: "1.4.2. HIV infection" },
              { value: "1.4.3. PAH associated with Portal hypertension", html: "1.4.3. Portal hypertension" },
              { value: "1.4.4. PAH associated with Congenital heart disease", html: "1.4.4. Congenital heart disease" },
              { value: "1.4.5. PAH associated with Schistosomiasis", html: "1.4.5. Schistosomiasis" },
            ],
          },
          { value: "1.5. PAH long-term responders to calcium channel blockers", text: "1.5. PAH long-term responders to calcium channel blockers" },
          {
            value: "1.6. PAH with overt features of venous/capillaries (PVOD/PCH) involvement",
            text: "1.6. PAH with overt features of venous/capillaries (PVOD/PCH) involvement",
          },
          { value: "1.7. Persistent PH of the newborn syndrome", text: "1.7. Persistent PH of the newborn syndrome" },
        ],
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        medical_scheme_info: "",
        // chosen_product: null,
        diagnosis: null,
        idc_10_code: null,
        idc_10_code_other: "",
        // medical_scheme_info: "<p></p>",
        clinical_trial_opsumit: {
          collapses: [
            {
              title: "CLINICAL DATA",
              visible: false,
              type: "checkbox",
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  options: [
                    {
                      type: "model",
                      value: false,
                      link: "pah.seraphin",
                      label: "SERAPHIN",
                      modal: "seraphin",
                      modalHeading: "SERAPHIN",
                    },
                  ],
                },
              ],
            },
          ],
        },
        clinical_trial_uptravi: {
          collapses: [
            {
              title: "CLINICAL DATA",
              visible: false,
              type: "checkbox",
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  options: [
                    {
                      type: "model",
                      value: false,
                      link: "pah.griphon",
                      label: "GRIPHON",
                      modal: "griphon",
                      modalHeading: "GRIPHON",
                      footerAppend: "<p>CP-331086</p>",
                    },
                  ],
                },
              ],
            },
          ],
        },
        uptravi: {
          collapses: [
            {
              title: "Motivation for Uptravi ® (Selexipag) ".toUpperCase(),
              visible: false,
              type: "assessment",
              sections: [
                {
                  show: false,
                  options: [
                    {
                      type: "text",
                      label: "Previous Treatment",
                      labelPrint: "Previous Treatment",
                      placeholder: "Enter text here",
                    },
                    {
                      type: "text",
                      label: "Reason for additional therapy (combination therapy) or reason for change in class of therapy",
                      labelPrint: "Reason for additional therapy (combination therapy) or reason for change in class of therapy",
                      placeholder: "Enter text here",
                    },
                    {
                      type: "dropdown",
                      label: "Functional Class Classification at diagnosis",
                      labelPrint: "Functional Class Classification at diagnosis",
                      name: "functional_class_classification_at_diagnosis",
                      value: null,
                      data: {
                        options: [
                          { text: "Please select one", value: null },
                          { text: "FCI", value: "FCI" },
                          { text: "FCII", value: "FCII" },
                          { text: "FCIII", value: "FCIII" },
                          { text: "FCIV", value: "FCIV" },
                        ],
                      },
                    },
                    {
                      type: "dropdown",
                      label: "Current Functional Class Classification",
                      labelPrint: "Current Functional Class Classification",
                      name: "functional_class_classification",
                      value: null,
                      data: {
                        options: [
                          { text: "Please select one", value: null },
                          { text: "FCI", value: "FCI" },
                          { text: "FCII", value: "FCII" },
                          { text: "FCIII", value: "FCIII" },
                          { text: "FCIV", value: "FCIV" },
                        ],
                      },
                    },
                    {
                      type: "radio",
                      items: null,
                      label: "6-minute walking distance at diagnosis",
                      value: "",
                      labelPrint: "6-minute walking distance at diagnosis",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Enter text here",
                      },
                    },
                    {
                      type: "radio",
                      items: null,
                      label: "Current 6-minute walking distance",
                      value: "",
                      labelPrint: "Current 6-minute walking distance",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Enter text here",
                      },
                    },
                    {
                      type: "radio",
                      items: null,
                      label: "6-minute walk distance at start of most recent therapy",
                      value: "",
                      labelPrint: "6-minute walk distance at start of most recent therapy",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Enter text here",
                      },
                    },
                    {
                      type: "radio",
                      items: null,
                      label: "Diagnostic: Right Heart Catheter Pressure",
                      value: "",
                      labelPrint: "Diagnostic: Right Heart Catheter Pressure",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Enter text here",
                      },
                    },
                    {
                      type: "radio",
                      items: null,
                      label: "Most recent Right Heart Catheter (if available)",
                      value: "",
                      labelPrint: "Most recent Right Heart Catheter (if available)",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Enter text here",
                      },
                    },
                    {
                      type: "radio",
                      items: null,
                      label: "Minimum O<sub>2</sub> saturation",
                      value: "",
                      labelPrint: "<span>Minimum O<sub>2</sub> saturation</span>",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Enter text here",
                      },
                    },
                    {
                      type: "checkboxOptions",
                      label: "Additional investigations included",
                      labelPrint: "Additional investigations included",
                      pre_items_note: "Please ensure that the test result are attached to the final document.",
                      items: [
                        "Echocardiogram",
                        "Blood tests",
                        "NT- Pro BNP Level",
                        "Kidney Function (incl Hb level)",
                        "Radiology (VQ/CT scan results)",
                        "Lung Function Test",
                        "Other",
                      ],
                      value: [],
                    },
                    {
                      type: "text",
                      label: "",
                      value: null,
                      placeholder: "Enter text here",
                      labelPrint: "Other investigations",
                    },
                    {
                      type: "text",
                      html: true,
                      label: "Number of hospitalisations in the last year",
                      labelPrint: "Number of hospitalisations in the last year",
                      placeholder: "Enter text here",
                    },
                  ],
                },
              ],
            },
          ],
        },

        additional_information: {
          collapses: [
            {
              title: "MEDICAL SCHEME INFORMATION",
              visible: false,
              header: "Medical scheme information",
              type: "documents",
              sections: [
                {
                  text: "MEDICAL SCHEME INFORMATION",
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "Please note the document is not auto populated kindly copy and paste any relevant sections into the free text region above",
                    },
                  ],

                  options: [
                    {
                      type: "parent",
                      value: "The legal framework for patient access",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "uptravi.legal_framework_uptravi",
                      label: "- The Legal Framework Around Patient Access To Uptravi",
                      modal: "legal_framework_uptravi",
                      modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO UPTRAVI",
                      footerAppend: "<p>CP-402066</p>",
                    },
                  ],
                },
              ],
            },
            {
              title: "PATIENT MOTIVATION",
              visible: false,
              type: "patient_motivation",
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: true,
                      details: `
                        <p>In this section you are able to leave space for  your patient to insert their hand written motivation as to what they are going through or why they want to motivate to their medical aid.  </p><p>Alternatively you can use the free text box to motivate on behalf of your patient in typed text</p>
                        `,
                    },
                  ],
                  text: "PATIENTS OWN MOTIVATION",
                  choice: null,
                  options: [
                    {
                      value: "",
                      label: "Patients Motivation",
                      type: "textarea",
                    },
                  ],
                },
              ],
            },
            {
              title: "MEDICAL INFORMATION",
              visible: false,
              header: "MEDICAL INFORMATION",
              type: "html",
              data: `

          <p>
          <strong>
          Should you require any further clinical trials or medical information please use the below contact information
          </strong>
          </p>
          <p>
          Email: <a href="mailto:ra-medinfoemmarkets@its.jnj.com">ra-medinfoemmarkets@its.jnj.com</a>
          <br>Telephone: 0860 11 11 17
          </p>
          `,
            },
          ],
        },
      },
    };
  },
  methods: {
    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepData = this.prepPah(this.form);
        let processed = this.flatData(prepData, pahJson);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    save() {
      this.setLocal("pah", this.form);
    },

    async exportPDF(reaction) {
      let prepData = this.prepPah(this.form);

      let processed = this.flatData(prepData, pahJson);
      let response = await this.exportPDFUrl(processed, this.form.patient_detail_full_name);

      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    async exportWord(reaction) {
      let prepData = this.prepPah(this.form);
      let x = new RegExp('<p class="indentity"', "g");
      let m = prepData.medical_scheme_info.replace(x, '<br><br><p class="indentity"');
      prepData.medical_scheme_info = m;
      let processed = this.flatData(prepData, pahJson);
      let response = await this.exportWordUrl(processed, this.form.patient_detail_full_name);
      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    prepPah(form) {
      let checkbox;

      let returnObject = {
        hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
        date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
        patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
        date_of_birth: form.date_of_birth ? moment(form.date_of_birth).format("DD/MM/YYYY") : "",
        medical_aid_name: form.medical_aid_name ? form.medical_aid_name : "",
        plan_option: form.plan_option ? form.plan_option : "",
        medical_aid_membership_number: form.medical_aid_membership_number ? form.medical_aid_membership_number : "",
        medical_scheme_info: form.medical_scheme_info ? form.medical_scheme_info : "",
        pmb_code: "901E – Hypertension – acute life-threatening complications and malignant hypertension; renal artery stenosis and other curable hypertension",
        idc_10_code: form.idc_10_code ? form.idc_10_code : "",
        product: form.chosen_product ?? "",
        diagnosis: form.diagnosis ?? "",
        medical_scheme_info: form.medical_scheme_info
          ? form.medical_scheme_info
            .replace(new RegExp("font-family: Poppins;", "g"), "")
            .replace(new RegExp("font-size: 20px;", "g"), "font-size: 15px;")
            .replace(new RegExp("list-style-type: none !important; counter-reset: item 0 !important;", "g"), "")
            .replace(new RegExp('<li style="box-sizing: border-box; display: table !important;', "g"), '<li style="box-sizing: border-box; display: list-item !important;')
            .replace(
              new RegExp(
                '<ol style="box-sizing: border-box; color: #212529;  font-size: 12px; background-color: #ffffff; margin: 0px !important;  padding: 0px !important;">',
                "g"
              ),
              "<ol>"
            )
            .replace(new RegExp('<ol style="box-sizing: border-box; margin: 0px !important;  padding: 0px !important;">', "g"), "<ol>")
          : "",
      };
      if (form.idc_10_code == "Other") {
        returnObject["idc_10_code_other"] = form.idc_10_code_other ? form.idc_10_code_other : "";
      }
      if (form.motivation) {
        returnObject["motivation_header"] = "MOTIVATION";
        returnObject["motivation"] = form.motivation ? form.motivation : "";
      }
      let tempMed = {
        collapses: [form.additional_information.collapses[0]],
      };
      let tempMedObj = this.getCheckbox(tempMed);
      if (tempMedObj.length > 0) {
        returnObject["uptravi_data"] = tempMedObj;
      }
      // if (form.chosen_product == "Uptravi") {
      let tempData = {
        collapses: [form.uptravi.collapses[0]],
      };
      let tempAddition = this.getCheckbox(tempData);
      if (tempAddition.length > 0) {
        returnObject["uptravi_data"] = tempAddition;
      }
      checkbox = this.getCheckbox(form.clinical_trial_uptravi);
      if (checkbox.length > 0) {
        returnObject["clinical_trial_header"] = "CLINICAL TRIALS";
        returnObject["clinical_trial"] = checkbox;
      }
      // }

      let tempInput = this.patientsMotivation(form.additional_information.collapses);
      if (tempInput.length > 0) {
        returnObject["additional_information"] = tempInput;
      }
      if (form.medical_scheme_info) {
        returnObject["medical_scheme_info_header"] = "MEDICAL SCHEME INFORMATION";
      }

      return returnObject;
    },
    reset() {
      this.resetStorage("pah");
    },
    // setProductLogo() {
    //   if (this.form.chosen_product != null) {
    //     if (this.form.chosen_product == "Opsumit") {
    //       document.querySelector(".navbar-brand:nth-child(3)").innerHTML = `<img src="https://motivateforms.co.za/public/img/frontend_assets/opsumit.svg" alt="opsumit logo" height="60rem">`;
    //     } else if (this.form.chosen_product == "Uptravi") {
    //       document.querySelector(".navbar-brand:nth-child(3)").innerHTML = `<img src="https://motivateforms.co.za/public/img/frontend_assets/uptravi.svg" alt="uptravi logo" height="60rem">`;
    //     }
    //   } else {
    //     document.querySelector(".navbar-brand:nth-child(3)").innerHTML = "";
    //   }
    // },
  },
  created() {
    let data = this.getLocal("pah");
    if (data) {
      this.form = data;
    }
  },
};
</script>
<style>
.collapse .custom-select {
  border: 1px solid #ced4da !important;
}
</style>
