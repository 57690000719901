<template>
  <span>
    <label :for="name">{{label}}</label>
    <b-form-select :name="name" :placeholder="placeholder" v-model="valueProp" :options="options"></b-form-select>
  </span>
</template>

<script>
export default {
  props: {
    name: String,
    binding: String,
    placeholder: String,
    label: String,
    options: Array,
    
  },
  data() {
    return {
      valueProp: null
    };
  },
  watch: {
    binding(){
 this.valueProp = this.$props.binding;
    },
    valueProp() {
      this.$emit("changeValue", this.valueProp);
    }
  },
  created() {
    this.valueProp = this.$props.binding;
  }
};
</script>

<style scoped>
label {
}
</style>