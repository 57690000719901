<template>
  <b-container>
    <b-row v-for="(section, indexSection) in data.sections" :key="indexSection">
      <b-col md="12" sm="12">
        <h5 class="section-title pt-3" v-if="section.type != 'reference'">
          {{ section.text }}
        </h5>

        <b-container>
          <template v-if="section.motivate">
            <b-row v-for="(motivate, motivateIndex) in section.motivate" :key="'motivation' + motivateIndex">
              <b-col md="12" sm="12">
                <b-alert show :variant="motivate.variant">
                  <b-container fluid>
                    <b-row class="h-100">
                      <b-col md="1" sm="1" class="pr-0 icon-col my-auto" v-if="motivate.icon">
                        <img src="https://motivateforms.co.za/public/img/frontend_assets/danger-info.png" alt />
                      </b-col>
                      <b-col v-if="motivate.html" v-html="motivate.details" md="11" sm="11" class="my-auto"></b-col>
                      <b-col v-if="motivate.html == false" md="11" sm="11" class="my-auto">{{ motivate.details }}</b-col>
                    </b-row>
                  </b-container>
                </b-alert>
              </b-col>
            </b-row>
          </template>

          <b-row v-for="(option, optionIndex) in section.options" :key="'opt' + optionIndex">
            <template v-if="option.type == 'model'">
              <b-col md="10" sm="12">
                <p class="checkbox-label">
                  {{ option.label }}
                </p>
              </b-col>

              <b-col md="2" sm="12">
                <p class="checkbox-label view-btn" @click="modalDetail(option.modal, option.modalHeading, option.footerAppend)">View</p>
              </b-col>
            </template>
            <template v-if="option.type == 'parent'">
              <b-col md="10" sm="12">
                <h6 class="checkbox-label ">
                  {{ option.value }}
                </h6>
              </b-col>
            </template>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: ["data"],
  methods: {
    modalDetail(modal, heading, footerAppend) {
      this.$emit("modalTrigger", { modal, heading, footerAppend });
    },
  },
};
</script>

<style lang="scss" scoped>
.view-btn {
  cursor: pointer;
}
</style>
