var render = function render(){var _vm=this,_c=_vm._self._c;return _c('editor',{attrs:{"api-key":"ip0isfsjqdxfklu8hg6b7ef50eonm6hyeu6bzqp0jtny2a4v","init":{
      height: 70,
      menubar: false,
      constent_style:
        'mce-content-body[data-mce-placeholder]{font-size: 0.8rem !important;}'
      ,
      fix_list_elements : true,
      plugins: [''],
      toolbar: '',
	    paste_word_valid_elements: 'b,strong,i,em,h1,h2,sup'
    }},model:{value:(_vm.valueProp),callback:function ($$v) {_vm.valueProp=$$v},expression:"valueProp"}})
}
var staticRenderFns = []

export { render, staticRenderFns }