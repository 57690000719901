<template>
  <Layout>
    <template v-slot:required-header>
      <b-row>
        <b-col md="2" sm="12">
          <h6>HCP Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input name="hcp_full_name" :required="validateErrors.hcp_full_name" type="text" :binding="form.hcp_full_name"
            placeholder="Enter Dr Name" label="HCP Name" @changeValue="
              form.hcp_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :required="validateErrors.date" :binding="form.date" name="date" label="Date"
            placeholder="Select Date" @changeValue="
              form.date = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row class="mb-0">
        <b-col md="2" sm="12">
          <h6>Patient's Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input :required="validateErrors.patient_detail_full_name" type="text" name="patient_detail_full_name"
            :binding="form.patient_detail_full_name" placeholder="Enter Patient's full name" label="Full Name"
            @changeValue="
              form.patient_detail_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :required="validateErrors.date_of_birth" :binding="form.date_of_birth" name="date_of_birth"
            placeholder="Select Date of birth" label="Date of Birth" @changeValue="
              form.date_of_birth = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input type="text" name="medical_aid_name" :binding="form.medical_aid_name"
            placeholder="Enter Medical Aid Name " label="Medical Aid Name"
            @changeValue="form.medical_aid_name = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input name="plan_option" type="text" :binding="form.plan_option" placeholder="Enter option here"
            label="Plan Option" @changeValue="form.plan_option = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input name="medical_aid_membership_number" type="text" :binding="form.medical_aid_membership_number"
            placeholder="Enter Membership Number" label="Membership Number"
            @changeValue="form.medical_aid_membership_number = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput :binding="form.idc_10_code" @changeValue="form.idc_10_code = $event" :options="options.icd_10_code"
            label="ICD 10 Codes" placeholder name="icd_10_codes"></SelectInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <SelectInput :binding="form.type_of_patient" placeholder="Type of Patient" name="type_of_patient"
            label="Type of Patient" :options="options.type_of_patient" @changeValue="
              prependPatientSupportingMaterials($event);
            selectedTab = 'trials';
            form.type_of_patient = $event;
            "></SelectInput>
        </b-col>
      </b-row>
    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Motivation</h6>
        </b-col>

        <b-col md="10" sm="12">
          <p>
            <span class="red-text">
              Remember to include your patient's clinical and treatment history as well as co-morbidity index when
              motivating. Please view below a checklist of all the relevant
              additional documentation you may need to attach with your motivational letter.
            </span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" offset="2" sm="12">
          <Input type="textarea" :binding="form.motivation" :required="validateErrors.motivation"
            placeholder="Motivate here" @changeValue="form.motivation = $event" />
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton :tabs="options.tabs" :selectedTab="selectedTab" @changeTab="
        selectedTab = $event;
      changeActive($event);
      "></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="selectedTab == 'trials'">
        <b-col v-if="form.type_of_patient">
          <!-- <Accordion :info="form.clinical_trial" type="checkbox"></Accordion> -->
          <Accordion :info="form.clinical_trial" type="radio" @addValue="radioAdd($event, 'clincial_trial')"
            @referencesAdd="referenceAdd($event, 'clinical_trial')"
            @referenceRemove="referenceRemove($event, 'clinical_trial')" @radioAdd="radioAdd($event, 'clinical_trial')">
          </Accordion>
        </b-col>
        <b-col md="12" sm="12" v-if="form.type_of_patient == null">
          <h6 class="text-center my-3">Please select a patient type</h6>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'additional'">
        <b-col v-if="form.additional_information">
          <Accordion :info="form.additional_information" type="checkbox">
            <template v-slot:dataInsert>
              <b-container fluid>
                <b-row>
                  <b-col>
                    <ul>
                      <li>Reliable access and reimbursement services.</li>
                      <li>Comprehensive disease information pack aimed at supporting you, the patient.</li>
                      <li>
                        Personalised product education on the administration and handling of Tremfya
                        <sup>®</sup> pre-filled syringes.
                      </li>
                      <li>Ongoing support to improve your adherence and mobility.</li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <img src="https://motivateforms.co.za/public/img/frontend_assets/patient_motivate.jpg" width="50%" />
                  </b-col>
                  <b-col>
                    <p>CONTACT DETAILS</p>
                    <p>(During regular business hours)</p>
                    <p>Tel. 0800112101</p>
                    <p>Fax to email: 086 649 1094</p>
                    <p>Email: janssenpatientsupport@its.jnj.com</p>
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'checklist'">
        <b-col md="12" sm="12">
          <Checklist>
            <template v-slot:pre-title>
              <b-alert variant="danger" show>
                <b-container fluid>
                  <b-row>
                    <b-col md="1" sm="1" class="pr-0 icon-col">
                      <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                    </b-col>
                    <b-col md="11" sm="11">Please remember to add / attach the below checklist documentation when
                      submitting your motivational letter.</b-col>
                  </b-row>
                </b-container>
              </b-alert>
            </template>

            <template v-slot:title>
              <h4 class="section-title section-title-spacing">CHECKLIST OF DOCUMENTS TO INCLUDE IN YOUR MOTIVATION FOR
                TREMFYA®</h4>
            </template>
            <template v-slot:content>
              <ul>
                <li>Motivation Letter</li>
                <li>Attach script</li>
                <li>Assessment scores</li>
                <li>Attach patient photos (Optional)</li>
                <li>Attach patient written motivation letter (Optional)</li>
              </ul>
            </template>
          </Checklist>
        </b-col>
      </b-row>
    </template>

    <template v-slot:modal-preview>
      <ModalComponent :name="previewModal.data" @closing="closeModal()" :response="true"
        :modalName="previewModal.modalName" :showModal="previewModal.show"></ModalComponent>
    </template>

    <template v-slot:modal-type>
      <ModalComponent size="md" :defaultModal="true" @closing="exportModal.show = false" :response="true"
        :DocumentType="exportModal.type" :modalName="exportModal.modalName" :showModal="exportModal.show" :footer="false"
        @saveExport="processType($event, 'save')" @exportReset="processType($event, 'reset')"></ModalComponent>
    </template>

    <template v-slot:formControl>
      <FormControl :alert="alertMessage" @alertClose="alertMessage.show = false" @preview="preview()" @save="save()"
        @reset="reset()" @exportPDF="showModalExports('pdf')" @exportWord="showModalExports('Word')"></FormControl>
    </template>
  </Layout>
</template>

<script>
import Preview from "@/components/Preview";
import Input from "@/components/Fields/Input";
import moment from "moment";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import { StelaraData } from "@/mixins/Stelara.js";
import pso_reference_json from "@/assets/json/PsO_reference.json";
import tremfyaJson from "@/assets/json/tremfya.json";
import FormControl from "../components/FormControl.vue";
import Layout from "@/pages/Layouts";

export default {
  components: {
    Layout,
    Preview,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent,
    FormControl,
  },
  mixins: [GeneralUsage, ModalInformation, StelaraData],
  data() {
    return {
      validateErrors: {
        hcp_full_name: { status: null, message: "" },
        date: { status: null, message: "" },
        patient_detail_full_name: { status: null, message: "" },
        date_of_birth: { status: null, message: "" },
        motivation: { status: null, message: "" },
      },
      selectedTab: null,
      alertMessage: {
        show: false,
        variant: "success",
        message: "",
      },
      supporting: [],
      options: {
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Supporting Material", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional",
          },
          {
            value: false,
            text: "Checklist",
            relation: "checklist",
          },
        ],
        type_of_patient: [
          { value: null, text: "Select patient type" },
          {
            value: "Biologic - Eligible Patient",
            text: "Biologic - Eligible Patient",
            references: [
              // "STELARA® approved package insert, October 2015.",
              // "CCC recommendation June 2017."
            ],
          },
          {
            value: "Biologic - Rem-motivating for Tremfya",
            text: "Biologic - Re-motivating for Tremfya",
            references: [
              // "Leonardi CL, Kimball AB, Papp KA, et al. Efficacy and safety of ustekinumab, a human interleukin-12/23 monoclonal antibody, in patients with psoriasis: 76-week results from a randomised, double-blind, placebo-controlled trial (PHOENIX 1). Lancet 2008;371(9625):1665–1674.",
              // "Kimball AB, Papp KA, Wasfi Y, et al. Long-term efficacy of ustekinumab in patients with moderate-to-severe psoriasis treated for up to 5 years in the PHOENIX 1 study. J Eur Acad Dermatol Venereol 2013;27(12):1535–1545.",
              // "Papp KA, Langley RG, Lebwohl M, et al. Efficacy and safety of ustekinumab, a human interleukin-12/23 monoclonal antibody, in patients with psoriasis: 52-week results from a randomised, double-blind, placebo-controlled trial (PHOENIX 2). Lancet 2008;371:1675–1684."
            ],
          },
          {
            value: "Appeal of medical scheme rejection",
            text: "Appeal of medical scheme rejection",
            references: [
              // "Leonardi CL, Kimball AB, Papp KA, et al. Efficacy and safety of ustekinumab, a human interleukin-12/23 monoclonal antibody, in patients with psoriasis: 76-week results from a randomised, double-blind, placebo-controlled trial (PHOENIX 1). Lancet 2008;371(9625):1665–1674.",
              // "Papp KA, Langley RG, Lebwohl M, et al. Efficacy and safety of ustekinumab, a human interleukin-12/23 monoclonal antibody, in patients with psoriasis: 52-week results from a randomised, double-blind, placebo-controlled trial (PHOENIX 2). Lancet 2008;371:1675–1684."
            ],
          },
        ],
        icd_10_code: [
          { value: null, text: "Please select one" },
          {
            value: "L40.0    Psoriasis vulgaris",
            text: "L40.0    Psoriasis vulgaris",
          },
          {
            value: "L40.1    Generalized pustular psoriasis",
            text: "L40.1    Generalized pustular psoriasis",
          },
          {
            value: "L40.2    Acrodermatitis continua",
            text: "L40.2    Acrodermatitis continua",
          },
          {
            value: "L40.3    Pustulosis palmaris et plantaris",
            text: "L40.3    Pustulosis palmaris et plantaris",
          },
          {
            value: "L40.4    Guttate psoriasis",
            text: "L40.4    Guttate psoriasis",
          },
          {
            value: "L40.8    Other psoriasis",
            text: "L40.8    Other psoriasis",
          },
          {
            value: "L40.9    Psoriasis, unspecified",
            text: "L40.9    Psoriasis, unspecified",
          },
          {
            value: "L41.3    Small plaque parapsoriasis",
            text: "L41.3    Small plaque parapsoriasis",
          },
          {
            value: "L41.4    Large plaque parapsoriasis",
            text: "L41.4    Large plaque parapsoriasis",
          },
          {
            value: "L41.5    Retiform parapsoriasis",
            text: "L41.5    Retiform parapsoriasis",
          },
          {
            value: "L41.8    Other parapsoriasis",
            text: "L41.8    Other parapsoriasis",
          },
          {
            value: "L41.9    Parapsoriasis, unspecified",
            text: "L41.9    Parapsoriasis, unspecified",
          },
          { value: "Other", text: "Other" },
        ],
        tabs: [
          { value: "trials", text: "Supporting Material" },
          {
            value: "additional",
            text: "Additional Information",
          },
          {
            value: "checklist",
            text: "Checklist",
          },
        ],
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        idc_10_code: null,
        idc_10_code_other: "",
        type_of_patient: null,
        type_of_patient_reference: [],
        motivation: "",
        clinical_trial: {
          collapses: [
            {
              title: "GUIDELINES ON BIOLOGIC ELIGIBILITY",
              visible: false,
              type: "checkbox",
              sections: [
                {
                  text: "GUIDELINES ON BIOLOGIC ELIGIBILITY",
                  options: [
                    {
                      type: "basic",
                      value: false,
                      label: "Regulation 15H",
                      link: "Regulation-15H",
                      special: true,
                      endSuper: "1",
                      description: "According to the Delphi consensus from the International Psoriasis Council, psoriasis severity has been recategorized. Psoriasis patients should be classified as either candidates for topical therapy or candidates for systemic therapy; the latter are patients who meet at least one of the following criteria: <br>(1) body surface area [10%], <br>(2) disease involving special areas, and <br>(3) failure of topical therapy.<br>The term ‘‘special areas’’ was used to refer to psoriasis affecting more impactful sites such as the face, palms, soles, genitalia, scalp, or nails.",
                      references: [
                        {
                          id: 1,
                          text: "Strober B, et al. (2020) J Am Acad Dermatol 82 (1): 117-122",
                        },
                      ],
                    },
                  ],
                },
                {
                  text: "References",
                  options: [
                    {
                      type: "reference",
                      static: ["Strober B, et al. (2020) J Am Acad Dermatol 82 (1): 117-122"],
                      references: [],
                    },
                  ],
                },
                // {
                //   text: "References",
                //   show: false,
                //   options: [
                //     {
                //       type: "reference",
                //       html: true,
                //       static: [
                //         "Strober B, et al. (2020) J Am Acad Dermatol 82 (1): 117-122",
                //       ],
                //       references: [
                //         { text: "1. Strober B, et al. (2020) J Am Acad Dermatol 82 (1): 117-122" },
                //       ],
                //     },
                //   ],
                // }
              ],
            },
            {
              title: "DERMATOLOGY LIFE QUALITY INDEX (DLQI)",
              visible: false,
              type: "assessment",

              sections: [
                {
                  // superScript: "1A+B,2A+B",
                  superScript: "1,2",
                  text: "DERMATOLOGY LIFE QUALITY INDEX (DLQI)",
                  downloadUrl: "/download/DLQI-form.pdf",
                  viewUrl: "dlqi",
                  attachedValue: 2,
                  references: true,
                  options: [
                    {
                      type: "information",
                      html: true,
                      data: `<p>USING THE DLQI TOTAL SCORE, ASSESS THE EFFECT ON QUALITY OF LIFE:</p><table><tr><td class="p-2">0 - 1 = NO EFFECT </td><td class="p-2">2 - 5 = SMALL EFFECT</td><td class="p-2">6 - 10 = MODERATE EFFECT</td></tr><tr><td>11 - 20 = LARGE EFFECT</td><td class="p-2"> 21 - 30 = EXTREMELY LARGE EFFECT</td><td></td></tr></table>`,
                    },
                    {
                      type: "number",
                      render: "0 - 1 = NO EFFECT, 2 - 5 = SMALL EFFECT, 6 - 10= MODERATE EFFECT, 11 - 20 = LARGE EFFECT 21 - 30 = EXTREMELY LARGE EFFECT",
                      min: 0,
                      max: 30,
                      label: "Enter Past DLQI Score",
                      description: " ",
                      labelPrint: "Previous DLQI Score",
                      value: 0,
                      references: true,
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 30,
                      label: "Enter Current DLQI Score",
                      description: " ",
                      labelPrint: "Current DLQI Score",
                      value: 0,
                      references: true
                    },
                  ],
                },
                {
                  text: "References",
                  show: false,
                  options: [
                    {
                      type: "reference",
                      html: true,
                      static: [
                        "Finlay AY, Khan GK. Dermatology life quality index (DLQI) – a simple practical measure for routine clinical use. Clin Exp Derm 1994;19:210-216",
                        "Dermatology Life Quality Index. British Association of Dermatology. [cited 2020 Apr 08]; Available from: <a href='https://www.bad.org.uk/shared/get-file.ashx?id=1653&itemtype=document'>https://www.bad.org.uk/shared/get-file.ashx?id=1653&itemtype=document</a>",
                      ],
                      references: [

                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: "PSORIATIC AREA AND SEVERITY INDEX (PASI) AND BODY SURFACE AREA (BSA)",
              type: "assessment",
              visible: false,
              sections: [
                {
                  text: "PSORIATIC AREA AND SEVERITY INDEX (PASI) AND BODY SURFACE AREA (BSA) ",
                  superScript: "1,2,3,4",
                  downloadUrl: "/download/PASI-v2.pdf",
                  // viewUrl: "/quiz/dlqi",

                  options: [
                    {
                      type: "information",
                      html: true,
                      data: `<p>RATE THE PASI SEVERITY</p><table><tr><td class="p-2">PASI &lt;7 = MILD PASI;</td><td  class="p-2">7 - 12 = MODERATE PASI;</td><td class="p-2">PASI &gt;12 = SEVERE</td></tr></table>`,
                    },
                    {
                      render: "Below are the PASI scores where < 7 = Mild; 7 - 12 = Moderate; > 12 = Severe",
                      type: "number",
                      min: 0,
                      max: 20,
                      label: "Enter Past PASI Score",
                      labelPrint: "Previous Score",
                      description: " ",
                      value: 0,
                      references: true

                    },
                    {
                      type: "number",
                      min: 0,
                      max: 20,
                      label: "Enter Current PASI Score",
                      labelPrint: "Current Score",
                      description: " ",
                      value: 0,
                      references: true

                    },
                    {
                      type: "information",
                      html: true,
                      data: `<p>RATE THE BSA SEVERITY</p><table><tr class="p-2"><td>BSA &lt;3% = MILD;</td><td class="p-2">BSA 3 - 10% = MODERATE;</td><td>BSA &gt;10% = SEVERE</td></tr></table>`,
                    },
                    {
                      render: "Below are the BSA scores where < 3 % = Mild; 3 - 10% = Moderate; > 10 % = Severe",
                      type: "number",
                      min: 0,
                      max: 100,
                      label: "Enter Past BSA Score",
                      labelPrint: "Previous Score",
                      description: " ",
                      value: 0,
                      references: true

                    },
                    {
                      type: "number",
                      min: 0,
                      max: 100,

                      label: "Enter Current BSA Score",
                      labelPrint: "Current Score",
                      description: " ",
                      value: 0,
                      references: true

                    },
                  ],
                },
                {
                  text: "References",
                  show: false,
                  options: [
                    {
                      type: "reference",
                      html: true,
                      static: [
                        "Mease PJ. Measures of psoriatic arthritis. Arthritis Care Res 2011;63(S11):S64-S85",
                        "Schmitt J, Wozel G. The Psoriasis Area and Severity Index is the adequate criterion to define severity in chronic plaque-type psoriasis. Dermatology 2005;210:194-199. ",
                        "Gladman DD, Poulin Y, Adams K, et al. Treating psoriasis and psoriatic arthritis: position paper on applying the treat-to-target concept to Canadian daily practice. J Rheumatol 2017;44(4):519-534.",
                        "Van Voorhees AS, Feldman SR, Lebwohl MG, et al. The psoriasis and psoriatic arthritis pocket guide. Treatment algorithms and management options. National Psoriasis Foundation. [cited 2020 Apr 08]; Available from: <a href='https://www.psoriasis.org/sites/default/files/npf_pocketguide_2018.pdf'>https://www.psoriasis.org/sites/default/files/npf_pocketguide_2018.pdf</a>",
                      ],
                      references: [
                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: "PATIENT SIGNS AND SYMPTOMS DIARY (PSSD)",
              type: "assessment",
              visible: false,
              sections: [
                {
                  text: "THE PSSD INCLUDES 11 ITEMS COVERING SYMPTOMS AND VISABLE SIGNS USING 0 - 10 NUMERICAL RATING SCALES",
                  superScript: "1",
                  downloadUrl: "/download/PSSD.pdf",
                  viewUrl: "pssd",
                  attachedValue: 2,
                  options: [
                    {
                      type: "information",
                      html: true,
                      data: `<p>RATE THE SEVERITY OF THE SYMPTOMS / VISIBLE SIGNS</p><p>OVERALL SCORES RANGE FROM 0 - 100 WITH A HIGHER SCORE INDICATING MORE SEVERE SYMPTOMS/SIGNS.<sup>1</sup></p>`,
                    },
                    {
                      render: "",
                      type: "number",
                      min: 0,
                      max: 100,
                      label: "Enter Past PSSD Score",
                      labelPrint: "Previous Score",
                      description: " ",
                      value: 0,
                      references: true

                    },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      label: "Enter Current PSSD Score",
                      labelPrint: "Current Score",
                      description: " ",
                      value: 0,
                      references: true

                    },

                  ],
                },
                {
                  text: "References",
                  show: false,
                  options: [
                    {
                      type: "reference",
                      html: true,
                      static: [
                        "Feldman SR <em>et al.</em> Development of a patient-reported outcome questionaire for use in adults with moderate-to-severe plaque psoriasis: The Psoriasis Symptoms and Signs Diary. Journal of Dermatologic Surgery. 2016;20:19-26",
                      ],

                      references: [

                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: "PSORIASIS EPIDEMIOLOGY SCREENING TOOL (PEST)",
              visible: false,
              type: "assessment",
              sections: [
                {
                  text: "<p style='color: black'>Any person with psoriasis who have not been diagnosed with psoriatic arthritis should complete a PEST questionnaire annually. <sup>1</sup><p><br> <strong style='color: black'> PLEASE DOWNLOAD THE PDF QUESTIONNAIRE AND SCORE 1 POINT FOR EACH QUESTION ANSWERED YES.</strong>",
                  hr: true,
                  html: true,
                },
                {
                  text: "PSORIASIS EPIDEMIOLOGY SCREENING TOOL (PEST)",
                  downloadUrl: "/download/PEST.pdf",

                },
                {
                  text: "References",
                  show: false,
                  options: [
                    {
                      type: "reference",
                      html: true,
                      static: [
                        "PEST screening tool. [cited 2023 May 03]; Available from https://www.mypsoriaticarthritis.org.au/article/your-psa-diagnosis/pest-screening-tool",
                      ],

                      references: [

                      ],
                    },
                  ],
                },
              ],
            },
            {
              title: "OTHER INVOLVEMENT",
              visible: false,
              type: "assessment",
              sections: [
                {
                  text: "OTHER INVOLVEMENT",
                  superScript: "1,2,3",
                  customText: "OTHER INVOLVEMENT <sup>1,2,3</sup>",
                  references: true,
                  options: [
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "NAILS",
                      value: null,
                      labelPrint: "NAILS",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "FACE",
                      value: null,
                      labelPrint: "FACE",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "PALMS",
                      value: null,
                      labelPrint: "PALMS",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "SOLES",
                      value: null,
                      labelPrint: "SOLES",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "GENITALIA",
                      value: null,
                      labelPrint: "GENITALIA",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "SCALP",
                      value: null,
                      labelPrint: "SCALP",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "GASTROINTESTINAL TRACT",
                      value: null,
                      labelPrint: "GASTROINTESTINAL TRACT",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "OCULAR",
                      value: null,
                      labelPrint: "Ocular",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "CO-MORBIDITIES (e.g. Diabetes, cardiovascular problems etc.) ",
                      value: null,
                      labelPrint: "CO-MORBIDITIES",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },
                    {
                      type: "radio",
                      items: ["Yes", "No"],
                      label: "DEPRESSION, ANXIETY AND OTHER PSYCHOLOGICAL CO-MORBIDITIES ",
                      value: null,
                      labelPrint: "DEPRESSION, ANXIETY AND OTHER PSYCHOLOGICAL CO-MORBIDITIES",
                      input: {
                        type: "text",
                        label: "",
                        value: "",
                        placeholder: "Any Comments (optional)",
                      },
                      references: true,
                    },

                    {
                      type: "textarea",
                      placeholder: "Enter text here",
                      label: "Additional Comments",
                      labelPrint: "Additional Comments",
                    },
                  ],
                },
                {
                  text: "References",
                  show: false,
                  options: [
                    {
                      type: "reference",
                      html: true,
                      static: [
                        "Beyond joints: how PsA affects the body. Arthritis Foundation [ cited 2023 May 03 ]; Available from: https://www.arthritis.org/health-wellness/about-arthritis/related-conditions/other-diseases/how-psoriatic-arthritis-affects-the-body",
                        "About psoriatic arthritis. Arthritis Foundation [cited 2023 May 03]; Available from: https://www.psoriasis.org/about-psoriatic-arthritis/",
                        "Strober B, et al. (2020) J Am Acad Dermatol 82 (1): 117-122",
                      ],

                      references: [
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        additional_information: {
          collapses: [
            {
              title: "JANSSEN PATIENT SUPPORT PROGRAM OUTLINE",
              visible: false,
              type: "outline-form-slot",
              html: true,
              header: "JANSSEN PATIENT SUPPORT PROGRAM OUTLINE",
              downloadUrl: "",
            },
            // {
            //   title: "REFERENCES",
            //   visible: false,
            //   text: "References",
            //   type: "references",
            //   references: pso_reference_json
            // }
          ],
        },
      },
      previewModal: {
        data: null,
        show: false,
        name: "Preview",
        modalName: "PREVIEW",
        footer: true,
      },
      exportModal: {
        show: false,
        type: "Word document",
        name: "Export to Word",
        modalName: "Export to Word",
        footer: true,
      },
      triggerWatch: false,
      eligiblePatientSupportingMaterial: {
        title: "SUPPORTING CLINICAL DATA (EFFICACY & SAFETY)",
        visible: false,
        type: "assessment",
        sections: [
          {
            text: "BIOLOGIC - ELIGIBLE PATIENT",
            description: " ",
            hr: true,
            options: [
              {
                type: "text",
                label: "Previous non-biologic therapy",
                placeholder: "Enter text here",
                value: "",
                labelPrint: "Previous non-biologic therapy",
              },
              {
                type: "checkboxOptions",
                labelPrint: "Reasons for adding biologic therapy to your current therapy",
                label: "Reasons for adding biologic therapy to your current therapy",
                items: ["Inadequate response to / lack of efficacy to current treatment", " Adverse event"],
                value: [],
              },
              {
                type: "text",
                label: "Other",
                placeholder: "Enter text here",
                value: "",
                labelPrint: "Other",
              },
            ],
          },
          {
            text: "<br>",
            html: true,
          },
          {
            text: "Why is Tremfya® your biologic of choice?",
            hr: true,
            randomNewField:
              "<strong>Tremfya clinical motivation:</strong> Tremfya® is a treatment option for moderate to severe plaque psoriasis in adults who are candidates for systemic therapy.<sup>4</sup><br>In Your motivation letter for Tremfya® , include any/all of the following clinical statements:",
            options: [],
          },
          {
            text: "Data to support the significant improvement in the quality of life for patients treated with TREMFYA®",
            html: true,
            hr: true,
            options: [

              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Evidence suggests that patients with psoriasis report a similar degree of health-related quality of life (HRQoL) impairment as patients with other serious chronic diseases, including cancer, diabetes and heart disease. <sup>2</sup> Patients treated with Tremfya experienced little or no impact of psoriasis on daily life. The VOYAGE 1 trial demonstrated that TREMFYA ® can provide complete skin clearance and improvements to DLQI that are sustained out to three years. <sup>3,4</sup></p>",
                label: "",
                labelPrint:
                  "<p>Evidence suggests that patients with psoriasis report a similar degree of health-related quality of life (HRQoL) impairment as patients with other serious chronic diseases, including cancer, diabetes and heart disease. <sup>2</sup> Patients treated with Tremfya experienced little or no impact of psoriasis on daily life. The VOYAGE 1 trial demonstrated that TREMFYA ® can provide complete skin clearance and improvements to DLQI that are sustained out to three years. <sup>3,4</sup></p>",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],

              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>A high proportion of patients achieve and sustain normalized HRQoL with TREFMYA®. <sup>3,4</sup> A significant improvement in signs and symptoms of disease, anxiety and depression, work productivity were observed. <sup>3,4,5,6</sup></p>",
                label: "",
                labelPrint:
                  "<p>A high proportion of patients achieve and sustain normalized HRQoL with TREFMYA®. <sup>3,4</sup> A significant improvement in signs and symptoms of disease, anxiety and depression, work productivity were observed. <sup>3,4,5,6</sup></p>",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>The Psoriasis Symptom and Sign Diary (PSSD) differs from existing patient-centric tools commonly used in psoriasis research, such as the DLQI. Unlike the DLQI, the PSSD was developed according to US FDA guidance and using direct input from patients. <sup>7</sup> Significantly more patients treated with TREMFYA® compared with adalimumab achieved a PSSD score of 0 (no impact of disease) for all symptoms and for all visible signs. <sup><sup>4</sup></p></sup>",
                label: "",
                labelPrint:
                  "<p>The Psoriasis Symptom and Sign Diary (PSSD) differs from existing patient-centric tools commonly used in psoriasis research, such as the DLQI. Unlike the DLQI, the PSSD was developed according to US FDA guidance and using direct input from patients. <sup>7</sup> Significantly more patients treated with TREMFYA® compared with adalimumab achieved a PSSD score of 0 (no impact of disease) for all symptoms and for all visible signs. <sup><sup>4</sup></p></sup>",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>TREMFYA® improves all major patient-reported symptoms and visible signs of psoriasis such as burning stinging, pain, cracking and bleeding. <sup>3,7</sup></p>",
                label: "",
                labelPrint:
                  "<p>TREMFYA® improves all major patient-reported symptoms and visible signs of psoriasis such as burning stinging, pain, cracking and bleeding. <sup>3,7</sup></p>",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Almost 60% of patients, with either depression or anxiety at baseline, achieved a HADS (Hospital Anxiety and Depression Scale) score &lt;8 (after 24 weeks of guselkumab treatment). This score indicates that patients experience little to no depression or anxiety allowing these patients to positively contribute and integrate back into society. <sup>4</sup></p>",
                label: "",
                labelPrint:
                  "<p>Almost 60% of patients, with either depression or anxiety at baseline, achieved a HADS (Hospital Anxiety and Depression Scale) score &lt;8 (after 24 weeks of guselkumab treatment). This score indicates that patients experience little to no depression or anxiety allowing these patients to positively contribute and integrate back into society. <sup>4</sup></p>",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
              },
            ],
          },
          {
            text: "<br>",
            html: true,

          },
          {
            text: "Efficacy and Safety",
            html: true,
            hr: true,
            options: [
              {
                type: "basic",
                header: "Long term efficacy data illustrated long-lasting skin clearance:",
              },
              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>In the VOYAGE 1 and VOYAGE 2 long-term extension, 84.1% &amp; 53.3% of patients treated with TREMFYA achieved PASI 90 and complete skin clearance (PASI 100) through 5 years, respectively. <sup>9</sup></p>",
                description:
                  "In the VOYAGE 1 and VOYAGE 2 long-term extension, 84.1% &amp; 53.3% of patients treated with TREMFYA achieved PASI 90 and complete skin clearance (PASI 100) through 5 years, respectively. <sup>9</sup>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                description:
                  "TREMFYA maintained improvements in clinical response as well as positive effects on HRQoL and general health through 5 years in patients with moderate to-severe psoriasis. <sup>9</sup>",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>TREMFYA maintained improvements in clinical response as well as positive effects on HRQoL and general health through 5 years in patients with moderate to-severe psoriasis. <sup>9</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint: "<p>The safety profile remained consistent and favorable during 5 years of continuous TREMFYA treatment of psoriasis. <sup>10</sup></p>",
                description: "The safety profile remained consistent and favorable during 5 years of continuous TREMFYA treatment of psoriasis. <sup>10</sup>",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>The safety profile of TREMFYA through 5 years is consistent with shorter-term treatment in patients with moderate-to-severe psoriasis in VOYAGE 1 and VOYAGE 2. TREMFYA is well tolerated through 5 years, providing an appropriate choice for safe long-term treatment for psoriasis. <sup>10</sup></p>",
                description:
                  "The safety profile of TREMFYA through 5 years is consistent with shorter-term treatment in patients with moderate-to-severe psoriasis in VOYAGE 1 and VOYAGE 2. TREMFYA is well tolerated through 5 years, providing an appropriate choice for safe long-term treatment for psoriasis. <sup>10</sup>",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                references: true,
                labelPrint: "<p>Almost 4 out of 10 patients on TREMFYA® achieved complete clearance at week 16. <sup>4</sup></p>",
                description: "Almost 4 out of 10 patients on TREMFYA® achieved complete clearance at week 16. <sup>4</sup>",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: true,
                labelPrint: "<p>TREMFYA® illustrated superior skin clearance vs secukinumab and adalimumab. <sup>3,6</sup></p>",
                description: "TREMFYA® illustrated superior skin clearance vs secukinumab and adalimumab. <sup>3,6</sup>",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],

              },

              {
                type: "basic",
                header: "Head to head efficacy data for TREMFYA® versus adalimumab:",
              },
              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [
                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Our VOYAGE 1 trial demonstrated that TREMFYA® is superior to adalimumab for all levels of skin clearance at all time points across 48 weeks. <sup>3</sup></p>",
                description:
                  "Our VOYAGE 1 trial demonstrated that TREMFYA® is superior to adalimumab for all levels of skin clearance at all time points across 48 weeks. <sup>3</sup>",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>In Voyage 1, TREMFYA® achieved a PASI 90 response in 73.3% of patients at week 16, compared with 49.7% on adalimumab (p&lt;0.001), through to week 48. <sup>3</sup></p>",
                description:
                  "In Voyage 1, TREMFYA® achieved a PASI 90 response in 73.3% of patients at week 16, compared with 49.7% on adalimumab (p&lt;0.001), through to week 48. <sup>3</sup>",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Voyage 2 illustrated that 6 out 10 patients who had shown a non-response to adalimumab achieved PASI 90 at week 48 when switched to TREMFYA®. <sup>4</sup></p>",
                description:
                  "Voyage 2 illustrated that 6 out 10 patients who had shown a non-response to adalimumab achieved PASI 90 at week 48 when switched to TREMFYA®. <sup>4</sup>",
              },

              {
                type: "basic",
                header: "Head to head efficacy data for TREMFYA® versus secukinumab:",
              },
              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>With 1,048 adult moderate-to-severe plaque psoriasis patients involved, the ECLIPSE study (a phase 3, multicentre, randomised, double-blind trial) is the largest study to compare the IL-23/IL- 17 classes. <sup>6</sup></p>",
                description:
                  "With 1,048 adult moderate-to-severe plaque psoriasis patients involved, the ECLIPSE study (a phase 3, multicentre, randomised, double-blind trial) is the largest study to compare the IL-23/IL- 17 classes. <sup>6</sup>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint: "<p>TREMFYA® demonstrated superiority over secukinumab with a higher proportion of patients achieving PASI 90 at week 48. <sup>6</sup></p>",
                description: "TREMFYA® demonstrated superiority over secukinumab with a higher proportion of patients achieving PASI 90 at week 48. <sup>6</sup>",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint: "<p>In the ECLIPSE study, TREMFYA® achieved a PASI 90 in 84.5% of patients at week 48, compared with 70% on secukinumab (p&lt;0.001). <sup>6</sup></p>",
                description: "In the ECLIPSE study, TREMFYA® achieved a PASI 90 in 84.5% of patients at week 48, compared with 70% on secukinumab (p&lt;0.001). <sup>6</sup>",
              },

              {
                type: "basic",
                header: "Head to head efficacy data for TREMFYA® versus ustekinumab:",
              },
              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Tremfya® improves skin clearance for your patients who inadequately respond to Adalimumab or Stelara® (Ustekinumab). Not only does TREMFYA® demonstrate short-term and long-term skin clearance in moderate to severe psoriasis, our VOYAGE 2 and NAVIGATE trials have demonstrated that TREMFYA® can be an effective treatment option for your patients who have inadequately responded to adalimumab or Stelara®. <sup>4,10</sup></p>",
                description:
                  "Tremfya® improves skin clearance for your patients who inadequately respond to Adalimumab or Stelara® (Ustekinumab). Not only does TREMFYA® demonstrate short-term and long-term skin clearance in moderate to severe psoriasis, our VOYAGE 2 and NAVIGATE trials have demonstrated that TREMFYA® can be an effective treatment option for your patients who have inadequately responded to adalimumab or Stelara®. <sup>4,10</sup>",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>In the NAVIGATE trial, more than 5 out of 10 patients who had shown a non-response to ustekinumab achieved PASI 90 at week 52 when switching to TREMFYA®. <sup>10</sup> </p>",
                description:
                  "In the NAVIGATE trial, more than 5 out of 10 patients who had shown a non-response to ustekinumab achieved PASI 90 at week 52 when switching to TREMFYA®. <sup>10</sup> ",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>TREMFYA® has a convenient dosing regimen of a single, 100mg maintenance dose once every 8 weeks after only 2 induction doses (at weeks 0 and 4). <sup>1</sup></p>",
                description:
                  "TREMFYA® has a convenient dosing regimen of a single, 100mg maintenance dose once every 8 weeks after only 2 induction doses (at weeks 0 and 4). <sup>1</sup>",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>TREMFYA® has a generally well-tolerated safety profile, and has no specific class effect warnings for candidiasis, Crohn’s disease, demyelinating diseases, or congestive heart failure. <sup>1,3,4,6</sup></p>",
                description:
                  "TREMFYA® has a generally well-tolerated safety profile, and has no specific class effect warnings for candidiasis, Crohn’s disease, demyelinating diseases, or congestive heart failure. <sup>1,3,4,6</sup>",
              },

              {
                type: "basic",
                html: true,
                value: false,
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>In Voyage 1 there were no disproportionate increases in rates adverse events between week 48 and 100. 6 In both Voyage 1 and 2, the number of adverse events of special interest (AEoSI) with Tremfya® were generally comparable to Adalimumab up to week 48. <sup>3,6</sup></p>",
                description:
                  "In Voyage 1 there were no disproportionate increases in rates adverse events between week 48 and 100. 6 In both Voyage 1 and 2, the number of adverse events of special interest (AEoSI) with Tremfya® were generally comparable to Adalimumab up to week 48. <sup>3,6</sup>",
              },
            ],
          },
          {
            text: "<br>",
            html: true,

          },
          {
            text: "Bionaive Data",
            hr: true,
            html: true,
            options: [
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>A high proportion (~80%) of 3867 patients enrolled in guselkumab Phase III PsO clinical trials were bionaïve. All had moderate to severe PsO, a high burden of disease and PsO that was prevalent in sensitive and difficult-to-treat regions. <sup>3,4 </sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>A high proportion (~80%) of 3867 patients enrolled in guselkumab Phase III PsO clinical trials were bionaïve. All had moderate to severe PsO, a high burden of disease and PsO that was prevalent in sensitive and difficult-to-treat regions. <sup>3,4</sup></p>",

              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>A pooled analysis of bionaive patients in VOYAGE 1 and VOYAGE 2 demonstrated that 7 out of 10 patients achieved a PASI 90 response after just three injections of TREMFYA®.<sup>3,4</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>A pooled analysis of bionaive patients in VOYAGE 1 and VOYAGE 2 demonstrated that 7 out of 10 patients achieved a PASI 90 response after just three injections of TREMFYA®.<sup>3,4</sup></p>",
              },
              //               {
              //                 type: "reference",
              //                 html: true,
              //                 data: `<p>Reference</p>
              //                   <ol>
              // <li>TREMFYA®. Approved package insert, May 2020</li>
              // <li>Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation.</li>
              // <li>Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417.</li>
              // <li>Reich K et al. J Am Acad Dermatol 2017;76(3):418-431.</li>
              // <li>Gordon K et al, EADV 2017, P1813</li>
              // <li>Reich K, et al. Lancet 2019;394:831-39</li>
              // <li>Feldman SR et al. 2016;20:19-26</li>
              // <li>Reich et al. 2021</li>
              // <li>Blauvelt et al. 2021</li>
              // <li> Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print].</li>
              // <li> Agnieszka et al. 2020 Jan 17;21(2):625.</li>
              // <li> Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120</li>
              // <li> Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1</li>
              // <li> Benezeder 2019</li>
              // <li> Mehta et al. 2021</li>
              // <li> Schäkel et al. 2021</li>
              // <li> Schäkel et al. 2022</li>
              // <li> Skov et al. 2019</li>
              // <li> Girolomoni et al. 2015</li>
              // <li> Scher et al. 2019</li>
              // <li> Kovitwanichkanont et al. 2020</li>
              // <li> Takeshita J, et al. J Am Acad Dermatol 2017</li>
              //                     </ol>
              //                 `,
              //               },
            ],
          },
          {
            text: "<br>",
            html: true,

          },
          {
            text: "Super-responders / GUIDE study",
            hr: true,
            html: true,
            options: [
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>The natural course of psoriasis is the appearance of new lesions in the place of previous ones, which disappeared after a successful therapy. Recent studies of psoriasis etiopathogenesis showed that after psoriatic plaques have disappeared, in healthy skin we can still find a trace of inflammation in the form of tissue resident memory cells (TRM). <sup>11</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>The natural course of psoriasis is the appearance of new lesions in the place of previous ones, which disappeared after a successful therapy. Recent studies of psoriasis etiopathogenesis showed that after psoriatic plaques have disappeared, in healthy skin we can still find a trace of inflammation in the form of tissue resident memory cells (TRM). <sup>11</sup></p>",

              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>In psoriasis, a distinct population of TRMs express the interleukin 23 (IL-23) receptor and are highly enriched in resolved lesions, suggesting that the IL-23 cytokine appears to be involved in the differentiation and survival of pathogenic TRMs, 12 (Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120) which are thought to be responsible for recurrence of psoriatic skin lesions in previously affected sites. <sup>13</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>In psoriasis, a distinct population of TRMs express the interleukin 23 (IL-23) receptor and are highly enriched in resolved lesions, suggesting that the IL-23 cytokine appears to be involved in the differentiation and survival of pathogenic TRMs, 12 (Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120) which are thought to be responsible for recurrence of psoriatic skin lesions in previously affected sites. <sup>13</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>An altered ratio of regulatory T cells (Treg) and tissue resident memory (TRM) cells could be the cause of recurrent skin lesions. 14 (Benezeder 2019). TREMFYA, but not secukinumab, demonstrated a reduction in the frequency of inflammatory CD8+ TRM cells, while maintaining levels of Treg cells. <sup>15</sup> </p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ], labelPrint:
                  "<p>An altered ratio of regulatory T cells (Treg) and tissue resident memory (TRM) cells could be the cause of recurrent skin lesions. 14 (Benezeder 2019). TREMFYA, but not secukinumab, demonstrated a reduction in the frequency of inflammatory CD8+ TRM cells, while maintaining levels of Treg cells. <sup>15</sup> </p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>When treated early with TREMFYA, more patients achieved complete skin clearance vs. patients starting later. 43.7% of patients who started TREMFYA early became super-responders, achieving complete skin clearance at Week 20 and Week 28, vs. patients starting later. Super-responders are defined as patients with an absolute PASI score of 0, at both Week 20 and Week 28 after the start of treatment. <sup>16,17</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>When treated early with TREMFYA, more patients achieved complete skin clearance vs. patients starting later. 43.7% of patients who started TREMFYA early became super-responders, achieving complete skin clearance at Week 20 and Week 28, vs. patients starting later. Super-responders are defined as patients with an absolute PASI score of 0, at both Week 20 and Week 28 after the start of treatment. <sup>16,17</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>A significantly greater proportion of short disease duration (SDD; psoriasis symptoms ≤2 years) versus long disease duration (LDD; psoriasis symptoms &gt;2 years) patients achieved a PASI 90 response (P=0.0189) and absolute PASI=0 (P&lt;0.001) at Week 28, and they achieved PASI=0 59 days quicker (median 141 vs 200 days, P&lt;0.001, respectively). The GUIDE W28 data demonstrated that patients with a SDD were more likely than patients with a LDD to be a super- responder, and achieved complete skin clearance faster and at a higher rate following TREMFYA treatment. <sup>16</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>A significantly greater proportion of short disease duration (SDD; psoriasis symptoms ≤2 years) versus long disease duration (LDD; psoriasis symptoms &gt;2 years) patients achieved a PASI 90 response (P=0.0189) and absolute PASI=0 (P&lt;0.001) at Week 28, and they achieved PASI=0 59 days quicker (median 141 vs 200 days, P&lt;0.001, respectively). The GUIDE W28 data demonstrated that patients with a SDD were more likely than patients with a LDD to be a super- responder, and achieved complete skin clearance faster and at a higher rate following TREMFYA treatment. <sup>16</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>TREMFYA® treatment resulted in significant reduction of different serum biomarkers (BD2, IL-17A, IL-17F) as early as Week 4 in all patient groups (combinations of short disease duration vs. long disease duration and super responders vs. non- super responders), indicating that TREMFYA® leads to a rapid decrease in systemic inflammation. <sup>16</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>TREMFYA® treatment resulted in significant reduction of different serum biomarkers (BD2, IL-17A, IL-17F) as early as Week 4 in all patient groups (combinations of short disease duration vs. long disease duration and super responders vs. non- super responders), indicating that TREMFYA® leads to a rapid decrease in systemic inflammation. <sup>16</sup></p>",
              },
            ],
          },
          {
            text: "<br>",
            html: true,

          },
          {
            text: "Comorbidities",
            hr: true,
            html: true,
            options: [
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Psoriatic plaques are usually the first manifestation of psoriatic disease which can progress into comorbidities such as psoriatic arthritis and other debilitating consequences. The chronic inflammation caused by the psoriasis brings an increased risk of comorbidities and early mortality compared with the general population. Intervention with biologics, as early as appropriate, could be key to improve control of symptoms and modify disease course and burden of PsO. <sup>18,19,20,21</sup>.</p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Psoriatic plaques are usually the first manifestation of psoriatic disease which can progress into comorbidities such as psoriatic arthritis and other debilitating consequences. The chronic inflammation caused by the psoriasis brings an increased risk of comorbidities and early mortality compared with the general population. Intervention with biologics, as early as appropriate, could be key to improve control of symptoms and modify disease course and burden of PsO. <sup>18,19,20,21</sup>.</p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description: "<p>Psoriasis is associated with increased risk of serious comorbidities, including <sup>22</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint: "<p>Psoriasis is associated with increased risk of serious comorbidities, including <sup>22</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Cardiometabolic disease: Cardiometabolic disease is prevalent among patients with psoriasis, especially those with more severe skin disease. Psoriasis may be an independent risk factor for diabetes and major adverse cardiovascular events; the risk of a major adverse cardiovascular event is greatest among those with severe psoriasis. Chronic systemic, specifically vascular, inflammation may be increased in patients with psoriasis and may contribute to atherogenesis. <sup>22</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Cardiometabolic disease: Cardiometabolic disease is prevalent among patients with psoriasis, especially those with more severe skin disease. Psoriasis may be an independent risk factor for diabetes and major adverse cardiovascular events; the risk of a major adverse cardiovascular event is greatest among those with severe psoriasis. Chronic systemic, specifically vascular, inflammation may be increased in patients with psoriasis and may contribute to atherogenesis. <sup>22</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Gastrointestinal disease: Psoriasis may be associated with an increased incidence and prevalence of inflammatory bowel disease, particularly Crohn’s disease. Few studies suggest that psoriasis is associated with an increased prevalence of hepatic diseases, particularly nonalcoholic fatty liver disease. <sup>22</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Gastrointestinal disease: Psoriasis may be associated with an increased incidence and prevalence of inflammatory bowel disease, particularly Crohn’s disease. Few studies suggest that psoriasis is associated with an increased prevalence of hepatic diseases, particularly nonalcoholic fatty liver disease. <sup>22</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Chronic kidney disease: Moderate to severe psoriasis may be an independent risk factor for chronic kidney disease and end-stage renal disease. The odds of chronic kidney disease increase in a dose- dependent manner with greater psoriasis severity. <sup>22</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Chronic kidney disease: Moderate to severe psoriasis may be an independent risk factor for chronic kidney disease and end-stage renal disease. The odds of chronic kidney disease increase in a dose- dependent manner with greater psoriasis severity. <sup>22</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Malignancy: Psoriasis, particularly severe disease, may be associated with an increased risk of cancer. Lymphoma has been most consistently associated with psoriasis, and risk of cutaneous T- cell lymphoma is suggested to be the highest. <sup>22</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Malignancy: Psoriasis, particularly severe disease, may be associated with an increased risk of cancer. Lymphoma has been most consistently associated with psoriasis, and risk of cutaneous T- cell lymphoma is suggested to be the highest. <sup>22</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Infection: Streptococcal pharyngitis is a trigger of guttate psoriasis, and exacerbation of psoriasis in the setting of HIV infection is known. Psoriasis may be associated with an increased risk of serious infection (ie, infection requiring hospitalization), especially respiratory infections. <sup>22</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Infection: Streptococcal pharyngitis is a trigger of guttate psoriasis, and exacerbation of psoriasis in the setting of HIV infection is known. Psoriasis may be associated with an increased risk of serious infection (ie, infection requiring hospitalization), especially respiratory infections. <sup>22</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Mood disorders: Mood disorders are common among patients with psoriasis. Psoriasis is associated with an increased risk of depression, anxiety, and suicidal ideation. <sup>22</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Mood disorders: Mood disorders are common among patients with psoriasis. Psoriasis is associated with an increased risk of depression, anxiety, and suicidal ideation. <sup>22</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Psoriatic arthritis: Psoriatic arthritis is an inflammatory arthritis that is present in 6% to 42% of patients with psoriasis. Psoriatic arthritis is more prevalent among patients with more extensive skin disease. Approximately 15% of patients with psoriasis have undiagnosed psoriatic arthritis. <sup>22</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Psoriatic arthritis: Psoriatic arthritis is an inflammatory arthritis that is present in 6% to 42% of patients with psoriasis. Psoriatic arthritis is more prevalent among patients with more extensive skin disease. Approximately 15% of patients with psoriasis have undiagnosed psoriatic arthritis. <sup>22</sup></p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Emerging comorbidities: Other emerging comorbidities of psoriasis include chronic obstructive pulmonary disease, peptic ulcer disease, sexual dysfunction, and obstructive sleep apnea. <sup>22</sup></p>",
                label: "",
                references: [

                  { id: 1, text: "1. TREMFYA®. Approved package insert, May 2022" },
                  { id: 2, text: "2. Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation." },
                  { id: 3, text: "3. Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417." },
                  { id: 4, text: "4. Reich K et al. J Am Acad Dermatol 2017;76(3):418-431." },
                  { id: 5, text: "5. Gordon K et al, EADV 2017, P1813" },
                  { id: 6, text: "6. Reich K, et al. Lancet 2019;394:831-39" },
                  { id: 7, text: "7. Feldman SR et al. 2016;20:19-26" },
                  { id: 8, text: "8. Reich et al. 2021" },
                  { id: 9, text: "9. Blauvelt et al. 2021" },
                  { id: 10, text: "10. Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print]." },
                  { id: 11, text: "11. Agnieszka et al. 2020 Jan 17;21(2):625." },
                  { id: 12, text: "12. Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120" },
                  { id: 13, text: "13. Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1" },
                  { id: 14, text: "14. Benezeder 2019" },
                  { id: 15, text: "15. Mehta et al. 2021" },
                  { id: 16, text: "16. Schäkel et al. 2021" },
                  { id: 17, text: "17. Schäkel et al. 2022" },
                  { id: 18, text: "18. Skov et al. 2019" },
                  { id: 19, text: "19. Girolomoni et al. 2015" },
                  { id: 20, text: "20. Scher et al. 2019" },
                  { id: 21, text: "21. Kovitwanichkanont et al. 2020" },
                  { id: 22, text: "22. Takeshita J, et al. J Am Acad Dermatol 2017" },
                ],
                labelPrint:
                  "<p>Emerging comorbidities: Other emerging comorbidities of psoriasis include chronic obstructive pulmonary disease, peptic ulcer disease, sexual dysfunction, and obstructive sleep apnea. <sup>22</sup></p>",
              },
            ],
          },
          {
            text: "References",
            options: [
              {
                type: "reference",
                static: [
                  "TREMFYA®. Approved package insert, May 2022",
                  "Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation.",
                  "Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417.",
                  "Reich K et al. J Am Acad Dermatol 2017;76(3):418-431.",
                  "Gordon K et al, EADV 2017, P1813",
                  "Reich K, et al. Lancet 2019;394:831-39",
                  "Feldman SR et al. 2016;20:19-26",
                  "Reich et al. 2021",
                  "Blauvelt et al. 2021",
                  "Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print].",
                  "Agnieszka et al. 2020 Jan 17;21(2):625.",
                  "Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120",
                  "Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1",
                  "Benezeder 2019",
                  "Mehta et al. 2021",
                  "Schäkel et al. 2021",
                  "Schäkel et al. 2022",
                  "Skov et al. 2019",
                  "Girolomoni et al. 2015",
                  "Scher et al. 2019",
                  "Kovitwanichkanont et al. 2020",
                  "Takeshita J, et al. J Am Acad Dermatol 2017",
                ],
                references: [
                  "TREMFYA®. Approved package insert, May 2022",
                  "Van Voorhees AS, et al. Treatment algorithms and management options, 5 th edition. National Psoriasis Foundation.",
                  "Blauvelt A et al. J Am Aced Dermatol 2017;76(3):405-417.",
                  "Reich K et al. J Am Acad Dermatol 2017;76(3):418-431.",
                  "Gordon K et al, EADV 2017, P1813",
                  "Reich K, et al. Lancet 2019;394:831-39",
                  "Feldman SR et al. 2016;20:19-26",
                  "Reich et al. 2021",
                  "Blauvelt et al. 2021",
                  "Langley R et al. Br J Dermatol 2017 Jun 21 [Epub ahead of print].",
                  "Agnieszka et al. 2020 Jan 17;21(2):625.",
                  "Cheuk et al. J Immunol. 2014 Apr 1; 192(7): 3111–3120",
                  "Clark et al. Sci Transl Med. 2015 Jan 7;7(269):269rv1",
                  "Benezeder 2019",
                  "Mehta et al. 2021",
                  "Schäkel et al. 2021",
                  "Schäkel et al. 2022",
                  "Skov et al. 2019",
                  "Girolomoni et al. 2015",
                  "Scher et al. 2019",
                  "Kovitwanichkanont et al. 2020",
                  "Takeshita J, et al. J Am Acad Dermatol 2017",
                ],
              },
            ],
          },

        ],
      },
      remotivatingPatientSupportingMaterial: {
        title: "BIOLOGIC - RE-MOTIVATING",
        visible: false,
        type: "assessment",
        sections: [
          {
            text: "BIOLOGIC RE-MOTIVATING",

            randomNewField:
              "TREMFYA has a robust clinical data set to support its use in moderate-to-severe PsO. The clinical data illustrates consistent clinical efficacy in all Phase III clinical trials in comparison to other biologics, whilst also showing an acceptable safety profile. <sup>1-6</sup>",
            options: [
              {
                type: "checkboxOptions",
                labelPrint: "Efficacy",
                label: "Efficacy",
                html: true,
                references: true,
                items: [
                  "<br>In the VOYAGE 1 and VOYAGE 2 long-term extension, 84.1% &amp; 53.3% of patients treated with TREMFYA achieved PASI 90 and complete skin clearance (PASI 100) through 5 years, respectively.",
                  "<br>TREMFYA maintained improvements in clinical response as well as positive effects on HRQoL and general health through 5 years in patients with moderate to-severe psoriasis.",
                ],
                value: [],
              },
              {
                type: "checkboxOptions",
                labelPrint: "Safety",
                label: "Safety",
                html: true,
                items: [
                  "<br>The safety profile remained consistent and favorable during 5 years of continuous TREMFYA treatment of psoriasis.",
                  "<br>The safety profile of TREMFYA through 5 years is consistent with shorter-term treatment in patients with moderate-to-severe psoriasis in VOYAGE 1 and VOYAGE 2. TREMFYA is well tolerated through 5 years, providing an appropriate choice for safe long-term treatment for psoriasis.",
                ],
                value: [],
                references: [],
              },
            ],
          },
          {
            text: "References",
            show: false,
            options: [
              {
                type: "reference",
                html: true,
                static: [
                  "Blauvelt A et al.J Am Aced Dermatol 2017; 76(3): 405 - 417.",
                  "Reich K et al.J Am Acad Dermatol 2017; 76(3): 418 - 431.",
                  "Reich K, et al.Lancet 2019; 394: 831 - 39",
                  "Reich et al. 2021",
                  "Blauvelt et al. 2021",
                  "Langley R et al.Br J Dermatol 2017 Jun 21[Epub ahead of print].",

                ],

                references: [
                  { text: "1. Blauvelt A et al.J Am Aced Dermatol 2017; 76(3): 405 - 417." },
                  { text: "2. Reich K et al.J Am Acad Dermatol 2017; 76(3): 418 - 431." },
                  { text: "3. Reich K, et al.Lancet 2019; 394: 831 - 39" },
                  { text: "4. Reich et al. 2021" },
                  { text: "5. Blauvelt et al. 2021" },
                  { text: "6. Langley R et al.Br J Dermatol 2017 Jun 21[Epub ahead of print]." },
                ],
              },
            ],
          },
        ],
      },
      appealPatientSupportingMaterial: {
        title: "LEGISLATIVE WORDING",
        visible: false,
        type: "assessment",
        sections: [
          {
            text: "The Constitution of the Republic of South Africa, 1996 (the Constitution) is the supreme (highest) law of the land.",
            html: true,
            options: [
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Section 27 of the constitution guarantees access to health care as a right, and when you limit this right as the medical scheme is now doing for my patient, it must as per Section 36 be; reasonable, justifiable, with the least possible intrusion.</p>",
                label: "",
                labelPrint:
                  "<p>Section 27 of the constitution guarantees access to health care as a right, and when you limit this right as the medical scheme is now doing for my patient, it must as per Section 36 be; reasonable, justifiable, with the least possible intrusion.</p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p> The fact that this patient has tried numerous treatment options with continued worsening of the disease and is unable to go continue with daily life to go out in public for social and even employment interactions certainly means that section 36 is not being complied with.</p>",
                label: "",
                labelPrint:
                  "<p> The fact that this patient has tried numerous treatment options with continued worsening of the disease and is unable to go continue with daily life to go out in public for social and even employment interactions certainly means that section 36 is not being complied with.</p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>Finally Section 10 of the Constitution guarantees our right to human dignity.By denying this patient access to appropriate care, the patient is unable to carry out their required employment activities or even those for daily life. </p>",
                label: "",
                labelPrint:
                  "<p>Finally Section 10 of the Constitution guarantees our right to human dignity.By denying this patient access to appropriate care, the patient is unable to carry out their required employment activities or even those for daily life. </p>",
              },
              {
                type: "basic",
                html: true,
                value: false,
                description:
                  "<p>By limiting my patient’s access to healthcare as the medical scheme you are infringing on the very basic rights as guaranteed to all South Africans.</p>",
                label: "",
                labelPrint:
                  "<p>By limiting my patient’s access to healthcare as the medical scheme you are infringing on the very basic rights as guaranteed to all South Africans.</p>",
              },
            ],
          },
        ],
      },
      trialOutputTest: [],
    };
  },
  methods: {
    prependPatientSupportingMaterials(event) {
      if (this.triggerWatch) {
        this.triggerWatch = false;
      } else {
        let collapseOption = {};
        // if(this.form.type_of_patient != null){ this.form.clinical_trial.collapses.pop()}
        // let body = this.form.clinical_trial.collapses;
        if (this.trialOutputTest.length == 0) {
          this.trialOutputTest = this.form.clinical_trial.collapses;
        }
        switch (event) {
          case "Biologic - Eligible Patient":
            this.form.clinical_trial.collapses = [...this.trialOutputTest, this.eligiblePatientSupportingMaterial, this.appealPatientSupportingMaterial];
            break;
          case "Biologic - Rem-motivating for Tremfya":
            this.form.clinical_trial.collapses = [...this.trialOutputTest, this.remotivatingPatientSupportingMaterial, this.appealPatientSupportingMaterial];
            break;
          case "Appeal of medical scheme rejection":
            this.form.clinical_trial.collapses = [this.appealPatientSupportingMaterial];
            break;

          default:
            this.form.clinical_trial.collapses = this.trialOutputTest;
            break;
        }
      }
    },
    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepPsa = this.prepPsa(this.form);
        let processed = this.flatData(prepPsa, tremfyaJson);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.validateErrors.motivation = check.data.motivation;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    save() {
      this.setLocal("tremfya", this.form);
    },

    async exportPDF(reaction) {
      let prepPsa = this.prepPsa(this.form);
      let processed = this.flatData(prepPsa, tremfyaJson);
      let response = await this.exportPDFUrl(processed, this.form.patient_detail_full_name);

      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    async exportWord(reaction) {
      let prepPsa = this.prepPsa(this.form);
      let processed = this.flatData(prepPsa, tremfyaJson);
      let response = await this.exportWordUrl(processed, this.form.patient_detail_full_name);
      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    prepPsa(form) {
      let checkbox;
      let returnObject = {
        hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
        date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
        patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
        date_of_birth: form.date_of_birth ? " " + moment(form.date_of_birth).format("DD/MM/YYYY") : "",
        medical_aid_name: form.medical_aid_name ? form.medical_aid_name : "",
        type_of_patient: form.type_of_patient ?? "",
        plan_option: form.plan_option ? form.plan_option : "",
        medical_aid_membership_number: form.medical_aid_membership_number ? form.medical_aid_membership_number : "",
        idc_10_code: form.idc_10_code ? form.idc_10_code : "",
      };
      console.log(returnObject);
      if (form.motivation) {
        returnObject["motivation_header"] = "MOTIVATION";
        returnObject["motivation"] = form.motivation ? form.motivation : "";
      }
      checkbox = this.getCheckbox(form.clinical_trial);
      //(checkbox)
      returnObject["clinical_trial"] = checkbox;

      return returnObject;
    },
    reset() {
      this.resetStorage("tremfya");
    },
  },
  created() {
    let storage = this.getLocal("tremfya");
    if (storage) {
      this.triggerWatch = true;
      this.form = storage;
      // } else {
      //   let data = this.psoDermData();
      //   this.form.clinical_trial.collapses = [...data];
    }
  },
};
</script>
