<template>
  <b-container class="quiz-body">
    <b-row>
      <b-col>
        <basicHeader>
          <template v-slot:icon>
            <img src="https://motivateforms.co.za/public
/img/frontend_assets/patient.png" alt srcset />
          </template>
          <template v-slot:title>TO BE COMPLETED BY THE PATIENT</template>
          <template v-slot:header>
            DERMATOLOGY LIFE QUALITY INDEX (DLQI)
            <sup>1,2</sup>
          </template>
          <template v-slot:content>
            The aim of this questionnaire is to measure how much your SKIN problem has affected your life
            <strong>OVER THE LAST WEEK. Please read each question and circle the box you feel is the most appropriate to describe how severe your condition has been.</strong>
          </template>
        </basicHeader>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <RadioQuiz :quizDetails="quiz"></RadioQuiz>
      </b-col>
    </b-row>
    <b-row class="pt-3 pb-3">
      <b-col md="5" sm="4"></b-col>
      <b-col>
        <b-container fluid>
          <b-row>
            <b-col md="6" sm="6">{{checkCompleted}} of {{quiz.length}} questions completed</b-col>
            <b-col md="3" sm="3">
              <b-button class="btn-style btn-reset-quiz" pill @click="reset()">RESET</b-button>
            </b-col>
            <b-col md="3" sm="3">
              <b-button
                class="primary-button btn-style"
                pill
                @click="complete()"
                :disabled="checkCompleted!=quiz.length"
              >SUBMIT</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          header="FOR CLINICIAN USE ONLY"
          header-tag="header"
          :header-class="['header-color-card',{'header-color-card-aval':avaliable}]"
          :body-class="['card-style-quiz',{'body-card-aval':avaliable}]"
        >
          <template v-slot:header>
            <b-container class="h-100">
              <b-row class="h-100">
                <b-col
                  md="6"
                  sm="12"
                  cols="12"
                  :class="['my-auto','textActive',!avaliable?'textColor':'text-view']"
                >
                  <h5 class="mb-0">FOR CLINICIAN USE ONLY</h5>
                </b-col>
                <b-col
                  md="4"
                  sm="12"
                  cols="12"
                  :class="['text-right-works','text-white','my-auto','textActive',{'textColor':!avaliable}]"
                >
                  <h5 class="mb-0 second-text-size">DLQI TOTAL SCORE (MAX 30)</h5>
                </b-col>
                <b-col md="2" sm="2">
                  <div class="total">{{total}}</div>
                </b-col>
              </b-row>
            </b-container>
          </template>
          <b-card-text class="card-background">
            <b-container :class="['quiz-title','parent-color',{'changed-acitve-color':!avaliable}]">
              <b-row>
                <b-col>
                  <h5 class="text-left pl-3 text-size-change">FOR EACH BOX TICKED ASSIGN A SCORE</h5>
                  <b-container>
                    <b-row>
                      <b-col>
                        <table>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td class="pr-4">VERY MUCH</td>
                            <td class="text-right">3</td>
                          </tr>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>A LOT</td>
                            <td class="text-right">2</td>
                          </tr>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>A LITTLE</td>
                            <td class="text-right">1</td>
                          </tr>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>NOT AT ALL</td>
                            <td class="text-right">0</td>
                          </tr>
                        </table>
                      </b-col>
                      <b-col>
                        <table>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td class="pr-4">NOT RELEVANT</td>
                            <td class="text-right">0</td>
                          </tr>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>YES</td>
                            <td class="text-right">3</td>
                          </tr>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>NO</td>
                            <td class="text-right">0</td>
                          </tr>
                        </table>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <b-col>
                  <h5
                    class="text-size-change"
                  >USING THE DLQI TOTAL SCORE, ASSESS THE EFFECT ON QUALITY OF LIFE</h5>
                  <b-container class="text-left">
                    <b-row>
                      <b-col>
                        <table>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>0 - 1 = NO EFFECT</td>
                          </tr>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>2 - 5 = SMALL EFFECT</td>
                          </tr>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>6 - 10 = MODERATE EFFECT</td>
                          </tr>
                        </table>
                      </b-col>
                      <b-col>
                        <table class="table-change">
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>11 - 20 = LARGE EFFECT</td>
                          </tr>
                          <tr :class="!avaliable?'label-card':'label-null'">
                            <td>21 - 30 = EXTREMELY LARGE EFFECT</td>
                          </tr>
                        </table>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="pb-2">
      <b-col md="7" sm="6"></b-col>
      <b-col md="3" sm="3">
        <Input
          type="text"
          name="patient_detail_full_name"
          :binding="patients_name"
          placeholder="Enter Patient full name"
          classes="white-background-input"
          @changeValue="patients_name=$event;"
        ></Input>
      </b-col>
      <b-col md="2" sm="3">
        <b-button
          class="float-right primary-button"
          :disabled="avaliable"
          pill
          @click="download()"
        >DOWNLOAD</b-button>
      </b-col>
    </b-row>
    <b-row class="references-section">
      <b-col>
        <b-container>
          <b-row>
            <b-col>
              <p>REFERENCES: 1. Finlay AY, Khan GK. Dermatology life quality index (DLQI) - a simple practical measure for routine clinical use. Clin Exp Derm 1994;19:210-216. 2. Dermatology Life Quality Index. British Association of Dermatology. [cited 2020 Apr 08]; Available from: https://www.bad.org.uk/shared/get-file.ashx?id=1653&itemtype=document.</p>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col>
              <p>S4 STELARAB 45 mg/90 mg SOLUTION FOR INJECTION Reg No.43/30.1/0727-0728. STELARAS contains 45 mg of ustekinumab in 0,5 ml and STELARAS contains 90 mg of Ustekinumab in 1,0 mL. Further information available on request from Market Authorisation Holder. JANSSEN PHARMACEUTICA (PTY) LTD/(EDMS) BPK: (REG. NO./REGNR. 1980/011122/07), No. 2, Medical Road, Halfway House, Midrand 1685. www.janssen.com, Medical Info Line: 0860 11 11 17. For full prescribing infor Cert No. PHZA/STE/0618/0002.</p>
            </b-col>
          </b-row> -->
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import RadioQuiz from "@/components/Quiz/RadioQuiz.vue";
import basicHeader from "@/components/Quiz/BasicHeader.vue";
import basicFooter from "@/components/Quiz/BasicFooter.vue";
import Input from "@/components/Fields/Input";
import moment from "moment";
import axios from "axios";
export default {
  components: {
    Input,
    RadioQuiz,
    basicHeader,
    basicFooter
  },
  computed: {
    checkCompleted() {
      let arraySize = this.quiz.filter(val => {
        return val.value != null;
      });
      return arraySize.length;
    }
  },
  methods: {
    async download() {
      try {
        let questions = this.quiz.map(question => {
          let tempObject = {
            id: question.id,
            question: question.question,
            trueOrFalse: question.trueOrFalse,
            options: [],
            dlqi_score: question.actualValue
          };

          let optionReturn = [];
          if (question.trueOrFalse) {
            if (question.value == 6) {
              optionReturn.push({
                selected: true,
                status: true,
                value: 6
              });
            } else {
              optionReturn.push({
                selected: false,
                status: true,
                value: 6
              });
            }
            if (question.value == 5) {
              optionReturn.push({
                selected: true,
                status: true,
                value: 5
              });
            } else {
              optionReturn.push({
                selected: false,
                status: true,
                value: 5
              });
            }
          }
          let options = question.options.map(option => {
            let obj = {
              selected: question.value == option.value,
              status: option.status,
              value: option.value
            };

            return obj;
          });
          tempObject.options = [...optionReturn, ...options];

          return tempObject;
        });
        //('hello')
        let headSection = [];
        if (this.patients_name) {
          headSection.push({
            classes: "",
            element: "p",
            text: "Patient name: " + this.patients_name
          });
        }
        headSection.push({
          classes: "",
          element: "p",
          text: "Date: " + moment().format("DD/MM/YYYY")
        });
        let dataRequest = {
          data: {
            headSection: headSection,
            questions: questions,
            score: {
              text: "DLQI TOTAL SCORE (MAX 30)",
              score: this.total,
              is_total: true
            }
          },
          document_name: "DLQI-form-"+(this.patients_name?this.patients_name:'unknown')
        };
        //(dataRequest);
        let response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/quiz-report/dlqi`,
          dataRequest
        );
        if (response) {
          window.open(response.data.data.url, "_blank");
        }
      } catch(Exception) {
        console.error(Exception)
      }
    },
    reset() {
      let quizTemp = this.quiz.map(val => {
        val.value = null;
        val.actualValue = null;
        return val;
      });
      this.quiz = quizTemp;
      this.avaliable = true;
      this.total = null;
    },
    complete() {
      let total = 0;
      this.quiz.forEach(val => {
        total = val.actualValue + total;
      });
      this.avaliable = false;
      this.total = total;
      //(total)
      this.$emit("response",total)
    }
  },

  data() {
    return {
      patients_name: "",
      avaliable: true,
      total: null,
      quiz: [
        {
          id: 1,
          question:
            "Over the last week, how <strong>itchy, sore, painful or stinging</strong> has your skin been?",
          trueOrFalse: false,
          options: [
            { status: true, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: false, selected: false, value: 0, actualValue: 0 }
          ],
          value: null,
          actualValue: null
        },
        {
          id: 2,
          question:
            "Over the last week, how <strong>embarrassed or self conscious</strong> have you been about your skin?",

          value: null,
          actualValue: null,
          trueOrFalse: false,
          options: [
            { status: true, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: false, selected: false, value: 0, actualValue: 0 }
          ]
        },
        {
          id: 3,
          trueOrFalse: false,
          question:
            "Over the last week, how much has your skin interfered with you going <strong>shopping</strong> or looking after your home or garden?",
          options: [
            { status: true, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: false, selected: false, value: 0, actualValue: 0 }
          ],
          value: null
        },
        {
          id: 4,
          trueOrFalse: false,
          question:
            "Over the last week, how much has your skin influenced the <strong>clothes</strong> you wear?",

          value: null,
          actualValue: null,
          options: [
            { status: true, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: true, selected: false, value: 0, actualValue: 0 }
          ]
        },
        {
          id: 5,
          trueOrFalse: false,
          question:
            "Over the last week, how much has your skin affected any <strong>social</strong> or <strong>leisure activities</strong>?",

          value: null,
          actualValue: null,
          options: [
            { status: true, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: true, selected: false, value: 0, actualValue: 0 }
          ]
        },
        {
          id: 6,
          trueOrFalse: false,
          question:
            "Over the last week, how much has your skin made it difficult for you to do any <strong>sport</strong>?",
          options: [
            { status: true, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: true, selected: false, value: 0, actualValue: 0 }
          ],
          value: null,
          actualValue: null
        },
        {
          id: 7,
          trueOrFalse: true,
          question:
            "Over the last week, has your skin prevented you from <strong>working</strong> or <strong>studying</strong>?",
          options: [
            { status: false, selected: false, value: 4 },
            { status: false, selected: false, value: 3 },
            { status: false, selected: false, value: 2 },
            { status: false, selected: false, value: 1 },
            { status: true, selected: false, value: 0, actualValue: 0 }
          ],
          value: null,
          actualValue: null
        },
        {
          id: null,
          condition: true,
          trueOrFalse: false,
          question:
            "If 'No', over the last week how much has your skin been a problem at <strong>work</strong> or <strong>studying</strong>?",
          options: [
            { status: false, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: true, selected: false, value: 0, actualValue: 0 }
          ],
          value: null,
          actualValue: null
        },
        {
          id: 8,
          trueOrFalse: false,
          question:
            "Over the last week, how much has your skin created problems with your <strong>partner</strong> or any of your <strong>close friends</strong> or <strong>relatives</strong>?",
          options: [
            { status: true, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: false, selected: false, value: 0, actualValue: 0 }
          ],
          value: null,
          actualValue: null
        },
        {
          id: 9,
          trueOrFalse: false,
          question:
            "Over the last week, how much has your skin caused any <strong>sexual difficulties</strong>?",
          options: [
            { status: true, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: true, selected: false, value: 0, actualValue: 0 }
          ],
          value: null,
          actualValue: null
        },
        {
          id: 10,
          trueOrFalse: false,
          question:
            "Over the last week, how much of a problem has the <strong>treatment</strong> for your skin been, for example, by making your home messy, or by taking up time?",
          options: [
            { status: true, selected: false, value: 4, actualValue: 3 },
            { status: true, selected: false, value: 3, actualValue: 2 },
            { status: true, selected: false, value: 2, actualValue: 1 },
            { status: true, selected: false, value: 1, actualValue: 0 },
            { status: true, selected: false, value: 0, actualValue: 0 }
          ],
          value: null,
          actualValue: null
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables";
table {
  width: 80%;
}
.total {
  height: 4rem;
  font-size: 4rem;
  color: $quiz-primary-strong;
  width: 80%;
  border-radius: 0.8rem;
  background-color: white;
  line-height: 4rem;
}
.total {
  text-align: center;
}
.references-section {
  font-size: 0.7rem;
}

.label-null {
  text-align: left;
  padding-right: 2rem;
  border-bottom: 2px solid $quiz-third-strong;
}
.label-card {
  text-align: left;
  padding-right: 2rem;
  border-bottom: 2px solid $quiz-primary-strong;
}
.card {
  border-radius: 1rem;
  margin-bottom: 1rem;
}
.card-header {
  min-height: 5rem;
  border-radius: 1rem 1rem 0 0 !important;
}
.card-body {
  border-radius: 0 0 1rem 1rem;
}
.btn-style {
  width: 100%;
}
.card-background {
  font-size: 0.7rem;
}
.table-change {
  width: 100%;
}
</style>