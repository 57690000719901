<template>
  <b-container class="quiz-body">
    <b-row>
      <b-col>
        <basicHeader>
          <template v-slot:icon>
            <img src="https://motivateforms.co.za/public/img/frontend_assets/doctor.png" alt srcset />
          </template>
          <template v-slot:title>TO BE COMPLETED BY THE HEALTHCARE PROFESSIONAL</template>
          <template v-slot:header>
            BASDAI BATH ANKYLOSING SPONDYLITIS DISEASE ACTIVITY INDEX.
            <sup>1,2</sup>
          </template>
          <template v-slot:content>
            Please read each question and circle the box you feel is the most appropriate to describe how severe your condition has been.
            <span>
              <u>Each question relates to how you felt in the past week.</u>
            </span>
            <span>

            <strong>&nbsp; &nbsp; PLEASE ONLY TICK ONE CIRCLE FOR EACH QUESTION.</strong>
            </span>
            There is no wrong answer.
          </template>
        </basicHeader>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <basicQuiz :quizDetails="quiz"></basicQuiz>
      </b-col>
    </b-row>
    <b-row class="pt-3 pb-3">
      <b-col class="d-none d-md-block"></b-col>
      <b-col>
        <b-container fluid>
          <b-row>
            <b-col
              md="6"
              sm="12"
              cols="12"
            >{{checkCompleted}} of {{quiz.length}} questions completed</b-col>
            <b-col md="3" sm="12" cols="12">
              <b-button class="btn-style btn-reset-quiz" pill @click="reset()">RESET</b-button>
            </b-col>
            <b-col md="3" sm="12" cols="12">
              <b-button
                pill
                class="primary-button btn-style"
                @click="complete()"
                :disabled="checkCompleted!=quiz.length"
              >COMPLETE</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          header="FOR CLINICIAN USE ONLY"
          header-tag="header"
          :header-class="['header-color-card',{'header-color-card-aval':avaliable}]"
          header-text-variant="white"
          :body-class="['body-card-aval',{'body-card-aval-false':!avaliable}]"
        >
          <template v-slot:header>
            <b-container>
              <b-row>
                <b-col
                  md="7"
                  sm="7"
                  :class="!avaliable?'textColor':'text-view'"
                >FOR CLINICIAN USE ONLY</b-col>
                <b-col
                  md="5"
                  sm="5"
                  :class="['second-text-size',!avaliable?'textColor':'text-view']"
                >BASDAI TOTAL SCORE</b-col>
              </b-row>
            </b-container>
          </template>
          <b-card-text class="card-background">
            <b-container :class="['quiz-title','parent-color',{'changed-acitve-color':!avaliable}]">
              <b-row>
                <b-col md="7" sm="7">
                  <h5 class="text-left text-size-change">CALCULATING THE BASDAI</h5>
                  <b-container class="pl-0">
                    <b-row class="pt-2">
                      <b-col md="8" sm="8" class="text-left my-auto">Add scores of questions 1 - 4</b-col>
                      <b-col md="4" sm="4">
                        <p :class="['pill-style',{'pill-active':!avaliable}]">{{calculatedValues.a}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="pt-2">
                      <b-col
                        md="8"
                        sm="8"
                        class="text-left my-auto"
                      >Add the scores from questions 5 + 6 and divide by 2</b-col>
                      <b-col md="4" sm="4">
                        <p class="pill-style">{{calculatedValues.b}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="pt-2">
                      <b-col md="8" sm="8" class="text-left my-auto">Add A and B and divide by 5</b-col>
                      <b-col md="4" sm="4">
                        <p class="pill-style">{{calculatedValues.c}}</p>
                      </b-col>
                    </b-row>
                    <b-row class="pt-2 text-left my-auto">
                      <b-col class>
                        <strong>The higher the BASDAI score, the more severe the patient's disability</strong>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
                <b-col md="5" sm="5" class="my-auto">
                  <div :class="['mx-auto total-score',{'total-score-available':!avaliable}]">
                    <p>{{calculatedValues.c}}</p>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="pb-2">
       <b-col md="7" sm="6"></b-col>
      <b-col md="3" sm="3">
        <Input
        type="text"
            name="patient_detail_full_name"
            :binding="patients_name"
            placeholder="Enter Patient full name"
            classes="white-background-input"
            @changeValue="patients_name=$event;"
        ></Input>
      </b-col>
      <b-col md="2" sm="3">
        <b-button
          class="float-right primary-button"
          :disabled="avaliable"
          pill
          @click="download()"
        >DOWNLOAD</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <basicFooter></basicFooter>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import basicQuiz from "@/components/Quiz/BasicQuiz.vue";
import basicHeader from "@/components/Quiz/BasicHeader.vue";
import basicFooter from "@/components/Quiz/BasicFooter.vue";
import moment from "moment";
import axios from "axios";
import Input from "@/components/Fields/Input";
export default {
  components: {
    Input,
    basicQuiz,
    basicHeader,
    basicFooter
  },
  computed: {
    checkCompleted() {
      let arraySize = this.quiz.filter(val => {
        return val.value != null;
      });
      return arraySize.length;
    }
  },
  methods: {
    async download() {
      try {
        let headSection = [];
        if (this.patients_name) {
          headSection.push({
            classes: "",
            element: "p",
            text: "Patient name: " + this.patients_name
          });
        }
        headSection.push({
          classes: "",
          element: "p",
          text: "Date: " + moment().format("DD/MM/YYYY")
        });
        let dataRequest = {
          data: {
            headSection: headSection,
            questions: this.quiz,
            scores: [
              {
                item_code: "A",
                text: "Add scores of questions 1 - 4",
                score: this.calculatedValues.a,
                is_total: false
              },
              {
                item_code: "B",
                text: "Add the scores from questions 5 + 6 and divide by 2",
                score: this.calculatedValues.b,
                is_total: false
              },
              {
                item_code: "C",
                text: "Add A and B and divide by 5",
                score: this.calculatedValues.c,
                is_total: true
              }
            ],
            total_score:this.calculatedValues.c
          },
          document_name: "BASDAI-form-"+(this.patients_name?this.patients_name:'unknown')
        };

        let response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/quiz-report/basdai`,
          dataRequest
        );
        if (response) {
          window.open(response.data.data.url, "_blank");
        }
      } catch (error) {}
    },
    reset() {
      let quizTemp = this.quiz.map(val => {
        val.value = null;
        return val;
      });
      this.quiz = quizTemp;
      this.calculatedValues = {
        a: null,
        b: null,
        c: null
      };
      this.avaliable = true;
    },
    complete() {
      let A_value = 0;
      let B_value = 0;
      let temp_b = 0;
      let C_value = 0;

      this.quiz.forEach(val => {
        if (val.id <= 4) {
          A_value += val.value;
        }
        if (val.id == 5 || val.id == 6) {
          temp_b += val.value;
        }
      });
      B_value = temp_b / 2;
      C_value = this.calculateC(A_value, B_value);
      this.calculatedValues = {
        a: A_value,
        b: B_value,
        c: C_value
      };
      this.avaliable = false;
      this.$emit("response",parseInt(C_value))
    },
    calculateC(a, b) {
      return (a + b) / 5;
    }
  },

  data() {
    return {
      patients_name:"",
      avaliable: true,
      calculatedValues: {
        a: null,
        b: null,
        c: null
      },
      quiz: [
        {
          id: 1,
          question:
            "How would you describe the overall level of fatigue/ tiredness you have experienced?",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 1, description: "None" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "Very Severe" }
          ]
        },
        {
          id: 2,
          question:
            "How would you describe the overall level of AS neck, back or hip pain you have had?",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 1, description: "None" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "Very Severe" }
          ]
        },
        {
          id: 3,
          question:
            "How would you describe the overall level of pain/ swelling in joints other than the neck, back or hips?",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 1, description: "None" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "Very Severe" }
          ]
        },
        {
          id: 4,
          question:
            "How would you describe the overall level of discomfort you have had from any tender areas to touch or pressure?",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 1, description: "None" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "Very Severe" }
          ]
        },
        {
          id: 5,
          question:
            "How would you describe the overall level of morning stiffness you have had from the time you wake up?",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 1, description: "None" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "Very Severe" }
          ]
        },
        {
          id: 6,
          question:
            "How long does your morning stiffness last from the time you wake up?",
          outOfScore: "Score out of 10",
          value: null,
          options: [
            { value: 1, description: "None" },
            { value: 2, description: "" },
            { value: 3, description: "" },
            { value: 4, description: "" },
            { value: 5, description: "1 Hour" },
            { value: 6, description: "" },
            { value: 7, description: "" },
            { value: 8, description: "" },
            { value: 9, description: "" },
            { value: 10, description: "2 or more hours" }
          ]
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables";
.card {
  border-radius: 1rem;
  margin-bottom: 1rem;
}
.card-header {
  border-radius: 1rem 1rem 0 0 !important;
}
.card-body {
  border-radius: 0 0 1rem 1rem;
}

.card-background {
  font-size: 0.8rem;
}
</style>