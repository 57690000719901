<template>
  <Layout>
    <template v-slot:required-header>
      <b-row>
        <b-col md="2" sm="12">
          <h6>HCP Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input name="hcp_full_name" :required="validateErrors.hcp_full_name" type="text" :binding="form.hcp_full_name"
            placeholder="Enter Dr Name" label="HCP Name" @changeValue="
              form.hcp_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :required="validateErrors.date" :binding="form.date" name="date" label="Date"
            placeholder="Select Date" @changeValue="
              form.date = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <hr class="my-3" />

      <b-row class="mb-0">
        <b-col md="2" sm="12">
          <h6>Patient's Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input :required="validateErrors.patient_detail_full_name" type="text" name="patient_detail_full_name"
            :binding="form.patient_detail_full_name" placeholder="Enter Patient's full name" label="Full Name"
            @changeValue="
              form.patient_detail_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :required="validateErrors.date_of_birth" :binding="form.date_of_birth" name="date_of_birth"
            placeholder="Select Date of birth" label="Date of Birth" @changeValue="
              form.date_of_birth = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
    </template>

    <template v-slot:additional-fields>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input type="text" name="medical_aid_name" :binding="form.medical_aid_name"
            placeholder="Enter Medical Aid Name " label="Medical Aid Name"
            @changeValue="form.medical_aid_name = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input name="plan_option" type="text" :binding="form.plan_option" placeholder="Enter option here"
            label="Plan Option" @changeValue="form.plan_option = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input name="medical_aid_membership_number" type="text" :binding="form.medical_aid_membership_number"
            placeholder="Enter Membership Number" label="Membership Number"
            @changeValue="form.medical_aid_membership_number = $event" />
        </b-col>
      </b-row>
      <hr class="my-3" />

      <b-row>
        <b-col md="6" offset="2" sm="12"> </b-col>
      </b-row>

      <b-row>
        <b-col md="2" sm="12">
          <h6>Patient's History</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input name="treatment_history" type="textarea" rows="6" :binding="form.treatment_history"
            placeholder="Enter Treatment history" label="Treatment history"
            @changeValue="form.treatment_history = $event" />
        </b-col>
        <b-col md="4">
          <SelectInput :binding="form.idc_10_code" @changeValue="form.idc_10_code = $event" :options="options.icd_10_code"
            label="ICD 10 Codes" placeholder name="icd_10_codes"></SelectInput>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6" offset="2" sm="12">
          <label :for="form.new_medication_reasons"><b>Reason(s) for selecting new medication:</b></label>
          <b-form-checkbox-group>
            <div>
              <b-form-checkbox v-model="form.new_medication_reasons"
                value="<br>Failure on treatment as per the algorithm">
                Failure on treatment as per the algorithm </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox v-model="form.new_medication_reasons" value="<br>Failure on current TNF Alpha inhibitors">
                Failure on current TNF Alpha inhibitors </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox v-model="form.new_medication_reasons"
                value="<br>Future harm where TNF Alpha inhibitors are contraindicated"> Future harm where TNF Alpha
                inhibitors
                are contraindicated </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox v-model="form.new_medication_reasons" value="<br>Systemic involvement"> Systemic
                involvement
              </b-form-checkbox>
            </div>
            <div>
              <b-form-checkbox v-model="form.new_medication_reasons"
                value="Evidence based treatment options as per SAGES Local and International Guidelines"> Evidence based
                treatment options as per SAGES Local and International Guidelines </b-form-checkbox>
            </div>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
      <hr class="my-3" />
    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Motivation</h6>
        </b-col>

        <b-col md="10" sm="12">
          <p>
            <span class="red-text">
              Remember to include your patient's clinical and treatment history as well as co-morbidity index when
              motivating. Please view below a checklist of all the relevant additional documentation you may need to
              attach with your motivational letter.
            </span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" offset="2" sm="12">
          <Input type="textarea" :binding="form.motivation" :required="validateErrors.motivation"
            placeholder="Motivate here" @changeValue="form.motivation = $event" />
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton :tabs="options.tabs" :selectedTab="selectedTab" @changeTab="
        selectedTab = $event;
      changeActive($event);
      "></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="selectedTab == 'supportingMaterials'">
        <b-col>
          <Accordion :info="form.supportingMaterials" type="checkbox"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'council_of_medical_schemes_case_studies'">
        <b-col v-if="form.council_of_medical_schemes_case_studies">
          <Accordion :info="form.council_of_medical_schemes_case_studies" type="checkbox">
            <template v-slot:dataInsert>
              <b-container fluid>
                <b-row>
                  <b-col>
                    <ul>
                      <li>Reliable access and reimbursement services.</li>
                      <li>Comprehensive disease information pack aimed at supporting you, the patient.</li>
                      <li>
                        Personalised product education on the administration and handling of Tremfya
                        <sup>®</sup> pre-filled syringes.
                      </li>
                      <li>Ongoing support to improve your adherence and mobility.</li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <img src="https://motivateforms.co.za/public/img/frontend_assets/patient_motivate.jpg" width="50%" />
                  </b-col>
                  <b-col>
                    <p>CONTACT DETAILS</p>
                    <p>(During regular business hours)</p>
                    <p>Tel. 0800112101</p>
                    <p>Fax to email: 086 649 1094</p>
                    <p>Email: janssenpatientsupport@its.jnj.com</p>
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'summary'">
        <b-col>
          <Accordion :info="form.summary" type="checkbox"></Accordion>
        </b-col>
      </b-row>
    </template>

    <template v-slot:modal-preview>
      <ModalComponent :name="previewModal.data" @closing="closeModal()" :response="true"
        :modalName="previewModal.modalName" :showModal="previewModal.show"></ModalComponent>
    </template>

    <template v-slot:modal-type>
      <ModalComponent size="md" :defaultModal="true" @closing="exportModal.show = false" :response="true"
        :DocumentType="exportModal.type" :modalName="exportModal.modalName" :showModal="exportModal.show" :footer="false"
        @saveExport="processType($event, 'save')" @exportReset="processType($event, 'reset')"></ModalComponent>
    </template>

    <template v-slot:formControl>
      <FormControl :alert="alertMessage" @alertClose="alertMessage.show = false" @preview="preview()" @save="save()"
        @reset="reset()" @exportPDF="showModalExports('pdf')" @exportWord="showModalExports('Word')"></FormControl>
    </template>
  </Layout>
</template>

<script>
import FormControl from "@/components/FormControl.vue";
import Preview from "@/components/Preview";
import Input from "@/components/Fields/Input";
import moment from "moment";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import { StelaraData } from "@/mixins/Stelara.js";
import ibdJson from "@/assets/json/IBD.json";
import Layout from "@/pages/Layouts";

export default {
  components: {
    Layout,
    Preview,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent,
    FormControl,
  },
  mixins: [GeneralUsage, ModalInformation, StelaraData],
  data() {
    return {
      validateErrors: {
        hcp_full_name: { status: null, message: "" },
        date: { status: null, message: "" },
        patient_detail_full_name: { status: null, message: "" },
        date_of_birth: { status: null, message: "" },
        motivation: { status: null, message: "" },
      },
      selectedTab: null,
      alertMessage: {
        show: false,
        variant: "success",
        message: "",
      },
      supporting: [],
      options: {
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Supporting Material", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional",
          },
          {
            value: false,
            text: "Summary",
            relation: "summary",
          },
        ],
        icd_10_code: [
          { value: null, text: "Please select one" },
          { text: "K50 Crohn's disease [regional enteritis]", value: "K50 Crohn's disease [regional enteritis]" },
          { text: "K50.0 Crohn's disease of small intestine", value: "K50.0 Crohn's disease of small intestine" },
          { text: "K50.1 Crohn's disease of large intestine", value: "K50.1 Crohn's disease of large intestine" },
          { text: "K50.8 Other Crohn's disease", value: "K50.8 Other Crohn's disease" },
          { text: "K50.9 Crohn's disease, unspecified", value: "K50.9 Crohn's disease, unspecified" },
          { text: "K51 Ulcerative colitis", value: "K51 Ulcerative colitis" },
          { text: "K51.0 Ulcerative (chronic) enterocolitis", value: "K51.0 Ulcerative (chronic) enterocolitis" },
          { text: "K51.1 Ulcerative (chronic) ileocolitis", value: "K51.1 Ulcerative (chronic) ileocolitis" },
          { text: "K51.2 Ulcerative (chronic) proctitis", value: "K51.2 Ulcerative (chronic) proctitis" },
          { text: "K51.3 Ulcerative (chronic) rectosigmoiditis", value: "K51.3 Ulcerative (chronic) rectosigmoiditis" },
          { text: "K51.4 Pseudopolyposis of colon", value: "K51.4 Pseudopolyposis of colon" },
          { text: "K51.5 Mucosal proctocolitis", value: "K51.5 Mucosal proctocolitis" },
          { text: "K51.8 Other ulcerative colitis", value: "K51.8 Other ulcerative colitis" },
          { text: "K51.9 Ulcerative colitis, unspecified", value: "K51.9 Ulcerative colitis, unspecified" },
        ],
        tabs: [
          { value: "supportingMaterials", text: "Supporting Material" },
          { value: "council_of_medical_schemes_case_studies", text: "Council of Medical Schemes Case Studies" },
          { value: "summary", text: "Summary" },
        ],
      },
      form: {
        hcp_full_name: "",
        date: "",
        patient_detail_full_name: "",
        date_of_birth: "",
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        idc_10_code: null,
        treatment_history: "",
        new_medication_reasons: [],
        motivation: "",
        council_of_medical_schemes_case_studies: {
          collapses: [
            {
              title: "Council of Medical Schemes Case Studies",
              type: "assessment",
              visible: false,
              sections: [
                {
                  html: true,
                  hr: true,
                  options: [
                    {
                      type: "basic",
                      html: true,
                      value: false,
                      description: `<p><b>Option plan / Scheme rules</b><br>
Case: Dr C J v Renburg v Bonitas (Appeals Committee ruling of 22 February 2014)<br>
Scheme refused to reimburse due to<br>
>Option plan<br>
>Biologic not on CDL algorithm<br>
>Product not on EML<br>
<b>CMS Ruling: </b>
Complaint dismissed<br>
>Schemes cannot lawfully exclude PMB treatment, or impose co-payment” for lower option<br>
>CDL algorithm is not an exhaustive list<br>
>EML is not part of the algorithm</p>`,
                      label: "",
                      labelPrint: `<p><b>Option plan / Scheme rules</b><br>
Case: Dr C J v Renburg v Bonitas (Appeals Committee ruling of 22 February 2014)<br>
Scheme refused to reimburse due to<br>
>Option plan<br>
>Biologic not on CDL algorithm<br>
>Product not on EML<br>
<b>CMS Ruling: </b>
Complaint dismissed<br>
>Schemes cannot lawfully exclude PMB treatment, or impose co-payment” for lower option<br>
>CDL algorithm is not an exhaustive list<br>
>EML is not part of the algorithm</p>`,
                    },
                    {
                      type: "basic",
                      html: true,
                      value: false,

                      description: `<p><b>Future harm</b><br>
Case: F v Discovery Health Medical Scheme (Appeals Committee ruling 13 March 2019)<br>
<b>CMS Ruling:</b> HCPs are entitled to avoid harm where evidence indicates that harm would be caused</p>`,
                      label: "",
                      labelPrint: `<p><b>Future harm</b><br>
Case: F v Discovery Health Medical Scheme (Appeals Committee ruling 13 March 2019)<br>
<b>CMS Ruling:</b> HCPs are entitled to avoid harm where evidence indicates that harm would be caused</p>`,
                    },
                    {
                      type: "basic",
                      html: true,
                      value: false,

                      description: `<p><b>Funding in full<b><br>
Case: Discovery Health Medical Scheme v Registrar and O obo D (Appeals Committee ruling of 16 March 2011)<br>
<b>CMS Ruling:<b> The Appeals Committee confirmed that where the formulary medicines have been ineffective, and exception must be made. It also ruled that the scheme cannot simply make a “bald claim” of unaffordability, without presenting evidence to that effect. It also failed to address how it implements regulation 15I(c) on exceptions.</p>`,
                      label: "",
                      labelPrint: `<p><b>Funding in full<b><br>
Case: Discovery Health Medical Scheme v Registrar and O obo D (Appeals Committee ruling of 16 March 2011)<br>
<b>CMS Ruling:<b> The Appeals Committee confirmed that where the formulary medicines have been ineffective, and exception must be made. It also ruled that the scheme cannot simply make a “bald claim” of unaffordability, without presenting evidence to that effect. It also failed to address how it implements regulation 15I(c) on exceptions.</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        summary: {
          collapses: [
            {
              title: "Summary",
              type: "assessment",
              visible: false,
              sections: [
                {
                  text: " ",
                  html: true,
                  hr: true,
                  options: [
                    {
                      type: "basic",
                      html: true,
                      value: false,
                      description: `<p>My patient has been diagnosed with a PMB condition and has failed the CDL algorithm, therefore is entitled to full reimbursement to the prescribed drug as an exception in terms of regulation 15 of the CMS act and as demonstrated by the precedent set by the CMS ruling stated above.</p>`,
                      label: "",
                      labelPrint: `<p>My patient has been diagnosed with a PMB condition and has failed the CDL algorithm, therefore is entitled to full reimbursement to the prescribed drug as an exception in terms of regulation 15 of the CMS act and as demonstrated by the precedent set by the CMS ruling stated above.</p>`,
                    },
                    {
                      type: "basic",
                      html: true,
                      value: false,
                      description: `<p>My patient has been diagnosed with a PMB condition .The treatment option offered by the scheme would cause harm as evidenced above. My patient is therefore entitled to full reimbursement to the prescribed drug as an exception in terms of regulation 15 of the CMS act and as demonstrated by the precedent set by the CMS ruling stated above.</p>`,
                      label: "",
                      labelPrint: `<p>My patient has been diagnosed with a PMB condition .The treatment option offered by the scheme would cause harm as evidenced above. My patient is therefore entitled to full reimbursement to the prescribed drug as an exception in terms of regulation 15 of the CMS act and as demonstrated by the precedent set by the CMS ruling stated above.</p>`,
                    },
                    {
                      type: "basic",
                      html: true,
                      value: false,
                      description: `<p>My patient has been diagnosed with a PMB condition .As per regulation 8 of the CMS act, PMBs must be paid for in full, therefore not funding in full will deprive my patient of a PMB treatment which will contravene the law, as demonstrated by the precedent set by the CMS ruling stated above.</p>`,
                      label: "",
                      labelPrint: `<p>My patient has been diagnosed with a PMB condition .As per regulation 8 of the CMS act, PMBs must be paid for in full, therefore not funding in full will deprive my patient of a PMB treatment which will contravene the law, as demonstrated by the precedent set by the CMS ruling stated above.</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        supportingMaterials: {
          collapses: [
            {
              title: "PMB LEGISLATION",
              visible: false,
              type: "assessment",
              sections: [
                {
                  // show: false,
                  text: "PMB LEGISLATION",
                  randomNewField: "These PMB regulations are what constitute the legal obligation of the medical scheme as per the Medical Schemes Act of 1994",
                  customText: "These PMB regulations are what constitute the legal obligation of the medical scheme as per the Medical Schemes Act of 1994",
                  hr: true,
                  options: [
                    {
                      type: "basic",
                      // header: "header",
                      value: false,
                      // superscript: "superscript",
                      label: "Regulation 15H:",
                      labelPrint: "<p>Regulation 15H states that provision must be made for appropriate exceptions where a protocol has been ineffective or causes or would cause harm to a beneficiary, without penalty to that beneficiary.</p>",
                      description: "Regulation 15H states that provision must be made for appropriate exceptions where a protocol has been ineffective or causes or would cause harm to a beneficiary, without penalty to that beneficiary.",
                      // html: 'lekker',
                    },
                    {
                      type: "basic",
                      value: false,
                      label: "Regulation 15I:",
                      description: "Regulation 15I states that provision must be made for appropriate substitution of drugs where a formulary drug has been ineffective or would cause adverse reaction to a beneficiary, without penalty to that beneficiary.",
                      labelPrint: "<p>Regulation 15I states that provision must be made for appropriate substitution of drugs where a formulary drug has been ineffective or would cause adverse reaction to a beneficiary, without penalty to that beneficiary.</p>",
                    },
                    {
                      type: "basic",
                      value: false,
                      label: "Regulation 8:",
                      description:
                        "As a general rule, the diagnosis, treatment and care of the PMBs must be funded in full and without co-payment, in terms of regulation 8(1). According to regulation 8(5), a scheme may impose a co-payment if the patient voluntarily chooses a product, and declines another product that would have been clinically appropriate. Conversely, the scheme cannot levy a co-payment if the patient declines a clinical inappropriate treatment. Medical schemes are able to use a designated service provider (DSP) system and/or managed health care tools to manage the cost associated with this “funding in full” (regulations 8(2) & 3, and regulation 8(4)).",
                      labelPrint:
                        "<p>As a general rule, the diagnosis, treatment and care of the PMBs must be funded in full and without co-payment, in terms of regulation 8(1). According to regulation 8(5), a scheme may impose a co-payment if the patient voluntarily chooses a product, and declines another product that would have been clinically appropriate. Conversely, the scheme cannot levy a co-payment if the patient declines a clinical inappropriate treatment. Medical schemes are able to use a designated service provider (DSP) system and/or managed health care tools to manage the cost associated with this “funding in full” (regulations 8(2) & 3, and regulation 8(4)).</p>",
                    },
                  ],
                },
              ],
            },
            {
              title: "EVIDENCE BASED GUIDELINES",
              visible: false,
              type: "assessment",
              sections: [
                {
                  text: "EVIDENCE BASED GUIDELINES",
                  randomNewField:
                    "The use of Evidence-based (which means the conscientious, explicit and judicious use of current best evidence medicine) forms part of regulation 15 to the Medical Schemes Act of 1994 and is therefore also the legal obligation of the Medical Scheme",

                  customText:
                    "The use of Evidence-based (which means the conscientious, explicit and judicious use of current best evidence medicine) forms part of regulation 15 to the Medical Schemes Act of 1994 and is therefore also the legal obligation of the Medical Scheme",
                },
                {
                  text: "Crohn’s Disease",
                  hr: true,
                  options: [
                    {
                      type: "basic",
                      header: "ECCO Guidelines 2021 (Commentary)",
                      subGroupForIBDHeadings: "<br><p>ECCO Guidelines 2021 (Commentary)</p>",
                      references: true,
                      value: false,
                      superscript: "1",
                      description: " ",
                      label: "In patients with an intolerance to or failure of anti-TNFα agents, recent retrospective data from propensity matched cohorts support the efficacy of ustekinumab over vedolizumab.",
                      labelPrint: "<p>In patients with an intolerance to or failure of anti-TNFα agents, recent retrospective data from propensity matched cohorts support the efficacy of ustekinumab over vedolizumab.<sup>1</sup></p>",
                    },
                    {
                      type: "basic",
                      header: "SAGES Position Paper",
                      subGroupForIBDHeadings: "<br><p>SAGES Position Paper</p>",
                      value: false,
                      references: true,
                      superscript: "2",
                      description: " ",

                      label: "Infliximab, adalimumab, ustekinumab, and vedolizumab are all appropriate as first line therapy inducing remission in patients with moderate to severe active Crohn’s disease who are biologic naive.",
                      labelPrint: "<p>Infliximab, adalimumab, ustekinumab, and vedolizumab are all appropriate as first line therapy inducing remission in patients with moderate to severe active Crohn’s disease who are biologic naive.<sup>2</sup></p>",
                    },
                    {
                      type: "basic",
                      references: true,
                      subGroupForIBDHeadings: "<br><p>SAGES Position Paper</p>",
                      value: false,
                      superscript: "2",

                      description: " ",
                      label: "Ustekinumab, and vedolizumab are both indicated as second line therapy in patients with moderate to severely active Crohn’s disease who have been exposed to anti-TNFs. ECCO recommends both ustekinumab and vedolizumab equally.",
                      labelPrint:
                        "<p>Ustekinumab, and vedolizumab are both indicated as second line therapy in patients with moderate to severely active Crohn’s disease who have been exposed to anti-TNFs. ECCO recommends both ustekinumab and vedolizumab equally.<sup>2</sup></p>",
                    },
                    {
                      type: "basic",
                      references: true,
                      subGroupForIBDHeadings: "<br><p>SAGES Position Paper</p>",
                      value: false,
                      superscript: "2",
                      description: " ",
                      label: "Infliximab is the preferred first-line medical therapy for complex perianal fistulae.<sup>1-3</sup> In the seminal ACCENT II trial, fistula closure was seen in 69% of patients at 14 weeks. At 54 weeks 36% of infliximabtreated patients had a complete absence of draining fistulae compared with 19% of placebo patients (p=0.009).116 Higher infliximab doses may be associated with better outcomes in patients with perianal fistulising disease and target levels of infliximab >10 μg/mL are associated with a better response.",
                      labelPrint:
                        "<p>Infliximab is the preferred first-line medical therapy for complex perianal fistulae.<sup>1-3</sup> In the seminal ACCENT II trial, fistula closure was seen in 69% of patients at 14 weeks. At 54 weeks 36% of infliximabtreated patients had a complete absence of draining fistulae compared with 19% of placebo patients (p=0.009).116 Higher infliximab doses may be associated with better outcomes in patients with perianal fistulising disease and target levels of infliximab >10 μg/mL are associated with a better response.</p>",
                    },

                  ],
                },

                {
                  text: "Ulcerative Colitis (UC)",
                  hr: true,
                  options: [
                    {
                      type: "basic",
                      references: true,
                      header: "ECCO Guidelines 2021",
                      subGroupForIBDHeadings: "<br><p>ECCO Guidelines 2021</p>",
                      value: false,
                      superscript: "3",
                      description: " ",
                      label:
                        "Recommend treatment with ustekinumab for the induction of remission in patients with moderately-to-severely active UC with inadequate response or intolerance to conventional therapy. [strong recommendation, moderate quality of evidence]",
                      labelPrint:
                        "<p>Recommend treatment with ustekinumab for the induction of remission in patients with moderately-to-severely active UC with inadequate response or intolerance to conventional therapy. [strong recommendation, moderate quality of evidence]</p>",
                    },
                    {
                      type: "basic",
                      references: true,
                      value: false,
                      subGroupForIBDHeadings: "<br><p>ECCO Guidelines 2021</p>",
                      superscript: "3",
                      description: " ",
                      label: "Recommend ustekinumab for the maintenance of remission in patients with UC who responded to induction therapy with ustekinumab [strong recommendation, moderate quality of evidence]",
                      labelPrint: "<p>Recommend ustekinumab for the maintenance of remission in patients with UC who responded to induction therapy with ustekinumab [strong recommendation, moderate quality of evidence]<sup>3</sup></p>",
                    },

                    {
                      type: "basic",
                      header: "SAGES 2020 Guidelines : Positioning of Biologics",
                      subGroupForIBDHeadings: "<br><p>SAGES 2020 Guidelines : Positioning of Biologics</p>",
                      references: true,
                      value: false,
                      superscript: "2",
                      description: " ",

                      label: "In infliximab exposed patients the AGA recommends ustekinumab above adalimumab or vedolizumab for the induction of remission",
                      labelPrint: "<p>In infliximab exposed patients the AGA recommends ustekinumab above adalimumab or vedolizumab for the induction of remission<sup>2</sup></p>",
                    },
                    {
                      type: "basic",
                      references: true,
                      value: false,
                      superscript: "2",
                      description: " ",
                      subGroupForIBDHeadings: "<br><p>SAGES 2020 Guidelines : Positioning of Biologics</p>",
                      label: "The AGA recommends inlfliximab and vedolizumab above adalimumab for the induction of remission in biologic naive patients.",
                      labelPrint: "<p>The AGA recommends inlfliximab and vedolizumab above adalimumab for the induction of remission in biologic naive patients.<sup>2</sup></p>",
                    },
                    {
                      type: "basic",
                      value: false,
                      references: true,
                      description: " ",
                      subGroupForIBDHeadings: "<br><p>SAGES 2020 Guidelines : Positioning of Biologics</p>",
                      label:
                        "Infliximab appears to be the most effective anti-TNF moderate to severe ulcerative colitis naïve to biologic therapy. Although there have been no head-to-head trials comparing these agents, comparisons of the various anti-TNF agents based on network meta-analyses show superiority of infliximab over adalimumab (OR 2.10; 95% CI, 1.16–3.79). Adalimumab or golimumb may be considered as first line therapy in patients where the convenience of selfadministered subcutaneous dosing outweighs the potential therapeutic benefit of infliximab, or if monotherapy is deemed necessary.",
                      labelPrint:
                        "<p>Infliximab appears to be the most effective anti-TNF moderate to severe ulcerative colitis naïve to biologic therapy. Although there have been no head-to-head trials comparing these agents, comparisons of the various anti-TNF agents based on network meta-analyses show superiority of infliximab over adalimumab (OR 2.10; 95% CI, 1.16–3.79).<sup>63</sup> Adalimumab or golimumb may be considered as first line therapy in patients where the convenience of selfadministered subcutaneous dosing outweighs the potential therapeutic benefit of infliximab, or if monotherapy is deemed necessary.</p>",
                    },

                  ],
                },
                {
                  randomNewField: "The Council of Medical Schemes CDL algorithms are outdated therefore prevailing evidence based best practice applies (Please see Note 2 to the algorithms)",
                  customText: "The Council of Medical Schemes CDL algorithms are outdated therefore prevailing evidence based best practice applies (Please see Note 2 to the algorithms)",

                  hr: true,
                  options: [
                    {
                      type: "basic",
                      value: false,
                      references: true,
                      description: " ",
                      label:
                        "Annexure 2 to the CDL algorithms state: To the extent that a medical scheme applies managed health care interventions in respect of this benefit, for example clinical protocols for diagnostic procedures or medical management, such interventions must; A) not be inconsistent with this algorithm; B) be developed on the basis of evidence-based medicine, taking into account considerations of cost-effectiveness and affordability; and C) comply with all other applicable regulations made in terms of the Medical Schemes Act, 131 of 1998.",
                      labelPrint:
                        "<p>Annexure 2 to the CDL algorithms state: To the extent that a medical scheme applies managed health care interventions in respect of this benefit, for example clinical protocols for diagnostic procedures or medical management, such interventions must; A) not be inconsistent with this algorithm; B) be developed on the basis of evidence-based medicine, taking into account considerations of cost-effectiveness and affordability; and C) comply with all other applicable regulations made in terms of the Medical Schemes Act, 131 of 1998.<sup>2</sup></p>",
                    },


                  ]
                },
                {
                  text: "References",
                  show: false,
                  options: [
                    {
                      type: "reference",
                      html: true,
                      static: [
                        "Sriranganathan D et al. Biologics recommendations in the ECCO guidelines on therapeutics in Crohn's disease: medical treatment. Frontline Gastroenterology 2022;13:168–170.",
                        "Watermeyer G et al. 2020. SAGES position paper on the use of drug therapies in the management of Inflammatory Bowel Disease.",
                        "Tim Raine and others, ECCO Guidelines on Therapeutics in Ulcerative Colitis: Medical Treatment, Journal of Crohn's and Colitis, Volume 16, Issue 1, January 2022, Pages 2–17, https://doi.org/10.1093/ecco-jcc/jjab178",
                      ],

                      references: [
                        { text: "1. Sriranganathan D et al. Biologics recommendations in the ECCO guidelines on therapeutics in Crohn's disease: medical treatment. Frontline Gastroenterology 2022;13:168–170." },
                        { text: "2. Watermeyer G et al. 2020. SAGES position paper on the use of drug therapies in the management of Inflammatory Bowel Disease." },
                        { text: "3. Tim Raine and others, ECCO Guidelines on Therapeutics in Ulcerative Colitis: Medical Treatment, Journal of Crohn's and Colitis, Volume 16, Issue 1, January 2022, Pages 2–17, https://doi.org/10.1093/ecco-jcc/jjab178" },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      previewModal: {
        data: null,
        show: false,
        name: "Preview",
        modalName: "PREVIEW",
        footer: true,
      },
      exportModal: {
        show: false,
        type: "Word document",
        name: "Export to Word",
        modalName: "Export to Word",
        footer: true,
      },
      triggerWatch: false,
      trialOutputTest: [],
    };
  },
  methods: {
    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;

      if (check.status === true) {
        let prepPsa = this.prepPsa(this.form);
        let processed = this.flatData(prepPsa, ibdJson);
        let response = await this.previewUrl(processed);

        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.validateErrors.motivation = check.data.motivation;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },

    save() {
      this.setLocal("ibd", this.form);
    },

    async exportPDF(reaction) {
      let prepPsa = this.prepPsa(this.form);
      let processed = this.flatData(prepPsa, ibdJson);
      let response = await this.exportPDFUrl(processed, this.form.patient_detail_full_name);

      window.open(response.data.data.url, "_blank");

      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },

    async exportWord(reaction) {
      let prepPsa = this.prepPsa(this.form);
      let processed = this.flatData(prepPsa, ibdJson);
      let response = await this.exportWordUrl(processed, this.form.patient_detail_full_name);

      window.open(response.data.data.url, "_blank");

      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },

    prepPsa(form) {
      let checkbox;
      let returnObject = {
        hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
        date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
        patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
        date_of_birth: form.date_of_birth ? " " + moment(form.date_of_birth).format("DD/MM/YYYY") : "",
        medical_aid_name: form.medical_aid_name ? form.medical_aid_name : "",
        plan_option: form.plan_option ? form.plan_option : "",
        medical_aid_membership_number: form.medical_aid_membership_number ? form.medical_aid_membership_number : "",
        treatment_history: form.treatment_history ?? "",
        new_medication_reasons: form.new_medication_reasons ?? null,
        idc_10_code: form.idc_10_code ? form.idc_10_code : "",
      };

      if (form.motivation) {
        returnObject["motivation_header"] = "MOTIVATION";
        returnObject["motivation"] = form.motivation ? form.motivation : "";
      }

      // get supporting material
      console.log('getting supporting material', form.supportingMaterials);
      let supportingMaterial = this.getCheckbox(form.supportingMaterials);
      console.log('got supporting material', supportingMaterial);
      if (supportingMaterial.length) {


        returnObject["supporting_material"] = supportingMaterial;
      }

      let council_of_medical_schemes = this.getCheckbox(form.council_of_medical_schemes_case_studies);
      if (council_of_medical_schemes.length) {
        returnObject["council_of_medical_schemes_header"] = "COUNCIL OF MEDICAL SCHEMES CASE STUDIES";
        returnObject["council_of_medical_schemes"] = council_of_medical_schemes;
      }

      let summary = this.getCheckbox(form.summary);
      if (summary.length) {
        returnObject["summary_header"] = "SUMMARY";
        returnObject["summary"] = summary;
      }

      // checkbox = this.getCheckbox(form.supportingMaterials);
      // returnObject["supporting_material"] = checkbox;
      console.log(returnObject);
      return returnObject;
    },

    reset() {
      this.resetStorage("ibd");
    },
  },
  // Create a function that generates a cool animation
  created() {
    let storage = this.getLocal("ibd");
    if (storage) {
      this.triggerWatch = true;
      this.form = storage;
      // } else {
      //   let data = this.ibdDermData();
      //   this.form.clinical_trial.collapses = [...data];
    }
  },
};
</script>
