<template>
  <Layout>
    <template v-slot:required-header>
      <b-row>
        <b-col md="2" sm="12">
          <h6>HCP Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input
            type="text"
            :binding="form.hcp_full_name"
            placeholder="Enter Dr Name"
            label="HCP Name"
            :required="validateErrors.hcp_full_name"
            @changeValue="
              form.hcp_full_name = $event;
              options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :binding="form.date"
            name="date"
            label="Date"
            :required="validateErrors.date"
            placeholder="Enter Date"
            @changeValue="
              form.date = $event;
              options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row class="mb-0">
        <b-col md="2" sm="12">
          <h6>Patient's Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input
            type="text"
            :binding="form.patient_detail_full_name"
            placeholder="Enter Patient's full name"
            label="Full Name"
            :required="validateErrors.patient_detail_full_name"
            @changeValue="
              form.patient_detail_full_name = $event;
              options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :binding="form.date_of_birth"
            name="date_of_birth"
            :required="validateErrors.date_of_birth"
            placeholder="Enter Date of birth"
            label="Date of Birth"
            @changeValue="
              form.date_of_birth = $event;
              options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input type="text" :binding="form.medical_aid_name" placeholder="Enter Medical Aid Name " label="Medical Aid Name" @changeValue="form.medical_aid_name = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="text" :binding="form.plan_option" placeholder="Enter option here" label="Plan Option" @changeValue="form.plan_option = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input type="text" :binding="form.medical_aid_membership_number" placeholder="Enter Membership Number" label="Membership Number" @changeValue="form.medical_aid_membership_number = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput :binding="form.icd_10_code" @changeValue="form.icd_10_code = $event" :options="options.icd_10_code" label="ICD 10 Codes" placeholder name="icd_10_codes"></SelectInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <SelectInput :binding="form.pmb_code" @changeValue="form.pmb_code = $event" :options="options.pmb_codes" label="PMB Code" placeholder name="pmb_code"></SelectInput>
          <Input type="text" v-if="form.pmb_code == 'other'" :binding="form.pmb_code_other" @changeValue="form.pmb_code_other = $event" :options="options.pmb_codes" label="PMB Code" placeholder name="pmb_code"></Input>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" offset="2" sm="12">
          <label for="">PMB Description</label>
          <b-form-checkbox-group>
            <div><b-form-checkbox v-model="form.pmb_description" value="Major affective disorders, including unipolar and bipolar depression,">Major affective disorders, including unipolar and bipolar depression</b-form-checkbox></div>
            <div>
              <b-form-checkbox v-model="form.pmb_description" value="Hospital-based management up to 3 weeks/year or outpatient psychotherapy of up to 15 contacts"
                >Hospital-based management up to 3 weeks/year or outpatient psychotherapy of up to 15 contacts</b-form-checkbox
              >
            </div>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <h6>History</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input type="date" :binding="form.date_of_first_diagnosis" name="date_of_first_diagnosis" placeholder="No date selected" label="Date of first diagnosis" @changeValue="form.date_of_first_diagnosis = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-md="2" md="6" sm="12">
          <Input type="text" :binding="form.relevant_patient_history" placeholder="Enter relevant patient history here" label="Other" @changeValue="form.relevant_patient_history = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-md="2" md="6" sm="12">
          <p>Current list of previous medications</p>
          <b-row class="pl-3">
            <b-col md="4"><p>First Line</p></b-col>
            <b-col><Input type="text" :binding="form.first_line" placeholder="Enter text here" @changeValue="form.first_line = $event" /></b-col>
          </b-row>
          <b-row class="pl-3">
            <b-col md="4"><p>Second Line</p></b-col>
            <b-col><Input type="text" :binding="form.second_line" placeholder="Enter text here" @changeValue="form.second_line = $event" /></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-md="2" md="6" sm="12">
          <Input type="text" :binding="form.other_augmentation_therapies" placeholder="Enter text here" label="Other augmentation therapies" @changeValue="form.other_augmentation_therapies = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <SelectInput
            :binding="form.reason_for_augmentation"
            @changeValue="form.reason_for_augmentation = $event"
            :options="options.reasons_for_augmentation"
            label="Reasons for augmentation with Spravato"
            placeholder
            name="reasons_for_augmentation"></SelectInput>

          <Input
            v-if="form.reason_for_augmentation == 'other'"
            type="text"
            :binding="form.reason_for_augmentation_other"
            placeholder="Enter text here"
            label="Other reason for augmentation therapies"
            @changeValue="form.reason_for_augmentation_other = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" offset="2" sm="12">
          <label for="">Benefits of augmentation with Spravato</label>
          <b-form-checkbox-group v-model="form.benefits_of_augmentation">
            <div><b-form-checkbox value="Relapse prevention (= harm prevention)">Relapse prevention (= harm prevention)</b-form-checkbox></div>
            <div><b-form-checkbox value="Improved tolerability">Improved tolerability</b-form-checkbox></div>
            <div><b-form-checkbox value="Reduced hospital admissions (= harm prevention and cost saving)">Reduced hospital admissions (= harm prevention and cost saving)</b-form-checkbox></div>
            <div>
              <b-form-checkbox value="other">Other </b-form-checkbox><br />
              <Input v-if="form.benefits_of_augmentation == 'other'" type="text" :binding="form.benefits_of_augmentation_other" placeholder="Enter text here" label="Other augmentation therapies" @changeValue="form.benefits_of_augmentation_other = $event" />
            </div>
          </b-form-checkbox-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-md="2" md="6">
          <Input type="textarea" :binding="form.rationale_for_augmentation" placeholder="Enter text here" label="Unmet Need:<br> Rationale for augmentation with Spravato" html @changeValue="form.rationale_for_augmentation = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-md="2" md="6">
          <Input type="textarea" :binding="form.dsm_criteria" placeholder="Enter text here" label="Meet DSM criteria for single episode MDD" html @changeValue="form.dsm_criteria = $event" />
        </b-col>
      </b-row>
    </template>

    <template v-slot:motivation-field>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Motivation</h6>
        </b-col>
        <b-col md="10" sm="12">
          <p>
            <span class="red-text">
              It is advisable that the motivational letter includes the clinical presentation of the patient, previous treatment history and the patient's co-morbidity Index. Please view the relevant additional documentation you may need to support your
              motivational letter. Medical scheme information to support the motivation is also available.
            </span>
          </p>
          <Input type="textarea" :binding="form.motivation" placeholder="Motivate here" @changeValue="form.motivation = $event" />
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton
        :tabs="options.tabs"
        :selectedTab="selectedTab"
        @changeTab="
          selectedTab = $event;
          changeActive($event);
        "></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="selectedTab == 'material'">
        <b-col md="12" sm="12">
          <Accordion :info="form.spravato"></Accordion>
          <Accordion :info="form.clinical_trial"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'additional'">
        <b-col md="12" sm="12">
          <Accordion :info="form.additional_information"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'checklist'">
        <b-col md="12" sm="12">
          <Checklist>
            <template v-slot:pre-title>
              <b-alert variant="danger" show>
                <b-container fluid>
                  <b-row>
                    <b-col md="1" sm="1" class="pr-0 icon-col">
                      <img src="https://motivateforms.co.za/public/img/frontend_assets/danger-info.png" alt />
                    </b-col>
                    <b-col md="11" sm="11">Please remember to add / attach the below checklist documentation when submitting your motivational letter.</b-col>
                  </b-row>
                </b-container>
              </b-alert>
            </template>

            <template v-slot:title>
              <h4 class="section-title section-title-spacing">CHECKLIST OF DOCUMENTS TO INCLUDE IN YOUR MOTIVATION FOR SPRAVATO</h4>
            </template>
            <template v-slot:content>
              <ul>
                <li>Attach script</li>
                <li>Attach motivation</li>
                <li>Disclaiming and dosing</li>
              </ul>
            </template>
          </Checklist>
        </b-col>
      </b-row>
    </template>
    <template v-slot:modal-preview>
      <ModalComponent :name="previewModal.data" @closing="closeModal()" :response="true" :modalName="previewModal.modalName" :showModal="previewModal.show"></ModalComponent>
    </template>
    <template v-slot:modal-type>
      <ModalComponent
        size="md"
        :defaultModal="true"
        @closing="exportModal.show = false"
        :response="true"
        :DocumentType="exportModal.type"
        :modalName="exportModal.modalName"
        :showModal="exportModal.show"
        :footer="false"
        @saveExport="processType($event, 'save')"
        @exportReset="processType($event, 'reset')"></ModalComponent>
    </template>
    <template v-slot:formControl>
      <FormControl :alert="alertMessage" @alertClose="alertMessage.show = false" @preview="preview()" @save="save()" @reset="reset()" @exportPDF="showModalExports('pdf')" @exportWord="showModalExports('Word')"></FormControl>
    </template>
  </Layout>
</template>

<script>
import Input from "@/components/Fields/Input";
import moment from "moment";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import spravatoJson from "@/assets/json/spravato.json";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import Layout from "./Layouts";
import HtmlEditor from "../components/HtmlEditor.vue";
import FormControl from "../components/FormControl.vue";
export default {
  components: {
    Layout,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent,
    HtmlEditor,
    FormControl,
  },
  mixins: [GeneralUsage, ModalInformation],
  data() {
    return {
      options: {
        tabs: [
          { value: "material", text: "Supporting Material" },
          {
            value: "additional",
            text: "Additional Information",
          },
          {
            value: "checklist",
            text: "Checklist",
          },
        ],
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Clinical Trials", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional",
          },
          { value: false, text: "Checklist", relation: "checklist" },
        ],
        icd_10_code: [
          { value: null, text: "Select an option" },
          { value: "F32	Depressive episode", text: "F32	Depressive episode" },
          { value: "F32.0	Mild depressive episode", text: "F32.0	Mild depressive episode" },
          { value: "F32.1	Moderate depressive episode", text: "F32.1	Moderate depressive episode" },
          { value: "F32.2	Severe depressive episode without psychotic symptoms", text: "F32.2	Severe depressive episode without psychotic symptoms" },
          { value: "F32.3	Severe depressive episode with psychotic symptoms", text: "F32.3	Severe depressive episode with psychotic symptoms" },
          { value: "F32.8	Other depressive episodes", text: "F32.8	Other depressive episodes" },
          { value: "F32.9	Depressive episode, unspecified", text: "F32.9	Depressive episode, unspecified" },
          { value: "F33	Recurrent depressive disorder", text: "F33	Recurrent depressive disorder" },
          { value: "F33.0	Recurrent depressive disorder, current episode mild", text: "F33.0	Recurrent depressive disorder, current episode mild" },
          { value: "F33.1	Recurrent depressive disorder, current episode moderate", text: "F33.1	Recurrent depressive disorder, current episode moderate" },
          { value: "F33.2	Recurrent depressive disorder, current episode severe without psychotic symptoms", text: "F33.2	Recurrent depressive disorder, current episode severe without psychotic symptoms" },
          { value: "F33.3	Recurrent depressive disorder, current episode severe with psychotic symptoms", text: "F33.3	Recurrent depressive disorder, current episode severe with psychotic symptoms" },
          { value: "F33.4	Recurrent depressive disorder, currently in remission", text: "F33.4	Recurrent depressive disorder, currently in remission" },
          { value: "F33.8	Other recurrent depressive disorders", text: "F33.8	Other recurrent depressive disorders" },
          { value: "F33.9	Recurrent depressive disorder, unspecified", text: "F33.9	Recurrent depressive disorder, unspecified" },
        ],
        pmb_codes: [
          { value: null, text: "Select an option" },
          { value: "902T", text: "902T" },
          { value: "other", text: "Other" },
        ],
        reasons_for_augmentation: [
          { value: null, text: "Select an option" },
          { value: "Lack of efficacy", text: "Lack of efficacy" },
          { value: "Safety", text: "Safety" },
          { value: "Causing harm", text: "Causing harm" },
          { value: "Would cause harm", text: "Would cause harm" },
          { value: "other", text: "Other" },
        ],
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        icd_10_code: null,
        icd_10_code_other: "",
        medical_scheme_info: "<p></p>",
        pmb_code: null,
        pmb_code_other: "",
        pmb_description: [],
        reason_for_augmentation: null,
        reason_for_augmentation_other: "",
        date_of_first_diagnosis: null,
        relevant_patient_history: "",
        other_augmentation_therapies: "",
        reason_for_augmentation: null,
        benefits_of_augmentation: null,
        benefits_of_augmentation_other: "",
        rationale_for_augmentation: "",
        dsm_criteria: "",
        spravato: {
          collapses: [
            {
              title: "MOTIVATING FOR SPRAVATO",
              visible: false,
              type: "assessment",
              sections: [
                {
                  text: "Supporting Legislation",
                  hr: true,
                  options: [
                    {
                      type: "checkboxOptions",
                      label: "Scientific and Clinical Evidence",

                      items: [
                        "Major Depressive disorder is the leading cause of disability worldwide related to total years lost due to disability and associated excess mortality (World Health Organization Media Centre: Depression (fact sheet). Last accessed March 22, 2021 (https://www.who.int/news-room/fact-sheets/detail/depression)). According to Rush et al. (2014), approximately 30% of patients will not respond to current treatment strategies available for the treatment of major depressive disorder. According to the worldwide consensus, patients who do not respond with adequate duration and dosage to two lines of oral antidepressants are considered treatment resistant depression (TRD).",
                        "There are no on-label and safe treatment options in treating TRD to attain an adequate response in alleviating symptom relief, therefore Esketamine intranasal spray (Spravato), fills this unmet need.  Esketamine, the S-enantiomer of ketamine, has been shown by numerous pre-clinical studies to be efficacious in mediating neuroplasticity by having a 4-fold higher affinity to the NMDA receptors located at the inhibitory neurons, thereby exacerbating the release of excitatory neurotransmitter, glutamate, at the post synaptic neuron which subsequently promotes spine growth on neurons that allows for more glutamate binding and therefore plasticity at the level of the synapse (Duman et al, 2012, Duman et al, 2016). This mechanism of action is therefore shown to promote adequate response in patients who are TRD (Papova et al., 2019).",
                        "Clinical data has further illustrated that intranasal esketamine is more efficacious than intravenous esketamine when augmented with an oral antidepressant (ref : FDA advisory committee briefing document. Available at: https://www.fda.gov/media/121376/download (last accessed October 2019).). Furthermore, Papova et al. (2019) describes that intranasal esketamine proved efficacious in 70% of 30% individuals struggling with TRD. Whilst Daly et al (2019) further showed that these patients tolerated intranasal esketamine and were less likely to relapse with time.",
                      ],
                      value: [],
                    },
                    {
                      type: "checkboxOptions",
                      label: "Legislative Relevance",
                      items: [
                        "Regulation 15H(c) and 15I(c) state that schemes must make appropriate exceptions to the protocols and formularies, where other treatments have been ineffective. This is clearly the case here. Due to the data relating to relapse reduction, there is also proof that treatment with esketamine will prevent harm (Psychiatric emergency and further disease progression).",
                        "Regulation 15H(c) and 15I(c) requires substitution with an “appropriate” alternative treatment. In this regard, eskatamine is appropriate.",
                        "Medical scheme decisions on treatment options for both PMBs and non-PMBs, must be on the basis of “evidence-based medicine” (EBM) (regulation 15H and 15I). EBM  is defined as “the conscientious, explicit and judicious use of current  best evidence in making decisions about the care of  beneficiaries whereby individual clinical experience is integrated with the best available external clinical evidence from systematic research”.",
                      ],
                      value: [],
                    },
                  ],
                },
                {
                  text: "Proposed Evidence",
                  hr: true,
                  options: [
                    {
                      type: "basic",
                      header: "Diagnostic tools to be included:",
                    },

                    {
                      type: "text",
                      label: "PHQ-9",
                      labelPrint: "<strong>PHQ-9</strong>",
                      value: "",
                      custom: true,
                      placeholder: "Enter score here",
                    },
                    {
                      type: "text",
                      label: "GAD-7",
                      labelPrint: "<strong>GAD-7</strong>",
                      value: "",
                      custom: true,
                      placeholder: "Enter score here",
                    },

                    {
                      type: "text",
                      label: "Outcomes Rating Scale",
                      value: "",
                      custom: true,
                      labelPrint: "<strong>Outcomes Rating Scale</strong>",
                      placeholder: "Enter score here",
                    },
                    {
                      type: "basic",

                      value: false,
                      header: "The following has also been considered",

                      description: "Spravato will be an add-on to SSRI or SNRI",
                      label: "",
                      labelPrint: "<p>Spravato will be an add-on to SSRI or SNRI</p>",
                    },
                  ],
                },
              ],
            },
          ],
        },
        clinical_trial: {
          collapses: [
            {
              title: "CLINICAL DATA",
              visible: false,
              type: "checkbox",
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  options: [
                    {
                      type: "model",
                      value: false,
                      link: "spravato.sustain",
                      label: "Sustain 1",
                      modal: "sustain",
                      modalHeading: "Sustain 1",
                      footerAppend: "<p>CP-341463</p>",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "spravato.transform",
                      label: "Transform 2",
                      modal: "transform",
                      modalHeading: "Transform 2",
                      footerAppend: "<p>CP-341463</p>",
                    },
                  ],
                },
              ],
            },
          ],
        },
        additional_information: {
          collapses: [
            {
              title: "PATIENT MOTIVATION",
              visible: false,
              type: "patient_motivation",
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: true,
                      details: `
                        <p>In this section you are able to leave space for  your patient to insert their hand written motivation as to what they are going through or why they want to motivate to their medical aid.  </p><p>Alternatively you can use the free text box to motivate on behalf of your patient in typed text</p>
                        `,
                    },
                  ],
                  text: "PATIENTS OWN MOTIVATION",
                  choice: null,
                  options: [
                    {
                      value: "",
                      label: "Patients Motivation",
                      type: "textarea",
                    },
                  ],
                },
              ],
            },
            {
              title: "MEDICAL INFORMATION",
              visible: false,
              header: "MEDICAL INFORMATION",
              type: "html",
              data: `

          <p>
          <strong>
          Should you require any further clinical trials or medical information please use the below contact information
          </strong>
          </p>
          <p>
          Email: <a href="mailto:ra-medinfoemmarkets@its.jnj.com">ra-medinfoemmarkets@its.jnj.com</a>
          <br>Telephone: 0860 11 11 17
          </p>
          `,
            },
          ],
        },
      },
    };
  },
  methods: {
    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepData = this.prepSpravato(this.form);
        let processed = this.flatData(prepData, spravatoJson);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    save() {
      this.setLocal("spravato", this.form);
    },

    async exportPDF(reaction) {
      let prepData = this.prepSpravato(this.form);

      let processed = this.flatData(prepData, spravatoJson);
      let response = await this.exportPDFUrl(processed, this.form.patient_detail_full_name);

      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    async exportWord(reaction) {
      let prepData = this.prepSpravato(this.form);
      // let x = new RegExp('<p class="indentity"', "g");
      // let m = prepData.medical_scheme_info.replace(x, '<br><br><p class="indentity"');
      // prepData.medical_scheme_info = m;
      let processed = this.flatData(prepData, spravatoJson);
      let response = await this.exportWordUrl(processed, this.form.patient_detail_full_name);
      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    prepSpravato(form) {
      let checkbox;

      let returnObject = {
        hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
        date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
        patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
        date_of_birth: form.date_of_birth ? moment(form.date_of_birth).format("DD/MM/YYYY") : "",
        medical_aid_name: form.medical_aid_name ?? "",
        plan_option: form.plan_option ?? "",
        medical_aid_membership_number: form.medical_aid_membership_number ?? "",
        icd_10_code: form.icd_10_code ?? "",
        pmb_code: form.pmb_code ?? "",
        history_header: "HISTORY",
        relevant_patient_history: form.relevant_patient_history ?? "",
        date_of_first_diagnosis: form.date_of_first_diagnosis ?? "",
        current_list_of_previous_medications: form.first_line || form.second_line ? "Current list of previous medications" : "",
        first_line: form.first_line ?? "",
        second_line: form.second_line ?? "",
        other_augmentation_therapies: form.other_augmentation_therapies ?? "",
        reason_for_augmentation: form.reason_for_augmentation,
        benefits_of_augmentation: form.benefits_of_augmentation,
        rationale_for_augmentation: form.rationale_for_augmentation,
        dsm_criteria: form.dsm_criteria,
      };

      if (form.pmb_code == "other") {
        returnObject["pmb_code"] = form.pmb_code_other;
      }
      if (form.benefits_of_augmentation == "other") {
        returnObject["benefits_of_augmentation"] = form.benefits_of_augmentation_other;
      }
      if (form.reason_for_augmentation == "other") {
        returnObject["reason_for_augmentation"] = form.reason_for_augmentation_other;
      }
      if (form.pmb_description.length) {
        returnObject["pmb_description"] = form.pmb_description;
      }
      if (form.motivation) {
        returnObject["motivation_header"] = "MOTIVATION";
        returnObject["motivation"] = form.motivation ? form.motivation : "";
      }

      checkbox = this.getCheckbox(form.spravato);
      if (checkbox.length > 0) {
        returnObject["motivating_for_spravato_header"] = "MOTIVATING FOR SPRAVATO";
        returnObject["spravato"] = checkbox;
      }

      checkbox = this.getCheckbox(form.clinical_trial);
      if (checkbox.length > 0) {
        returnObject["clinical_trial_header"] = "CLINICAL TRIALS";
        returnObject["clinical_trial"] = checkbox;
      }

      let tempInput = this.patientsMotivation(form.additional_information.collapses);
      if (tempInput.length > 0) {
        returnObject["additional_information"] = tempInput;
      }

      return returnObject;
    },
    reset() {
      this.resetStorage("spravato");
    },
  },
  created() {
    let data = this.getLocal("spravato");
    if (data) {
      this.form = data;
    }
  },
};
</script>
