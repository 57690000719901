<template>
  <b-container>
    <b-row>
      <b-col md="8" sm="12">
        <p>Your online motivation support template that assists in formulating a dynamic patient motivation for insurers.</p>
      </b-col>
      <b-col class="text-center" md="4" sm="12">
        <b-button pill class="" @click="show=true">
            <img src="https://motivateforms.co.za/public/img/frontend_assets/more-info.png" class="more-icon" />
            <span class="more-text">MORE INFORMATION</span>
        </b-button>
      </b-col>
    </b-row>
    <ModalComponent
      :modalIcon="true"
      :modalName="modalName"
      :showModal="show"
      @closing="show=false"
      :response="true"
      :name="data"
    ></ModalComponent>
  </b-container>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
export default {
  components: {
    ModalComponent
  },
  props: {
    previewDetails: Array,
    
  },
  data() {
    return {
      modalName: "MORE INFORMATION",
      name: "",
      show: false,
      data: `
      <h4 class="text-color-primary">HOW TO USE THE FORM</h4>
<p>
    This is a dynamic Medical Aid Motivational form built with the relevant content, supporting data and other useful
    information you may require to motivate to funders on behalf of your patients.
</p>
<p>
    While using this form you can:
</p>
<ul>
    <li>Build your patient motivation by filling in the basic patient, medical aid and motivation information. </li>
    <li>Select to include any of the clinical or any other information provided within the Additional Information and
        Supporting Material sections in your letter to support your patient’s case for motivation.</li>
    <li>
        View a form submission Checklist of suggested supporting documents you can submit to support the motivation
        letter as well as view all the References provided for your motivation.
    </li>
    <li>
        Modify / edit / remove any of the pre-populated text.
    </li>
    <li>Modify / edit / remove any information once you have exported your patient form in a Microsoft Word&trade; document format.
    </li>
    <li>Save your form at any time for completion at a later stage.</li>

</ul>
<p>
    Once you have completed filling in or editing the information, export the form as a  Microsoft Word&trade; (for
    further offline editing) or export it as a PDF document.
</p>
<h5 class="text-color-primary mt-3">
    PREVIEW, SAVING AND EXPORTING YOUR FORM
</h5>
<p>
    You have options located on the left of your screen that will allow you to SAVE, PREVIEW and EXPORT WORD or PDF
    versions of your form.
</p>
<ul>
    <li><strong>SAVE</strong> - Allows you to save your progress in completing the form and return to continue
        editing. Please see the Disclaimer below regarding the information. </li>
    <li><strong>PREVIEW</strong> - Allows you to preview how your form will look once it is exported.
    </li>
    <li><strong>EXPORT TO WORD</strong> - Export allows you to export the form as a Microsoft Word&trade; document to continue
        editing offline.
    </li>
    <li><strong>EXPORT TO PDF</strong> - Export allows you to export the form  as a PDF document.</li>
    <li><strong>RESET</strong> - Will reset the form, removing all information captured, to start a new patient
        motivation.</li>
</ul>

<p>
    <strong>IMPORTANT</strong>: For security and privacy reasons once you have reset the form, you will be required to
    complete the form again from the beginning as all content is removed from both the form fields and your computer
    history.
</p>

<h5 class="text-color-primary mt-3">
    REFERENCING
</h5>
<p>
    Any references used in this form will automatically be included at the end of the motivational letter. You can
    choose to include or remove references, once the form has been exported.

</p>
<h5 class="text-color-primary mt-3">
    DISCLAIMER
</h5>
<p>
    Please be advised that Janssen Pharmaceutica (Pty) Ltd does not store, cannot view, has no access, or save any
    patient information or data you input. Any data inputted below is encrypted and saved locally on your machine via
    your internet browser. Once you export your form all data is removed from your device and cannot be retrieved.
</p>
        `
    };
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables";

.btn-more {
  max-width: 16rem;
  margin: auto;
  margin-bottom: 2rem;
  background-color: white;
  border: none;
  box-shadow: 10px 10px 18px -15px rgba(0,0,0,1);
-webkit-box-shadow: 10px 10px 18px -15px rgba(0,0,0,1);
-moz-box-shadow: 10px 10px 18px -15px rgba(0,0,0,1);
  p {
    margin-bottom: 0;
    color: $primary-color;
    font-size: 1.2rem;
  }
  img {
    height: 1.2rem;
  }

  &:hover,
  &:active,
  &:focus,
  &:link,
  &:visited {
    background-color: $secondary-color !important;
    color: white;
  }
}

.text-color-primary {
  color: $primary-color;
}
.text-size {
  color: #ababab;

  text-align: center;
  margin: auto;
  margin-bottom: 1rem;
  text-align: center;
  line-height: 1rem;
}
.more-icon {
  height: 20px;
  margin-right: 1rem;
}
.more-text {
  color: $primary-color;
  font-size: 1rem;
}
</style>