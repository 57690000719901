<template>
  <b-navbar toggleable="lg" type="dark">
    <b-navbar-brand tag="div" class="logo">
      <img
        height="60rem"
        src="https://motivateforms.co.za/public
/img/frontend_assets/janssen.png"
        alt
        srcset
      />
    </b-navbar-brand>
    <b-navbar-brand tag="div" class="logo"> <span class="motivation-letter">Motivation letter</span> to Medical Aids </b-navbar-brand>

    <b-navbar-brand tag="div">
      <img
        :src="mode == 'production' ? 'https://motivateforms.co.za/public/img/frontend_assets/imbruvica-logo.png' : require('@/assets/images/imbruvica-logo.png')"
        alt="imbruvica"
        height="60rem"
        v-if="name == 'imbruvica'"
      />
      <img
        :src="mode == 'production' ? 'https://motivateforms.co.za/public/img/frontend_assets/XeplionLogo.png' : require('@/assets/images/XeplionLogo.png')"
        alt="xeplion"
        height="60rem"
        v-if="name == 'xeplion'"
      />
      <img
        :src="mode == 'production' ? 'https://motivateforms.co.za/public/img/frontend_assets/velcadeLogo.png' : require('@/assets/images/velcadeLogo.png')"
        alt="imbruvica"
        height="60rem"
        v-if="name == 'velcade'"
      />
      <img
        :src="mode == 'production' ? 'https://motivateforms.co.za/public/img/frontend_assets/stelaraLogo.png' : require('@/assets/images/stelaraLogo.png')"
        alt="imbruvica"
        height="60rem"
        v-if="name == 'stelara-psa' || name == 'stelara-pso'"
      />
      <img
        :src="mode == 'production' ? 'https://motivateforms.co.za/public/img/frontend_assets/darzalex.png' : require('@/assets/images/darzalex.png')"
        alt="imbruvica"
        height="60rem"
        v-if="name == 'darzalex'"
      />
      <img src="https://motivateforms.co.za/public/img/spravato.svg" alt="spravato" height="60rem" v-if="name == 'spravato'" />
      <img
        :src="mode == 'production' ? 'https://motivateforms.co.za/public/img/frontend_assets/zytigaLogo.png' : require('@/assets/images/zytigaLogo.png')"
        alt="imbruvica"
        height="60rem"
        v-if="name == 'zytiga'"
      />

      <img
        :src="mode == 'production' ? 'https://motivateforms.co.za/public/img/frontend_assets/Erleada_logo.png' : require('@/assets/images/Erleada_logo.png')"
        alt="imbruvica"
        height="60rem"
        v-if="name == 'erleada'"
      />
      <img src="https://motivateforms.co.za/public/img/frontend_assets/uptravi.svg" alt="pah" height="60rem" v-if="name == 'pah'" />
      <img
        :src="mode == 'production' ? 'https://motivateforms.co.za/public/img/frontend_assets/trevicta_logo.png' : require('@/assets/images/trevicta_logo.png')"
        alt="trevicta"
        height="60rem"
        v-if="name == 'trevicta'"
      />
      <img
        :src="mode == 'production' ? 'https://motivateforms.co.za/public/img/frontend_assets/tremfya.png' : require('@/assets/images/tremfya.png')"
        alt="tremfya"
        height="60rem"
        v-if="name == 'tremfya'"
      />
    </b-navbar-brand>
  </b-navbar>
</template>

<script>
export default {
  props: {
    name: String,
  },
  data() {
    return {
      mode: process.env.NODE_ENV,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables";
.logo {
  flex-grow: 1;
}
div {
  color: black !important;
}
.navbar {
  z-index: 0;
  -webkit-box-shadow: 0px 3px 49px 2px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0px 3px 49px 2px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 3px 49px 2px rgba(0, 0, 0, 0.23);
  background-color: white;
  padding-left: 10rem;
  padding-right: 10rem;
}
.motivation-letter {
  font-size: 2.6rem;
  color: $primary-color;
}
</style>
