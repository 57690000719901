<template>
  <Layout>
    <template v-slot:previewArea>
      <Preview :alert="alertMessage" @alertClose="alertMessage.show = false" :previewDetails="options.preview" @preview="preview()" @save="save()" @reset="reset()" @exportPDF="showModalExports('pdf')" @exportWord="showModalExports('Word')"></Preview>
    </template>

    <template v-slot:required-header>
      <b-row>
        <b-col md="12" sm="12">
          <h5>HCP Details</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            type="text"
            :binding="form.hcp_full_name"
            placeholder="Enter Dr Name"
            label="HCP Name"
            :required="validateErrors.hcp_full_name"
            @changeValue="
              form.hcp_full_name = $event;
              options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :binding="form.date"
            name="date"
            label="Date"
            :required="validateErrors.date"
            placeholder="Enter Date"
            @changeValue="
              form.date = $event;
              options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Patient's Details</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            type="text"
            :binding="form.patient_detail_full_name"
            placeholder="Enter Patient's full name"
            label="Full Name"
            :required="validateErrors.patient_detail_full_name"
            @changeValue="
              form.patient_detail_full_name = $event;
              options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :binding="form.date_of_birth"
            name="date_of_birth"
            :required="validateErrors.date_of_birth"
            placeholder="Enter Date of birth"
            label="Date of Birth"
            @changeValue="
              form.date_of_birth = $event;
              options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <b-row>
        <b-col md="8" sm="12">
          <Input type="text" :binding="form.medical_aid_name" placeholder="Enter Medical Aid Name " label="Medical Aid Name" @changeValue="form.medical_aid_name = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="text" :binding="form.plan_option" placeholder="Enter option here" label="Plan Option" @changeValue="form.plan_option = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input type="text" :binding="form.medical_aid_membership_number" placeholder="Enter Membership Number" label="Membership Number" @changeValue="form.medical_aid_membership_number = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput :binding="form.idc_10_code" @changeValue="form.idc_10_code = $event" :options="options.idc_10_code" label="ICD 10 Codes" placeholder name="icd_10_codes"></SelectInput>
        </b-col>
      </b-row>
      <b-row v-if="form.idc_10_code == 'Other'">
        <b-col md="8" sm="0"></b-col>
        <b-col md="4" sm="12">
          <Input type="text" :binding="form.idc_10_code_other" placeholder="Other" label="Please type other code" @changeValue="form.idc_10_code_other = $event" />
        </b-col>
      </b-row>
    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Motivation</h5>
          <p>
            <span class="red-text">
              It is advisable that the motivational letter includes the clinical presentation of the patient, previous treatment history and the patient's co-morbidity Index.
              <br />Please view the relevant additional documentation you may need to support your motivational letter.
            </span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <Input type="textarea" :binding="form.motivation" placeholder="Motivate here" @changeValue="form.motivation = $event" />
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton
        :tabs="options.tabs"
        :selectedTab="selectedTab"
        @changeTab="
          selectedTab = $event;
          changeActive($event);
        "></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="'trials' == selectedTab">
        <b-col md="12" sm="12">
          <Accordion :info="form.clinical_trial"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="'additional' == selectedTab">
        <b-col md="12" sm="12">
          <Accordion :info="form.additional_information" @referenceRemove="referenceRemove($event, 'additional_information')" @referencesAdd="referenceAdd($event, 'additional_information')"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'checklist'">
        <Checklist>
          <template v-slot:pre-title>
            <b-alert variant="danger" show>
              <b-container fluid>
                <b-row>
                  <b-col md="1" sm="1" class="pr-0 icon-col">
                    <img
                      src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png"
                      alt />
                  </b-col>
                  <b-col md="11" sm="11">Please remember to add / attach the below checklist documentation when submitting your motivational letter.</b-col>
                </b-row>
              </b-container>
            </b-alert>
            <b-alert variant="danger" show>
              <b-container fluid>
                <b-row>
                  <b-col md="1" sm="1" class="pr-0 icon-col">
                    <img
                      src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png"
                      alt />
                  </b-col>
                  <b-col md="11" sm="11">
                    <span>
                      Reference:
                      <br />Adapted from NCCN Guidelines &reg;, Multiple Myeloma
                      <a href="https://www.nccn.org">https://www.nccn.org</a>
                    </span>
                  </b-col>
                </b-row>
              </b-container>
            </b-alert>
          </template>
          <template v-slot:title>
            <h4 class="section-title section-title-spacing">CHECKLIST - FOR A NEWLY DIAGNOSED MYELOMA PATIENT</h4>
          </template>
          <template v-slot:download>
            <b-button class="download-btn" @click="downloadFile('velcade')">Download</b-button>
          </template>
          <template v-slot:content>
            <ul>
              <li>Full blood count (FBC), differently, platelet count</li>
              <li>Serum blood urea nitrogen (BUN)/ creatine, electrolytes albumin, and calcium</li>
              <li>Creatinine clearance (calculated or measured directly)</li>
              <li>Serum uric acid</li>
              <li>Serum lactate dehydrogenase (LDH) and beta-2 microglobulin</li>
              <li>Serum quantitative immunoglobulins, serum protein electrophoresis (SPEP), serum immunofixation electrophoresis (SIFE)</li>
              <li>24-h urine for total protein, urine protein electrophoresis (UPEP), urine immunofixation electrophoresis (UIFE)</li>
              <li>Serum free light chain (FLC) assay</li>
            </ul>
            <p style="color: red">Please include the following as attachments</p>
            <ul>
              <li>Skeletal survey or whole body low-dose computed tomography (CT) scan</li>
              <li>Unilateral bone marrow aspirate + biopsy, including bone marrow immunohistochemistry and/or bone marrow flow cytometry</li>
              <li>Metaphase cytogenetics on bone marrow</li>
              <li>Plasma cell FISH [del 13, del 17p13, t(4;14), t(11;14), t(14;16), t(14:20), 1q21 amplification], 1p abnormality</li>
            </ul>
            <p style="color: red">Consider attaching the previous motivational letter to further your application reimbursement</p>
          </template>
        </Checklist>
        <Checklist>
          <template v-slot:title>
            <h4 class="section-title">CHECKLIST - FOR A RELAPSED AND REFRACTORY PATIENT</h4>
          </template>
          <template v-slot:content>
            <ul>
              <li>Full blood count (FBC), differential, platelet count</li>
              <li>Serum quantitative immunoglobulins, SPEP, SIFE</li>
              <li>24-h urine for total protein, UPEP, UIFE</li>
              <li>Creatinine, corrected calcium</li>
              <li>Serum FLC assay as clinically indicated</li>
              <li>Bone marrow aspirate and biopsy as clinically indicated</li>
              <li>Assess minimal residual disease (MRD) as indicated</li>
            </ul>
            <p style="color: red">Please include the following as attachments</p>
            <ul>
              <li>Skeletal surveyor whole body low-dose CT scan as clinically indicated</li>
              <li>Whole body or skeletal MRI or whole body FDG PET/CT scan as clinically indicated</li>
              <li>Consider attaching the previous motivational letter to further your application reimbursement.</li>
            </ul>
            <p style="color: red">Consider attaching the previous motivational letter to further your application reimbursement</p>
          </template>
        </Checklist>
      </b-row>
    </template>
    <template v-slot:modal-preview>
      <ModalComponent :name="previewModal.data" @closing="closeModal()" :response="true" :modalName="previewModal.modalName" :showModal="previewModal.show"></ModalComponent>
    </template>
    <template v-slot:modal-type>
      <ModalComponent
        size="md"
        :defaultModal="true"
        @closing="exportModal.show = false"
        :response="true"
        :DocumentType="exportModal.type"
        :modalName="exportModal.modalName"
        :showModal="exportModal.show"
        :footer="false"
        @saveExport="processType($event, 'save')"
        @exportReset="processType($event, 'reset')"></ModalComponent>
    </template>
  </Layout>
</template>

<script>
import Preview from "@/components/Preview";
import Input from "@/components/Fields/Input";
import moment from "moment";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import velcadeJSON from "@/assets/json/newVelcade.json";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import Layout from "./Layouts";

export default {
  components: {
    Layout,
    Preview,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent,
  },
  mixins: [GeneralUsage, ModalInformation],
  data() {
    return {
      options: {
        tabs: [
          { value: "trials", text: "Clinical Trials" },
          {
            value: "additional",
            text: "Additional Information",
          },
          {
            value: "checklist",
            text: "Checklist",
          },
        ],
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Clinical Trials", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional",
          },
          { value: false, text: "Checklist", relation: "checklist" },
        ],
        idc_10_code: [
          { value: null, text: "Select an option" },
          { value: "C90.0 Multiple Myeloma", text: "C90.0 Multiple Myeloma" },
          { value: "Other", text: "Other" },
        ],
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        idc_10_code: null,
        idc_10_code_other: "",

        clinical_trial: {
          collapses: [
            {
              title: "CLINICAL TRIALS",
              visible: false,
              type: "checkbox",
              sections: [
                {
                  text: "NEWLY DIAGNOSED",
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  options: [
                    {
                      type: "model",
                      value: false,
                      link: "velcade.vista-study",
                      label: "VISTA STUDY",
                      modal: "VISTA STUDY",
                      modalHeading: "VISTA STUDY",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "velcade.vista-study-five",
                      label: "VISTA STUDY: FIVE YEARS FOLLOW UP",
                      modal: "VISTA STUDY: FIVE YEARS FOLLOW UP",
                      modalHeading: "VISTA STUDY: FIVE YEARS FOLLOW UP",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "velcade.vista-study-assessment",
                      label: "VISTA STUDY: ASSESSMENT OF THE PROGNOSTIC IMPACT OF RESPONSE ON TIME-TO-EVENT PARAMETERS",
                      modal: "VISTA STUDY: ASSESSMENT OF THE PROGNOSTIC IMPACT OF RESPONSE ON TIME-TO-EVENT PARAMETERS",
                      modalHeading: "VISTA STUDY: ASSESSMENT OF THE PROGNOSTIC IMPACT OF RESPONSE ON TIME-TO-EVENT PARAMETERS",
                    },
                  ],
                },
                {
                  text: "RELAPSED OR REFRACTORY",
                  options: [
                    {
                      type: "model",
                      value: false,
                      link: "velcade.crest-study",
                      label: "CREST STUDY",
                      modal: "CREST STUDY",
                      modalHeading: "CREST STUDY",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "velcade.summit-study",
                      label: "SUMMIT STUDY",
                      modal: "SUMMIT STUDY",
                      modalHeading: "SUMMIT STUDY",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "velcade.summit-study-extend",
                      label: "SUMMIT STUDY: EXTENDED FOLLOW UP",
                      modal: "SUMMIT STUDY: EXTENDED FOLLOW UP",
                      modalHeading: "SUMMIT STUDY: EXTENDED FOLLOW UP",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "velcade.crest-study",
                      label: "CREST & SUMMIT STUDIES: RETROSPECTIVE ANALYSIS IN PATIENTS WITH RENAL",
                      modal: "CREST & SUMMIT STUDIES: RETROSPECTIVE ANALYSIS IN PATIENTS WITH RENAL",
                      modalHeading: "CREST & SUMMIT STUDIES: RETROSPECTIVE ANALYSIS IN PATIENTS WITH RENAL",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "velcade.apex-study",
                      label: "APEX STUDY",
                      modal: "APEX STUDY",
                      modalHeading: "APEX STUDY",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "velcade.apex-study-extend",
                      label: "APEX STUDY: EXTENDED FOLLOW UP",
                      modal: "APEX STUDY: EXTENDED FOLLOW UP",
                      modalHeading: "APEX STUDY: EXTENDED FOLLOW UP",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "velcade.apex-study-health-related",
                      label: "APEX STUDY: HEALTH RELATED QUALITY OF LIFE (HRQL) ANALYSIS",
                      modal: "APEX STUDY: HEALTH RELATED QUALITY OF LIFE (HRQL) ANALYSIS",
                      modalHeading: "APEX STUDY: HEALTH RELATED QUALITY OF LIFE (HRQL) ANALYSIS",
                    },
                  ],
                },
              ],
            },
          ],
        },
        additional_information: {
          collapses: [
            {
              type: "checkbox",
              title: "CONFIRMATION OF ACTIVE MYELOMA",
              visible: false,
              sections: [
                {
                  text: "IMWG CRITERIA CLASSIC CRAB FEATURES",

                  motivate: [
                    {
                      icon: false,
                      variant: "dark",
                      html: true,
                      details: `<p><strong>International Myeloma Working Group (IMWG) Criteria for the diagnosis of MM</strong><sup>1</sup><br/>
The IMWG criteria allow, in addition to the classic CRAB features, three myeloma-defining events (MDEs). The presence of at least one of these markers is considered sufficient for a diagnosis of MM, regardless of the presence or absence of symptoms or CRAB features. Each of these markers has been shown in two or more independent studies to be associated with an approximately 80 % or higher risk of developing myeloma-related organ damage within two years.

</p>`,
                    },
                    {
                      icon: false,
                      variant: "dark",
                      html: true,
                      details: `
                      <p><strong>Revised IMWG Criteria for the Diagnosis of Multiple Myeloma</strong><sup>1</sup><br>
  <strong>Definition of Active MM</strong></p>
  <p>Clonal bone marrow plasma cells &gt; 10 % or biopsy-proven bony or extramedullary plasmacytoma and any one or more of the following CRAB features and MDEs</p>
                      `,
                    },
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "Upon selecting the relevant criteria below, your selection will automatically populate onto you letter when exporting. It is advisable to elaborate on your selection criteria to the funder",
                    },
                  ],
                  options: [
                    {
                      type: "basic",
                      value: false,
                      description: "Serum calcium >0,25 mmol/L (>1 mg/dL) higher than the upper limit of normal or >2,75 mmol/L (>11 mg/dL)",
                      label: "Hypercalcaemia",
                      references: [
                        {
                          id: 1,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: <a href='https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma'>https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma</a>. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description: "Creatinine clearance <40 mL per minute or serum creatinine >177 mmol/L (>2 mg/dL)",
                      label: "Renal insufficiency",
                      references: [
                        {
                          id: 2,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: <a href='https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma'>https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma</a>. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description: "Haemoglobin valure of >20 g/L below the lowest limit of normal, or a haemoglobin value <100 g/L) ",
                      label: "Anaemia",
                      references: [
                        {
                          id: 3,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: <a href='https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma'>https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma</a>. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description:
                        "One or more osteolytic lesion on skeletal radiography, CT, or PET/CT. If bone marrow has <10 % clonal plasma cells, more than one bone lesion is required to distinguish from solitary plasmacytoma with minimal marrow involvement",
                      label: "Bone Lesions",
                      references: [
                        {
                          id: 4,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: <a href='https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma'>https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma</a>. Accessed 30 August 2019",
                        },
                      ],
                    },
                  ],
                },
                {
                  text: "BIOMARKERS OF MALIGNACY (MDES)",
                  options: [
                    {
                      type: "basic",
                      value: false,
                      description: "60 % or greater clonal plasma cells on bone marrow examination",
                      label: "",
                      references: [
                        {
                          id: 5,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: <a href='https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma'>https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma</a>. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description:
                        "Serum involved/uninvolved free light chain ratio of 100 or greater, provided the absolute level of the involved light chain is at least 100 mg/L (a patient’s involved free light chain either kappa or lambda is the one that is above the normal reference range; the uninvolved free light chain is the one that is typically in, or below, the normal range)",
                      label: "",
                      references: [
                        {
                          id: 6,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: <a href='https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma'>https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma</a>. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description: "More than one focal lesion on MRI that is at least 5 mm or greater in size",
                      label: "",
                      references: [
                        {
                          id: 7,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: <a href='https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma'>https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma</a>. Accessed 30 August 2019",
                        },
                      ],
                    },
                  ],
                },
                {
                  text: "References",
                  options: [
                    {
                      type: "reference",
                      html: true,
                      static: [
                        "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: <a href='https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma'>https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma</a>. Accessed 30 August 2019",
                      ],
                      references: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "view",
              title: "RESPONSE CRITERIA",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: false,
                      variant: "empty",
                      html: true,
                      details: `<p>
                     International Myeloma Working group (IMWG) criteria for response assessment including criteria for minimal residual disease (MRD)</p>`,
                    },
                    {
                      icon: true,
                      variant: "danger",
                      html: true,
                      details: `<span>
                      Reference:<br>
                      Adapted from NCCN Guidelines&reg;: Multiple Myeloma <a href="https://www.nccn.org" >https://www.nccn.org</a></span>`,
                    },
                  ],
                  text: "IMWG MRD CRITERIA",
                  description: "",
                  data: `imwg-mrd-criteria`,
                },
                {
                  text: "IMWG STANDARD CRITERIA",
                  description: "",
                  data: `imwg-mrd-criteria-standard`,
                },
              ],
            },
            {
              type: "view",
              title: "HIGH RISK PROGNOSTIC DISEASE",
              visible: false,
              sections: [
                {
                  text: "FACTORS PROGNOSTIC OF HIGH RISK DISEASE IN MULTIPLE MYELOMA (MM)",
                  superscript: "1",
                  description: null,
                  data: "factors-prognostic",
                },
              ],
            },
            {
              type: "view",
              title: "INTERNATIONAL STAGING SYSTEM",

              sections: [
                {
                  superscript: "1",
                  text: "INTERNATIONAL STAGING SYSTEM (ISS) FOR MULTIPLE MYELOMA (MM)",
                  description: null,
                  data: `internation-staging-system`,
                },
              ],
            },
            {
              title: "INTERNATIONAL GUIDELINES",
              visible: false,
              header: "USEFUL LINKS FOR ADDITIONAL INFORMATION ",
              type: "information",
              sections: [
                {
                  title: "ESMO - European Society for Medical Oncology",
                  link: "https://www.esmo.org/",
                },
                {
                  title: "NCCN - National Comprehensive Cancer Network",
                  link: "https://www.nccn.org",
                },
              ],
            },
            {
              title: "MEDICAL INFORMATION",
              visible: false,
              header: null,
              type: "html",
              data: `
          <p><strong class="text-color-change">
          Should you require any further clinical trials or medical information please use the below contact information 
          </strong>
          </p>
          <p>
          Email: <a class="link-color" href="mailto:ra-medinfoemmarkets@its.jnj.com">ra-medinfoemmarkets@its.jnj.com</a>
          <br>Telephone: 0860 11 11 17
          </p>
          `,
            },
          ],
        },
      },
    };
  },

  methods: {
    referenceAdd(evnt, name) {
      let lengthForRef = 0;
      if (this.form[name].collapses[evnt.collapseIndex].sections.length > 0) {
        lengthForRef = this.form[name].collapses[evnt.collapseIndex].sections.length - 1;
      }

      evnt.data.forEach((val, index) => {
        this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options[0].references.push(val);
      });
    },
    referenceRemove(evnt, name) {
      let lengthForRef = 0;
      if (this.form[name].collapses[evnt.collapseIndex].sections.length > 0) {
        lengthForRef = this.form[name].collapses[evnt.collapseIndex].sections.length - 1;
      }

      let tempArray = this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options.filter((val, index) => {
        return val.type == "reference";
      });
      let tempRemoved = [];

      tempArray[0].references.forEach((val, index) => {
        evnt.data.forEach((data, dataIndex) => {
          if (Object.is(data, val)) {
          } else {
            tempRemoved.push(val);
          }
        });
      });
      this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options[0].references = tempRemoved;
    },

    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepData = this.prepVelcade(this.form);

        let processed = this.flatData(prepData, velcadeJSON);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    save() {
      this.setLocal("velcade", this.form);
    },

    async exportPDF(reaction) {
      let prepData = this.prepVelcade(this.form);
      let processed = this.flatData(prepData, velcadeJSON);
      let response = await this.exportPDFUrl(processed, this.form.patient_detail_full_name);

      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    async exportWord(reaction) {
      let prepData = this.prepVelcade(this.form);
      let processed = this.flatData(prepData, velcadeJSON);
      let response = await this.exportWordUrl(processed, this.form.patient_detail_full_name);
      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    prepVelcade(form) {
      let checkbox;

      let returnObject = {
        hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
        date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
        patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
        date_of_birth: form.date_of_birth ? moment(form.date_of_birth).format("DD/MM/YYYY") : "",
        medical_aid_name: form.medical_aid_name ? form.medical_aid_name : "",
        plan_option: form.plan_option ? form.plan_option : "",
        medical_aid_membership_number: form.medical_aid_membership_number ? form.medical_aid_membership_number : "",
        idc_10_code: form.idc_10_code ? form.idc_10_code : "",
      };
      if (form.motivation) {
        returnObject["motivation_header"] = "MOTIVATION";
        returnObject["motivation"] = form.motivation ? form.motivation : "";
      }
      checkbox = this.getCheckbox(form.clinical_trial);
      returnObject["clinical_trial"] = checkbox;
      let tempData = {
        collapses: [form.additional_information.collapses[0]],
      };
      let tempAddition = this.getCheckbox(tempData);

      if (tempAddition.length > 0) {
        returnObject["confirmation_active_myeloma_header"] = "CONFIRMATION OF ACTIVE MYELOMA";
        returnObject["additional_information"] = tempAddition;
      }

      return returnObject;
    },
    reset() {
      this.resetStorage("velcade");
    },
  },
  created() {
    let data = this.getLocal("velcade");
    if (data) {
      this.form = data;
    }
  },
};
</script>
