import Vue from 'vue';
import axios from 'axios'
export const ModalInformation = {

    methods: {
        TrialModal(name) {
            //(name)
            switch (name) {
                case 'resonate_2':
                    return this.$route.params.data.resonate_2
                case 'RESONATE CLINICAL TRIAL':
                    return this.$route.params.data.resonate
                case 'alliance':
                    return this.$route.params.data.alliance
                case 'PCYC 1101/3 CLINICAL TRIAL':
                    return this.$route.params.data.pcyc_1101
                case 'SPARK (MCL2001) CLINICAL TRIAL':
                    return this.$route.params.data.spark;
                case 'huang_et_al':
                    return this.$route.params.data.huang_et_al;
                case 'PCYC-1104 CLINICAL TRIAL':
                    return this.$route.params.data.pycyc_1104
                case 'PCYC-118e CLINICAL TRIAL':
                    return this.$route.params.data.pycyc__118e
                case 'VISTA STUDY':
                    return this.$route.params.data.vista_study
                case 'VISTA STUDY: FIVE YEARS FOLLOW UP':
                    return this.$route.params.data.vista_study_five
                case 'sustain':
                    return this.$route.params.data.sustain
                case 'transform':
                    return this.$route.params.data.transform
                case 'VISTA STUDY: ASSESSMENT OF THE PROGNOSTIC IMPACT OF RESPONSE ON TIME-TO-EVENT PARAMETERS':
                    return this.$route.params.data.vista_study_assessment
                case 'CREST STUDY':
                    return this.$route.params.data.crest_study
                case 'SUMMIT STUDY':
                    return this.$route.params.data.summit_study
                case 'SUMMIT STUDY: EXTENDED FOLLOW UP':
                    return this.$route.params.data.summit_study_extend
                case 'CREST & SUMMIT STUDIES: RETROSPECTIVE ANALYSIS IN PATIENTS WITH RENAL':
                    return this.$route.params.data.crest_and_study
                case 'APEX STUDY':
                    return this.$route.params.data.apex_study
                case 'APEX STUDY: EXTENDED FOLLOW UP':
                    return this.$route.params.data.apex_study_extend
                case 'APEX STUDY: HEALTH RELATED QUALITY OF LIFE (HRQL) ANALYSIS':
                    return this.$route.params.data.apex_study_health
                case 'factors-prognostic':
                    return this.$route.params.data.factors_prognostic
                case 'imwg-mrd-criteria':
                    return this.$route.params.data.imwg_mrd_criteria
                case 'imwg-mrd-criteria-standard':
                    return this.$route.params.data.imwg_mrd_criteria_standard
                case 'internation-staging-system':
                    return this.$route.params.data.internation_staging_system
                case 'cassiopeia':
                    return this.$route.params.data.cassiopeia
                case 'maia':
                    return this.$route.params.data.maia
                case 'maia2':
                    return this.$route.params.data.maia2
                case 'seraphin':
                    return this.$route.params.data.seraphin
                case 'griphon':
                    return this.$route.params.data.griphon
                case 'dreyling':
                    return this.$route.params.data.dreyling
                case 'alcyone':
                    return this.$route.params.data.alcyone
                case 'fonesca':
                    return this.$route.params.data.fonesca
                case 'kiss2021':
                    return this.$route.params.data.kiss2021
                case 'castor':
                    return this.$route.params.data.castor
                case 'pollux':
                    return this.$route.params.data.pollux
                case 'rwe':
                    return this.$route.params.data.rwe
                case 'final_results':
                    return this.$route.params.data.final_results
                case 'chari':
                    return this.$route.params.data.chari
                case 'cavo':
                    return this.$route.params.data.cavo
                case 'post_chemo':
                    return this.$route.params.data.post_chemo
                case 'pre_chemo':
                    return this.$route.params.data.pre_chemo
                case 'latitude':
                    return this.$route.params.data.latitude
                case 'spartan':
                    return this.$route.params.data.spartan
                case 'final_survival':
                    return this.$route.params.data.final_survival
                case 'immune_restoration_moreno':
                    return this.$route.params.data.immune_restoration_moreno
                case 'titan':
                    return this.$route.params.data.titan
                case 'final_titan':
                    return this.$route.params.data.final_titan
                case 'savitz':
                    return this.$route.params.data.savitz;
                case 'weiden_ct':
                    return this.$route.params.data.weiden_ct;
                case 'alliance_older':
                    return this.$route.params.data.alliance_older;
                case 'aleration':
                    return this.$route.params.data.aleration;
                case 'cross_trial_comparison':
                    return this.$route.params.data.cross_trial_comparison;
                case 'general_manage':
                    return this.$route.params.data.general_manage;
                case 'relapse_refactory':
                    return this.$route.params.data.relapse_refactory;
                case 'resonate_refractory_resonate':
                    return this.$route.params.data.resonate_refractory_resonate;
                case 'GHIA-2022':
                    return this.$route.params.data.GHIA_2022;
                case 'single_agent':
                    return this.$route.params.data.single_agent;
                case 'long_term_efficacy_5_year':
                    return this.$route.params.data.long_term_efficacy_5_year;
                case 'long_term_efficacy_7_year':
                    return this.$route.params.data.long_term_efficacy_7_year;
                case 'illuminate':
                    return this.$route.params.data.illuminate;
                case 'resonate_17':
                    return this.$route.params.data.resonate_17;
                case 'helios':
                    return this.$route.params.data.helios;
                case 'innovate':
                    return this.$route.params.data.innovate;
                case 'castillo':
                    return this.$route.params.data.castillo;
                case 'combination_therapy':
                    return this.$route.params.data.combination_therapy;
                case 'pcyc1102':
                    return this.$route.params.data.pcyc1102;
                case 'regulations_2020111':
                    return this.$route.params.data.regulations_2020111;
                case 'rebuttals_2020112':
                    return this.$route.params.data.rebuttals_2020112;
                case 'legal_framework':
                    return this.$route.params.data.legal_framework
                case 'legal_framework_pmb':
                    return this.$route.params.data.legal_framework_pmb
                case 'legal_framework_co_payments':
                    return this.$route.params.data.legal_framework_co_payments
                case 'rebuttals_state':
                    return this.$route.params.data.rebuttals_state
                case 'rebuttals_innapropriate_care':
                    return this.$route.params.data.rebuttals_innapropriate_care
                case 'rebuttals_medicine_costs':
                    return this.$route.params.data.rebuttals_medicine_costs
                case 'rebuttals_benefit':
                    return this.$route.params.data.rebuttals_benefit




                case 'legal_reimbursement':
                    return this.$route.params.data.legal_reimbursement
                case 'darzalex_rebuttal':
                    return this.$route.params.data.darzalex_rebuttal
                case 'imbruvica_rebuttal':
                    return this.$route.params.data.imbruvica_rebuttal

                case 'legal_framework_uptravi':
                    return this.$route.params.data.legal_framework_uptravi
            }
        },

    },
    created: function () {


    }
}
