<template>
  <Layout>
    <template v-slot:previewArea>
      <Preview :alert="alertMessage" @alertClose="alertMessage.show = false" :previewDetails="options.preview"
        @preview="preview()" @save="save()" @reset="reset()" @exportPDF="showModalExports('pdf')"
        @exportWord="showModalExports('Word')"></Preview>
    </template>
    <template v-slot:required-header>
      <b-row>
        <b-col md="12" sm="12">
          <h5>HCP Details</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input type="text" :binding="form.hcp_full_name" placeholder="Enter Dr Name"
            :required="validateErrors.hcp_full_name" label="HCP Name" @changeValue="
              form.hcp_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :binding="form.date" name="date" label="Date" :required="validateErrors.date"
            placeholder="Enter Date" @changeValue="
              form.date = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Patient Details</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input type="text" :binding="form.patient_detail_full_name" placeholder="Enter Patient's full name"
            label="Full Name" :required="validateErrors.patient_detail_full_name" @changeValue="
              form.patient_detail_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :binding="form.date_of_birth" name="date_of_birth" :required="validateErrors.date_of_birth"
            placeholder="Enter Date of birth" label="Date of Birth" @changeValue="
              form.date_of_birth = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <b-row>
        <b-col md="8" sm="12">
          <Input type="text" :binding="form.medical_aid_name" placeholder="Enter Medical Aid Name "
            label="Medical Aid Name" @changeValue="form.medical_aid_name = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="text" :binding="form.plan_option" placeholder="Enter option here" label="Plan Option"
            @changeValue="form.plan_option = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input type="text" :binding="form.medical_aid_membership_number" placeholder="Enter Membership Number"
            label="Membership Number" @changeValue="form.medical_aid_membership_number = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput :binding="form.idc_10_code" @changeValue="form.idc_10_code = $event" :options="options.idc_10_code"
            label="ICD 10 Codes" placeholder name="icd_10_codes"></SelectInput>
        </b-col>
      </b-row>
      <b-row v-if="form.idc_10_code == 'Other'">
        <b-col md="8" sm="0"></b-col>
        <b-col md="4" sm="12">
          <Input type="text" :binding="form.idc_10_code_other" placeholder="Other" label="Please type other code"
            @changeValue="form.idc_10_code_other = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <SelectInput :binding="form.pmb_code" @changeValue="form.pmb_code = $event" :options="options.pmb_code"
            label="PMB Code" placeholder="PMB Code" name="pmb_code"></SelectInput>
        </b-col>
        <b-col md="4" sm="12">
          <Input v-if="form.pmb_code == 'Other'" type="text" :binding="form.pmb_code_other" placeholder="Other"
            label="Please type other code" @changeValue="form.pmb_code_other = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Medication</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input type="text" :binding="form.medication_previous" placeholder="Previous Medication"
            label="Previous Medication" @changeValue="form.medication_previous = $event" />
        </b-col>
        <b-col md="4" sm="0"></b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <SelectInput :binding="form.reason_for_stopping" @changeValue="form.reason_for_stopping = $event"
            :options="options.reason_for_stopping" label="Reason for stopping" placeholder="Reason for stopping"
            name="reason_for_stopping"></SelectInput>
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput :binding="form.reason_for_selecting_new_medication"
            @changeValue="form.reason_for_selecting_new_medication = $event"
            :options="options.reason_for_selecting_new_medication" label="Reason for selecting new medication"
            placeholder="Reason for selecting new medication" name="reason_for_selecting_new_medication"></SelectInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input v-if="form.reason_for_stopping == 'Other'" type="text" :binding="form.reason_for_stopping_other"
            placeholder="Other" label="Please type other code" @changeValue="form.reason_for_stopping_other = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input v-if="form.reason_for_selecting_new_medication == 'Other'" type="text"
            :binding="form.reason_for_selecting_new_medication_other" placeholder="Other" label="Please type other code"
            @changeValue="form.reason_for_selecting_new_medication_other = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Hospitalisation</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input type="text" :binding="form.hospitalisation_reason" placeholder="Enter Reason for hospitialisation"
            label="Reason for hospitialisation" @changeValue="form.hospitalisation_reason = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :binding="form.hospitalisation_date" name="hospitalisation_date"
            placeholder="Enter Date of hospitalisation" label="Date of hospitalisation"
            @changeValue="form.hospitalisation_date = $event" />
        </b-col>
      </b-row>
    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Motivation</h5>
          <p>
            <span class="red-text"> Please view and include any necessary clinical data that can be found in the
              Additional Information section below. Remember to attach all your patient’s results with your motivational
              letter. </span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <Input type="textarea" :binding="form.motivation" placeholder="Motivate here"
            @changeValue="form.motivation = $event" />
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton :tabs="options.tabs" :selectedTab="selectedTab" @changeTab="
        selectedTab = $event;
      changeActive($event);
      "></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="'trials' == selectedTab">
        <b-col md="12" sm="12">
          <Accordion :info="form.clinical_data_choice" type="radio" @addValue="radioAdd($event, 'clinical_data_choice')"
            @referencesAdd="referenceAdd($event, 'clinical_data_choice')"
            @referenceRemove="referenceRemove($event, 'clinical_data_choice')"
            @radioAdd="radioAdd($event, 'clinical_data_choice')"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="'additional' == selectedTab">
        <b-col md="12" sm="12">
          <Accordion :info="form.additional_information" type="radio"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'checklist'">
        <Checklist>
          <template v-slot:pre-title>
            <b-alert variant="danger" show>
              <b-container fluid>
                <b-row>
                  <b-col md="1" sm="1" class="pr-0 icon-col">
                    <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                  </b-col>
                  <b-col md="11" sm="11">Please remember to add / attach the below checklist documentation when submitting
                    your motivational letter.</b-col>
                </b-row>
              </b-container>
            </b-alert>
          </template>
          <template v-slot:download>
            <b-button class="download-btn" @click="downloadFile('xeplion')">Download</b-button>
          </template>
          <template v-slot:title>
            <h4 class="section-title section-title-spacing">CHECKLIST OF DOCUMENTS TO INCLUDE IN YOUR MOTIVATION FOR
              XEPLION</h4>
          </template>
          <template v-slot:content>
            <ul>
              <li>Attach script</li>
              <li>Attach motivation</li>
              <li>Disclaiming and dosing</li>
            </ul>
          </template>
        </Checklist>
      </b-row>
    </template>
    <template v-slot:modal-preview>
      <ModalComponent :name="previewModal.data" @closing="closeModal()" :response="true"
        :modalName="previewModal.modalName" :showModal="previewModal.show"></ModalComponent>
    </template>
    <template v-slot:modal-type>
      <ModalComponent size="md" :defaultModal="true" @closing="exportModal.show = false" :response="true"
        :DocumentType="exportModal.type" :modalName="exportModal.modalName" :showModal="exportModal.show" :footer="false"
        @saveExport="processType($event, 'save')" @exportReset="processType($event, 'reset')"></ModalComponent>
    </template>
    <template v-slot:formControl>
      <FormControl :alert="alertMessage" @alertClose="alertMessage.show = false" @preview="preview()" @save="save()"
        @reset="reset()" @exportPDF="showModalExports('pdf')" @exportWord="showModalExports('Word')"></FormControl>
    </template>
  </Layout>
</template>

<script>
import Preview from "@/components/Preview";
import Input from "@/components/Fields/Input";
import moment from "moment";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import xeplionJSON from "@/assets/json/newXeplion.json";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import Layout from "@/pages/Layouts";
import FormControl from "../components/FormControl.vue";
export default {
  components: {
    Layout,
    Preview,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent,
    FormControl
  },
  mixins: [GeneralUsage],
  data() {
    return {
      options: {
        tabs: [
          { value: "trials", text: "Clinical Trials" },
          {
            value: "additional",
            text: "Additional Information",
          },
          {
            value: "checklist",
            text: "Checklist",
          },
        ],
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Clinical Trials", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional",
          },
          { value: false, text: "Checklist", relation: "checklist" },
        ],
        idc_10_code: [
          { value: null, text: "Select an option" },
          { value: "F20.0 (Schizophrenia)", text: "F20.0 (Schizophrenia)" },
          {
            value: "F20.1 (Paranoid Schizophrenia)",
            text: "F20.1 (Paranoid Schizophrenia)",
          },
          {
            value: "F20.2 (Hebephrenic Schizophrenia)",
            text: "F20.2 (Hebephrenic Schizophrenia)",
          },
          {
            value: "F20.3 (Catatonic Schizophrenia)",
            text: "F20.3 (Catatonic Schizophrenia)",
          },
          {
            value: "F20.4 (Undifferentiated Schizophrenia)",
            text: "F20.4 (Undifferentiated Schizophrenia)",
          },
          {
            value: "F20.5 (Post Schizophrenia Depression)",
            text: "F20.5 (Post Schizophrenia Depression)",
          },
          {
            value: "F20.6 (Residual Schizophrenia)",
            text: "F20.6 (Residual Schizophrenia)",
          },
          {
            value: "F20.7 (Simple Schizophrenia)",
            text: "F20.7 (Simple Schizophrenia)",
          },
          {
            value: "F20.8 (Other Schizophrenia)",
            text: "F20.8 (Other Schizophrenia)",
          },
          {
            value: "F20.9 (Schizophrenia Unspecified)",
            text: "F20.9 (Schizophrenia Unspecified)",
          },
          { value: "Other", text: "Other (If other then free text) ) " },
        ],
        pmb_code: [
          { value: null, text: "Select one" },
          { value: "907T", text: "907T" },
          { value: "Other", text: "Other" },
        ],
        reason_for_stopping: [
          { value: null, text: "Select an option" },
          { value: "Lack of efficacy", text: "Lack of efficacy" },
          {
            value: "Safety",
            text: "Safety",
          },
          { value: "Causing harm", text: "Causing harm" },
          { value: "Would cause harm", text: "Would cause harm" },
          { value: "Other", text: "Other" },
        ],
        reason_for_selecting_new_medication: [
          { value: null, text: "Select an option" },
          { value: "Improved adherence", text: "Improved adherence " },
          { value: "Reduce hospitalizations", text: "Reduce hospitalizations" },
          { value: "Metabolic Benefit ", text: "Metabolic Benefit " },
          { value: "Improved Tolerability", text: "Improved Tolerability " },
          { value: "Other", text: "Other" },
        ],
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        idc_10_code: null,
        idc_10_code_other: "",
        pmb_code: null,
        pmb_code_other: "",
        medication_previous: "",
        reason_for_stopping: null,
        reason_for_stopping_other: "",
        reason_for_selecting_new_medication: null,
        reason_for_selecting_new_medication_other: "",
        hospitalisation_reason: "",
        hospitalisation_date: null,
        motivation: "",
        icd_type: "",
        clinical_data: null,
        clinical_data_ref: null,
        regulation_header: null,
        clinical_data_choice: {
          collapses: [
            {
              title: "XEPLION CLINICAL DATA",
              visible: false,
              type: "radio",
              group: null,
              sections: [
                {
                  text: "CLINICAL DATA",
                  options: [
                    {
                      title: "RELAPSE PREVENTION",
                      value: "RELAPSE PREVENTION",
                      superscript: 1,
                      description: "XEPLION® demonstrated better symptom control and a 6-fold reduction in relapse at 1 year with the LAI formulation compared with oral formulations.",
                      references: [
                        {
                          id: 1,
                          text: "Mauri MC, Reggiori A, Paletta S, et al. Palperidone for the treatment of schizophrenia and schizoaffective disorders - a drug safety evaluation, Expert Opinion Drug Safety, 2017;16(3):365 -379.",
                        },
                      ],
                    },
                    {
                      title: "EFFICACY",
                      value: "EFFICACY",
                      superscript: 1,
                      description: "XEPLION® demonstrated to improve relapse prevention and to reduce hospitalisation rate enhancing antipsychotic efficacy with reduced drug exposure and tolerance.",
                      references: [
                        {
                          id: 2,
                          text: "Mauri MC, Reggiori A, Paletta S, et al. Palperidone for the treatment of schizophrenia and schizoaffective disorders - a drug safety evaluation, Expert Opinion Drug Safety, 2017;16(3):365 -379.",
                        },
                      ],
                    },
                  ],
                },
                {
                  text: "References",
                  options: [
                    {
                      type: "reference",
                      static: ["Mauri MC, Reggiori A, Paletta S, et al. Palperidone for the treatment of schizophrenia and schizoaffective disorders - a drug safety evaluation, Expert Opinion Drug Safety, 2017;16(3):365 -379."],
                      references: [],
                    },
                  ],
                },
              ],
            },
            {
              title: "REGULATION 15H & 15I",
              visible: false,
              type: "checkbox",
              sections: [
                {
                  text: "REGULATION 15H & 15I",
                  options: [
                    {
                      type: "basic",
                      value: false,
                      label: "Regulation 15H",
                      link: "Regulation-15H",
                      special: true,
                      endSuper: "1",
                      description: "Regulation 15H states that provision must be made for appropriate exceptions where a protocol has been ineffective or causes or would cause harm to a beneficiary, without penalty to that beneficiary.",
                      references: [
                        {
                          id: 3,
                          text: "Regulations to the Medical Schemes Act, 1998. GNR.1262 of 20 October 1999 as amended.",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      link: "Regulation-15I",
                      label: "Regulation 15I",
                      endSuper: "1",
                      special: true,
                      description: "Regulation 15I states that provision must be made for appropriate substitution of drugs where a formulary drug has been ineffective or would cause adverse reaction in a beneficiary, without penalty to that beneficiary.",
                      references: [
                        {
                          id: 4,
                          text: "Regulations to the Medical Schemes Act, 1998. GNR.1262 of 20 October 1999 as amended.",
                        },
                      ],
                    },
                  ],
                },
                {
                  text: "References",
                  options: [
                    {
                      type: "reference",
                      static: ["Regulations to the Medical Schemes Act, 1998. GNR.1262 of 20 October 1999 as amended."],
                      references: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        additional_information_choice: null,
        additional_information_choice_input: "",
        additional_information: {
          collapses: [
            {
              title: "PATIENT MOTIVATION",
              visible: false,
              type: "patient_motivation",
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: true,
                      details: `
                        <p>In this section you are able to leave space for  your patient to insert their hand written motivation as to what they are going through or why they want to motivate to their medical aid.  
</p><p>
Alternatively you can use the free text box to motivate on behalf of your patient in typed text
</p>
                        `,
                    },
                  ],
                  text: "PATIENTS OWN MOTIVATION",
                  choice: null,
                  options: [
                    {
                      value: "",
                      label: "Patients Motivation",
                      type: "textarea",
                    },
                  ],
                },
              ],
            },
            {
              title: "MEDICAL INFORMATION",
              visible: false,
              header: "MEDICAL INFORMATION",
              type: "html",
              data: `

          <p>
          <strong>
          Should you require any further clinical trials or medical information please use the below contact information 
          </strong>
          </p>
          <p>
          Email: <a href="mailto:ra-medinfoemmarkets@its.jnj.com">ra-medinfoemmarkets@its.jnj.com</a>
          <br>Telephone: 0860 11 11 17
          </p>
          `,
            },
          ],
        },
      },
      previewModal: {
        data: null,
        show: false,
        name: "Preview",
        modalName: "PREVIEW",
        footer: true,
      },
      exportModal: {
        show: false,
        type: "Word document",
        name: "Export to Word",
        modalName: "Export to Word",
        footer: true,
      },
    };
  },
  methods: {
    radioAdd(evnt, name) {

      let lengthForRef = 0;
      if (this.form[name].collapses[evnt.collapseIndex].sections.length > 0) {
        lengthForRef = this.form[name].collapses[evnt.collapseIndex].sections.length - 1;
      }
      this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options[0].references = evnt.data;
    },

    // Preview screen and modal
    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepData = this.prepXeplion(this.form);
        let processed = this.flatData(prepData, xeplionJSON);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    // Save to local storage
    save() {
      this.setLocal("xeplion", this.form);
    },
    // Export pdf
    async exportPDF(reaction) {
      let prepXeplion = this.prepXeplion(this.form);
      let processed = this.flatData(prepXeplion, xeplionJSON);
      let response = await this.exportPDFUrl(processed, this.form.patient_detail_full_name);
      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    // Export word
    async exportWord(reaction) {
      let prepXeplion = this.prepXeplion(this.form);
      let processed = this.flatData(prepXeplion, xeplionJSON);
      let response = await this.exportWordUrl(processed, this.form.patient_detail_full_name);
      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },

    // Reset the data
    reset() {
      this.resetStorage("xeplion");
    },
    prepXeplion(form) {
      let checkbox;

      let returnObject = {
        hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
        date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
        patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
        date_of_birth: form.date_of_birth ? moment(form.date_of_birth).format("DD/MM/YYYY") : "",
        medical_aid_name: form.medical_aid_name ? form.medical_aid_name : "",
        plan_option: form.plan_option ? form.plan_option : "",
        medical_aid_membership_number: form.medical_aid_membership_number ? form.medical_aid_membership_number : "",
        idc_10_code: form.idc_10_code ? form.idc_10_code : "",
        pmb_code: form.pmb_code ? form.pmb_code : "",
        // medication_previous: form.medication_previous ? form.medication_previous : "",
        // reason_for_stopping: form.reason_for_stopping ? form.reason_for_stopping : "",
        // hospitalisation_reason: form.hospitalisation_reason ? form.hospitalisation_reason : "",
        // hospitalisation_date: form.hospitalisation_date ? form.hospitalisation_date : ""
      };
      if (form.idc_10_code == "Other") {
        returnObject["idc_10_code_other"] = form.idc_10_code_other;
      }
      if (form.pmb_code == "Other") {
        returnObject["pmb_code_other"] = form.pmb_code_other;
      }
      if (form.reason_for_stopping == "Other") {
        returnObject["reason_for_stopping_other"] = form.reason_for_stopping_other;
      }
      if (form.reason_for_selecting_new_medication == "Other") {
        returnObject["reason_for_selecting_new_medication_other"] = form.reason_for_selecting_new_medication_other;
      }
      if (form.motivation) {
        returnObject["motivation_header"] = "MOTIVATION";
        returnObject["motivation"] = form.motivation ? form.motivation : "";
      }
      if (form.medication_previous) {
        returnObject["previous_medication_header"] = "PREVIOUS MEDICATION";
        returnObject["medication_previous"] = form.medication_previous ? form.medication_previous : "";
      }
      if (form.reason_for_stopping) {
        returnObject["reason_for_stopping_header"] = "REASON FOR STOPPING";
        returnObject["reason_for_stopping"] = form.reason_for_stopping ? form.reason_for_stopping : "";
      }
      if (form.reason_for_selecting_new_medication) {
        returnObject["reason_for_selecting_new_medication_header"] = "REASON FOR SELECTING NEW MEDICATION";

        returnObject["reason_for_selecting_new_medication"] = form.reason_for_selecting_new_medication ? form.reason_for_selecting_new_medication : "";
      }

      if (form.hospitalisation_reason || form.hospitalisation_date) {
        returnObject["hospitalisation_header"] = "HOSPITALISATION";
        returnObject["hospitalisation_reason"] = form.hospitalisation_reason ? form.hospitalisation_reason : "";
        returnObject["hospitalisation_date"] = form.hospitalisation_date ? moment(form.hospitalisation_date).format("DD/MM/YYYY") : "";
      }
      checkbox = this.getCheckbox(form.clinical_data_choice);

      returnObject["clinical_trial"] = checkbox;
      let tempInput = this.patientsMotivation(form.additional_information.collapses);
      returnObject["additional_information"] = tempInput;

      return returnObject;
    },
  },
  created() {
    let data = this.getLocal("xeplion");
    if (data) {
      this.form = data;
    }
  },
};
</script>
