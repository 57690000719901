<template>
  <div role="tablist">
    <b-card no-body class="mb-1" v-for="(item, index) in info.collapses" :key="'collapse' + index + item.title"
      :id="item.title + index">
      <b-card-header header-tag="header" :class="['p-2', 'text-white', { backgroundChange: item.visible }]"
        :aria-controls="'collapse-' + index + item.title" role="tab">
        <div class="accordion-btn" @click="
          item.visible = !item.visible;
        scrollTopFunction();
        ">
          <b-container fluid>
            <b-row class="mb-0">
              <b-col md="11" sm="10">
                <strong class="center-title">{{ item.title }}</strong>
              </b-col>
              <b-col md="1" sm="2">
                <strong class="text-sign">{{ item.visible ? "-" : "+" }}</strong>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-card-header>
      <b-collapse v-model="item.visible" :id="'collapse-' + index + item.title" accordion="my-accordion" role="tabpanel"
        :appear="true">
        <template v-if="item.type == 'table'">
          <b-card-body>
            <b-container>
              <b-row v-for="(section, indexSection) in item.sections" :key="indexSection">
                <b-col md="12" sm="12">
                  <h5 class="section-title pt-3" v-if="section.type != 'reference'">
                    {{ section.text }}
                  </h5>
                  <hr v-if="section.type != 'reference'" />
                  <template v-if="section.subheading">
                    <b-row>
                      <b-col>
                        <b-alert show variant="danger">
                          <b-container fluid>
                            <b-row class="h-100">
                              <b-col md="11" sm="11" class="my-auto">{{ section.subheading }}</b-col>
                            </b-row>
                          </b-container>
                        </b-alert>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-if="section.type == 'reference'">
                    <b-row>
                      <b-col md="12" sm="12">
                        <!-- {{section}} -->
                        <b-alert show variant="danger" class="reference-text">
                          <p>References</p>
                          <ol>
                            <li v-for="(reference, referIndex) in section.static" :key="'reference' + referIndex">
                              <span v-html="reference"></span>
                            </li>
                          </ol>
                        </b-alert>
                      </b-col>
                    </b-row>
                  </template>
                  <table v-else class="table">
                    <thead>
                      <tr>
                        <th v-for="(theads, tindex) in section.table_header" :key="tindex">
                          {{ theads }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tableRow, tableRowIndex) in section.table_data" :key="tableRowIndex"
                        class="tableRowStyle">
                        <td v-for="(tableColumn, tableColumnIndex) in section.table_key" :key="tableColumnIndex"
                          @click="section.selectedValue = tableRow.value">
                          <b-form-radio v-model="section.selectedValue" v-if="tableColumn == 'value'"
                            :value="tableRow[tableColumn]"></b-form-radio>
                          <span v-if="tableColumn != 'value'">{{ tableRow[tableColumn] }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
        <template v-if="item.type == 'documents'">
          <b-card-body>
            <documents :data="item" @modalTrigger="setModalDetails($event.modal, $event.heading, $event.footerAppend)">
            </documents>
          </b-card-body>
        </template>
        <template v-if="item.type == 'multiple-choice'">
          <b-card-body>
            <b-container>
              <b-row v-for="(section, indexSection) in item.sections" :key="indexSection">
                <b-col md="12" sm="12">
                  <h5 class="section-title pt-3" v-if="section.type != 'reference'">
                    {{ section.text }}
                  </h5>
                  <hr v-if="section.type != 'reference'" />
                  <b-container>
                    <template v-if="section.motivate">
                      <b-row v-for="(motive, motiveIndex) in section.motivate" :key="'motivation' + motiveIndex">
                        <b-col md="12" sm="12">
                          <b-alert show :variant="motive.variant">
                            <b-container fluid>
                              <b-row class="h-100">
                                <b-col md="1" sm="1" class="pr-0 icon-col my-auto" v-if="motive.icon">
                                  <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                                </b-col>
                                <b-col v-if="motive.html" v-html="motive.details" md="11" sm="11" class="my-auto"></b-col>
                                <b-col v-if="motive.html == false" md="11" sm="11" class="my-auto">{{ motive.details
                                }}</b-col>
                              </b-row>
                            </b-container>
                          </b-alert>
                        </b-col>
                      </b-row>
                    </template>
                    <template v-if="section.type == 'reference'">
                      <b-row class="pt-3">
                        <b-col md="12" sm="12">
                          <!-- {{section}} -->
                          <b-alert show variant="danger" class="reference-text">
                            <p>References</p>
                            <ol>
                              <li v-for="(reference, referIndex) in section.static" :key="'reference' + referIndex">
                                <span v-html="reference"></span>
                              </li>
                            </ol>
                          </b-alert>
                        </b-col>
                      </b-row>
                    </template>
                    <template v-if="section.type == 'single'">
                      <b-row v-for="(option, optionIndex) in section.options" :key="optionIndex">
                        <b-col>
                          <b-form-radio v-model="section.radioValueBind" :value="option.value">{{ option.label
                          }}</b-form-radio>
                          <b-container v-if="section.radioValueBind == option.value && option.subs" class="pl-5">
                            <template v-if="option.subType == null">
                              <b-row v-for="(sub, subIndex) in option.subs" :key="subIndex">
                                <b-form-radio v-model="section.radioValueBindSub" :value="sub.value">{{ sub.label
                                }}</b-form-radio>
                              </b-row>
                            </template>
                            <template v-if="option.subType">
                              <b-form-group>
                                <b-form-checkbox-group id="checkbox-group-1" v-model="section.multiValueBindSub">
                                  <b-row v-for="(sub, subIndex) in option.subs" :key="subIndex">
                                    <b-form-checkbox :value="sub.value">{{ sub.label }}</b-form-checkbox>
                                  </b-row>
                                </b-form-checkbox-group>
                              </b-form-group>
                            </template>
                          </b-container>
                        </b-col>
                      </b-row>
                    </template>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
        <template v-if="item.type == 'checkbox'">
          <b-card-body>
            <b-container fluid>
              <b-row v-for="(section, indexSection) in item.sections" :key="'section' + indexSection">
                <b-col md="12" sm="12">
                  <b-container fluid>
                    <template v-if="section.motivate">
                      <b-row v-for="(motive, motiveIndex) in section.motivate" :key="'motivation' + motiveIndex">
                        <b-col md="12" sm="12">
                          <b-alert show :variant="motive.variant">
                            <b-container fluid>
                              <b-row class="h-100">
                                <b-col md="1" sm="1" class="pr-0 icon-col my-auto" v-if="motive.icon">
                                  <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                                </b-col>
                                <b-col v-if="motive.html" v-html="motive.details" md="11" sm="11" class="my-auto"></b-col>
                                <b-col v-if="motive.html == false" md="11" sm="11" class="my-auto">{{ motive.details
                                }}</b-col>
                              </b-row>
                            </b-container>
                          </b-alert>
                        </b-col>
                      </b-row>
                    </template>

                    <b-row v-if="section.options[0].type != 'reference'">
                      <b-col md="12" sm="12">
                        <h5 class="section-title" v-if="section.options[0].type != 'reference'">
                          {{ section.text }}
                        </h5>
                        <hr v-if="section.options[0].type != 'reference'" />
                        <p class="text-color-change mb-4"
                          v-if="section.text != 'IMWG CRITERIA CLASSIC CRAB FEATURES' && section.text != 'BIOMARKERS OF MALIGNACY (MDES)' && section.text != 'PATIENTS OWN MOTIVATION' && indexSection === 0">
                          <strong v-if="section.options[0].type != 'reference'">
                            Below are summaries of clinical trials that will aid you in your motivation.
                            <br />By selecting the trial summary, it will be automatically included in your letter. You
                            can also view the summary.
                          </strong>
                        </p>
                      </b-col>
                    </b-row>

                    <b-row v-for="(option, optionIndex) in section.options" :key="'option' + optionIndex" :class="[
                      'internal-options',
                      {
                        'extra-spacing': optionIndex == section.options.length - 1,
                      },
                    ]">
                      <b-container fluid v-if="option.type == 'reference'">
                        <b-row>
                          <b-col md="12" sm="12">
                            <b-alert show variant="danger" class="reference-text">
                              <p>References</p>
                              <ol>
                                <li v-for="(reference, referIndex) in option.static" :key="'reference' + referIndex">
                                  <span v-html="reference"></span>
                                </li>
                              </ol>
                            </b-alert>
                          </b-col>
                        </b-row>
                      </b-container>
                      <template v-if="option.type == 'model'">
                        <b-col cols="12" v-if="option.description">
                          <h6>
                            {{ option.description }}
                          </h6>
                        </b-col>
                        <b-col md="10" sm="12">
                          <b-form-checkbox v-model="option.value">
                            <span :class="[
                              'checkbox-label',
                              'text-color-change',
                              {
                                'checkbox-selected': option.value,
                              },
                            ]">
                              <p v-if="option.html" v-html="option.label_html"></p>
                              <template v-else>
                                {{ option.label }}
                              </template>
                            </span>
                          </b-form-checkbox>
                        </b-col>

                        <b-col md="2" sm="12">
                          <div class="checkbox-label view-btn"
                            @click="setModalDetails(option.modal, option.modalHeading, option.footerAppend)">View</div>
                        </b-col>
                      </template>
                      <template v-if="option.type == 'basic'">
                        <b-col md="12" sm="12">
                          <b-form-checkbox v-model="option.value" @change="
                            changeValue($event, {
                              collapseIndex: index,
                              sectionID: indexSection,
                              optionID: optionIndex,
                              data: option.references,
                            })
                            ">
                            <span :class="[
                              'checkbox-label',
                              'text-color-change',
                              {
                                'checkbox-selected': option.value,
                              },
                            ]">
                              {{ option.label }}
                              <sup v-if="option.superscript">{{ option.superscript }}</sup>
                            </span>
                            <span :class="[
                              {
                                'checkbox-selected': option.value,
                              },
                            ]" v-if="option.label == ''">{{ option.description }}</span>
                            <span :class="[
                              {
                                'checkbox-selected': option.value,
                              },
                            ]" v-if="option.label != ''">: {{ option.description }}</span>
                            <sup v-if="option.endSuper">{{ option.endSuper }}</sup>
                          </b-form-checkbox>
                        </b-col>
                      </template>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
        <template v-if="item.type == 'radio'">
          <b-card-body>
            <b-container fluid>
              <b-row v-for="(section, indexSection) in item.sections" :key="'section' + indexSection">
                <b-col md="12" sm="12">
                  <b-container fluid>
                    <template v-if="section.motivate">
                      <b-row v-for="(motive, motiveIndex) in section.motivate" :key="'motive' + motiveIndex">
                        <b-col md="12" sm="12">
                          <b-alert show :variant="motive.variant">
                            <b-container>
                              <b-row class="h-100">
                                <b-col md="1" class="pr-0 icon-col my-auto" v-if="motive.icon">
                                  <img src="https://motivateforms.co.za/public/img/frontend_assets/danger-info.png" alt />
                                </b-col>
                                <b-col v-if="motive.html" v-html="motive.details" class="my-auto"></b-col>
                                <b-col v-if="motive.html == false" class="my-auto">{{ motive.details }}</b-col>
                              </b-row>
                            </b-container>
                          </b-alert>
                        </b-col>
                      </b-row>
                    </template>
                    <b-row v-if="section.text != 'References'">
                      <b-col md="12" sm="12">
                        <h5 class="section-title">
                          {{ section.text }}
                        </h5>
                        <hr />
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-container fluid>
                          <b-row v-for="(option, optionIndex) in section.options" :key="'opt' + optionIndex">
                            <template v-if="option.type != 'reference'" class="internal-options">
                              <p v-if="optionIndex == 0">Please select one of the options</p>
                              <b-form-radio @change="
                                addValue($event, {
                                  collapseIndex: index,
                                  sectionID: indexSection,
                                  optionID: optionIndex,
                                  data: option.references,
                                })
                                " v-model="item.group" :value="option.value" class="mb-2">
                                <strong>
                                  {{ option.title }}
                                  <sup v-if="option.superscript">{{ option.superscript }}</sup>
                                </strong>
                                :
                                {{ option.description }}
                              </b-form-radio>
                            </template>
                            <template v-if="option.type == 'reference'">
                              <b-row>
                                <b-col md="12" sm="12">
                                  <b-alert show variant="danger" class="reference-text">
                                    <ol>
                                      <li v-for="(reference, referIndex) in option.static"
                                        :key="'reference' + referIndex">
                                        {{ reference }}
                                      </li>
                                    </ol>
                                  </b-alert>
                                </b-col>
                              </b-row>
                            </template>
                          </b-row>
                        </b-container>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
        <template v-if="item.type == 'html'">
          <b-card-body>
            <b-container fluid>
              <template v-if="item.motivate">
                <b-row v-for="(motive, motiveIndex) in item.motivate" :key="'motive' + motiveIndex">
                  <b-col md="12" sm="12">
                    <b-alert show :variant="motive.variant">
                      <b-container>
                        <b-row class="h-100">
                          <b-col md="1" class="pr-0 icon-col my-auto" v-if="motive.icon">
                            <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                          </b-col>
                          <b-col v-if="motive.html" v-html="motive.details" class="my-auto"></b-col>
                          <b-col v-if="motive.html == false" class="my-auto">{{ motive.details }}</b-col>
                        </b-row>
                      </b-container>
                    </b-alert>
                  </b-col>
                </b-row>
              </template>
              <b-row v-if="item.header">
                <b-col md="12" sm="12">
                  <h5 class="section-title">
                    {{ item.header }}
                  </h5>
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div v-html="item.data"></div>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
        <template v-if="item.type == 'information'">
          <b-card-body>
            <b-container fluid>
              <b-row>
                <b-col md="12" sm="12">
                  <strong>{{ item.header }}</strong>
                </b-col>
              </b-row>

              <b-row v-for="(section, indexSection) in item.sections" :key="'section' + indexSection">
                <b-col>
                  <br />
                  {{ section.title }}
                  <p>
                    Click this link for
                    <a class="link-color" @click="linkModal(section.link)">{{ section.title }}</a>
                  </p>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
        <template v-if="item.type == 'patient_motivation'">
          <b-card-body>
            <b-row v-for="(section, indexSection) in item.sections" :key="'sections' + indexSection">
              <b-col md="12" sm="12">
                <b-container>
                  <template v-if="section.motivate">
                    <b-row v-for="(motive, motiveIndex) in section.motivate" :key="'motive' + motiveIndex">
                      <b-col md="12" sm="12">
                        <b-alert show :variant="motive.variant">
                          <b-container>
                            <b-row class="h-100">
                              <b-col md="1" class="pr-0 icon-col my-auto" v-if="motive.icon">
                                <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                              </b-col>
                              <b-col v-if="motive.html" v-html="motive.details" class="my-auto"></b-col>
                              <b-col v-if="motive.html == false" class="my-auto">{{ motive.details }}</b-col>
                            </b-row>
                          </b-container>
                        </b-alert>
                      </b-col>
                    </b-row>
                  </template>
                  <b-row>
                    <b-col md="12" sm="12">
                      <h5 class="section-title">
                        {{ section.text }}
                      </h5>
                      <hr />
                    </b-col>
                  </b-row>

                  <b-row v-for="(option, optionIndex) in section.options" :key="'options' + optionIndex">
                    <b-col md="12" sm="12">
                      <!-- <p class="mb-4"> -->
                      <!-- <strong> -->
                      <!-- Below are summaries of clinical trials that will aid you in your motivation. -->
                      <!-- <br />By selecting the trial summary, it will be automatically included in your letter. You can also view the summary. -->
                      <!-- </strong> -->
                      <!-- </p> -->

                      <b-form-select v-model="section.choice" :options="options_patient_motivation"
                        class="white-background-input mb-4"></b-form-select>

                      <Input v-if="section.choice == 'input_yourself'" type="textarea" :binding="option.value"
                        placeholder="Motivation here" label="Please motivation" @changeValue="option.value = $event"
                        classes="white-background-input" />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-card-body>
        </template>
        <template v-if="item.type == 'view'">
          <b-card-body>
            <b-container fluid>
              <b-row v-if="item.description">
                <b-col md="12" sm="12">
                  <p>{{ item.description }}</p>
                </b-col>
              </b-row>
              <b-row v-for="(section, indexSection) in item.sections" :key="'sections' + indexSection">
                <b-container>
                  <template v-if="section.motivate">
                    <b-row v-for="(motive, motiveIndex) in section.motivate" :key="'motive' + motiveIndex">
                      <b-col md="12" sm="12">
                        <b-alert show :variant="motive.variant">
                          <b-container>
                            <b-row class="h-100">
                              <b-col md="1" class="pr-0 icon-col my-auto" v-if="motive.icon">
                                <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                              </b-col>
                              <b-col v-if="motive.html" v-html="motive.details" class="my-auto"></b-col>
                              <b-col v-if="motive.html == false" class="my-auto">{{ motive.details }}</b-col>
                            </b-row>
                          </b-container>
                        </b-alert>
                      </b-col>
                    </b-row>
                  </template>
                  <b-row>
                    <b-col md="12" sm="12">
                      <p>{{ section.description }}</p>
                      <h5 class="section-title">
                        {{ section.text }}
                        <sup v-if="section.superscript">{{ section.superscript }}</sup>
                      </h5>
                      <hr />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12" sm="12">
                      <div v-html="TrialModal(section.data)" ref="trialModalRef"></div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
        <template v-if="item.type == 'assessment'">
          <b-card-body>
            <b-container fluid>
              <b-row v-for="(section, sectionIndex) in item.sections" :key="sectionIndex" class="mb-2 mt-2 h-100">
                <b-col class="my-auto">
                  <b-container fluid>
                    <template v-if="section.show != false">
                      <b-row class="h-100">
                        <b-col v-if="section.text" :md="section.viewUrl ? 6 : section.downloadUrl ? 9 : 12"
                          class="my-auto">
                          <h6 class="section-title" v-if="!section.html">
                            {{ section.text }}
                            <sup v-if="section.superScript">{{ section.superScript }}</sup>
                          </h6>
                          <h5 class="section-title" v-if="section.html" v-html="section.text"></h5>
                        </b-col>
                        <b-col v-if="section.viewUrl" md="3">
                          <b-button class="download-btn"
                            @click="assessment(index, section.viewUrl, sectionIndex, section.attachedValue)">Online
                            Assessment</b-button>
                        </b-col>

                        <b-col v-if="section.downloadUrl" md="3">
                          <b-button class="download-btn" @click="downloadFile(section.downloadUrl)">Download</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col v-if="section.randomNewField">
                          <p v-html="section.randomNewField"></p>
                        </b-col>
                      </b-row>
                      <b-row v-if="section.text">
                        <b-col v-if="section.hr ? false : true">
                          <hr />
                        </b-col>
                      </b-row>
                    </template>
                    <b-row v-for="(option, optionIndex) in section.options" :key="optionIndex" class="mt-2">
                      <b-col md="12">
                        <template v-if="option.type == 'text'">
                          <Input :html="option.html" classes="white-background-input " :name="'input' + option.value"
                            type="text" :binding="option.value" :placeholder="option.placeholder" :label="option.label"
                            @changeValue="option.value = $event" />
                        </template>
                        <template v-if="option.type == 'checkboxOptions'">
                          <b-container>
                            <b-row>
                              <b-col>
                                <label style="font-size: 16px">{{ option.label }}</label>
                                <b-alert show variant="danger" v-if="option.pre_items_note">
                                  <b-container fluid>
                                    <img src="https://motivateforms.co.za/public/img/frontend_assets/danger-info.png"
                                      alt="" style="display: inline-block" />
                                    <div class="ml-2" style="display: inline-block">
                                      {{ option.pre_items_note }}
                                    </div>
                                  </b-container>
                                </b-alert>

                                <b-form-checkbox-group v-model="option.value" :options="option.items" stacked
                                  class="ml-4"></b-form-checkbox-group>
                              </b-col>
                            </b-row>
                          </b-container>
                        </template>
                        <template v-if="option.type == 'basic'">
                          <p v-if="option.header">
                            <strong>{{ option.header }}</strong>
                          </p>
                          <b-form-checkbox v-model="option.value" v-if="option.value != null" @change="
                            changeValue($event, {
                              collapseIndex: index,
                              sectionID: sectionIndex,
                              optionID: optionIndex,
                              data: option.references,
                            })
                            ">
                            <span :class="[
                              'checkbox-label',
                              'text-color-change',
                              {
                                'checkbox-selected': option.value,
                              },
                            ]">
                              {{ option.label }}
                              <sup v-if="option.superscript">{{ option.superscript }}</sup>
                            </span>
                            <span :class="[
                              {
                                'checkbox-selected': option.value,
                              },
                            ]" v-if="option.label == ''">
                              <template v-if="!option.html">{{ option.description }}</template>

                              <p v-if="option.html" v-html="option.description"></p>
                            </span>
                            <span :class="[
                              {
                                'checkbox-selected': option.value,
                              },
                            ]" v-if="option.label != ''">
                              {{ option.description }}</span>
                          </b-form-checkbox>
                        </template>
                        <template v-if="option.type == 'information'">
                          <b-alert show variant="danger">
                            <div v-html="option.data"></div>
                          </b-alert>
                        </template>
                        <template v-if="option.type == 'number'">
                          <b-container>
                            <b-row class="h-100">
                              <b-col>
                                <label class>
                                  <p>
                                    {{ option.label }}
                                  </p>
                                  <p v-if="option.description">
                                    {{ option.description }}
                                  </p>
                                </label>
                              </b-col>
                              <b-col class="my-auto">
                                <NumberInput :details="option"></NumberInput>
                              </b-col>
                              <b-col></b-col>
                            </b-row>
                          </b-container>
                        </template>
                        <template v-if="option.type == 'textarea'">
                          <Input :html="option.html" classes="white-background-input" type="textarea"
                            :binding="option.value" :placeholder="option.placeholder" :label="option.label"
                            @changeValue="option.value = $event" />
                        </template>
                        <template v-if="(option.type == 'reference')">
                          <b-row>
                            <b-col md="12" sm="12">
                              <b-alert show variant="danger" class="reference-text">
                                <p>References</p>
                                <ol>
                                  <li v-for="(reference, referIndex) in option.static" :key="'reference' + referIndex">
                                    <span v-html="reference"></span>
                                  </li>
                                </ol>
                              </b-alert>
                            </b-col>
                          </b-row>
                          <!-- <b-alert show variant="danger" v-html="option.data" class="reference-text"></b-alert> -->
                        </template>
                        <template v-if="option.type == 'radio'">
                          <b-container fluid class="mb-2">
                            <b-row v-if="optionIndex == 0">
                              <b-col></b-col>
                              <b-col></b-col>
                              <b-col>
                                <p>Comments</p>
                              </b-col>
                            </b-row>
                            <b-row class="h-100">
                              <b-col class="my-auto" v-html="option.label"></b-col>
                              <b-col class="my-auto" v-if="option.items">
                                <b-form-radio-group v-model="option.value" :options="option.items"></b-form-radio-group>
                              </b-col>
                              <b-col class="my-auto">
                                <Input classes="white-background-input" type="text" :binding="option.input.value"
                                  :placeholder="option.input.placeholder" :label="option.input.label"
                                  @changeValue="option.input.value = $event" />
                              </b-col>
                            </b-row>
                          </b-container>
                        </template>
                        <template v-if="option.type == 'dropdown'">
                          <!-- <b-col md="6"> -->
                          <SelectInput :binding="option.value" @changeValue="option.value = $event"
                            :options="option.data.options" :label="option.label" :name="option.name"></SelectInput>
                          <!-- </b-col> -->
                        </template>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
        <template v-if="item.type == 'outline-form-slot'">
          <b-card-body>
            <b-container>
              <b-row>
                <b-col md="12">
                  <p class="section-title">
                    {{ item.header }}
                  </p>
                  <hr />
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <slot name="dataInsert"></slot>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
        <template v-if="item.type == 'references'">
          <b-card-body>
            <b-container>
              <b-row>
                <b-col>
                  <h5 class="section-title text-uppercase" v-if="item.text">
                    {{ item.text }}
                  </h5>
                  <hr />
                </b-col>
              </b-row>
              <b-row v-for="(ref, refIndex) in item.references" :key="refIndex">
                <template v-if="ref.id">
                  <b-col md="1" sm="1">{{ ref.id }} )</b-col>
                  <b-col md="11" sm="11" class="text-left">
                    <ul class="list-no-bullet">
                      <li v-for="(refer, itemIndex) in ref.data">
                        <p v-html="refer"></p>
                      </li>
                    </ul>
                  </b-col>
                </template>
                <template v-else>
                  <b-col sm="12" md="12">
                    <p>{{ ref.section }}</p>
                  </b-col>
                  <b-col sm="12" md="12">
                    <ul class="list-no-bullet">
                      <li v-for="(refer, itemIndex) in ref.data">
                        {{ refer }}
                      </li>
                    </ul>
                  </b-col>
                </template>
              </b-row>
            </b-container>
          </b-card-body>
        </template>
      </b-collapse>
    </b-card>
    <QuizModalBASDAI :show="basdaiModal" @changeValue="basdaiModal = $event" @placementData="changeDataBasdai($event)">
    </QuizModalBASDAI>
    <QuizModalDLQI :show="dlqiModal" @changeValue="dlqiModal = $event" @placementData="changeDataDlqi($event)">
    </QuizModalDLQI>
    <QuizModalPSSD :show="pssdModal" @changeValue="pssdModal = $event" @placementData="changeDataPssd($event)">
    </QuizModalPSSD>
    <b-modal v-model="showLeaver" centered>
      <b-container>
        <b-row>
          <b-col>You are leaving Janssen Medical Cloud to navigate to an external website. We invite you to read its
            Privacy policy.</b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" class="btn-primary" @click="
          activateLink();
        ok();
        ">I Agree</b-button>
        <b-button size="sm" class="resetExportBtn" @click="
          showLeaver = false;
        linkActivated = null;
        cancel();
        ">Go Back</b-button>
      </template>
    </b-modal>
    <ModalComponent :showModal="showModal" @closing="showModal = false" :footer="true" :name="name" :modalName="heading"
      :additionalCode="footerAddition"></ModalComponent>
  </div>
</template>

<script>
import ModalComponent from "./ModalComponent.vue";
import Input from "./Fields/Input";
import SelectInput from "./Fields/SelectInput";
import { ModalInformation } from "../mixins/modals.js";
import NumberInput from "./Fields/NumberInput.vue";
import QuizModalBASDAI from "./Quiz/QuizModalBASDAI";
import QuizModalDLQI from "./Quiz/QuizModalDLQI";
import QuizModalPSSD from "./Quiz/QuizModalPSSD";
import documents from "./AccordianComponents/documents.vue";
export default {
  components: {
    ModalComponent,
    Input,
    NumberInput,
    QuizModalBASDAI,
    QuizModalDLQI,
    QuizModalPSSD,
    documents,
    SelectInput,
  },
  mixins: [ModalInformation],
  props: {
    info: Object,

    type: String,
  },
  data() {
    return {
      showLeaver: false,
      linkActivated: null,
      showModal: false,
      heading: "",
      name: "",
      footerAddition: null,
      dlqiModal: false,
      dlqiSectionIndex: 0,
      dlqiValuePosition: 0,
      dlqiCollapseValuePosition: 0,
      basdaiSectionIndex: 0,
      basdaiValuePosition: 0,
      basdaiCollapseValuePosition: 0,
      pssdCollapseValuePosition: 0,
      pssdSectionIndex: 0,
      pssdValuePosition: 0,
      basdaiModal: false,
      pssdModal: false,
      options_patient_motivation: [
        { value: null, text: "Please select one" },
        { value: null, text: "Leave this section out" },
        {
          value: "input_yourself",
          text: "Type in your patients motivation",
        },
        {
          value: "leave_blank",
          text: "Leave space on export for patient to hand write motivation",
        },
      ],
    };
  },
  methods: {
    changeDataPssd(value) {
      this.info.collapses[this.pssdCollapseValuePosition].sections[this.pssdSectionIndex].options[this.pssdValuePosition].value = value;
    },
    changeDataBasdai(value) {
      //(value);
      this.info.collapses[this.basdaiCollapseValuePosition].sections[this.basdaiSectionIndex].options[this.basdaiValuePosition].value = value;
    },
    changeDataDlqi(value) {
      this.info.collapses[this.dlqiCollapseValuePosition].sections[this.dlqiSectionIndex].options[this.dlqiValuePosition].value = value;
    },
    linkModal(link) {
      this.showLeaver = true;
      this.linkActivated = link;
    },
    scrollTopFunction() {
      // var elmnt = document.getElementById("scrollTopArea");
      // elmnt.scrollIntoView();
    },
    assessment(index, url, sectionIndex, valuePosition) {
      //([index,url,sectionIndex,valuePosition])
      if ("basdai" == url) {
        this.basdaiModal = true;

        this.basdaiSectionIndex = sectionIndex;
        this.basdaiValuePosition = valuePosition;
        this.basdaiCollapseValuePosition = index;
      } else if (url == "dlqi") {
        this.dlqiModal = true;
        this.dlqiSectionIndex = sectionIndex;
        this.dlqiValuePosition = valuePosition;
        this.dlqiCollapseValuePosition = index;
      } else if (url == "pssd") {
        this.pssdModal = true;
        this.pssdSectionIndex = sectionIndex;
        this.pssdValuePosition = valuePosition;
        this.pssdCollapseValuePosition = index;
      } else {
        window.open(url, "_blank");
      }
    },
    downloadFile(url) {
      window.open(`${process.env.VUE_APP_BACKEND_URL}${url}`, "_blank");
    },
    downloadApp(url) {
      this.linkModal(url);
    },
    activateLink() {
      window.open(this.linkActivated, "_blank");
    },
    references(references) {
      let tempArray = [];
      references.forEach((val) => {
        tempArray.push(val.text);
      });
      let refer = [...new Set(tempArray)];
      return refer;
    },
    changeValue(changedTo, ref) {
      if (changedTo) {
        this.$emit("referencesAdd", ref);
      } else {
        this.$emit("referenceRemove", ref);
      }
    },
    addValue(changedTo, ref) {
      this.$emit("radioAdd", ref);
    },
    setModalDetails(name, heading, footerAddition) {
      this.footerAddition = footerAddition ? footerAddition : null;
      this.heading = heading;
      this.name = name;
      this.showModal = true;
    },
  },
  mounted() {
    for (var ls = document.links, numLinks = ls.length, i = 0; i < numLinks; i++) {
      let data = ls[i].href;
      ls[i].onclick = (evt) => {
        evt.preventDefault();
        this.linkModal(data);
      };
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables";

header {
  background-color: $primary-color;
}

.view-btn {
  cursor: pointer;
}

.alert {
  border-radius: 1.5rem;
}

.center-title {
  line-height: 2.3rem;
}

.icon-col {
  max-width: fit-content;
  line-height: 2rem;
}

.extra-spacing {
  margin-bottom: 2.5rem;
}

hr {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
