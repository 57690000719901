<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <p>REFERENCES: 1. Sieper J, Rudwaloit M, Baraliakos X, ot al. The assessment of SpondyloArthritis international Society (ASAS) handbook a guide to assess spondyloarthritis. Ann Rhoum Dis 2009;68(Suppl 1):1-144. 2. The Bath indices. Ankylosing spondylitis (Axial Spondyloathritis) (AS). National Ankylosing Spondylitis Society, 2016. [cited 2020 Apr 08]; Available from: https://nass.co.uk/homepage/health-professionals/resources-for-health-professionals/bath-indices/.</p>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col>
        <p>S4 STELARAB 45 mg/90 mg SOLUTION FOR INJECTION Reg No.43/30.1/0727-0728. STELARAS contains 45 mg of ustekinumab in 0,5 ml and STELARAS contains 90 mg of Ustekinumab in 1,0 mL. Further information available on request from Market Authorisation Holder. JANSSEN PHARMACEUTICA (PTY) LTD/(EDMS) BPK: (REG. NO./REGNR. 1980/011122/07), No. 2, Medical Road, Halfway House, Midrand 1685. www.janssen.com, Medical Info Line: 0860 11 11 17. For full prescribing infor Cert No. PHZA/STE/0618/0002.</p>
      </b-col>
    </b-row> -->
  </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p{
    font-size:0.7rem;
}
</style>