<template>
  <b-container class="checklist-container">
    <b-row>
      <b-col>
        <slot name="pre-title"></slot>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" sm="6">
        <slot name="title"></slot>
      </b-col>
      <b-col md="3" sm="6">
        <slot name="download"></slot>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <hr />
        <slot name="content"></slot>
      </b-col>
    </b-row>
    <b-modal v-model="showLeaver" centered>
      <b-container>
        <b-row>
          <b-col>You are leaving Janssen Medical Cloud to navigate to an external website. We invite you to read its Privacy policy.</b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          class="btn-primary"
          @click="
            activateLink();
            ok();
          "
          >I Agree</b-button
        >
        <b-button
          size="sm"
          class="resetExportBtn"
          @click="
            showLeaver = false;
            linkActivated = null;
            cancel();
          "
          >Go Back</b-button
        >
      </template>
    </b-modal>
  </b-container>
</template>
<script>
  export default {
    data() {
      return {
        showLeaver: false,
        linkActivated: null,
      };
    },
    methods: {
      linkModal(link) {
        this.showLeaver = true;
        this.linkActivated = link;
      },
    },
    mounted() {
      for (var ls = document.links, numLinks = ls.length, i = 0; i < numLinks; i++) {
        let data = ls[i].href;

        ls[i].onclick = (evt) => {
          evt.preventDefault();
          this.linkModal(data);
        };
      }
    },
  };
</script>
<style scoped>
  hr {
    margin-top: 8px;
  }
  .alert {
    border-radius: 1.5rem;
  }
</style>
