import Vue from "vue";
import VueRouter from "vue-router";
import imbruvica from "@/pages/Imbruvica.vue";
import xeplion from "@/pages/Xeplion.vue";
import zytiga from "@/pages/Zytiga.vue";
import pah from "@/pages/Pah.vue";
import erleada from "@/pages/Erleada.vue";
import velcade from "@/pages/Velcade.vue";
import routingPage from "@/pages/routingPage.vue";
import stelara_psa from "@/pages/Stelara/PSA.vue";
import stelara_pso from "@/pages/Stelara/PSO.vue";
import stelara_ibd from "@/pages/Stelara/IBD.vue";
import spravato from "@/pages/Spravato.vue";
import darzalex from "@/pages/Darzalex.vue";
import axios from "axios";
import dqli_quiz from "@/pages/Quiz/DLQI.vue";
import basdai_quiz from "@/pages/Quiz/BASDAI.vue";
import trevicta from "@/pages/Trevicta.vue";
import tremfya from "@/pages/Tremfya.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "routesview",
    component: routingPage,
  },
  {
    path: "/imbruvica",
    name: "imbruvica",
    component: imbruvica,
  },
  {
    path: "/xeplion",
    name: "xeplion",
    component: xeplion,
  },
  {
    path: "/velcade",
    name: "velcade",
    component: velcade,
  },
  {
    path: "/stelara/psa",
    name: "stelara-psa",
    component: stelara_psa,
  },
  {
    path: "/stelara/ibd",
    name: "stelara-ibd",
    component: stelara_ibd,
  },
  {
    path: "/stelara/pso",
    name: "stelara-pso",
    component: stelara_pso,
  },
  {
    path: "/quiz/dlqi",
    name: "dqli-quiz",
    component: dqli_quiz,
  },
  {
    path: "/quiz/basdai",
    name: "basdai-quiz",
    component: basdai_quiz,
  },
  {
    path: "/darzalex",
    name: "darzalex",
    component: darzalex,
  },
  {
    path: "/zytiga",
    name: "zytiga",
    component: zytiga,
  },
  {
    path: "/trevicta",
    name: "trevicta",
    component: trevicta,
  },
  {
    path: "/erleada",
    name: "erleada",
    component: erleada,
  },
  {
    path: "/tremfya",
    name: "tremfya",
    component: tremfya,
  },
  {
    path: "/pah",
    name: "pah",
    component: pah,
  },
  {
    path: "/spravato",
    name: "spravato",
    component: spravato,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let setData = {};

  if (
    to.name == "xeplion" ||
    to.name == "tremfya" ||
    to.name == "stelara-psa" ||
    to.name == "stelara-pso" ||
    to.name == "stelara-ibd" ||
    to.name == "dqli-quiz" ||
    to.name == "basdai-quiz" ||
    to.name == "routesview"
  ) {
    next();
  } else {
    axios.get(`${process.env.VUE_APP_BACKEND_URL}/links/${to.name}`).then((resp) => {
      if (to.name == "velcade") {
        setData.apex_study = resp.data["apex-study"];
        setData.apex_study_extend = resp.data["apex-study-extend"];
        setData.apex_study_health = resp.data["apex-study-health-related"];
        setData.crest_study = resp.data["crest-study"];
        setData.crest_and_study = resp.data["crest-and-summit"];
        setData.summit_study = resp.data["summit-study"];
        setData.summit_study_extend = resp.data["summit-study-extend"];
        setData.vista_study = resp.data["vista-study"];
        setData.vista_study_five = resp.data["vista-study-five"];
        setData.vista_study_assessment = resp.data["vista-study-assessment"];
        setData.factors_prognostic = resp.data["factors-prognostic"];
        setData.imwg_mrd_criteria = resp.data["imwg-mrd-criteria"];
        setData.imwg_mrd_criteria_standard = resp.data["imwg-mrd-criteria-standard"];
        setData.internation_staging_system = resp.data["internation-staging-system"];
      }
      if (to.name == "pah") {
        setData.seraphin = resp.data["seraphin"];
        setData.griphon = resp.data["griphon"];
        setData.legal_framework_uptravi = resp.data["legal_framework_uptravi"];
      }

      if (to.name == "imbruvica") {
        setData.resonate_2 = resp.data["resonate_2"];
        setData.resonate = resp.data["resonate"];
        setData.alliance = resp.data["alliance"];
        setData.pcyc_1101 = resp.data["pcyc1102-3"];
        setData.spark = resp.data["spark(mcl2001)"];
        setData.dreyling = resp.data["dreyling"];
        setData.pycyc_1104 = resp.data["pcyc-1104"];
        setData.pycyc__118e = resp.data["PCYC-118e"];
        setData.alliance_older = resp.data["alliance-older"];
        setData.aleration = resp.data["alterations"];
        setData.cross_trial_comparison = resp.data["cross_trial_comparison"];
        setData.huang_et_al = resp.data["huang_et_al"];
        setData.immune_restoration_moreno = resp.data["immune_restoration_moreno"];
        setData.combination_therapy = resp.data["combination_therapy"];
        setData.general_manage = resp.data["general-manage"];
        setData.relapse_refactory = resp.data["relapse-refactory"];
        setData.resonate_refractory_resonate = resp.data["resonate-refractory-resonate"];
        setData.griphon = resp.data["resonate-refractory-resonate"];
        setData.GHIA_2022 = resp.data["GHIA-2022"];
        setData.single_agent = resp.data["single_agent"];
        setData.long_term_efficacy_5_year = resp.data["long_term_efficacy_5_year"];
        setData.long_term_efficacy_7_year = resp.data["long_term_efficacy_7_year"];
        setData.illuminate = resp.data["illuminate"];
        setData.helios = resp.data["helios"];
        setData.resonate_17 = resp.data["resonate-17"];
        setData.innovate = resp.data["innovate"];
        setData.castillo = resp.data["castillo"];
        setData.pcyc1102 = resp.data["pcyc-1102"];
        setData.regulations_2020111 = resp.data["regulations_2020111"];
        setData.rebuttals_2020112 = resp.data["rebuttals_2020112"];
        setData.legal_framework = resp.data["legal_framework"];
        setData.legal_framework_pmb = resp.data["legal_framework_pmb"];
        setData.legal_framework_co_payments = resp.data["legal_framework_co_payments"];
        setData.rebuttals_state = resp.data["rebuttals_state"];
        setData.rebuttals_innapropriate_care = resp.data["rebuttals_innapropriate_care"];
        setData.rebuttals_medicine_costs = resp.data["rebuttals_medicine_costs"];
        setData.rebuttals_benefit = resp.data["rebuttals_benefit"];
        setData.imbruvica_rebuttal = resp.data["imbruvica_rebuttal"];
      }

      if (to.name == "darzalex") {
        setData.cassiopeia = resp.data["cassiopeia"];
        setData.maia = resp.data["maia"];
        setData.maia2 = resp.data["maia2"];
        setData.alcyone = resp.data["alcyone"];
        setData.fonesca = resp.data["fonesca"];
        setData.kiss2021 = resp.data["kiss2021"];
        setData.castor = resp.data["castor"];
        setData.pollux = resp.data["pollux"];
        setData.rwe = resp.data["rwe"];
        setData.final_results = resp.data["final_results"];
        setData.chari = resp.data["chari"];
        setData.cavo = resp.data["cavo"];
        setData.factors_prognostic = resp.data["factors-prognostic"];
        setData.imwg_mrd_criteria = resp.data["imwg-mrd-criteria"];
        setData.imwg_mrd_criteria_standard = resp.data["imwg-mrd-criteria-standard"];
        setData.internation_staging_system = resp.data["internation-staging-system"];
        setData.legal_framework = resp.data["legal_framework"];
        setData.legal_framework_pmb = resp.data["legal_framework_pmb"];
        setData.legal_framework_co_payments = resp.data["legal_framework_co_payments"];
        setData.rebuttals_state = resp.data["rebuttals_state"];
        setData.rebuttals_innapropriate_care = resp.data["rebuttals_innapropriate_care"];
        setData.rebuttals_medicine_costs = resp.data["rebuttals_medicine_costs"];
        setData.rebuttals_benefit = resp.data["rebuttals_benefit"];
        setData.darzalex_rebuttal = resp.data["darzalex_rebuttal"];
      }
      if (to.name == "zytiga") {
        setData.post_chemo = resp.data["post_chemo"];
        setData.pre_chemo = resp.data["pre_chemo"];
        setData.latitude = resp.data["latitude"];
        setData.legal_reimbursement = resp.data["legal_reimbursement"];
      }
      if (to.name == "spravato") {
        setData.sustain = resp.data["sustain"];
        setData.transform = resp.data["transform"];
      }
      if (to.name == "erleada") {
        setData.spartan = resp.data["spartan"];
        setData.final_survival = resp.data["final_survival"];
        setData.titan = resp.data["titan"];
        setData.final_titan = resp.data["final_titan"];
        setData.legal_reimbursement = resp.data["legal_reimbursement"];
      }
      if (to.name == "trevicta") {
        setData.savitz = resp.data["savitz"];
        setData.weiden_ct = resp.data["weiden_ct"];
      }
      to.params.data = setData;
      next();
    });
  }
});

export default router;
