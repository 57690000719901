<template>
  <b-container fluid class="container-to-basic-quiz">
    <b-row v-for="(quiz,quizIndex) in quizDetails" :key="quizIndex" class="main-row-quiz ">
      <b-col md="10" sm="10" class="quiz-col-area">
        <b-container fluid>
          <b-row class="qustion-row pb-2">
            <b-col sm="1" md="1">
              <div class="question-number">{{quiz.id}}</div>
            </b-col>
            <b-col sm="11" md="11">
              <p v-html="quiz.question"></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-none d-md-block"></b-col>

            <b-col
              v-for="(option,optionIndex) in quiz.options"
              :key="optionIndex"
              class="column-height pl-2 pr-2"
              
            >
            
              <div
                :class="['circle-value',{'selected-circle':quiz.value==option.value}]"
                @click="selectOption(quizIndex,option.value)"
              >
                <p class="quiz-value-enter">{{option.value}}</p>
              </div>
              <div class="circle-description" v-if="option.description">{{option.description}}</div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col md="2" sm="2" class="pr-0 d-none d-md-block" >
        <div
          :class="['col-quiz-area-score',{'border-change':quizIndex==0},{'color-active-change':quiz.value}]"
        >
          <div class="header-col-quiz" v-if="quizIndex==0">{{quiz.outOfScore}}</div>
          <div class="area-for-score">
            <p class="quiz-value">{{quiz.value}}</p>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    quizDetails: Array
  },
  methods: {
    selectOption(quizIndex, value) {
      this.quizDetails[quizIndex].value = value;
    }
  }
};
</script>

<style>

</style>