<template>
  <Layout>
    <template v-slot:previewArea>
      <Preview
        :alert="alertMessage"
        @alertClose="alertMessage.show=false"
        :previewDetails="options.preview"
        @preview="preview()"
        @save="save()"
        @reset="reset()"
        @exportPDF="showModalExports('pdf')"
        @exportWord="showModalExports('Word')"
      ></Preview>
    </template>
    <template v-slot:required-header>
      <b-row>
        <b-col md="12" sm="12">
          <h5>HCP Details</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            name="hcp_full_name"
            :required="validateErrors.hcp_full_name"
            type="text"
            :binding="form.hcp_full_name"
            placeholder="Enter Dr Name"
            label="HCP Name"
            @changeValue="form.hcp_full_name=$event;options.preview[0].value=true;"
          />
        </b-col>
        <b-col md="4" sm="12">
          <Input
          :required="validateErrors.date"
            type="date"
            :binding="form.date"
            name="date"
            label="Date"
            placeholder="Select Date"
            @changeValue="form.date=$event;options.preview[0].value=true;"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Patient's Details</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            :required="validateErrors.patient_detail_full_name"
            type="text"
            name="patient_detail_full_name"
            :binding="form.patient_detail_full_name"
            placeholder="Enter Patient's full name"
            label="Full Name"
            @changeValue="form.patient_detail_full_name=$event;options.preview[0].value=true;"
          />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :required="validateErrors.date_of_birth"
            :binding="form.date_of_birth"
            name="date_of_birth"
            placeholder="Select Date of birth"
            label="Date of Birth"
            @changeValue="form.date_of_birth=$event;options.preview[0].value=true;"
          />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <b-row>
        <b-col sm="12" md="12">
          <SelectInput
            :binding="form.type_of_patient"
            placeholder="Type of Patient"
            name="type_of_patient"
            label="Type of Patient"
            :options="options.type_of_patient"
            @changeValue="form.type_of_patient=$event;mergeArray($event,'PSA');"
          ></SelectInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            type="text"
            name="medical_aid_name"
            :binding="form.medical_aid_name"
            placeholder="Enter Medical Aid Name "
            label="Medical Aid Name"
            @changeValue="form.medical_aid_name=$event"
          />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            name="plan_option"
            type="text"
            :binding="form.plan_option"
            placeholder="Enter option here"
            label="Plan Option"
            @changeValue="form.plan_option=$event"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            name="medical_aid_membership_number"
            type="text"
            :binding="form.medical_aid_membership_number"
            placeholder="Enter Membership Number"
            label="Membership Number"
            @changeValue="form.medical_aid_membership_number=$event"
          />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput
            :binding="form.idc_10_code"
            @changeValue="form.idc_10_code=$event"
            :options="options.idc_10_code"
            label="ICD 10 Code"
            placeholder
            name="icd_20_codes"
          ></SelectInput>
        </b-col>
      </b-row>
      <b-row v-if="form.idc_10_code=='Other'">
        <b-col md="8" sm="0"></b-col>
        <b-col md="4" sm="12">
          <Input
            name="idc_10_code_other"
            type="text"
            :binding="form.idc_10_code_other"
            placeholder="Other"
            label="Please type other code"
            @changeValue="form.idc_10_code_other=$event"
          />
        </b-col>
      </b-row>
    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Motivation</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <p>
            <span class="red-text">
              Remember to include your patient's clinical and treatment history as well as co-morbidity index when motivating.
              Please view below a checklist of all the relevant additional documentation you may need to attach with your motivational letter.
            </span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <Input
            type="textarea"
            :binding="form.motivation"
            placeholder="Motivate here"
            @changeValue="form.motivation=$event"
          />
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton
        :tabs="options.tabs"
        :selectedTab="selectedTab"
        @changeTab="selectedTab=$event;changeActive($event)"
      ></TabButton>
    </template>
    <template v-slot:tab-selects>
      <span id="scrollTopArea"></span>
      <b-row v-if="selectedTab=='trials'">
        <b-col v-if="form.type_of_patient">
          <Accordion :info="form.clinical_trial" type="checkbox"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab=='additional'">
        <b-col v-if="form.additional_information">
          <Accordion :info="form.additional_information" type="checkbox">
            <template v-slot:dataInsert>
              <b-container fluid>
                <b-row>
                  <b-col>
                    <ul>
                      <li>Reliable access and reimbursement services.</li>
                      <li>Comprehensive disease information pack aimed at supporting you, the patient.</li>
                      <li>
                        Personalised product education on the administration and handling of STELARA
                        <sup>®</sup> pre-filled syringes.
                      </li>
                      <li>Ongoing support to improve your adherence and mobility.</li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    
                    <img src="https://motivateforms.co.za/public/img/frontend_assets/patient_motivate.jpg" width="50%" />
                  </b-col>
                  <b-col>
                    <p>CONTACT DETAILS</p>
                    <p>(During regular business hours)</p>
                    <p>Tel. 0800112101</p>
                    <p>Fax to email: 086 649 1094</p>
                    <p>Email: janssenpatientsupport@its.jnj.com</p>
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab=='checklist'">
        <b-col md="12" sm="12">
          <Checklist>
            <template v-slot:pre-title>
              <b-alert variant="danger" show>
                <b-container fluid>
                  <b-row>
                    <b-col md="1" sm="1" class="pr-0 icon-col">
                      <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                    </b-col>
                    <b-col
                      md="11"
                      sm="11"
                    >Please remember to add / attach the below checklist documentation when submitting your motivational letter.</b-col>
                  </b-row>
                </b-container>
              </b-alert>
            </template>

            <template v-slot:title>
              <h4
                class="section-title section-title-spacing"
              >CHECKLIST OF DOCUMENTS TO INCLUDE IN YOUR MOTIVATION FOR STELARA</h4>
            </template>
            <template v-slot:content>
              <ul>
                <li>Motivation Letter</li>
                <li>Attach script</li>
                <li>Assessment scores</li>
                <li>Attach patient photos (Optional)</li>
                <li>Attach patient written motivation letter (Optional)</li>
              </ul>
            </template>
          </Checklist>
        </b-col>
      </b-row>
    </template>
    <template v-slot:modal-preview>
      <ModalComponent
        :name="previewModal.data"
        @closing="closeModal()"
        :response="true"
        :modalName="previewModal.modalName"
        :showModal="previewModal.show"
      ></ModalComponent>
    </template>
    <template v-slot:modal-type>
      <ModalComponent
        size="md"
        :defaultModal="true"
        @closing="exportModal.show=false"
        :response="true"
        :DocumentType="exportModal.type"
        :modalName="exportModal.modalName"
        :showModal="exportModal.show"
        :footer="false"
        @saveExport="processType($event,'save')"
        @exportReset="processType($event,'reset')"
      ></ModalComponent>
    </template>
  </Layout>
</template>

<script>
import Preview from "@/components/Preview";
import Input from "@/components/Fields/Input";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import psa_reference from "@/assets/json/PsA_reference.json";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import { StelaraData } from "@/mixins/Stelara.js";
import StelaraJson from "@/assets/json/PsA.json";
import Layout from "@/pages/Layouts";
export default {
  components: {
    Layout,
    Preview,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent
  },
  mixins: [GeneralUsage, ModalInformation, StelaraData],
  data() {
    return {
      options: {
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Supporting Material", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional"
          },
          {
            value: false,
            text: "Checklist",
            relation: "checklist"
          }
        ],
        type_of_patient: [
          { value: null, text: "Select patient type" },
          {
            value: "BIOLOGIC - NAÏVE PsA PATIENT",
            text: "BIOLOGIC - NAÏVE PsA PATIENT",
            reference: ["CCC recommendation 23 June 2017."]
          },
          {
            value: "BIOLOGIC - EXPERIENCED PsA PATIENT",
            text: "BIOLOGIC - EXPERIENCED PsA PATIENT",
            reference: [
              "CCC recommendation 23 June 2017.",
              "Roberts J, O’Rielly DD, Rahman P. A review of ustekinumab in the treatment of psoriatic arthritis. Immunotherapy 2018;10(5):361-372."
            ]
          },
          {
            value: "BIOLOGIC - RE-MOTIVATING FOR STELARA",
            text: "BIOLOGIC - RE-MOTIVATING FOR STELARA",
            reference: [
              "Araujo EG, Englbrecht M, Hoepken S, et al. Scientific Abstract OP0217. Ann Rheum Dis 2017;76(Suppl 2):142; DOI: 10.1136/annrheumdis-2017-eular.5398"
            ]
          }
        ],
        idc_10_code: [
          { value: null, text: "Please select one" },
          {
            value: "L40.50   Arthropathic psoriasis, unspecified",
            text: "L40.50   Arthropathic psoriasis, unspecified"
          },
          {
            value: "L40.51   Distal interphalangeal psoriatic arthropathy",
            text: "L40.51   Distal interphalangeal psoriatic arthropathy"
          },
          {
            value: "L40.52   Psoriatic arthritis mutilans",
            text: "L40.52   Psoriatic arthritis mutilans"
          },
          {
            value: "L40.53   Psoriatic spondylitis",
            text: "L40.53   Psoriatic spondylitis"
          },
          {
            value: "L40.54   Psoriatic juvenile arthropathy",
            text: "L40.54   Psoriatic juvenile arthropathy"
          },
          {
            value: "L40.59   Other psoriatic arthropathy",
            text: "L40.59   Other psoriatic arthropathy"
          },
          { value: "Other", text: "Other" }
        ],
        tabs: [
          { value: "trials", text: "Supporting Material" },
          {
            value: "additional",
            text: "Additional Information"
          },
          {
            value: "checklist",
            text: "Checklist"
          }
        ]
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        idc_10_code: null,
        idc_10_code_other: "",
        type_of_patient: null,
        type_of_patient_reference: [],
        motivation: "",
        clinical_trial: {
          collapses: []
        },
        additional_information: {
          collapses: [
            {
              title: "JANSSEN PATIENT SUPPORT PROGRAM OUTLINE",
              visible: false,
              type: "outline-form-slot",
              html: true,
              header: "JANSSEN PATIENT SUPPORT PROGRAM OUTLINE"
            },
            // {
            //   title: "REFERENCES",
            //   visible: false,
            //   text: "References",
            //   type: "references",
            //   references: psa_reference
            // }
          ]
        }
      },
      triggerWatch: false
    };
  },
  methods: {
    // Preview data
    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepPsa = this.prepPsa(this.form);
       
        let processed = this.flatData(prepPsa, StelaraJson);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name =
          check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    // Saved data
    save() {
      this.setLocal("stelara-psa", this.form);
    },

    // Export pdf function
    async exportPDF(reaction) {
      let prepPsa = this.prepPsa(this.form);
      let processed = this.flatData(prepPsa, StelaraJson);
      let response = await this.exportPDFUrl(
        processed,
        this.form.patient_detail_full_name
      );

      if (response.error) {
        this.alertMessage = {
          show: true,
          variant: "error",
          message: response.error.message
        };
      } else {
        window.open(response.data.data.url, "_blank");
        if (reaction == "save") {
          this.save();
        } else {
          this.reset();
        }
      }
    },
    // Export to word function
    async exportWord(reaction) {
      let prepPsA = this.prepPsa(this.form);

      let processed = this.flatData(prepPsA, StelaraJson);

      let response = await this.exportWordUrl(
        processed,
        this.form.patient_detail_full_name
      );
      if (response.error) {
        this.alertMessage = {
          show: true,
          variant: "error",
          message: response.error.message
        };
      } else {
        window.open(response.data.data.url, "_blank");
        if (reaction == "save") {
          this.save();
        } else {
          this.reset();
        }
      }
    },
    // Reset data
    reset() {
      this.resetStorage("stelara-psa");
    }
  },
  created() {
    let storage = this.getLocal("stelara-psa");
    if (storage) {
      this.triggerWatch = true;
      this.form = storage;
    } else {
      let data = this.psaDermData();

      this.form.clinical_trial.collapses = [...data];
    }
  }
};
</script>
