<template>
  <Layout>
    <template v-slot:previewArea>
      <Preview
        :alert="alertMessage"
        @alertClose="alertMessage.show=false"
        :previewDetails="options.preview"
        @preview="preview()"
        @save="save()"
        @reset="reset()"
        @exportPDF="showModalExports('pdf')"
        @exportWord="showModalExports('Word')"
      ></Preview>
    </template>
    <template v-slot:required-header>
      <b-row>
        <b-col md="12" sm="12">
          <h5>HCP Details</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            name="hcp_full_name"
            :required="validateErrors.hcp_full_name"
            type="text"
            :binding="form.hcp_full_name"
            placeholder="Enter Dr Name"
            label="HCP Name"
            @changeValue="form.hcp_full_name=$event;options.preview[0].value=true;"
          />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :required="validateErrors.date"
            :binding="form.date"
            name="date"
            label="Date"
            placeholder="Select Date"
            @changeValue="form.date=$event;options.preview[0].value=true;"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Patient's Details</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            :required="validateErrors.patient_detail_full_name"
            type="text"
            name="patient_detail_full_name"
            :binding="form.patient_detail_full_name"
            placeholder="Enter Patient's full name"
            label="Full Name"
            @changeValue="form.patient_detail_full_name=$event;options.preview[0].value=true;"
          />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :required="validateErrors.date_of_birth"
            :binding="form.date_of_birth"
            name="date_of_birth"
            placeholder="Select Date of birth"
            label="Date of Birth"
            @changeValue="form.date_of_birth=$event;options.preview[0].value=true;"
          />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <b-row>
        <b-col sm="12" md="12">
          <SelectInput
            :binding="form.type_of_patient"
            placeholder="Type of Patient"
            name="type_of_patient"
            label="Type of Patient"
            :options="options.type_of_patient"
            @changeValue="form.type_of_patient=$event;mergeArray($event,'PSO')"
          ></SelectInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            type="text"
            name="medical_aid_name"
            :binding="form.medical_aid_name"
            placeholder="Enter Medical Aid Name "
            label="Medical Aid Name"
            @changeValue="form.medical_aid_name=$event"
          />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            name="plan_option"
            type="text"
            :binding="form.plan_option"
            placeholder="Enter option here"
            label="Plan Option"
            @changeValue="form.plan_option=$event"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" sm="12">
          <Input
            name="medical_aid_membership_number"
            type="text"
            :binding="form.medical_aid_membership_number"
            placeholder="Enter Membership Number"
            label="Membership Number"
            @changeValue="form.medical_aid_membership_number=$event"
          />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput
            :binding="form.idc_10_code"
            @changeValue="form.idc_10_code=$event;"
            :options="options.icd_10_code"
            label="ICD 10 Codes"
            placeholder
            name="icd_10_codes"
          ></SelectInput>
        </b-col>
      </b-row>
      <b-row v-if="form.idc_10_code=='Other'">
        <b-col md="8" sm="0"></b-col>
        <b-col md="4" sm="12">
          <Input
            name="idc_10_code_other"
            type="text"
            :binding="form.idc_10_code_other"
            placeholder="Other"
            label="Please type other code"
            @changeValue="form.idc_10_code_other=$event"
          />
        </b-col>
      </b-row>
    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="12" sm="12">
          <h5>Motivation</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <p>
            <span class="red-text">
              Remember to include your patient's clinical and treatment history as well as co-morbidity index when motivating.
              Please view below a checklist of all the relevant additional documentation you may need to attach with your motivational letter.
            </span>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
          <Input
            type="textarea"
            :binding="form.motivation"
            placeholder="Motivate here"
            @changeValue="form.motivation=$event"
          />
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton
        :tabs="options.tabs"
        :selectedTab="selectedTab"
        @changeTab="selectedTab=$event;changeActive($event)"
      ></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="selectedTab=='trials'">
        <b-col v-if="form.type_of_patient">
          <Accordion :info="form.clinical_trial" type="checkbox"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab=='additional'">
        <b-col v-if="form.additional_information">
          <Accordion :info="form.additional_information" type="checkbox">
            <template v-slot:dataInsert>
              <b-container fluid>
                <b-row>
                  <b-col>
                    <ul>
                      <li>Reliable access and reimbursement services.</li>
                      <li>Comprehensive disease information pack aimed at supporting you, the patient.</li>
                      <li>
                        Personalised product education on the administration and handling of STELARA
                        <sup>®</sup> pre-filled syringes.
                      </li>
                      <li>Ongoing support to improve your adherence and mobility.</li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <img src="https://motivateforms.co.za/public/img/frontend_assets/patient_motivate.jpg" width="50%" />
                  </b-col>
                  <b-col>
                    <p>CONTACT DETAILS</p>
                    <p>(During regular business hours)</p>
                    <p>Tel. 0800112101</p>
                    <p>Fax to email: 086 649 1094</p>
                    <p>Email: janssenpatientsupport@its.jnj.com</p>
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab=='checklist'">
        <b-col md="12" sm="12">
          <Checklist>
            <template v-slot:pre-title>
              <b-alert variant="danger" show>
                <b-container fluid>
                  <b-row>
                    <b-col md="1" sm="1" class="pr-0 icon-col">
                      <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                    </b-col>
                    <b-col
                      md="11"
                      sm="11"
                    >Please remember to add / attach the below checklist documentation when submitting your motivational letter.</b-col>
                  </b-row>
                </b-container>
              </b-alert>
            </template>

            <template v-slot:title>
              <h4
                class="section-title section-title-spacing"
              >CHECKLIST OF DOCUMENTS TO INCLUDE IN YOUR MOTIVATION FOR STELARA</h4>
            </template>
            <template v-slot:content>
              <ul>
                <li>Motivation Letter</li>
                <li>Attach script</li>
                <li>Assessment scores</li>
                <li>Attach patient photos (Optional)</li>
                <li>Attach patient written motivation letter (Optional)</li>
              </ul>
            </template>
          </Checklist>
        </b-col>
      </b-row>
    </template>

    <template v-slot:modal-preview>
      <ModalComponent
        :name="previewModal.data"
        @closing="closeModal()"
        :response="true"
        :modalName="previewModal.modalName"
        :showModal="previewModal.show"
      ></ModalComponent>
    </template>

    <template v-slot:modal-type>
      <ModalComponent
        size="md"
        :defaultModal="true"
        @closing="exportModal.show=false"
        :response="true"
        :DocumentType="exportModal.type"
        :modalName="exportModal.modalName"
        :showModal="exportModal.show"
        :footer="false"
        @saveExport="processType($event,'save')"
        @exportReset="processType($event,'reset')"
      ></ModalComponent>
    </template>
  </Layout>
</template>
<script>
import Preview from "@/components/Preview";
import Input from "@/components/Fields/Input";
import moment from "moment";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import { StelaraData } from "@/mixins/Stelara.js";
import pso_reference_json from "@/assets/json/PsO_reference.json";
import psoJson from "@/assets/json/PsO.json";
import Layout from "@/pages/Layouts";
export default {
  components: {
    Layout,
    Preview,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent
  },
  mixins: [GeneralUsage, ModalInformation, StelaraData],
  data() {
    return {
      validateErrors: {
        hcp_full_name: { status: null, message: "" },
        date: { status: null, message: "" },
        patient_detail_full_name: { status: null, message: "" },
        date_of_birth: { status: null, message: "" }
      },
      selectedTab: null,
      alertMessage: {
        show: false,
        variant: "success",
        message: ""
      },
      options: {
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Supporting Material", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional"
          },
          {
            value: false,
            text: "Checklist",
            relation: "checklist"
          }
        ],
        type_of_patient: [
          { value: null, text: "Select patient type" },
          {
            value: "BIOLOGIC - NAÏVE PsO PATIENT",
            text: "BIOLOGIC - NAÏVE PsO PATIENT",
            references: [
              "STELARA® approved package insert, October 2015.",
              "CCC recommendation June 2017."
            ]
          },
          {
            value: "BIOLOGIC - EXPERIENCED PsO PATIENT",
            text: "BIOLOGIC - EXPERIENCED PsO PATIENT",
            references: [
              "Leonardi CL, Kimball AB, Papp KA, et al. Efficacy and safety of ustekinumab, a human interleukin-12/23 monoclonal antibody, in patients with psoriasis: 76-week results from a randomised, double-blind, placebo-controlled trial (PHOENIX 1). Lancet 2008;371(9625):1665–1674.",
              "Papp KA, Langley RG, Lebwohl M, et al. Efficacy and safety of ustekinumab, a human interleukin-12/23 monoclonal antibody, in patients with psoriasis: 52-week results from a randomised, double-blind, placebo-controlled trial (PHOENIX 2). Lancet 2008;371:1675–1684."
            ]
          },
          {
            value: "BIOLOGIC - RE-MOTIVATING FOR STELARA",
            text: "BIOLOGIC - RE-MOTIVATING FOR STELARA",
            references: [
              "Leonardi CL, Kimball AB, Papp KA, et al. Efficacy and safety of ustekinumab, a human interleukin-12/23 monoclonal antibody, in patients with psoriasis: 76-week results from a randomised, double-blind, placebo-controlled trial (PHOENIX 1). Lancet 2008;371(9625):1665–1674.",
              "Kimball AB, Papp KA, Wasfi Y, et al. Long-term efficacy of ustekinumab in patients with moderate-to-severe psoriasis treated for up to 5 years in the PHOENIX 1 study. J Eur Acad Dermatol Venereol 2013;27(12):1535–1545.",
              "Papp KA, Langley RG, Lebwohl M, et al. Efficacy and safety of ustekinumab, a human interleukin-12/23 monoclonal antibody, in patients with psoriasis: 52-week results from a randomised, double-blind, placebo-controlled trial (PHOENIX 2). Lancet 2008;371:1675–1684."
            ]
          }
        ],
        icd_10_code: [
          { value: null, text: "Please select one" },
          {
            value: "L40.0    Psoriasis vulgaris",
            text: "L40.0    Psoriasis vulgaris"
          },
          {
            value: "L40.1    Generalized pustular psoriasis",
            text: "L40.1    Generalized pustular psoriasis"
          },
          {
            value: "L40.2    Acrodermatitis continua",
            text: "L40.2    Acrodermatitis continua"
          },
          {
            value: "L40.3    Pustulosis palmaris et plantaris",
            text: "L40.3    Pustulosis palmaris et plantaris"
          },
          {
            value: "L40.4    Guttate psoriasis",
            text: "L40.4    Guttate psoriasis"
          },
          {
            value: "L40.8    Other psoriasis",
            text: "L40.8    Other psoriasis"
          },
          {
            value: "L40.9    Psoriasis, unspecified",
            text: "L40.9    Psoriasis, unspecified"
          },
          {
            value: "L41.3    Small plaque parapsoriasis",
            text: "L41.3    Small plaque parapsoriasis"
          },
          {
            value: "L41.4    Large plaque parapsoriasis",
            text: "L41.4    Large plaque parapsoriasis"
          },
          {
            value: "L41.5    Retiform parapsoriasis",
            text: "L41.5    Retiform parapsoriasis"
          },
          {
            value: "L41.8    Other parapsoriasis",
            text: "L41.8    Other parapsoriasis"
          },
          {
            value: "L41.9    Parapsoriasis, unspecified",
            text: "L41.9    Parapsoriasis, unspecified"
          },
          { value: "Other", text: "Other" }
        ],
        tabs: [
          { value: "trials", text: "Supporting Material" },
          {
            value: "additional",
            text: "Additional Information"
          },
          {
            value: "checklist",
            text: "Checklist"
          }
        ]
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        idc_10_code: null,
        idc_10_code_other: "",
        type_of_patient: null,
        type_of_patient_reference: [],
        motivation: "",
        clinical_trial: {
          collapses: []
        },
        additional_information: {
          collapses: [
            {
              title: "JANSSEN PATIENT SUPPORT PROGRAM OUTLINE",
              visible: false,
              type: "outline-form-slot",
              html: true,
              header: "JANSSEN PATIENT SUPPORT PROGRAM OUTLINE",
              downloadUrl: ""
            },
            // {
            //   title: "REFERENCES",
            //   visible: false,
            //   text: "References",
            //   type: "references",
            //   references: pso_reference_json
            // }
          ]
        }
      },

      previewModal: {
        data: null,
        show: false,
        name: "Preview",
        modalName: "PREVIEW",
        footer: true
      },
      exportModal: {
        show: false,
        type: "Word document",
        name: "Export to Word",
        modalName: "Export to Word",
        footer: true
      },
      triggerWatch: false
    };
  },
  methods: {
    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepPsa = this.prepPsa(this.form);
        let processed = this.flatData(prepPsa, psoJson);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name =
          check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    save() {
      this.setLocal("stelara-pso", this.form);
    },

    async exportPDF(reaction) {
      let prepPsa = this.prepPsa(this.form);
      let processed = this.flatData(prepPsa, psoJson);
      let response = await this.exportPDFUrl(
        processed,
        this.form.patient_detail_full_name
      );

      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    async exportWord(reaction) {
      let prepPsa = this.prepPsa(this.form);
      let processed = this.flatData(prepPsa, psoJson);
      let response = await this.exportWordUrl(
        processed,
        this.form.patient_detail_full_name
      );
      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    reset() {
      this.resetStorage("stelara-pso");
    }
  },
  created() {
    let storage = this.getLocal("stelara-pso");
    if (storage) {
      this.triggerWatch = true;
      this.form = storage;
    } else {
      let data = this.psoDermData();
      this.form.clinical_trial.collapses = [...data];
    }
  }
};
</script>
