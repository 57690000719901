<template>
  <editor
    v-model="valueProp"
    api-key="ip0isfsjqdxfklu8hg6b7ef50eonm6hyeu6bzqp0jtny2a4v"
    :init="{
      height: 70,
      menubar: false,
      constent_style:
        'mce-content-body[data-mce-placeholder]{font-size: 0.8rem !important;}'
      ,
      fix_list_elements : true,
      plugins: [''],
      toolbar: '',
	    paste_word_valid_elements: 'b,strong,i,em,h1,h2,sup'
    }"
  />
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    editor: Editor,
  },
  props: {
    inputData: String,
  },
  data() {
    return {
      valueProp: this.$props.inputData,
    };
  },
  watch: {
    valueProp() {

      this.$emit("changeValue", this.valueProp);
    },
  },
};
</script>