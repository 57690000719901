<template>
  <Layout>
    <template v-slot:required-header>
      <b-row>
        <b-col md="2" sm="12">
          <h6>HCP Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input type="text" :binding="form.hcp_full_name" placeholder="Enter Dr Name" label="HCP Name"
            :required="validateErrors.hcp_full_name" @changeValue="
              form.hcp_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :binding="form.date" name="date" label="Date" :required="validateErrors.date"
            placeholder="Enter Date" @changeValue="
              form.date = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row class="mb-0">
        <b-col md="2" sm="12">
          <h6>Patient's Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input type="text" :binding="form.patient_detail_full_name" placeholder="Enter Patient's full name"
            label="Full Name" :required="validateErrors.patient_detail_full_name" @changeValue="
              form.patient_detail_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :binding="form.date_of_birth" name="date_of_birth" :required="validateErrors.date_of_birth"
            placeholder="Enter Date of birth" label="Date of Birth" @changeValue="
              form.date_of_birth = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <b-row class="mb-0">
        <b-col md="6" offset="2" sm="12">
          <Input type="text" :binding="form.medical_aid_name" placeholder="Enter Medical Aid Name "
            label="Medical Aid Name" @changeValue="form.medical_aid_name = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="text" :binding="form.plan_option" placeholder="Enter option here" label="Plan Option"
            @changeValue="form.plan_option = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input type="text" :binding="form.medical_aid_membership_number" placeholder="Enter Membership Number"
            label="Membership Number" @changeValue="form.medical_aid_membership_number = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput :binding="form.idc_10_code" @changeValue="form.idc_10_code = $event" :options="options.idc_10_code"
            label="ICD 10 Codes" placeholder name="icd_10_codes"></SelectInput>
        </b-col>
      </b-row>
      <b-row v-if="form.idc_10_code == 'Other'">
        <b-col md="6" offset="2" sm="12"></b-col>
        <b-col md="4" sm="12">
          <Input type="text" :binding="form.idc_10_code_other" placeholder="Other" label="Please type other code"
            @changeValue="form.idc_10_code_other = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10" offset="2" sm="12"> PMB Code 910S </b-col>
      </b-row>
    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Motivation</h6>
        </b-col>
        <b-col md="10" sm="12">
          <p>
            <span class="red-text">
              It is advisable that the motivational letter includes the clinical presentation of the patient, previous
              treatment history and the patient's co-morbidity Index.
              Please view the relevant additional documentation you may need to support your motivational letter. Medical
              scheme information to support the motivation is also
              available.
            </span>
          </p>
          <Input type="textarea" :binding="form.motivation" placeholder="Motivate here"
            @changeValue="form.motivation = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Medical Scheme Information</h6>
        </b-col>
        <b-col md="10" sm="12">
          <p>
            <span class="red-text"> Text from medical scheme information, found under additional information can be pasted
              below should you require.</span>
          </p>
          <HtmlEditor :inputData="form.medical_scheme_info" @changeValue="form.medical_scheme_info = $event"></HtmlEditor>
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton :tabs="options.tabs" :selectedTab="selectedTab" @changeTab="
        selectedTab = $event;
      changeActive($event);
      "></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="'trials' == selectedTab">
        <b-col md="12" sm="12">
          <Accordion :info="form.clinical_trial"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="'additional' == selectedTab">
        <b-col md="12" sm="12">
          <Accordion :info="form.additional_information"
            @referenceRemove="referenceRemove($event, 'additional_information')"
            @referencesAdd="referenceAdd($event, 'additional_information')"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'checklist'">
        <Checklist>
          <template v-slot:title>
            <h4 class="section-title">CHECKLIST - FOR A RELAPSED AND REFRACTORY PATIENT</h4>
          </template>
          <template v-slot:content>
            <ul>
              <li>Full blood count (FBC), differential, platelet count</li>
              <li>Serum quantitative immunoglobulins, SPEP, SIFE</li>
              <li>24-h urine for total protein, UPEP, UIFE</li>
              <li>Creatinine, corrected calcium</li>
              <li>Serum FLC assay as clinically indicated</li>
              <li>Bone marrow aspirate and biopsy as clinically indicated</li>
              <li>Assess minimal residual disease (MRD) as indicated</li>
            </ul>
            <p style="color: red">Please include the following as attachments</p>
            <ul>
              <li>Skeletal surveyor whole body low-dose CT scan as clinically indicated</li>
              <li>Whole body or skeletal MRI or whole body FDG PET/CT scan as clinically indicated</li>
              <li>Consider attaching the previous motivational letter to further your application reimbursement.</li>
            </ul>
            <p style="color: red">Consider attaching the previous motivational letter to further your application
              reimbursement</p>
            <ul>
              <li>Medical scheme information to support the motivation.</li>
            </ul>
          </template>
        </Checklist>
      </b-row>
    </template>
    <template v-slot:modal-preview>
      <ModalComponent :name="previewModal.data" @closing="closeModal()" :response="true"
        :modalName="previewModal.modalName" :showModal="previewModal.show"></ModalComponent>
    </template>
    <template v-slot:modal-type>
      <ModalComponent size="md" :defaultModal="true" @closing="exportModal.show = false" :response="true"
        :DocumentType="exportModal.type" :modalName="exportModal.modalName" :showModal="exportModal.show" :footer="false"
        @saveExport="processType($event, 'save')" @exportReset="processType($event, 'reset')"></ModalComponent>
    </template>
    <template v-slot:formControl>
      <FormControl :alert="alertMessage" @alertClose="alertMessage.show = false" @preview="preview()" @save="save()"
        @reset="reset()" @exportPDF="showModalExports('pdf')" @exportWord="showModalExports('Word')"></FormControl>
    </template>
  </Layout>
</template>

<script>
import Input from "@/components/Fields/Input";
import moment from "moment";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import darzalexJson from "@/assets/json/darzalex.json";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import Layout from "./Layouts";
import HtmlEditor from "../components/HtmlEditor.vue";
import FormControl from "../components/FormControl.vue";
export default {
  components: {
    Layout,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent,
    HtmlEditor,
    FormControl,
  },

  mixins: [GeneralUsage, ModalInformation],
  data() {
    return {
      options: {
        tabs: [
          { value: "trials", text: "Clinical Trials" },
          {
            value: "additional",
            text: "Additional Information",
          },
          {
            value: "checklist",
            text: "Checklist",
          },
        ],
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Clinical Trials", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional",
          },
          { value: false, text: "Checklist", relation: "checklist" },
        ],
        idc_10_code: [
          { value: null, text: "Select an option" },
          { value: "C90.0 Multiple Myeloma", text: "C90.0 Multiple Myeloma" },
          { value: "Other", text: "Other" },
        ],
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        idc_10_code: null,
        idc_10_code_other: "",
        medical_scheme_info: "<p></p>",
        clinical_trial: {
          collapses: [
            {
              title: "CLINICAL TRIALS",
              visible: false,
              type: "checkbox",
              sections: [
                {
                  text: "Front Line",
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  options: [
                    {
                      type: "model",
                      value: false,
                      description: "Transplant-eligible",
                      link: "darzalex.cassiopeia",
                      label: "Bortezomib, thalidomide, and dexamethasone with or without daratumumab before and after autologous stem-cell transplantation (CASSIOPEIA)",
                      modal: "cassiopeia",
                      modalHeading: "Bortezomib, thalidomide, and dexamethasone with or without daratumumab before and after autologous stem-cell transplantation (CASSIOPEIA)",
                      footerAppend: "<p>CP-320675</p>",
                    },
                    {
                      type: "model",
                      value: false,
                      description: "Transplant-ineligible",
                      link: "darzalex.maia",
                      label: "Daratumumab plus lenalidomide and dexamethasone (D-Rd) versus lenalidomide and dexamethasone (Rd) (MAIA)",
                      modal: "maia",
                      modalHeading: "Daratumumab plus lenalidomide and dexamethasone (D-Rd) versus lenalidomide and dexamethasone (Rd) (MAIA)",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.maia2",
                      label: "Daratumumab plus bortezomib, and melphalan (ALCYONE)",
                      modal: "maia2",
                      modalHeading: "Daratumumab plus bortezomib, and melphalan (ALCYONE)",

                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.kiss2021",
                      label: "Network Meta-analysis of efficacy of Front Line treatments in Transplant-ineligible Multiple Myeloma (KISS 2021)",
                      modal: "kiss2021",
                      modalHeading: "Network Meta-analysis of efficacy of Front Line treatments in Transplant-ineligible Multiple Myeloma (KISS 2021)",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.fonesca",
                      label: "Impact of treatment sequencing on overall survival in patients with transplant-ineligible newly diagnosed myeloma (FONSECA 2023)",
                      modal: "fonesca",
                      modalHeading: "Impact of treatment sequencing on overall survival in patients with transplant-ineligible newly diagnosed myeloma (FONSECA 2023)",
                    },
                  ],
                },
                {
                  text: "Relapsed-Refractory",
                  options: [
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.castor",
                      description: "1 Prior Line",
                      label: "Daratumumab, bortezomib, and dexamethasone versus bortezomib and dexamethasone with updated overall survival (CASTOR)",
                      modal: "castor",
                      modalHeading: "Daratumumab, bortezomib, and dexamethasone versus bortezomib and dexamethasone with updated overall survival (CASTOR)",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.pollux",
                      label: "Daratumumab plus lenalidomide and dexamethasone with updated overall survival (POLLUX)",
                      modal: "pollux",
                      modalHeading: " Daratumumab plus lenalidomide and dexamethasone with updated overall survival (POLLUX)",
                    },
                    {
                      type: "model",
                      value: false,
                      description: "Heavily pre-treated",
                      link: "darzalex.rwe",
                      label: "Real-world data on the efficacy and safety of daratumumab treatment (LOVAS)",
                      modal: "rwe",
                      modalHeading: "Real-world data on the efficacy and safety of daratumumab treatment (LOVAS)",
                      footerAppend: "<p>EM-31127</p>",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.final_results",
                      label: "Daratumumab monotherapy in patients with heavily pretreated relapsed or refractory multiple myeloma (GEN501 AND SIRIUS)",
                      modal: "final_results",
                      modalHeading: "Daratumumab monotherapy in patients with heavily pretreated relapsed or refractory multiple myeloma (GEN501 AND SIRIUS)",
                      footerAppend: "<p>EM-38075</p>",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.chari",
                      label: "Daratumumab plus pomalidomide and dexamethasone in relapsed and/or refractory multiple myeloma (EQUULEUS)",
                      modal: "chari",
                      modalHeading: "Daratumumab plus pomalidomide and dexamethasone in relapsed and/or refractory multiple myeloma(EQUULEUS)",
                      footerAppend: "<p>CP-325616</p>",
                    },
                  ],
                },
                {
                  text: "MRD in MM",
                  options: [
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.cavo",
                      label: "Prognostic value of minimal residual disease negativity in myeloma (CAVO)",
                      modal: "cavo",
                      modalHeading: "Prognostic value of minimal residual disease negativity in myeloma (CAVO)",
                      footerAppend: `<p>CP-325614</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        additional_information: {
          collapses: [
            {
              title: "MEDICAL SCHEME INFORMATION",
              visible: false,
              header: "Medical scheme information",
              type: "documents",
              sections: [
                {
                  text: "MEDICAL SCHEME INFORMATION",
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "Please note the document is not auto populated kindly copy and paste any relevant sections into the free text region above",
                    },
                  ],

                  options: [
                    {
                      type: "parent",
                      value: "The legal framework for patient access",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.legal_framework_pmb",
                      label: "- With PMB regulations",
                      modal: "legal_framework_pmb",
                      modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO DARZALEX",
                      footerAppend: "<p>CP-205379</p>",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.legal_framework_co_payments",
                      label: "- Avoidance of co-payments",
                      modal: "legal_framework_co_payments",
                      modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO DARZALEX",
                      footerAppend: "<p>CP-205379</p>",
                    },
                    {
                      type: "parent",
                      value: "Rebuttals",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.rebuttals_state",
                      label: "- Rebuttals vs state level of care ",
                      modal: "rebuttals_state",
                      modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO DARZALEX",
                      footerAppend: "<p>CP-205379</p>",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.rebuttals_innapropriate_care",
                      label: "- Rebuttals vs suggestion of inappropriate care",
                      modal: "rebuttals_innapropriate_care",
                      modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO DARZALEX",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.rebuttals_medicine_costs",
                      label: "- Rebuttals vs medicine cost exceeds available benefit",
                      modal: "rebuttals_medicine_costs",
                      modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO DARZALEX",
                      footerAppend: "<p>CP-205379</p>",
                    },
                    {
                      type: "model",
                      value: false,
                      link: "darzalex.rebuttals_benefit",
                      label: "- Rebuttals vs benefit option",
                      modal: "rebuttals_benefit",
                      modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO DARZALEX",
                      footerAppend: "<p>CP-205379</p>",
                    },
                  ],
                },
              ],
            },
            {
              type: "checkbox",
              title: "CONFIRMATION OF ACTIVE MYELOMA",
              visible: false,
              sections: [
                {
                  text: "IMWG CRITERIA CLASSIC CRAB FEATURES",

                  motivate: [
                    {
                      icon: false,
                      variant: "dark",
                      html: true,
                      details: `<p><strong>International Myeloma Working Group (IMWG) Criteria for the diagnosis of MM</strong><sup>1</sup><br/>
The IMWG criteria allow, in addition to the classic CRAB features, three myeloma-defining events (MDEs). The presence of at least one of these markers is considered sufficient for a diagnosis of MM, regardless of the presence or absence of symptoms or CRAB features. Each of these markers has been shown in two or more independent studies to be associated with an approximately 80 % or higher risk of developing myeloma-related organ damage within two years.

</p>`,
                    },
                    {
                      icon: false,
                      variant: "dark",
                      html: true,
                      details: `
                      <p><strong>Revised IMWG Criteria for the Diagnosis of Multiple Myeloma</strong><sup>1</sup><br>
  <strong>Definition of Active MM</strong></p>
  <p>Clonal bone marrow plasma cells &gt; 10 % or biopsy-proven bony or extramedullary plasmacytoma and any one or more of the following CRAB features and MDEs</p>
                      `,
                    },
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details:
                        "Upon selecting the relevant criteria below, your selection will automatically populate onto your letter when exporting. It is advisable to elaborate on your selection criteria to the funder",
                    },
                  ],
                  options: [
                    {
                      type: "basic",
                      value: false,
                      description: "Serum calcium >0,25 mmol/L (>1 mg/dL) higher than the upper limit of normal or >2,75 mmol/L (>11 mg/dL)",
                      label: "Hypercalcaemia",
                      references: [
                        {
                          id: 1,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description: "Creatinine clearance <40 mL per minute or serum creatinine >177 mmol/L (>2 mg/dL)",
                      label: "Renal insufficiency",
                      references: [
                        {
                          id: 2,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description: "Haemoglobin valure of >20 g/L below the lowest limit of normal, or a haemoglobin value <100 g/L) ",
                      label: "Anaemia",
                      references: [
                        {
                          id: 3,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description:
                        "One or more osteolytic lesion on skeletal radiography, CT, or PET/CT. If bone marrow has <10 % clonal plasma cells, more than one bone lesion is required to distinguish from solitary plasmacytoma with minimal marrow involvement",
                      label: "Bone Lesions",
                      references: [
                        {
                          id: 4,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma. Accessed 30 August 2019",
                        },
                      ],
                    },
                  ],
                },
                {
                  text: "BIOMARKERS OF MALIGNACY (MDES)",
                  options: [
                    {
                      type: "basic",
                      value: false,
                      description: "60 % or greater clonal plasma cells on bone marrow examination",
                      label: "",
                      references: [
                        {
                          id: 5,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description:
                        "Serum involved/uninvolved free light chain ratio of 100 or greater, provided the absolute level of the involved light chain is at least 100 mg/L (a patient�s involved free light chain either kappa or lambda is the one that is above the normal reference range; the uninvolved free light chain is the one that is typically in, or below, the normal range)",
                      label: "",
                      references: [
                        {
                          id: 6,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma. Accessed 30 August 2019",
                        },
                      ],
                    },
                    {
                      type: "basic",
                      value: false,
                      description: "More than one focal lesion on MRI that is at least 5 mm or greater in size",
                      label: "",
                      references: [
                        {
                          id: 7,
                          text: "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma. Accessed 30 August 2019",
                        },
                      ],
                    },
                  ],
                },
                {
                  text: "References",
                  options: [
                    {
                      type: "reference",
                      html: true,
                      static: [
                        "International Myeloma Foundation: International Myeloma Working Group (IMWG) Criteria for the Diagnosis of Multiple Myeloma. Available at: <a href='https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma'>https://www.myeloma.org/resource-library/international-myeloma-working-group-imwg-criteria-diagnosis-multiple-myeloma</a>. Accessed 30 August 2019",
                      ],
                      references: [],
                    },
                  ],
                },
              ],
            },
            {
              type: "view",
              title: "RESPONSE CRITERIA",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: false,
                      variant: "empty",
                      html: true,
                      details: `<p>
                     International Myeloma Working group (IMWG) criteria for response assessment including criteria for minimal residual disease (MRD)</p>`,
                    },
                    {
                      icon: true,
                      variant: "danger",
                      html: true,
                      details: `<span>
                      Reference:<br>
                      Adapted from NCCN Guidelines&reg;: Multiple Myeloma <a href="https://www.nccn.org" >https://www.nccn.org</a></span>`,
                    },
                  ],
                  text: "IMWG MRD CRITERIA",
                  description: "",
                  data: `imwg-mrd-criteria`,
                },
                {
                  text: "IMWG STANDARD CRITERIA",
                  description: "",
                  data: `imwg-mrd-criteria-standard`,
                },
              ],
            },
            {
              type: "view",
              title: "HIGH RISK PROGNOSTIC DISEASE",
              visible: false,
              sections: [
                {
                  text: "FACTORS PROGNOSTIC OF HIGH RISK DISEASE IN MULTIPLE MYELOMA (MM)",
                  superscript: "1",
                  description: null,
                  data: "factors-prognostic",
                },
              ],
            },
            {
              type: "view",
              title: "INTERNATIONAL STAGING SYSTEM",

              sections: [
                {
                  superscript: "1",
                  text: "INTERNATIONAL STAGING SYSTEM (ISS) FOR MULTIPLE MYELOMA (MM)",
                  description: null,
                  data: `internation-staging-system`,
                },
              ],
            },
            {
              title: "INTERNATIONAL GUIDELINES",
              visible: false,
              header: "USEFUL LINKS FOR ADDITIONAL INFORMATION ",
              type: "information",
              sections: [
                {
                  title: "ELN - European Leukemia Network",
                  link: "https://www.leukemia-net.org/leukemias/",
                },
                {
                  title: "ESMO - European Society for Medical Oncology",
                  link: "https://www.esmo.org/",
                },
                {
                  title: "NCCN - National Comprehensive Cancer Network",
                  link: "https://www.nccn.org",
                },
                {
                  title: "South African Council for Medical Schemes: PMB Definition Guidelines",
                  link: "https://www.medicalschemes.co.za/publications/#2009-3568-wpfd-haematology",
                },
              ],
            },
            {
              title: "MEDICAL INFORMATION",
              visible: false,
              header: null,
              type: "html",
              data: `
                <p><strong class="text-color-change">
                Should you require any further clinical trials or medical information please use the below contact information 
                </strong>
                </p>
                <p>
                Email: <a class="link-color" href="mailto:ra-medinfoemmarkets@its.jnj.com">ra-medinfoemmarkets@its.jnj.com</a>
                <br>Telephone: 0860 11 11 17
                </p>
              `,
            },
          ],
        },
      },
    };
  },
  methods: {
    referenceAdd(evnt, name) {
      let lengthForRef = 0;
      if (this.form[name].collapses[evnt.collapseIndex].sections.length > 0) {
        lengthForRef = this.form[name].collapses[evnt.collapseIndex].sections.length - 1;
      }

      evnt.data.forEach((val, index) => {
        this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options[0].references.push(val);
      });
    },
    referenceRemove(evnt, name) {
      let lengthForRef = 0;
      if (this.form[name].collapses[evnt.collapseIndex].sections.length > 0) {
        lengthForRef = this.form[name].collapses[evnt.collapseIndex].sections.length - 1;
      }

      let tempArray = this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options.filter((val, index) => {
        return val.type == "reference";
      });
      let tempRemoved = [];

      tempArray[0].references.forEach((val, index) => {
        evnt.data.forEach((data, dataIndex) => {
          if (Object.is(data, val)) {
          } else {
            tempRemoved.push(val);
          }
        });
      });
      this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options[0].references = tempRemoved;
    },

    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepData = this.prepVelcade(this.form);
        let processed = this.flatData(prepData, darzalexJson);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    save() {
      this.setLocal("darzalex", this.form);
    },

    async exportPDF(reaction) {
      let prepData = this.prepVelcade(this.form);

      let processed = this.flatData(prepData, darzalexJson);
      let response = await this.exportPDFUrl(processed, this.form.patient_detail_full_name);

      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    async exportWord(reaction) {
      let prepData = this.prepVelcade(this.form);
      let x = new RegExp('<p class="indentity"', "g");
      let m = prepData.medical_scheme_info.replace(x, '<br><br><p class="indentity"');
      prepData.medical_scheme_info = m;
      let processed = this.flatData(prepData, darzalexJson);
      let response = await this.exportWordUrl(processed, this.form.patient_detail_full_name);
      window.open(response.data.data.url, "_blank");
      if (reaction == "save") {
        this.save();
      } else {
        this.reset();
      }
    },
    prepVelcade(form) {
      let checkbox;

      let returnObject = {
        hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
        date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
        patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
        date_of_birth: form.date_of_birth ? moment(form.date_of_birth).format("DD/MM/YYYY") : "",
        medical_aid_name: form.medical_aid_name ? form.medical_aid_name : "",
        plan_option: form.plan_option ? form.plan_option : "",
        medical_aid_membership_number: form.medical_aid_membership_number ? form.medical_aid_membership_number : "",
        idc_10_code: form.idc_10_code ? form.idc_10_code : "",
        pmb_code: "910S",
        medical_scheme_info: form.medical_scheme_info
          ? form.medical_scheme_info
            .replace(new RegExp("font-family: Poppins;", "g"), "")
            .replace(new RegExp("font-size: 20px;", "g"), "font-size: 15px;")
            .replace(new RegExp("list-style-type: none !important; counter-reset: item 0 !important;", "g"), "")
            .replace(new RegExp('<li style="box-sizing: border-box; display: table !important;', "g"), '<li style="box-sizing: border-box; display: list-item !important;')
            .replace(
              new RegExp(
                '<ol style="box-sizing: border-box; color: #212529;  font-size: 12px; background-color: #ffffff; margin: 0px !important;  padding: 0px !important;">',
                "g"
              ),
              "<ol>"
            )
            .replace(new RegExp('<ol style="box-sizing: border-box; margin: 0px !important;  padding: 0px !important;">', "g"), "<ol>")
          : "",
      };
      if (form.idc_10_code == "Other") {
        returnObject["idc_10_code_other"] = form.idc_10_code_other ? form.idc_10_code_other : "";
      }
      if (form.motivation) {
        returnObject["motivation_header"] = "MOTIVATION";
        returnObject["motivation"] = form.motivation ? form.motivation : "";
      }
      checkbox = this.getCheckbox(form.clinical_trial);
      returnObject["clinical_trial_header"] = "CLINICAL TRIALS";

      returnObject["clinical_trial"] = checkbox;
      let tempData = {
        collapses: [form.additional_information.collapses[0]],
      };
      let tempAddition = this.getCheckbox(tempData);

      if (tempAddition.length > 0) {
        returnObject["confirmation_active_myeloma_header"] = "CONFIRMATION OF ACTIVE MYELOMA";
        returnObject["additional_information"] = tempAddition;
      }
      if (form.medical_scheme_info) {
        returnObject["medical_scheme_info_header"] = "MEDICAL SCHEME INFORMATION";
      }
      return returnObject;
    },
    reset() {
      this.resetStorage("darzalex");
    },
  },
  created() {
    let data = this.getLocal("darzalex");
    if (data) {
      this.form = data;
    }
  },
};
</script>