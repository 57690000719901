<script>
import Header from "@/components/Header.vue";

export default {
  components: {
    Header
  },
  data(){
    return{
      disclaimer:true,
    }
  },
  beforeMount: function(){
      let route = this.$route.name;
      if(route=='dqli-quiz' || route=='basdai-quiz' || route == 'routesview'){
        this.disclaimer = false;
      }
  }
};
</script>
<template>
  <div id="app">
    <div :class="['regularBackground',{ 'differentBackground':$route.name=='dqli-quiz'||$route.name=='basdai-quiz'}]">
      <Header :name="$route.name"></Header>
      <router-view class="router-view" />
    </div>
    <b-modal
        v-model="disclaimer"
        hide-header
        hide-header-close
        size="lg"
        ok-only
        centered
        no-close-on-backdrop
        no-close-on-esc>

      <b-container>
        <b-row>
          <b-col>
            <p>
              <strong>Website disclaimer</strong>
            </p>
            <p>The information is provided by Janssen Pharmaceutica (Pty) Ltd (Janssen), and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk and utilised at your discretion with no undue influence from Janssen. Therefore, please always maintain a responsible approach of satisfying yourself of the content of information used in the best interests of your patients when utilising this site.</p>
            <p>In utilising this service, you may generate Personal and Health Information of your patient. Accordingly, you are responsible to capture, maintain and securely retain the confidentiality of Such Personal and Health Information. Please be advised that Janssen does not store, cannot view, has no access to or save any patient information or data you input.</p>
            <p>Janssen Pharmaceutica (Pty) Ltd will not be liable for any direct or indirect or consequential damage, loss or cost arising from the use of this website, except in the event that there is negligence of Janssen or to the extent that liability for such damage cannot be excluded by law.</p>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-ok>Agree</template>
    </b-modal>
  </div>
</template>
