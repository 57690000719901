import { render, staticRenderFns } from "./documents.vue?vue&type=template&id=4574dfdd&scoped=true&"
import script from "./documents.vue?vue&type=script&lang=js&"
export * from "./documents.vue?vue&type=script&lang=js&"
import style0 from "./documents.vue?vue&type=style&index=0&id=4574dfdd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4574dfdd",
  null
  
)

export default component.exports