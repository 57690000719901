<template>
  <Layout>
    <template v-slot:required-header>
      <b-row>
        <b-col md="2" sm="12">
          <h6>HCP Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input
            name="hcp_full_name"
            :required="validateErrors.hcp_full_name"
            type="text"
            :binding="form.hcp_full_name"
            placeholder="Enter Dr Name"
            label="HCP Name"
            @changeValue="
              form.hcp_full_name = $event;
              options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :required="validateErrors.date"
            :binding="form.date"
            name="date"
            label="Date"
            placeholder="Select Date"
            @changeValue="
              form.date = $event;
              options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row class="mb-0">
        <b-col md="2" sm="12">
          <h6>Patient's Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input
            :required="validateErrors.patient_detail_full_name"
            type="text"
            name="patient_detail_full_name"
            :binding="form.patient_detail_full_name"
            placeholder="Enter Patient's full name"
            label="Full Name"
            @changeValue="
              form.patient_detail_full_name = $event;
              options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            :required="validateErrors.date_of_birth"
            type="date"
            :binding="form.date_of_birth"
            name="date_of_birth"
            placeholder="Select Date of birth"
            label="Date of Birth"
            @changeValue="
              form.date_of_birth = $event;
              options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <b-row class="mb-0">
        <b-col md="6" offset="2" sm="12">
          <Input type="text" name="medical_aid_name" :binding="form.medical_aid_name" placeholder="Enter Medical Aid Name " label="Medical Aid Name" @changeValue="form.medical_aid_name = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input name="plan_option" type="text" :binding="form.plan_option" placeholder="Enter option here" label="Plan Option" @changeValue="form.plan_option = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input name="medical_aid_membership_number" type="text" :binding="form.medical_aid_membership_number" placeholder="Enter Membership Number" label="Membership Number" @changeValue="form.medical_aid_membership_number = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput
            :binding="form.idc_10_code"
            @changeValue="
              form.idc_10_code = $event;
              changeTypeInput();
            "
            :options="options.idc_10_code"
            label="ICD 10 Codes"
            placeholder
            name="icd_10_codes"></SelectInput>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <SelectInput
            :binding="form.type_of_patient"
            @changeValue="
              form.type_of_patient = $event;
              changeICD();
            "
            :options="options.type_of_patient_options"
            label="Diagnosis"
            placeholder
            name="type_of_patient_codes"></SelectInput>
        </b-col>
        <b-col md="4" sm="12"></b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Prostate Specific Antigen (PSA)</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input
            name="initial_diagnosis_enter"
            :required="validateErrors.initial_diagnosis_enter"
            type="text"
            :binding="form.initial_diagnosis_enter"
            placeholder="Enter PSA value"
            label="Initial Diagnosis"
            @changeValue="form.initial_diagnosis_enter = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :binding="form.initial_diagnosis"
            :required="validateErrors.initial_diagnosis"
            name="initial_diagnosis"
            placeholder="Enter PSA value"
            label="Initial Diagnosis Date"
            @changeValue="
              form.initial_diagnosis = $event;
              options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row>
        <b-col offset-md="2" md="6" sm="12">
          <Input name="psa_value_enter" type="text" :required="validateErrors.psa_value_enter" :binding="form.psa_value_enter" placeholder="Enter PSA value" label="Most Recent PSA Value" @changeValue="form.psa_value_enter = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input
            type="date"
            :required="validateErrors.psa_value"
            :binding="form.psa_value"
            name="psa_value"
            placeholder="Enter PSA value"
            label="Most Recent PSA Value Date"
            @changeValue="
              form.psa_value = $event;
              options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row v-if="form.type_of_patient == 'Non-metastatic castration-resistant prostate cancer'">
        <b-col offset-md="2" md="6" sm="12">
          <Input name="prostate_specific_antigen" type="text" :binding="form.prostate_specific_antigen" placeholder="Enter PSA doubling time" label="Prostate Specific Antigen (PSADT) Doubling Time" @changeValue="form.prostate_specific_antigen = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Castration Status</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input name="castrate_status" type="text" :binding="form.castrate_status" placeholder="Specify the most recent testosterone levels" label="Castration Status" @changeValue="form.castrate_status = $event" />
        </b-col>
      </b-row>
      <b-row v-if="form.type_of_patient == 'Non-metastatic castration-resistant prostate cancer'">
        <b-col md="2" sm="12">
          <h6>Nodal Involvement</h6>
        </b-col>
        <b-col md="6" sm="12">
          <SelectInput :binding="form.nodal_involvement" :options="options.nodal_involvement_options" label="Nodal Involvement" placeholder name="nodal_involvement" @changeValue="form.nodal_involvement = $event"></SelectInput>
        </b-col>
        <b-col md="4" sm="12"></b-col>
      </b-row>
    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Motivation</h6>
        </b-col>
        <b-col md="10" sm="12">
          <p>
            <span class="red-text"
              >It is advisable that your motivational letter include the clinical presentation of the patient, previous treatment history and the patient’s co-morbid disease. Please view below the relevant supporting documentation you may need to support your
              motivational letter. It includes: clinical staging, PSA score, Gleason score and ECOG performance status.</span
            >
          </p>
          <Input type="textarea" :binding="form.motivation" placeholder="Motivate here" name="motivation" @changeValue="form.motivation = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Medical Scheme Information</h6>
        </b-col>
        <b-col md="10" sm="12">
          <p>
            <span class="red-text"> Text from medical scheme information, found under additional information can be pasted below should you require.</span>
          </p>
          <HtmlEditor :inputData="form.medical_scheme_info" @changeValue="form.medical_scheme_info = $event"></HtmlEditor>
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton
        :tabs="options.tabs"
        :selectedTab="selectedTab"
        @changeTab="
          selectedTab = $event;
          changeActive($event);
        "></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="selectedTab == 'trials'">
        <b-col v-if="form.type_of_patient" md="12" sm="12">
          <Accordion v-if="form.type_of_patient == 'Non-metastatic castration-resistant prostate cancer'" :info="form.non_metastatic_castration" type="checkbox"></Accordion>
          <Accordion v-if="form.type_of_patient == 'Metastatic Hormone-sensitive prostate cancer'" :info="form.metastatic_hormone" type="checkbox"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'additional'">
        <b-col md="12" sm="12">
          <Accordion :info="form.additionalDetails" type></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'checklist'">
        <b-col md="12" sm="12">
          <Checklist>
            <template v-slot:pre-title>
              <b-alert variant="danger" show>
                <b-container fluid>
                  <b-row>
                    <b-col md="1" sm="1" class="pr-0 icon-col">
                      <img
                        src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png"
                        alt />
                    </b-col>
                    <b-col md="11" sm="11">Please remember to add / attach the below checklist documentation when submitting your motivational letter.</b-col>
                  </b-row>
                </b-container>
              </b-alert>
            </template>

            <template v-slot:title>
              <h4 class="section-title section-title-spacing">CHECKLIST OF DOCUMENTS TO INCLUDE IN YOUR MOTIVATION FOR ERLEADA</h4>
            </template>
            <template v-slot:content>
              <ul>
                <li>Laboratory results</li>
                <li>
                  Imaging
                  <ul>
                    <li>Bone Scan and CT Scan</li>
                    <li>MRI Scan</li>
                  </ul>
                </li>
                <li>Any other document that can strengthen your case</li>
              </ul>
            </template>
          </Checklist>
        </b-col>
      </b-row>
    </template>
    <template v-slot:modal-preview>
      <ModalComponent :name="previewModal.data" @closing="closeModal()" :response="true" :modalName="previewModal.modalName" :showModal="previewModal.show"></ModalComponent>
    </template>
    <template v-slot:modal-type>
      <ModalComponent
        size="md"
        :defaultModal="true"
        @closing="exportModal.show = false"
        :response="true"
        :DocumentType="exportModal.type"
        :modalName="exportModal.modalName"
        :showModal="exportModal.show"
        :footer="false"
        @saveExport="processType($event, 'save')"
        @exportReset="processType($event, 'reset')"></ModalComponent>
    </template>
    <template v-slot:formControl>
      <FormControl :alert="alertMessage" @alertClose="alertMessage.show = false" @preview="preview()" @save="save()" @reset="reset()" @exportPDF="showModalExports('pdf')" @exportWord="showModalExports('Word')"></FormControl>
    </template>
  </Layout>
</template>

<script>
import Input from "@/components/Fields/Input";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import moment from "moment";
import Accordion from "@/components/Accordion";
import ZytigaJSON from "@/assets/json/erleada.json";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import Layout from "./Layouts";
import HtmlEditor from "../components/HtmlEditor.vue";
import FormControl from "../components/FormControl.vue";
export default {
  components: {
    Layout,
    Input,
    SelectInput,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent,
    HtmlEditor,
    FormControl,
  },
  mixins: [GeneralUsage, ModalInformation],
  data() {
    return {
      validateErrors: {
        hcp_full_name: {
          status: null,
          message: "",
        },
        date: {
          status: null,
          message: "",
        },
        patient_detail_full_name: {
          status: null,
          message: "",
        },
        date_of_birth: {
          status: null,
          message: "",
        },
        initial_diagnosis: {
          status: null,
          message: "",
        },
        initial_diagnosis_enter: {
          status: null,
          message: "",
        },
        psa_value: {
          status: null,
          message: "",
        },
        psa_value_enter: {
          status: null,
          message: "",
        },
      },
      options: {
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Clinical Trials", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional",
          },
          {
            value: false,
            text: "Checklist",
            relation: "checklist",
          },
        ],
        type_of_diagnosis_options: [
          {
            value: null,
            text: "Select a diagnosis",
          },
          {
            value: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
            text: "Metastatic hormone sensitive prostate cancer",
          },
          {
            value: "MANTLE CELL LYMPHOMA (MCL)",
            text: "Metastatic castrate resistance prostate cancer- pre-chemo",
          },
          {
            value: "WALDENSTRÖM’S  MACROGLOBULINAEMIA (WM)",
            text: "Metastatic castrate resistance prostate cancer- post-chemo",
          },
        ],
        type_of_patient_options: [
          { value: null, text: "Please select one" },
          {
            value: "Non-metastatic castration-resistant prostate cancer",
            text: "Non-metastatic castration-resistant prostate cancer",
          },
          {
            value: "Metastatic Hormone-sensitive prostate cancer",
            text: "Metastatic Hormone-sensitive prostate cancer",
          },
        ],
        nodal_involvement_options: [
          {
            value: "None",
            text: "None",
          },
          {
            value: "&lt;2cm below riliac bifurcation (Erleada indicated as per SPARTAN Trial)",
            text: "< 2cm below iliac bifurcation (Erleada indicated as per SPARTAN Trial)",
          },
          {
            disabled: true,
            value: "&gr;2cm or situated beyond iliac bifurcation (Erleada is not indicated in nmCRPC setting)",
            text: "> 2cm or situated beyond iliac bifurcation (Erleada is not indicated in nmCRPC setting)",
          },
        ],
        patient_type: [
          {
            condition: "Metastatic hormone sensitive prostate cancer",
            text: "CHRONIC LYMPHOCYTIC LEUKAEMIA (CLL)",
          },
          {
            condition: "Metastatic castrate resistance prostate cancer- pre-chemo",
            text: "MANTLE CELL LYMPHOMA (MCL)",
          },
          {
            condition: "Metastatic castrate resistance prostate cancer- post-chemo",
            text: "WALDENSTRÖM’S  MACROGLOBULINAEMIA (WM)",
          },
        ],
        idc_10_code: [
          {
            value: null,
            text: "Please select ICD Code",
          },
          {
            value: "C. 61 (Non-metastatic castration-resistant prostate cancer)",
            text: "C. 61 (Non-metastatic castration-resistant prostate cancer)",
          },
          {
            value: "C. 61 (Metastatic Hormone-sensitive prostate cancer)",
            text: "C. 61 (Metastatic Hormone-sensitive prostate cancer)",
          },
        ],
        tabs: [
          {
            value: "trials",
            text: "Clinical Trials",
          },
          {
            value: "additional",
            text: "Additional Information",
          },
          {
            value: "checklist",
            text: "Checklist",
          },
        ],
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        idc_10_code: null,
        idc_10_code_other: "",
        type_of_patient: null,
        type_of_patient_type: null,
        motivation: "",
        initial_diagnosis_enter: "",
        initial_diagnosis: null,
        psa_value_enter: "",
        psa_value: null,
        prostate_cancer_stage: null,
        prostate_cancer_stage_sub: null,
        prostate_specific_antigen: "",
        castrate_status: "",
        nodal_involvement: null,
        medical_scheme_info: "<p></p>",
        non_metastatic_castration: {
          collapses: [
            {
              type: "checkbox",
              title: "NON-METASTATIC CASTRATION RESISTANT PROSTATE CANCER",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "SPARTAN",
                  options: [
                    {
                      name: "SPARTAN",
                      type: "model",
                      value: false,
                      label: "SPARTAN Trial",
                      link: "erleada.spartan",
                      modal: "spartan",
                      modalHeading: "SPARTAN Trial",
                      footerAppend: `<p></p>`,
                    },
                    {
                      name: "final_survival",
                      type: "model",
                      value: false,
                      label: "Final Survival Results From SPARTAN",
                      link: "erleada.final_survival",
                      modal: "final_survival",
                      modalHeading: "Final Survival Results From SPARTAN",
                      footerAppend: `<p></p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        metastatic_hormone: {
          collapses: [
            {
              type: "checkbox",
              title: "METASTATIC HORMONE SENSITIVE PROSTATE CANCER",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "TITAN",
                  options: [
                    {
                      name: "TITAN",
                      type: "model",
                      value: false,
                      label: "TITAN Trial",
                      link: "erleada.titan",
                      modal: "titan",
                      modalHeading: "TITAN Trial",
                      footerAppend: `<p></p>`,
                    },
                    {
                      name: "final_titan",
                      type: "model",
                      value: false,
                      label: "Final Survival Results From TITAN",
                      link: "erleada.final_titan",
                      modal: "final_titan",
                      modalHeading: "Final Survival Results From TITAN",
                      footerAppend: `<p></p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        sensitive_prostate_cancer: {
          collapses: [
            {
              type: "checkbox",
              title: "METASTATIC HORMONE SENSITIVE PROSTATE CANCER",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "Latitude",
                  options: [
                    {
                      name: "latitude",
                      type: "model",
                      value: false,
                      label: "Latitude - (In Patients with Newly Diagnosed High-risk Metastatic Hormone Sensitive Prostate Cancer)",
                      link: "zytiga.latitude",
                      modal: "latitude",
                      modalHeading: "LATITUDE",
                      footerAppend: `<p></p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        resistance_prostate_cancer_pre_chemo: {
          collapses: [
            {
              type: "checkbox",
              title: "METASTATIC CASTRATE RESISTANT PROSTATE CANCER - PRE-CHEMOTHERAPY",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "COU-AA-302",
                  options: [
                    {
                      name: "pre_chemo",
                      type: "model",
                      value: false,
                      link: "zytiga.pre_chemo",
                      label: "COU-AA-302 - (Abiraterone Acetate in patients with chemo-naive metastatic castration-resistant prostate cancer)",
                      modal: "pre_chemo",
                      modalHeading: "COU-AA-302",
                      footerAppend: `<p></p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        resistance_prostate_cancer_post_chemo: {
          collapses: [
            {
              type: "checkbox",
              title: "METASTATIC CASTRATE RESISTANT PROSTATE CANCER - POST-CHEMOTHERAPY",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "COU-AA-301",
                  options: [
                    {
                      name: "post_chemo",
                      type: "model",
                      value: false,
                      link: "zytiga.post_chemo",
                      label: "COU-AA-301 - (Abiraterone Acetate in castration-resistant prostate cancer previously treated with docetaxel-based chemotherapy)",
                      modal: "post_chemo",
                      modalHeading: "COU-AA-301",
                      footerAppend: `<p></p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        additionalDetails: {
          collapses: [
            {
              title: "PROSTATE CANCER STAGING",
              visible: false,
              type: "multiple-choice",
              header: "PROSTATE CANCER STAGING",

              sections: [
                {
                  text: "PRIMARY TUMOR",
                  type: "single",
                  id: "primary_tumor",
                  motivate: [
                    {
                      icon: false,
                      variant: "danger",
                      html: true,
                      details: `<p>Select the prostate cancer stage below to support your diagnosis</p>`,
                    },
                  ],
                  radioValueBind: null,
                  radioValueBindSub: null,
                  options: [
                    {
                      label: "TX - Primary tumor cannot be assessed",
                      value: "TX - Primary tumor cannot be assessed",
                    },
                    {
                      label: "T0 - No evidence of primary tumor",
                      value: "T0 - No evidence of primary tumor",
                    },
                    {
                      label: "T1 - Clinically inapparent tumor neither palpable nor visible by imaging",
                      value: "T1 - Clinically inapparent tumor neither palpable nor visible by imaging",
                      subs: [
                        {
                          label: "T1a - Tumor incidental histologic finding in 5% or less of tissue resected",
                          value: "T1a - Tumor incidental histologic finding in 5% or less of tissue resected",
                        },
                        {
                          label: "T1b - Tumor incidental histologic finding in more than 5% of tissue resected",
                          value: "T1b - Tumor incidental histologic finding in more than 5% of tissue resected",
                        },
                        {
                          label: "T1c - Tumor identified by needle biopsy (e.g. because of elevated PSA)",
                          value: "T1c - Tumor identified by needle biopsy (e.g. because of elevated PSA)",
                        },
                      ],
                    },
                    {
                      label: "T2 - Tumor confined within prostate",
                      value: "T2 - Tumor confined within prostate",
                      subs: [
                        {
                          label: "T2a - Tumor involves one-half of one lobe or less",
                          value: "T2a - Tumor involves one-half of one lobe or less",
                        },
                        {
                          label: "T2b - Tumor involves more than one-half of one lobe but not both lobes",
                          value: "T2b - Tumor involves more than one-half of one lobe but not both lobes",
                        },
                        {
                          label: "T2c - Tumor involves both lobes",
                          value: "T2c - Tumor involves both lobes",
                        },
                      ],
                    },
                    {
                      label: "T3 - Tumor extends through the prostate capsule",
                      value: "T3 - Tumor extends through the prostate capsule",
                      subs: [
                        {
                          label: "T3a - Extracapsular extension (unilateral or bilateral)",
                          value: "T3a - Extracapsular extension (unilateral or bilateral)",
                        },
                        {
                          label: "T3b - Tumor invades seminal vesicle(s)",
                          value: "T3b - Tumor invades seminal vesicle(s)",
                        },
                      ],
                    },
                    {
                      label: "T4 - Tumor is fixed or invades adjacent structures other than seminal vesicles, such as external sphincter, rectum, bladder, levator muscles, and/or pelvic wall.",
                      value: "T4 - Tumor is fixed or invades adjacent structures other than seminal vesicles, such as external sphincter, rectum, bladder, levator muscles, and/or pelvic wall.",
                    },
                  ],
                },
                {
                  text: "REGIONAL LYMPH NODES",
                  type: "single",
                  radioValueBind: null,
                  id: "regional_lymph_nodes",
                  radioValueBindSub: null,
                  options: [
                    {
                      label: "NX - regional lymph nodes were not assessed",
                      value: "NX - regional lymph nodes were not assessed",
                    },
                    {
                      label: "N0 - No regional lymph node metastasis",
                      value: "N0 - No regional lymph node metastasis",
                    },
                    {
                      label: "N1 - Metastasis in regional node(s)",
                      value: "N1 - Metastasis in regional node(s)",
                    },
                  ],
                },
                {
                  text: "DISTANT METASTASIS ",
                  type: "single",
                  radioValueBind: null,
                  id: "distant_metastasies",
                  multiValueBindSub: [],
                  options: [
                    {
                      label: "M0 - No distant metastasis",
                      value: "M0 - No distant metastasis",
                    },
                    {
                      label: "M1 - Distant metastasis",
                      value: "M1 - Distant metastasis",
                      subType: "multi",
                      subs: [
                        {
                          label: "M1a - Non-regional lymph node(s)",
                          value: "M1a - Non-regional lymph node(s)",
                        },
                        {
                          label: "M1b - Bones",
                          value: "M1b - Bones",
                        },
                        {
                          label: "M1c - Other site(s) with or without bone disease",
                          value: "M1c - Other site(s) with or without bone disease",
                        },
                      ],
                    },
                  ],
                },
                {
                  text: "References",

                  type: "reference",
                  html: true,
                  static: ["Parker, C., Gillessen, S., Heidenreich, A. and Horwich, A., 2015. Cancer of the prostate: ESMO Clinical Practice Guidelines for diagnosis, treatment and follow-up. Annals of Oncology, 26(suppl_5), pp.v69-v77."],
                  references: [],
                },
              ],
            },
            {
              title: "GLEASON SCORE",
              visible: false,
              header: "GLEASON SCORE",
              type: "table",
              sections: [
                {
                  id: "gleason_score",
                  text: "GLEASON SCORE",
                  table_header: ["", "ISUP Grade", "Gleason Score", "Definition"],

                  subheading: "Upon selecting the relevant grade below, your selection will automatically populate onto your letter when exporting.",
                  table_key: ["value", "isup_grade", "score", "definition"],
                  selectedValue: null,
                  table_data: [
                    {
                      value: "2-6",
                      isup_grade: "Grade 1",
                      score: "2-6",
                      definition: "Only individual discrete well-formed glands",
                    },
                    {
                      value: "7      (3 + 4)",
                      isup_grade: "Grade 2",
                      score: "7      (3 + 4)",
                      definition: "Predominantly well-formed glands with lesser component of poorly formed/fused/cribriform glands",
                    },
                    {
                      value: "7     (4 + 3)",
                      isup_grade: "Grade 3",
                      score: "7     (4 + 3)",
                      definition: "Predominantly poorly formed/fused/cribriform glands with lesser component of well-formed glands",
                    },
                    {
                      value: "8     (4 + 4)",
                      isup_grade: "Grade 4",
                      score: "8     (4 + 4)",
                      definition: "Only poorly formed/fused/cribriform glands",
                    },
                    {
                      value: "8     (3 + 5)",
                      isup_grade: "Grade 4",
                      score: "8     (3 + 5)",
                      definition: "Predominantly well-formed glands and lesser component lacking glands (or with necrosis)",
                    },
                    {
                      value: "8     (5 + 3)",
                      isup_grade: "Grade 4",
                      score: "8     (5 + 3)",
                      definition: "Predominantly lacking glands (or with necrosis) and lesser component of well-formed glands",
                    },
                    {
                      value: "9-10",
                      isup_grade: "Grade 5",
                      score: "9-10",
                      definition: "Lacking gland formation (or with necrosis) with or without poorly formed/fused/cribriform glands",
                    },
                  ],
                },
                {
                  text: "References",

                  type: "reference",
                  html: true,
                  static: ["International Society of Urological (ISUP) grading system <a href='https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5085914/'>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5085914/</a>"],
                  references: [],
                },
              ],
            },
            {
              title: "ECOG PERFORMANCE STATUS",
              visible: false,
              header: "ECOG PERFORMANCE STATUS",
              type: "table",
              sections: [
                {
                  id: "ecog_performance_status",
                  text: "ECOG PERFORMANCE STATUS",
                  subheading: "Upon selecting the relevant grade below, your selection will automatically populate onto your letter when exporting.",
                  table_header: ["", "Performance status", "Definition"],
                  table_key: ["value", "performance_status", "definition"],
                  selectedValue: null,
                  table_data: [
                    {
                      value: "ECOG PS = 0 (Fully active; no performance restrictions.)",
                      performance_status: 0,
                      definition: "Fully active; no performance restrictions.",
                    },
                    {
                      value: "ECOG PS = 1 (Strenuous physical activity restricted; fully ambulatory and able to carry out light work.)",
                      performance_status: 1,
                      definition: "Strenuous physical activity restricted; fully ambulatory and able to carry out light work.",
                    },
                    {
                      value: "ECOG PS = 2 (Capable of all self-care but unable to carry out any work activities. Up and about >50% of waking hours.)",
                      performance_status: 2,
                      definition: "Capable of all self-care but unable to carry out any work activities. Up and about >50% of waking hours.",
                    },
                    {
                      value: "ECOG PS = 3 (Capable of only limited self-care; confined to bed or chair >50% of waking hours.)",
                      performance_status: 3,
                      definition: "Capable of only limited self-care; confined to bed or chair >50% of waking hours.",
                    },
                    {
                      value: "ECOG PS = 4 (Completely disabled; cannot carry out any self-care; totally confined to bed or chair.)",
                      performance_status: 4,
                      definition: "Completely disabled; cannot carry out any self-care; totally confined to bed or chair.",
                    },
                  ],
                },
                {
                  text: "References",

                  type: "reference",
                  html: true,
                  static: ["Eastern Cooperative Oncology Group (ECOG, Zubrod, World Health Organization) performance scale <a href='https://ecog-acrin.org/resources/ecog-performance-status'>https://ecog-acrin.org/resources/ecog-performance-status</a>"],
                  references: [],
                },
              ],
            },
            {
              title: "INTERNATIONAL GUIDELINES",
              visible: false,
              header: "USEFUL LINKS FOR ADDITIONAL INFORMATION ",
              type: "information",
              sections: [
                {
                  title: "ESMO - European Society for Medical Oncology",
                  link: "https://www.esmo.org/guidelines/haematological-malignancies",
                },
                {
                  title: "NCCN - National Comprehensive Cancer Network",
                  link: "https://www.nccn.org/professionals/physician_gls/default.aspx",
                },
                {
                  title: "EAU- European Association of Urology",
                  link: "https://uroweb.org/guidelines/",
                },
                {
                  title: "AUA- American Urology Association",
                  link: "https://www.auanet.org/guidelines",
                },
              ],
            },
            {
              title: "MEDICAL INFORMATION",
              visible: false,
              header: "MEDICAL INFORMATION",
              type: "html",
              data: `
          <p><strong>
          Should you require any further clinical trials or medical information please use the below contact information 
          </strong>
          </p>
          <p>
          Email: <a class="link-color" href="mailto:ra-medinfoemmarkets@its.jnj.com">ra-medinfoemmarkets@its.jnj.com</a>
          <br>Telephone: 0860 11 11 17
          </p>
          `,
            },
            {
              title: "MEDICAL SCHEME INFORMATION",
              visible: false,
              header: "Medical scheme information",
              type: "documents",
              sections: [
                {
                  text: "MEDICAL SCHEME INFORMATION",
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "Please note the document is not auto populated kindly copy and paste any relevant sections into the free text region above",
                    },
                  ],

                  options: [
                    {
                      type: "model",
                      value: false,
                      link: "erleada.legal_reimbursement",
                      label: "The legal framework around patient access to Erleada: Legislation-based responses to declines for reimbursement",
                      modal: "legal_reimbursement",
                      modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO ERLEADA",
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    };
  },
  methods: {
    prepImbruvica(form) {
      let checkbox;

      let returnObject = {
        hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
        date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
        patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
        date_of_birth: form.date_of_birth ? moment(form.date_of_birth).format("DD/MM/YYYY") : "",
        medical_aid_name: form.medical_aid_name ? form.medical_aid_name : "",
        plan_option: form.plan_option ? form.plan_option : "",
        medical_aid_membership_number: form.medical_aid_membership_number ? form.medical_aid_membership_number : "",
        idc_10_code: form.idc_10_code ? form.idc_10_code : "",
        medical_scheme_info: form.medical_scheme_info
          ? form.medical_scheme_info
              .replace(new RegExp("font-family: Poppins;", "g"), "")
              .replace(new RegExp("font-size: 20px;", "g"), "font-size: 15px;")
              .replace(new RegExp("list-style-type: none !important; counter-reset: item 0 !important;", "g"), "")
              .replace(new RegExp('<li style="box-sizing: border-box; display: table !important;', "g"), '<li style="box-sizing: border-box; display: list-item !important;')
              .replace(new RegExp('<ol style="box-sizing: border-box; color: #212529;  font-size: 12px; background-color: #ffffff; margin: 0px !important;  padding: 0px !important;">', "g"), "<ol>")
              .replace(new RegExp('<ol style="box-sizing: border-box; margin: 0px !important;  padding: 0px !important;">', "g"), "<ol>")
          : "",

        initial_diagnosis_enter: form.initial_diagnosis_enter ? form.initial_diagnosis_enter : "",
        initial_diagnosis: form.initial_diagnosis ? moment(form.initial_diagnosis).format("DD/MM/YYYY") : "",
        psa_value_enter: form.psa_value_enter ? form.psa_value_enter : "",
        psa_value: form.psa_value ? moment(form.psa_value).format("DD/MM/YYYY") : "",
        prostate_specific_antigen: form.prostate_specific_antigen ? form.prostate_specific_antigen : "",
      };
      if (form.idc_10_code == "Other") {
        returnObject["idc_10_code_other"] = form.idc_10_code_other;
      }
      returnObject["type_of_patient"] = form.type_of_patient ? form.type_of_patient : "";
      returnObject["castrate_status"] = form.castrate_status ? form.castrate_status : "";
      if (form.motivation) {
        returnObject["motivation_header"] = "MOTIVATION";
        returnObject["motivation"] = form.motivation ? form.motivation : "";
      }
      if (form.medical_scheme_info) {
        returnObject["medical_scheme_info_header"] = "MEDICAL SCHEME INFORMATION";
      }
      if (form.type_of_patient == "Non-metastatic castration-resistant prostate cancer") {
        returnObject["clinical_trial_header"] = "CLINICAL TRIALS";
        returnObject["non_metastatic_castration_header"] = "NON-METASTATIC CASTRATION RESISTANT PROSTATE CANCER";
        checkbox = this.getCheckbox(form.non_metastatic_castration);
        returnObject["non_metastatic_castration_trials"] = checkbox;
        returnObject["nodal_involvement_header"] = "NODAL INVOLVEMENT";
        returnObject["nodal_involvement"] = form.nodal_involvement ? form.nodal_involvement : "";
      } else {
        if (form.type_of_patient == "Metastatic Hormone-sensitive prostate cancer") {
          returnObject["clinical_trial_header"] = "CLINICAL TRIALS";
          returnObject["metastatic_hormone_header"] = "METASTATIC HORMONE SENSITIVE PROSTATE CANCER";
          checkbox = this.getCheckbox(form.metastatic_hormone);
          returnObject["metastatic_hormone_trials"] = checkbox;
        }
      }
      if (form.additionalDetails) {
        form.additionalDetails.collapses.forEach((collapse) => {
          if (collapse.type == "multiple-choice") {
            collapse.sections.forEach((section) => {
              if (section.radioValueBind != null) {
                returnObject[section.id + "_header"] = section.text;
                returnObject[section.id] = section.radioValueBind;
                if (section.options) {
                  let val = section.options.some((val) => {
                    if (val.label == section.radioValueBind) {
                      if (val.subs) {
                        return true;
                      }
                    }
                  });

                  if (val) {
                    if (section.radioValueBindSub) {
                      returnObject[section.id + "_sub"] = section.radioValueBindSub;
                    }
                    if (section.multiValueBindSub) {
                      returnObject[section.id + "_multi"] = section.multiValueBindSub;
                    }
                  }
                }
              }
            });
          } else if (collapse.type == "table") {
            collapse.sections.forEach((section) => {
              if (section.id) {
                if (section.selectedValue) {
                  returnObject[section.id + "_header"] = collapse.title;
                  returnObject[section.id] = section.selectedValue;
                }
              }
            });
          }
        });
      }

      return returnObject;
    },
    customMicroValidate(form) {
      let array = {};
      if (form.hcp_full_name == null || form.hcp_full_name == "") {
        array = {
          hcp_full_name: {
            status: false,
            message: "HCP Name Required!",
          },
          ...array,
        };
      }
      if (form.date == null || form.date == "") {
        array = {
          date: {
            status: false,
            message: "Date Required!",
          },
          ...array,
        };
      }
      if (form.patient_detail_full_name == null || form.patient_detail_full_name == "") {
        array = {
          patient_detail_full_name: {
            status: false,
            message: "Patient Detail Full Name Required!",
          },
          ...array,
        };
      }
      if (form.date_of_birth == null || form.date_of_birth == "") {
        array = {
          date_of_birth: {
            status: false,
            message: "Date of birth Required!",
          },
          ...array,
        };
      }
      if (form.psa_value_enter == null || form.psa_value_enter == "") {
        array = {
          psa_value_enter: {
            status: false,
            message: "Most recent PSA value Required!",
          },
          ...array,
        };
      }
      if (form.psa_value == null || form.psa_value == "") {
        array = {
          psa_value: {
            status: false,
            message: "Most recent PSA value Date Required!",
          },
          ...array,
        };
      }

      if (Object.keys(array).length > 0) {
        return {
          status: false,
          data: array,
        };
      }
      return {
        status: true,
      };
    },
    // Preview data
    async preview() {
      let check = this.customMicroValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepImbruv = this.prepImbruvica(this.form);
        let processed = this.flatData(prepImbruv, ZytigaJSON);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;

        this.validateErrors.psa_value = check.data.psa_value;
        this.validateErrors.psa_value_enter = check.data.psa_value_enter;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    changeTypeInput() {
      switch (this.form.idc_10_code) {
        case "C. 61 (Non-metastatic castration-resistant prostate cancer)":
          this.form.type_of_patient = "Non-metastatic castration-resistant prostate cancer";
          break;
        case "C. 61 (Metastatic Hormone-sensitive prostate cancer)":
          this.form.type_of_patient = "Metastatic Hormone-sensitive prostate cancer";
          break;
      }
    },

    changeICD() {
      switch (this.form.type_of_patient) {
        case "Non-metastatic castration-resistant prostate cancer":
          this.form.idc_10_code = "C. 61 (Non-metastatic castration-resistant prostate cancer)";
          break;
        case "Metastatic Hormone-sensitive prostate cancer":
          this.form.idc_10_code = "C. 61 (Metastatic Hormone-sensitive prostate cancer)";
          break;
      }
    },
    // Saved data
    save() {
      this.setLocal("eleada", this.form);
    },
    // Show modal exports with two options

    // Export pdf function
    async exportPDF(reaction) {
      this.alertMessage.show = false;
      let prepImbruv = this.prepImbruvica(this.form);
      let processed = this.flatData(prepImbruv, ZytigaJSON);
      let response = await this.exportPDFUrl(processed, this.form.patient_detail_full_name);
      if (response.error) {
        this.alertMessage = {
          show: true,
          variant: "error",
          message: response.error.message,
        };
      } else {
        window.open(response.data.data.url, "_blank");
        if (reaction == "save") {
          this.save();
        } else {
          this.reset();
        }
      }
    },
    // Export to word function
    async exportWord(reaction) {
      let prepImbruv = this.prepImbruvica(this.form);
      let processed = this.flatData(prepImbruv, ZytigaJSON);
      let response = await this.exportWordUrl(processed, this.form.patient_detail_full_name);
      if (response.error) {
        this.alertMessage = {
          show: true,
          variant: "error",
          message: response.error.message,
        };
      } else {
        window.open(response.data.data.url, "_blank");
        if (reaction == "save") {
          this.save();
        } else {
          this.reset();
        }
      }
    },
    // Reset data
    reset() {
      this.resetStorage("eleada");
    },
  },
  created() {
    let data = this.getLocal("eleada");
    if (data) {
      this.form = data;
    }
  },
};
</script>
