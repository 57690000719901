<template>
  <b-container fluid class="shift-container">
    <div class="d-none d-md-block">
      <b-row class="mt-2">
        <b-col md="1" sm="1"></b-col>
        <b-col md="5" sm="5" class="question-area"></b-col>

        <b-col md="1" sm="1" class="headerStyles">
          <div class="rounding secondaryColoring">Very Much</div>
        </b-col>
        <b-col md="1" sm="1" class="headerStyles">
          <div class="rounding secondaryColoring">A Lot</div>
        </b-col>
        <b-col md="1" sm="1" class="headerStyles">
          <div class="rounding secondaryColoring">A Little</div>
        </b-col>
        <b-col md="1" sm="1" class="headerStyles">
          <div class="rounding secondaryColoring">Not at all</div>
        </b-col>
        <b-col md="1" sm="1" class="headerStyles">
          <div class="rounding secondaryColoring">Not Relevant</div>
        </b-col>
        <b-col md="1" sm="1" class="headerStyles overflow-hidden">
          <div class="rounding primaryColoring">DLQI (For Clinician)</div>
        </b-col>
      </b-row>
      <b-row v-for="(quiz,quizIndex) in quizDetails" :key="quizIndex" class="radio-quiz-row h-100">
        <b-col md="1" class="other-similar">
          <div class="question-number-radio" v-if="quiz.id!=null">{{quiz.id}}</div>
        </b-col>
        <b-col md="5" sm="5" class="question-area other-similar">
          <b-container>
            <b-row>
              <b-col>
                <p v-html="quiz.question"></p>
              </b-col>
            </b-row>
            <b-row v-if="quiz.trueOrFalse" class="pt-2">
              <b-col>
                <div class="image-style-parent">
                  <img src="https://motivateforms.co.za/public
/img/frontend_assets/onState.png" class="linkable" v-if="quiz.value==6" />
                  <img
                    src="https://motivateforms.co.za/public
/img/frontend_assets/offState.png"
                    class="linkable"
                    @click="quiz.value=6;quiz.actualValue=3"
                    v-if="quiz.value!=6"
                  /> Yes
                </div>
              </b-col>
              <b-col>
                <div class="image-style-parent">
                  <img src="https://motivateforms.co.za/public
/img/frontend_assets/onState.png" class="linkable" v-if="quiz.value==5" />
                  <img
                    src="https://motivateforms.co.za/public
/img/frontend_assets/offState.png"
                    class="linkable"
                    @click="quiz.value=5;quiz.actualValue=0"
                    v-if="quiz.value!=5"
                  /> No
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-col>

        <template v-for="(option,optionIndex) in quiz.options">
          <b-col :key="optionIndex" md="1" sm="1" class="radio-area coloringCol col-coloring">
            <div v-if="option.status" class="image-style-parent">
              <img
                src="https://motivateforms.co.za/public
/img/frontend_assets/onState.png"
                class="linkable"
                v-if="quiz.value==option.value"
              />
              <img
                src="https://motivateforms.co.za/public
/img/frontend_assets/offState.png"
                class="linkable"
                @click="quiz.value=option.value;quiz.actualValue=option.actualValue"
                v-if="quiz.value!=option.value"
              />
            </div>
          </b-col>
        </template>

        <b-col
          md="1"
          sm="1"
          :class="['radio-area', quiz.actualValue!=null?'changed-color-purple':'specialColoring' ,'col-coloring']"
        >
          <div class="column-primary">{{quiz.actualValue}}</div>
        </b-col>
      </b-row>
    </div>
    <div class="d-md-none">
      <b-row class="pt-2">
        <b-col class="pl-1 pr-1">
          <div class="rounding secondaryColoring">Very Much</div>
        </b-col>
        <b-col class="pl-1 pr-1">
          <div class="rounding secondaryColoring">A Lot</div>
        </b-col>
        <b-col class="pl-1 pr-1">
          <div class="rounding secondaryColoring">A Little</div>
        </b-col>
        <b-col class="pl-1 pr-1">
          <div class="rounding secondaryColoring">Not at All</div>
        </b-col>
        <b-col class="pl-1 pr-1">
          <div class="rounding secondaryColoring">Not Relevant</div>
        </b-col>
      </b-row>
      <template>
        <div v-for="(quiz,quizIndex) in quizDetails" :key="quizIndex" class="mt-2">
          <b-row>
            <b-col cols="2">
              <div class="question-number-radio" v-if="quiz.id!=null">{{quiz.id}}</div>
            </b-col>
            <b-col cols="10">
              <p v-html="quiz.question"></p>
            </b-col>
          </b-row>
          <b-row v-if="quiz.trueOrFalse" class="pt-2">
            <b-col>
              <div class="image-style-parent side">
                <img src="https://motivateforms.co.za/public
/img/frontend_assets/onState.png" class="linkable-moble " v-if="quiz.value==6" />
                <img
                  src="https://motivateforms.co.za/public
/img/frontend_assets/offState.png"
                  class="linkable-moble"
                  @click="quiz.value=6;quiz.actualValue=3"
                  v-if="quiz.value!=6"
                /> Yes
              </div>
            </b-col>
            <b-col class="no-option">
              <div class="image-style-parent">
                <img src="https://motivateforms.co.za/public
/img/frontend_assets/onState.png" class="linkable-moble" v-if="quiz.value==5" />
                <img
                  src="https://motivateforms.co.za/public
/img/frontend_assets/offState.png"
                  class="linkable-moble"
                  @click="quiz.value=5;quiz.actualValue=0"
                  v-if="quiz.value!=5"
                /> No
              </div>
            </b-col>
           
          </b-row>

          <b-row v-if="quiz.trueOrFalse" class="mb-2">
            <b-col class="btm-line"></b-col>
          </b-row>
          <b-row>
            <template v-for="(option,optionIndex) in quiz.options">
              <b-col
                :key="optionIndex"
                :class="['radio-area',optionIndex!=quiz.options.length-1?'side':'']"
              >
                <div v-if="option.status" class="image-style-parent">
                  <img
                    src="https://motivateforms.co.za/public
/img/frontend_assets/onState.png"
                    class="linkable-moble"
                    v-if="quiz.value==option.value"
                  />
                  <img
                    src="https://motivateforms.co.za/public
/img/frontend_assets/offState.png"
                    class="linkable-moble"
                    @click="quiz.value=option.value;quiz.actualValue=option.actualValue"
                    v-if="quiz.value!=option.value"
                  />
                </div>
              </b-col>
            </template>
          </b-row>

          <b-row>
            <b-col class="btm-line"></b-col>
          </b-row>
        </div>
      </template>
    </div>
  </b-container>
</template>

<script>
export default {
  props: {
    quizDetails: Array
  },
  methods: {
    score(index, value) {
      this.quizDetails[index].value = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.headerStyles {
  padding-left: 0.6rem;
  margin-left: 0;
  padding-right: 0rem;
  margin-right: 0;
}
.linkable {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  height: 3rem;
}
.image-style-parent {
  text-align: center;
}

.question-area {
  padding-right: 0;
  padding-left: 0;
  font-size: 0.9rem;
}
.radio-area {
  border-left: 0.6rem solid white;
}
.linkable-moble {
  height: 2rem;
}
</style>