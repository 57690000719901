<template>
  <div>
    <b-modal
      content-class="height-limiting-modal"
      v-model="showModal"
      scrollable
      centered
      :size="size"
      :hide-footer="footer"
      no-close-on-esc
      no-close-on-backdrop
    >
      <template v-slot:modal-header="{ close }">
        <b-container fluid>
          <b-row>
            <!-- <b-col v-if="modalIcon" md="1" class="pr-0">
            <img src="https://motivateforms.co.za/public
/img/frontend_assets/more-info.png" height="30rem" class="icon-placement"/>
          </b-col> -->
            <b-col :md="modalIcon ? 11 : 11" class="pl-2 pt-2 pb-2">
              <h4 class="modal-title">{{ modalName }}</h4></b-col
            >
            <b-col md="1">
              <b-button
                size="md"
                class="button-close-modal"
                @click="$emit('closing')"
                >X</b-button
              ></b-col
            >
          </b-row>
        </b-container>
      </template>
      <div class="spacing-div">
        <template v-if="!response || defaultModal">
          <div v-html="TrialModal(name)" class="p-2" v-if="name"></div>
          <div v-html="additionalCode" class="pt-1" v-if="additionalCode"></div>
        </template>
        <template v-if="response || defaultModal">
          <div v-html="name" class="p-2" v-if="name"></div>
        </template>
        <template v-if="defaultModal" class="align-middle"
          >Are you sure you want to export to a
          {{ DocumentType }} document?</template
        >
      </div>

      <template v-slot:modal-footer="{ ok }" v-if="!footer">
        <b-button
          class="saveExportBtn"
          @click="$emit('saveExport', DocumentType)"
          >SAVE AND EXPORT</b-button
        >
        <b-button
          class="resetExportBtn"
          @click="$emit('exportReset', DocumentType)"
          >EXPORT AND RESET</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ModalInformation } from "@/mixins/modals.js";
export default {
  mixins: [ModalInformation],
  props: {
    name: String,
    modalName: String,
    showModal: Boolean,
    modalIcon: { type: Boolean, default: false },
    size: {
      type: String,
      default: "xl",
    },
    DocumentType: { type: String },
    defaultModal: { type: Boolean, default: false },
    footer: { type: Boolean, default: true },
    response: { type: Boolean, default: false },
    additionalCode: { type: String, default: null },
  },
};
</script>

<style scoped lang="scss" >
.modal-body {
  padding: 2rem;
}
.height-limiting-modal {
  max-height: 20rem !important;
  background-color: yellow !important;
  color: red;
}
.icon-placement {
  text-align: center;
}



</style>
