import moment from 'moment';
export const StelaraData = {
    data() {
        return {
            dermatology_life_quality_index: {
                title: "DERMATOLOGY LIFE QUALITY INDEX (DLQI)",

                visible: false,
                type: "assessment",
                sections: [{
                    // superScript: "1A+B,2A+B",
                    superScript: "1,2",
                    text: "DERMATOLOGY LIFE QUALITY INDEX (DLQI)",
                    downloadUrl: "/download/DLQI-form.pdf",
                    viewUrl: "dlqi",
                    attachedValue: 2,
                    options: [{
                        type: "information",
                        html: true,
                        data: `<p>USING THE DLQI TOTAL SCORE, ASSESS THE EFFECT ON QUALITY OF LIFE:</p><table><tr><td class="p-2">0 - 1 = NO EFFECT </td><td class="p-2">2 - 5 = SMALL EFFECT</td><td class="p-2">6 - 10 = MODERATE EFFECT</td></tr><tr><td>11 - 20 = LARGE EFFECT</td><td class="p-2"> 21 - 30 = EXTREMELY LARGE EFFECT</td><td></td></tr></table>`
                    },
                    {
                        type: "number",
                        render: "0 - 1 = NO EFFECT, 2 - 5 = SMALL EFFECT, 6 - 10= MODERATE EFFECT, 11 - 20 = LARGE EFFECT 21 - 30 = EXTREMELY LARGE EFFECT",
                        min: 0,
                        max: 30,
                        label: "Enter Past DLQI Score",
                        description: "",
                        labelPrint: "Previous DLQI Score",
                        value: 0,
                    },
                    {
                        type: "number",
                        min: 0,
                        max: 30,
                        label: "Enter Current DLQI Score",
                        description: "",
                        labelPrint: "Current DLQI Score",
                        value: 0,

                    },
                    {
                        type: "reference",
                        html: true,
                        data: `<p>Reference</p><ol><li>Finlay AY, Khan GK. Dermatology life quality index (DLQI) – a simple practical measure for routine clinical use. Clin Exp Derm 1994;19:210-216</li>
                        <li>Dermatology Life Quality Index. British Association of Dermatology. [cited 2020 Apr 08]; Available from: <a href="https://www.bad.org.uk/shared/get-file.ashx?id=1653&itemtype=document">https://www.bad.org.uk/shared/get-file.ashx?id=1653&itemtype=document</a>.</li></ol>`
                    }
                    ]
                },]
            },

            basdai_bath_ankylosing_spondylitis: {
                title: "BASDAI BATH ANKYLOSING SPONDYLITIS DISEASE ACTIVITY INDEX",
                visible: false,
                type: "assessment",
                sections: [{
                    superScript: "1,2",
                    // superScript: "1A,2A",
                    text: "BASDAI BATH ANKYLOSING SPONDYLITIS DISEASE ACTIVITY INDEX",
                    downloadUrl: "/download/BASDAI-form.pdf",
                    viewUrl: "basdai",
                    attachedValue: 2,
                    options: [{
                        type: "information",
                        html: true,
                        data: `<p>RATE THE BASDAI SEVERITY</p><p>The higher the BASDAI score, the more severe the patient’s disability</p>
                            <table>
                            <tr>
                            <td class="p-2">
                            0 = None;
                            </td>
                            <td class="p-2">
                            10 = Very severe
                            </td>
                            </tr>
                            </table>
                            `
                    },

                    {
                        type: "number",
                        render: "O = None, 10 = Very severe",
                        min: 0,
                        max: 10,
                        label: "Enter Past BASDAI Score",
                        description: "",
                        labelPrint: "Previous BASDAI Score",
                        value: 0,

                    },
                    {
                        type: "number",
                        min: 0,
                        max: 10,
                        label: "Enter Current BASDAI Score",
                        labelPrint: "Current BASDAI Score",
                        description: "",
                        value: 0,

                    }, {
                        type: "reference",
                        html: true,
                        data: `<p>References</p>
                            <p>1. Sieper J, Rudwaleit M, Baraliakos X, et al. The assessment of SpondyloArthritis international Society (ASAS) handbook: a guide to assess spondyloarthritis. Ann Rheum Dis 2009;68(Suppl II):ii1–ii44. </p>
                            <p>2. The Bath indices. Ankylosing spondylitis (Axial Spondyloathritis) (AS). National Ankylosing Spondylitis Society, 2016. [cited 2020 Apr 08]; Available from: <a href="https://nass.co.uk/homepage/health-professionals/resources-for-health-professionals/bath-indices/">https://nass.co.uk/homepage/health-professionals/resources-for-health-professionals/bath-indices/</a></p>
                            `
                    }
                    ]
                }]

            },

            enthesitis_dactylitis_scoring: {
                title: "ENTHESITIS & DACTYLITIS SCORING",
                visible: false,
                type: "assessment",
                sections: [{
                    // superScript: "1A,2A+B,3A+B",
                    superScript: "1,2,3",
                    text: "ENTHESITIS & DACTYLITIS SCORING",
                    downloadUrl: "/download/ENTHESITIS-DACTYLITIS-form.pdf",
                    viewUrl: "dlqi",
                    attachedValue: 2,
                    options: [{
                        type: "information",
                        html: true,
                        data: `
                        <p>THE LEEDS ENTHESITIS INDEX (LEI)</p>
<p>The LEI consists of 6 sites: Achilles tendon insertions, medial femoral condyles, and lateral epicondyles of the
    humerus (left and right of each). Tenderness at each site is quantified as either non-tender (score of 0) or tender
    (score of 1) giving a total score out of 6.  A higher count represents greater enthesitis burden. </p>
                        `
                    },

                    {
                        render: "0-6 represent the number of sites that are affected by tenderness",
                        type: "number",
                        min: 0,
                        max: 6,
                        label: "Enter Past LEI Score",
                        description: "",
                        labelPrint: "Previous LEI Score",
                        value: 0,

                    },

                    {
                        type: "number",
                        min: 0,
                        max: 6,
                        label: "Enter Current LEI Score",
                        description: "",
                        labelPrint: "Current LEI Score",
                        value: 0,

                    },
                    {
                        type: "information",
                        html: true,
                        data: `<p>THE TENDER DACTYLITIS COUNT</p><p>The tender dactylitis count is a simple count based on the presence or absence of tender joints. 20 digits are assessed looking for signs of tender dactylitis. The hands and feet should be visually assessed side by side. </p>`
                    },

                    {
                        render: "0-20 represent the number of tender joints on hands and feet.",
                        type: "number",
                        min: 0,
                        max: 20,
                        label: "Enter Past Dactylitis Count",
                        description: "",
                        labelPrint: "Previous Dactylitis Score",
                        value: 0,

                    },
                    {
                        type: "number",
                        min: 0,
                        max: 20,
                        label: "Enter Current Dactylitis Count",
                        description: "",
                        labelPrint: "Current Dactylitis Score",
                        value: 0,

                    },
                    {
                        type: "reference",
                        html: true,
                        data: `<p>References</p>
                        <ol>
                        <li>Healy PJ, Helliwell PS. Measuring clinical enthesitis in psoriatic arthritis: assessment of existing measures and development of an instrument specific to psoriatic arthritis. Arthritis Rheum (Arthritis Care Res) 2008;59(5):686-691.</li>
                        <li>Mease PJ. Measures of psoriatic arthritis. Arthritis Care Res 2011;63(S11):S64-S85.</li><li>Salaffi F, Ciapetti A, Carotti M, et al. Disease activity in psoriatic arthritis: comparison of the discriminative capacity and construct validity of six composite indices in a real world. Biomed Res Int 2014;2014:528105, 12 pages. </li>
                        </ol>`
                    }
                    ]
                }]

            },
            // TODO Been asked to be removed will remain till everything is approved
            skin_assessment: {
                title: "SKIN ASSESSMENT: BODY SURFACE AREA AFFECTED BY PSORIASIS",
                visible: false,
                type: "assessment",
                sections: [{
                    text: "SKIN ASSESSMENT: BODY SURFACE AREA AFFECTED BY PSORIASIS",

                    options: [{
                        type: "information",
                        html: true,
                        data: `<p>RATE THE BSA SEVERITY</p><table><tr><td>BSA &lt;3% = MILD;</td><td >BSA 3 - 10% = MODERATE; </td><td> &gt;10% = SEVERE</td></tr><table>`
                    },
                    {
                        type: "number",
                        min: 0,
                        max: 10,
                        label: "Enter Past BSA Score",
                        labelPrint: "Previous BSA Score",
                        description: "",

                    },
                    {
                        type: "number",
                        min: 0,
                        max: 10,
                        label: "Enter Current BSA Score",
                        labelPrint: "Current BSA Score",
                        description: "",

                    },
                    {
                        type: "reference",
                        html: true,
                        data: `<p>Reference</p><ol><li>Mease PJ. Measures of psoriatic arthritis. Arthritis Care Res 2011;63(S11):S64-S85</li></ol>`
                    }
                    ]
                }]

            },
            other_involvement: {
                title: "OTHER INVOLVEMENT",
                visible: false,
                type: "assessment",
                sections: [{
                    text: "OTHER INVOLVEMENT",
                    superScript: "1,2",
                    // superScript: "1A+B,2A",
                    options: [{
                        type: "radio",
                        items: ["Yes", "No"],
                        label: "NAILS",
                        value: null,
                        labelPrint: "Nails",
                        input: {
                            type: "text",
                            label: "",
                            value: "",
                            placeholder: "Enter text here"
                        }
                    },
                    {
                        type: "radio",
                        items: ["Yes", "No"],
                        label: "GASTROINTESTINAL TRACT",
                        value: null,
                        labelPrint: "Gastrointestinal Tract",
                        input: {
                            type: "text",
                            label: "",
                            value: "",
                            placeholder: "Enter text here"
                        }
                    },
                    {
                        type: "radio",
                        items: ["Yes", "No"],
                        label: "OCULAR",
                        value: null,
                        labelPrint: "Ocular",
                        input: {
                            type: "text",
                            label: "",
                            value: "",
                            placeholder: "Enter text here"
                        }
                    },
                    {
                        type: "radio",
                        items: ["Yes", "No"],
                        label: "CO-MORBIDITIES (e.g. Diabetes, cardiovascular problems etc.) ",
                        value: null,
                        labelPrint: "Co-morbidities",
                        input: {
                            type: "text",
                            label: "",
                            value: "",
                            placeholder: "Enter text here"
                        }
                    },

                    {
                        type: "textarea",
                        placeholder: "Enter text here",
                        label: "Additional Comments",
                        labelPrint: ""

                    }, {
                        type: "reference",
                        html: true,
                        data: `<p>Reference</p><ol><li> Beyond joints: how PsA affects the body. Arthritis Foundation [cited 2020 Apr 08]; Available from: <a href="https://www.arthritis.org/better-living-toolkit/how-psa-affects-the-body">https://www.arthritis.org/better-living-toolkit/how-psa-affects-the-body</a>.</li>
                        <li>About psoriatic arthritis. Arthritis Foundation [cited 2020 Apr 08]; Available from: <a href="https://www.psoriasis.org/about-psoriatic-arthritis#symptoms.php">https://www.psoriasis.org/about-psoriatic-arthritis#symptoms.php</a>.</li></ol>`
                    }
                    ]
                }]

            },
            biological_experienced_pso_patient: {
                text: "",
                description: "",
                options: [{
                    type: "text",
                    label: "Previous non-biologic therapy",
                    placeholder: "Enter text here",
                    value: "",
                    labelPrint: "Previous non-biologic therapy"
                },
                {
                    type: "checkboxOptions",
                    label: "Reasons for discontinuing biologic therapy/therapies",
                    items: [
                        "Lack of efficacy",
                        "Adverse events",
                        "Pregnancy",
                        "Lack of funds",
                        "Switching to the biosimilar",
                        "Remission"
                    ],
                    value: []
                },
                {
                    type: "text",
                    label: "Other",
                    placeholder: "Enter text here",
                    value: "",
                    labelPrint: "Other"
                }
                ]
            },
            biological_name_psa_patient_experienced: {
                text: "",
                description: "",
                options: [{
                    type: "text",
                    label: "Previous biologic therapy",
                    placeholder: "Enter text here",
                    value: "",
                    labelPrint: "Previous biologic therapy"
                },
                {
                    type: "checkboxOptions",
                    label: "Reasons for discontinuing biologic therapy",
                    items: [
                        "Lack of efficacy",
                        "Adverse events",
                        "Pregnancy",
                        "Lack of funds",
                        "Switching to the biosimilar",
                        "Remission"
                    ],
                    value: []
                },
                {
                    type: "text",
                    label: "Other",
                    placeholder: "Enter text here",
                    value: "",
                    labelPrint: "Other"
                }
                ]
            },
            biological_name_pso_patient: {
                text: "",
                description: "",
                options: [{
                    type: "text",
                    label: "Previous biologic therapy",
                    placeholder: "Enter text here",
                    labelPrint: "Previous non-biologic therapy ",
                    value: ""
                },
                {
                    type: "checkboxOptions",
                    label: "Reasons for discontinuing biologic therapy/therapies",
                    pre_items_note: "Reasons for discontinuing biologic therapy/therapies",
                    items: [
                        "Lack of efficacy",
                        "Adverse events",
                        "Pregnancy",
                        "Lack of funds",
                        "Switching to the biosimilar",
                        "Remission"
                    ],
                    value: []
                },
                {
                    type: "text",
                    label: "Other",
                    labelPrint: "Other",
                    placeholder: "Enter text here",
                    value: "",

                }
                ]
            },
            biological_name_psa_patient: {
                text: "",
                description: "",
                options: [{
                    type: "text",
                    label: "Previous non-biologic therapy",
                    placeholder: "Enter text here",
                    labelPrint: "Previous non-biologic therapy ",
                    value: ""
                },
                {
                    type: "checkboxOptions",
                    label: "Reasons for discontinuing DMARD/NSAID therapy",
                    items: [
                        "Lack of efficacy",
                        "Adverse events",
                        "Pregnancy",
                        "Lack of funds",
                        "Switching to the biosimilar",
                        "Remission"
                    ],
                    value: []
                },
                {
                    type: "text",
                    label: "Other",
                    labelPrint: "Other",
                    placeholder: "Enter text here",
                    value: "",

                }
                ]
            },
            why_prescribed: {
                text: "Why is STELARA<sup>®</sup> being prescribed as an alternative to an anti-TNF?",
                html: true,
                customText: "Why is STELARA® being prescribed as an alternative to an anti-TNF?",
                options:

                    [

                        {
                            html: true,
                            type: "basic",
                            value: false,
                            description: "<p>Phase III data support the use of STELARA<sup>®</sup> with consistent clinical efficacy and an acceptable safety profile in PsA patients who are anti-TNF-naïve, irrespective of methotrexate use<sup>1,2</sup></p>",
                            label: "",
                            labelPrint: "<p>Phase III data support the use of STELARA® with consistent clinical efficacy and an acceptable safety profile in PsA patients who are anti-TNF-naïve, irrespective of methotrexate use.<sup>1,2</sup></p>",

                        },

                        {
                            html: true,
                            type: "basic",
                            value: false,
                            description: "<p>STELARA<sup>®</sup> has been shown to be superior to an anti-TNF in resolving the enthesitis component of the disease in PsA patients<sup>3</sup></p>",
                            label: "",
                            labelPrint: "<p>STELARA® has been shown to be superior to an anti-TNF in resolving the enthesitis component of the disease in PsA patients.<sup>3</sup></p>",

                        },
                        {
                            html: true,
                            type: "basic",
                            value: false,
                            description: "<p>STELARA<sup>®</sup> is preferred if anti-TNFs are contraindicated or if the patient has extensive psoriasis<sup>2</sup></p>",
                            label: "",
                            labelPrint: "<p>STELARA® is preferred if anti-TNFs are contraindicated or if the patient has extensive psoriasis.<sup>2</sup></p>",

                        },
                        {
                            html: true,
                            type: "basic",
                            value: false,
                            description: "<p>STELARA<sup>®</sup> has demonstrated an acceptable long-term safety profile with no reported cases of tuberculosis or deaths in anti-TNF naïve patients<sup>1</sup></p>",
                            label: "",
                            labelPrint: "<p>STELARA® has demonstrated an acceptable long-term safety profile with no reported cases of tuberculosis or deaths in anti-TNF naïve patients.<sup>1</sup></p>",

                        }, {
                            type: "reference",
                            html: true,
                            data: `<p>Referenes</p>
                            <p>1. McInnes IB, Kavanaugh A, Gottlieb AB, et al. Efficacy and safety of ustekinumab in patients with active psoriatic arthritis: 1 year results of the phase 3, multicentre, double-blind, placebo-controlled PSUMMIT 1 trial. Lancet 2013;382:780 -789.</p>
                            <p>2. Roberts J, O'Rielly DD, Rahman P. A review of ustekinumab in the treatment of psoriatic arthritis. Immunotherapy 2018;10(5):361-372.</p>
                            <p>3. Araujo EG, Englbrecht M, Hoepken S, et al. Scientific Abstract OP0217. Ann Rheum Dis 2017;76(Suppl 2):142; DOI: 10.1136/annrheumdis-2017-eular.5398.7 </p>
                                `
                        }
                    ]
            },

            patient_global: {
                title: "PATIENT GLOBAL ASSESSMENT LIFE",
                visible: false,
                type: "assessment",
                sections: [{
                    text: "PATIENT GLOBAL ASSESSMENT LIFE",
                    options: [{
                        type: "information",
                        html: true,
                        data: `<p>TO BE COMPLETED ON BEHALF OF YOUR PATIENT<sup>1</sup></p><p>The aim of this assessment is to measure how much your patient's SKIN and JOINT problem has affected their life OVER THE PAST WEEK. Please rate how they feel from 0 (excellent) to 100 (poor).</p>`
                    },
                    {
                        render: "The assessments below are rated on a scale from 0 - 100 where 0 is Excellent and 100 is Poor.",
                        type: "number",
                        increment: 10,
                        min: 0,
                        max: 100,
                        labelPrint: "SKIN AND JOINT ASSESSMENT",
                        label: "SKIN AND JOINT ASSESSMENT",
                        description: "In all the ways in which your PSORIASIS and ARTHRITIS as a whole affects you, how would you rate the way you felt over the past week?",
                        value: 0,

                    },
                    {
                        type: "number",
                        min: 0,
                        increment: 10,
                        max: 100,
                        labelPrint: "JOINT ASSESSMENT",
                        label: "JOINT ASSESSMENT",
                        description: "In all the ways your ARTHRITIS affects you, how would you rate the way you felt over the past week? ",
                        value: 0,

                    },
                    {
                        type: "number",
                        min: 0,
                        max: 100,
                        increment: 10,
                        labelPrint: "SKIN ASSESSMENT",
                        label: "SKIN ASSESSMENT",
                        description: "In all the ways your PSORIASIS affects you, how would you rate the way you felt over the past week?",
                        value: 0,

                    },
                    {
                        type: "textarea",

                        placeholder: "Enter text here",
                        label: "Describe in the patient’s own words how the disease has affected their everyday life:",
                        value: "",
                        labelPrint: "In the patients own words"

                    },
                    {
                        type: "reference",
                        html: true,
                        data: `<p>Reference</p><ol><li>Cauli A, Gladman DD, Mathieu A, et al. Patient global assessment in psoriatic arthritis: a multicenter GRAPPA and OMERACT study. J Rheumatol 2011;38(5):898-903.</li></ol>`
                    }
                    ]

                }],

            },
            why_prescribed_as_alternative: {
                text: "Why is STELARA<sup>®</sup> being prescribed as an alternative to another anti-TNF agent?",
                customText: "Stelara is being prescribed as an alternative to another anti-TNF agent because", html: true,
                options:

                    [

                        {
                            type: "basic", html: true,
                            value: false,
                            description: "<p>Phase III data support the use of STELARA<sup>®</sup> with consistent clinical efficacy and an acceptable safety profile in PsA patients who are anti-TNF-experienced<sup>1,2</sup></p>",
                            label: "",
                            labelPrint: "<p>Phase III data support the use of STELARA® with consistent clinical efficacy and an acceptable safety profile in PsA patients who are anti-TNF-experienced.<sup>1,2</sup></p>",

                        },
                        {
                            type: "basic",
                            value: false, html: true,
                            description: "<p>When compared to other anti-TNFs, STELARA<sup>®</sup> has demonstrated the highest drug survival over a 10-year period<sup>3</sup></p>",
                            label: "",
                            labelPrint: "<p>When compared to other anti-TNFs, STELARA® has demonstrated the highest drug survival over a 10-year period.<sup>3</sup></p>",

                        },
                        {
                            type: "basic",
                            value: false,
                            description: "<p>STELARA<sup>®</sup> has been shown to be superior to an anti-TNF in resolving the enthesitis component of the disease in PsA patients<sup>4</sup></p>",
                            label: "", html: true,
                            labelPrint: "<p>STELARA® has been shown to be superior to an anti-TNF in resolving the enthesitis component of the disease in PsA patients.<sup>4</sup></p>",

                        },
                        {
                            type: "basic",
                            value: false, html: true,
                            description: "<p>STELARA<sup>®</sup> has demonstrated significant improvements in both physical function and health-related quality in anti-TNF experieced patients<sup>1</sup></p>",
                            label: "",
                            labelPrint: "<p>STELARA® has demonstrated significant improvements in both physical function and health-related quality in anti-TNF experienced patients.<sup>1</sup></p>",

                        },
                        {
                            type: "basic",
                            value: false, html: true,
                            description: "<p>Prior failure of anti-TNF agents, and side effects related to anti-TNF agents warrant strong consideration for the use of STELARA<sup>®</sup> over other anti-TNF agents<sup>2</sup></p>",
                            label: "",
                            labelPrint: "<p>Prior failure of anti-TNF agents, and side effects related to anti-TNF agents warrant strong consideration for the use of STELARA® over other anti-TNF agents.<sup>2</sup></p>",

                        },
                        {
                            type: "reference",
                            html: true,
                            data: `<p>Reference</p>
                            <ol>
                            <li> Ritchlin C, Rahman P, Kavanaugh A, et al. Efficacy and safety of the anti-IL-12/23 p40 monoclonal antibody, ustekinumab, in patients with active psoriatic arthritis despite conventional nonbiological and biological anti-tumour necrosis factor therapy: 6-month and 1-year results of the phase 3, multicentre, double-blind, placebo-controlled, randomised PSUMMIT 2 trial. Ann Rheum Dis 2014;73:990 -999.</li>
<li> Roberts J, O'Rielly DD, Rahman P. A review of ustekinumab in the treatment of psoriatic arthritis. Immunotherapy 2018;10(5):361-372.</li>
<li> Zweegers J, van den Reek JMPA, van de Kerkhof PCM, et al. Body mass index predicts discontinuation due to ineffectiveness and female sex predicts discontinuation due to side-effects in patients with psoriasis treated with adalimumab, etanercept or ustekinumab in daily practice: a prospective, comparative, long-term drug-survival study from the BioCAPTURE registry. Br J Dermatol 2016;175:340-347 </li>
<li> Araujo EG, Englbrecht M, Hoepken S, et al. Scientific Abstract OP0217. Ann Rheum Dis 2017;76(Suppl 2):142; DOI: 10.1136/annrheumdis-2017-eular.5398.</li>
                            
                            </ol>`
                        }
                    ]
            },
            first_choice: {
                text: "Why is STELARA<sup>®</sup> your first biologic choice?", html: true,
                customText: "Stelara® is my first biological choice",
                options: [{
                    type: "basic",
                    value: false, html: true,
                    description: "<p>Bio-naïve STELARA<sup>®</sup> patients reported significantly lower % BSA vs bio-naïve adalimumab patients<sup>1</sup></p>",
                    label: "",
                    labelPrint: "<p>Bio-naïve STELARA® patients reported significantly lower % BSA vs bio-naïve adalimumab patients.<sup>1</sup></p>",

                },
                {
                    type: "basic", html: true,
                    value: false,
                    description: "<p>Bio-naïve STELARA<sup>®</sup> patients were found to be significantly more adherent than adalimumab patients<sup>1</sup></p>",
                    label: "",
                    labelPrint: "<p>Bio-naïve STELARA® patients were found to be significantly more adherent than adalimumab patients.<sup>1</sup></p>",

                },
                {
                    type: "basic",
                    value: false, html: true,
                    description: "<p>STELARA<sup>®</sup> demonstrated consistent clinical response and well tolerated safety profile through 5 and 10 years of treatment<sup>2</sup></p>",
                    label: "",
                    labelPrint: "<p>STELARA® demonstrated consistent clinical response and well tolerated safety profile through 5 and 10 years of treatment.<sup>2</sup></p>",

                },
                {
                    type: "basic", html: true,
                    value: false,
                    description: "<p>STELARA<sup>®</sup> bio-naive patients showed the highest drug survival compared with infliximab, adalimumab, secukinumab and etanercept<sup>3</sup></p>",
                    label: "",
                    labelPrint: "<p>STELARA® bio-naive patients showed the highest drug survival compared with infliximab, adalimumab, secukinumab and etanercept.<sup>3</sup></p>",

                },
                {
                    type: "basic",
                    value: false, html: true,
                    description: "<p>STELARA<sup>®</sup> bio-naive patients showed higher drug survival than patients who had failed one or more biologics<sup>4</sup></p>",
                    label: "",
                    labelPrint: "<p>STELARA® bio-naive patients showed higher drug survival than patients who had failed one or more biologics.<sup>4</sup></p>",

                },
                {
                    type: "reference",
                    html: true,
                    data: `<p>Reference</p>
                    <ol>
                    <li>
                    Goren A, Carter C, Lee S. Patient reported health outcomes and nonadherence in psoriasis patients receiving adalimumab or ustekinumab for moderate to severe plaque psoriasis. J Dermatolog Treat 2016;27(1):43 -50.
                    </li>
                    <li>
                    Menter A, Papp KA, Gooderham M, et al. Drug survival of biologic therapy in a large, disease-based registry of patients with psoriasis: results from the Psoriasis Longitudinal Assessment and Registry (PSOLAR). J Eur Acad Dermatol Venereol 2016;30(7):1148 -1158.
                    </li>
                    <li>
                    Kimball AB, Papp KA, Wasfi Y, et al. Long-term efficacy of ustekinumab in patients with moderate-to-severe psoriasis treated for up to 5 years in the PHOENIX 1 study. J Eur Acad Dermatol Venereol 2013;27(12):1535 -1545.
                    </li>
                    <li>
                     Egeberg A, Ottosen MB, Gniadecki R, et al. Safety, efficacy and drug survival of biologics and biosimilars for moderate-to-severe plaque psoriasis. Br J Dermatol 2018;178:328 -329 
                    </li>
                    </ol>`
                }
                ]
            },

            remotivate_stelara_psa: {
                text: "RE-MOTIVATING FOR STELARA ",

                options: [{
                    type: "text", html: true,
                    label: "<p>How has your patient’s disease scores and assessments improved on STELARA<sup>®</sup>?</p>",
                    placeholder: "Enter text here",
                    value: "",
                    labelPrint: "<p>How has your patient’s disease scores and assessments improved on STELARA® ?</p>",


                },
                {
                    type: "text", html: true,
                    label: "<p>How has STELARA<sup>®</sup> shown consistent benefit in all important domains of PsA including joint, skin, physical function and quality of life, as well as dactylitis and enthesitis?<sup>1</sup> </p>",
                    placeholder: "Enter text here",
                    labelPrint: "<p>How has STELARA® shown consistent benefit in all important domains of PsA including joint, skin, physical function and quality of life, as well as dactylitis and enthesitis?<sup>1</sup></p>",
                    value: "",

                },
                {
                    type: "text", html: true,
                    label: "<p>How has STELARA<sup>®</sup> shown a significant and sustained effect on radiographic inhibition of PsA?<sup>1</sup></p>",
                    placeholder: "Enter text here",
                    value: "",
                    labelPrint: "<p>How has STELARA® shown a significant and sustained effect on radiographic inhibition of PsA?<sup>1</sup></p>",

                }, {
                    type: "reference",
                    html: true,
                    data: `<p>Reference</p>
                    <ol>
                    <li>Roberts J, O'Rielly DD, Rahman P. A review of ustekinumab in the treatment of psoriatic arthritis. Immunotherapy 2018;10(5):361-372.</li>
                    </ol>`
                }
                ]

            },

            remotivate_stelara_pso: {
                text: "RE-MOTIVATING PsO PATIENT ",

                options: [{
                    type: "text", html: true,
                    label: "How has your patient’s disease scores and assessments improved on STELARA<sup>®</sup>?",
                    placeholder: "Enter text here",
                    labelPrint: "How has your patient’s disease scores and assessments improved on STELARA®?",
                    value: "",

                },
                {
                    type: "text", html: true,
                    label: "How has STELARA<sup>®</sup> illustrated an effective and significant improvement of skin manifestations?<sup>1,2,3</sup>",
                    placeholder: "Enter text here",
                    labelPrint: "How has STELARA® illustrated an effective and significant improvement of skin manifestations?<sup>1,2,3</sup>",
                    value: "",

                },
                {
                    type: "text", html: true,
                    label: "How has STELARA<sup>®</sup> contributed to the patient’s overall quality of life?<sup>1,2,3</sup>",
                    placeholder: "Enter text here",
                    value: "",
                    labelPrint: "How has STELARA contributed to the patient’s overall quality of life?<sup>1,2,3</sup>",

                }, {
                    type: "reference",
                    html: true,
                    data: `<p>Reference</p><ol>
               
<li>
Leonardi CL. Kimball AB. Papp KA. et al. Efficacy and safety of ustekinumab. a human interleukin-12/23 monoclonal antibody. in patients with psoriasis : 76-week results from a randomised. double-blind. placebocontrolled trial (PHOENIX1 ). Lancet 2008;371(9625}1665-1674.
</li>
<li>
Kimball AB. Papp KA. Wasfi Y. et al. Long-term efficacy of ustekinumab in patients with moderate-to-severe psoriasis treated for up to 5 years in the PHOENIX1 study. J Eur Acad Dermatol Venereal 2013;27(12}1535-1545.
</li>
<li>
 Papp KA. Langley RG. Lebwohl M. et al. Efficacy and safety of ustekinumab. a human interleukin-12/23 monoclonal antibody. in patients with psoriasis: 52-week results from a randomised . double-blind . placebocontrolled trial (PHOENIX 2). Lancet 2008;371:1675-1684.
</li>


                    </ol>`
                }
                ]

            },
            psoriasic_area_and_severity_index_psa: {
                title: "BODY SURFACE AREA (BSA)",
                type: "assessment",
                visible: false,
                sections: [{
                    text: "BODY SURFACE AREA (BSA) ",
                    superScript: "1,2",
                    downloadUrl: "/download/PASI-form.pdf",
                    // viewUrl: "/quiz/dlqi",

                    options: [
                        {
                            type: "information",
                            html: true,
                            data: `<p>RATE THE BSA SEVERITY</p><table><tr class="p-2"><td>BSA &lt;3% = MILD;</td><td class="p-2">BSA 3 - 10% = MODERATE;</td><td>BSA &gt;10% = SEVERE</td></tr></table>`
                        },
                        {
                            render: "Below are the BSA scores where < 3 % = Mild; 3 - 10% = Moderate; > 10 % = Severe",
                            type: "number",
                            min: 0,

                            max: 100,
                            label: "Enter Past BSA Score",
                            labelPrint: "Previous Score",
                            description: "",
                            value: 0,

                        },
                        {
                            type: "number",
                            min: 0,
                            max: 100,

                            label: "Enter Current BSA Score",
                            labelPrint: "Current Score",
                            description: "",
                            value: 0,

                        },
                        {
                            type: "reference",
                            html: true,
                            data: `<p>Reference</p><ol><li>Mease PJ. Measures of psoriatic arthritis. Arthritis Care Res 2011;63(S11):S64-S85</li>
<li>Van Voorhees AS, Feldman SR, Lebwohl MG, et al. The psoriasis and psoriatic arthritis pocket guide. Treatment algorithms and management options. National Psoriasis Foundation. [cited 2020 Apr 08]; Available from: <a href="https://www.psoriasis.org/sites/default/files/npf_pocketguide_2018.pdf">https://www.psoriasis.org/sites/default/files/npf_pocketguide_2018.pdf</a></li>
                        
                        </ol>`
                        },
                    ]
                }]
            },
            psoriasic_area_and_severity_index: {
                title: "PSORIATIC AREA AND SEVERITY INDEX (PASI) AND BODY SURFACE AREA (BSA)",
                type: "assessment",
                visible: false,
                sections: [{
                    text: "PSORIATIC AREA AND SEVERITY INDEX (PASI) AND BODY SURFACE AREA (BSA) ",
                    superScript: "1,2,3,4",
                    downloadUrl: "/download/PASI-form.pdf",
                    // viewUrl: "/quiz/dlqi",

                    options: [{
                        type: "information",
                        html: true,
                        data: `<p>RATE THE PASI SEVERITY</p><table><tr><td class="p-2">PASI &lt;7 = MILD PASI;</td><td  class="p-2">7 - 12 = MODERATE PASI;</td><td class="p-2">PASI &gt;12 = SEVERE</td></tr></table>`
                    },
                    {
                        render: "Below are the PASI scores where < 7 = Mild; 7 - 12 = Moderate; > 12 = Severe",
                        type: "number",
                        min: 0,
                        max: 20,
                        label: "Enter Past PASI Score",
                        labelPrint: "Previous Score",
                        description: "",
                        value: 0,

                    },
                    {
                        type: "number",
                        min: 0,
                        max: 20,
                        label: "Enter Current PASI Score",
                        labelPrint: "Current Score",
                        description: "",
                        value: 0,

                    },
                    {
                        type: "information",
                        html: true,
                        data: `<p>RATE THE BSA SEVERITY</p><table><tr class="p-2"><td>BSA &lt;3% = MILD;</td><td class="p-2">BSA 3 - 10% = MODERATE;</td><td>BSA &gt;10% = SEVERE</td></tr></table>`
                    },
                    {
                        render: "Below are the BSA scores where < 3 % = Mild; 3 - 10% = Moderate; > 10 % = Severe",
                        type: "number",
                        min: 0,

                        max: 100,
                        label: "Enter Past BSA Score",
                        labelPrint: "Previous Score",
                        description: "",
                        value: 0,

                    },
                    {
                        type: "number",
                        min: 0,
                        max: 100,

                        label: "Enter Current BSA Score",
                        labelPrint: "Current Score",
                        description: "",
                        value: 0,

                    },
                    {
                        type: "reference",
                        html: true,
                        data: `<p>Reference</p><ol><li>Mease PJ. Measures of psoriatic arthritis. Arthritis Care Res 2011;63(S11):S64-S85</li>
                        <li>Schmitt J, Wozel G. The Psoriasis Area and Severity Index is the adequate criterion to define severity in chronic plaque-type psoriasis. Dermatology 2005;210:194-199. </li>
<li>Gladman DD, Poulin Y, Adams K, et al. Treating psoriasis and psoriatic arthritis: position paper on applying the treat-to-target concept to Canadian daily practice. J Rheumatol 2017;44(4):519-534.</li>
<li>Van Voorhees AS, Feldman SR, Lebwohl MG, et al. The psoriasis and psoriatic arthritis pocket guide. Treatment algorithms and management options. National Psoriasis Foundation. [cited 2020 Apr 08]; Available from: <a href="https://www.psoriasis.org/sites/default/files/npf_pocketguide_2018.pdf">https://www.psoriasis.org/sites/default/files/npf_pocketguide_2018.pdf</a></li>
                        
                        </ol>`
                    },
                    ]
                }]
            },
            biological_experienced_pso_patient_options: {
                text: "Why is STELARA<sup>®</sup> being prescribed as an alternative to another anti-TNF agent? ",
                html: true,
                customText: "Why is STELARA® being prescribed as an alternative to another anti-TNF agent? ",
                options:

                    [

                        {
                            type: "basic",
                            html: true,
                            value: false,
                            description: "<p>STELARA<sup>®</sup> Phase III data illustrates consistent efficacy and safety profiles in anti-TNF experienced patients<sup>1,2,3</sup></p>",
                            label: "",
                            labelPrint: "<p>STELARA® Phase III data illustrates consistent efficacy and safety profiles in anti-TNF experienced patients.<sup>1,2,3</sup></p>",

                        },
                        {
                            type: "basic",

                            value: false,
                            html: true,
                            description: "<p>Onset of response was seen within 2 weeks of the first dose of STELARA<sup>® 1,3</sup></p>",
                            label: "",
                            labelPrint: "<p>Onset of response was seen within 2 weeks of the first dose of STELARA®.<sup> 1,3</sup</p>",

                        },
                        {
                            type: "basic",
                            html: true,
                            value: false,
                            description: "<p>STELARA<sup>®</sup> offers patients a PASI90 response that’s maintained through to 5 years<sup>2</sup></p>",
                            label: "",
                            labelPrint: "<p>STELARA® offers patients a PASI90 response that’s maintained through to 5 years.<sup>2</sup></p>",

                        },
                        {
                            type: "basic",
                            value: false,
                            html: true,
                            description: "<p>Patients consistently stay longer on STELARA<sup>®</sup> vs. anti-TNF’s and secukinumab<sup>4,5</sup></p>",
                            label: "",
                            labelPrint: "<p>Patients consistently stay longer on STELARA® vs. anti-TNF’s and secukinumab.<sup>4,5</sup></p>",

                        },
                        {
                            type: "basic", html: true,
                            value: false,
                            description: "<p>STELARA<sup>®</sup> offers patients a meaningful increase in quality of life long-term<sup>1,2,3</sup></p>",
                            label: "",
                            labelPrint: "<p>STELARA® offers patients a meaningful increase in quality of life long-term.<sup>1,2,3</sup></p>",

                        },
                        {
                            type: "basic",
                            value: false, html: true,
                            description: "<p>PSOLAR confirmed that STELARA<sup>®</sup> was significantly more effective than the other 3 anti-TNFs studied over 6 to 12 months<sup>6</sup></p>",
                            label: "",
                            labelPrint: "<p>PSOLAR confirmed that STELARA® was significantly more effective than the other 3 anti-TNFs studied over 6 to 12 months.<sup>6</sup></p>",

                        },
                        {
                            type: "basic", html: true,
                            value: false,
                            description: "<p>Infrequent dosing of STELARA<sup>®</sup> could positively influence adherence in PsO patients<sup>1</sup></p>",
                            label: "",
                            labelPrint: "<p>Infrequent dosing of STELARA® could positively influence adherence in PsO patients.<sup>1</sup></p>",

                        },
                        {
                            type: "basic", html: true,
                            value: false,
                            description: "<p>STELARA<sup>®</sup> was associated with the highest drug survival and lowest risk of drug discontinuation vs. all other treatments<sup>5</sup></p>",
                            label: "",
                            labelPrint: "<p>STELARA® was associated with the highest drug survival and lowest risk of drug discontinuation vs. all other treatments.<sup>5</sup></p>",

                        },
                        {
                            type: "basic", html: true,
                            value: false,

                            description: "<p>Fewer PsO patients discontinued treatment due to a lack of efficacy with STELARA<sup>®</sup> vs. anti-TNF’s and secukinumab<sup>5</sup></p>",
                            label: "",
                            labelPrint: "<p>Fewer PsO patients discontinued treatment due to a lack of efficacy with STELARA® vs. anti-TNF’s and secukinumab.<sup>5</sup></p>",

                        },
                        {
                            type: "reference",
                            html: true,
                            data: `<p>References</p><ol>
                            <li>
Leonardi CL. Kimball AB. Papp KA. et al. Efficacy and safety of ustekinumab. a human interleukin-12/23 monoclonal antibody. in patients with psoriasis : 76-week results from a randomised. double-blind. placebocontrolled trial (PHOENIX1 ). Lancet 2008;371(9625}1665-1674.
</li>
<li>
Kimball AB. Papp KA. Wasfi Y. et al. Long-term efficacy of ustekinumab in patients with moderate-to-severe psoriasis treated for up to 5 years in the PHOENIX1 study. J Eur Acad Dermatol Venereal 2013;27(12}1535-1545.
</li>
<li>
Papp KA. Langley RG. Lebwohl M. et al. Efficacy and safety of ustekinumab. a human interleukin-12/23 monoclonal antibody. in patients with psoriasis: 52-week results from a randomised . double-blind . placebocontrolled trial (PHOENIX 2). Lancet 2008;371:1675-1684.
</li>
<li>
Menter A. Papp KA. Gooderham M. et al. Drug survival of biologic therapy in a large . disease-based registry of patients with psoriasis : results from the Psoriasis Longitudinal Assessment and Registry (PSOLAR)J. Eur Acad Dermatol Venereol 2016;30(7):1148-115
</li>
<li>
Egeberg A. Ottosen MB. Gniadecki R. et al. Safety. efficacy and drug survival of biologics and biosimilars for moderate-to-severe plaque psoriasis. Br J Dermatol 2018;178:328-329.
</li>
<li>
Strober BE. Bissonnette R. Florentino D. et al. Comparative effectiveness of biologic agents for the treatment of psoriasis in a real-world setting: results from a large . prospective. observational study (Psoriasis Longitudinal Assessment and Registry [PSOLAR])J. Am Acad Dermatol 2016;74:851-861.
</li>
                            </ol>`
                        }
                    ]
            },

        }
    },
    methods: {

        prepPsa(form) {

            let checkbox;
            let returnObject = {
                hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
                date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
                patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
                date_of_birth: form.date_of_birth ? ' ' + moment(form.date_of_birth).format("DD/MM/YYYY") : "",
                medical_aid_name: form.medical_aid_name ? form.medical_aid_name : "",
                plan_option: form.plan_option ? form.plan_option : "",
                medical_aid_membership_number: form.medical_aid_membership_number ? form.medical_aid_membership_number : "",
                idc_10_code: form.idc_10_code ? form.idc_10_code : "",

            }
            if (form.motivation) {
                returnObject["motivation_header"] = "MOTIVATION"
                returnObject["motivation"] = form.motivation ? form.motivation : ""
            }
            checkbox = this.getCheckbox(form.clinical_trial);
            //(checkbox)
            returnObject['clinical_trial'] = checkbox

            return returnObject
        },
        psaDermData() {
            let copy = { ...this.enthesitis_dactylitis_scoring };
            delete copy.sections[0].viewUrl;
            return [this.patient_global,
            this.dermatology_life_quality_index,
            this.basdai_bath_ankylosing_spondylitis,
                copy,
            this.psoriasic_area_and_severity_index_psa,
            // this.skin_assessment,
            this.other_involvement
            ];

        },
        psoDermData() {

            return [this.dermatology_life_quality_index,
            this.psoriasic_area_and_severity_index,
            this.other_involvement
            ];

        },
        choosenCollapsePSO(type) {
            switch (type) {

                case 'BIOLOGIC - NAÏVE PsO PATIENT':
                    this.biological_experienced_pso_patient.text = "BIOLOGIC - NAÏVE PsO PATIENT"
                    return {
                        title: "BIOLOGIC - NAÏVE PsO PATIENT",
                        visible: false,
                        type: "assessment",
                        sections: [this.biological_experienced_pso_patient, this.first_choice]
                    }

                case 'BIOLOGIC - EXPERIENCED PsO PATIENT':
                    this.biological_name_pso_patient.text = "BIOLOGIC - EXPERIENCED PsO PATIENT"
                    return {
                        title: "BIOLOGIC - EXPERIENCED PsO PATIENT",
                        visible: false,
                        type: "assessment",
                        sections: [this.biological_name_pso_patient, this.biological_experienced_pso_patient_options]
                    }

                case 'BIOLOGIC - RE-MOTIVATING FOR STELARA':
                    return {
                        title: "BIOLOGIC - RE-MOTIVATING FOR STELARA",
                        visible: false,
                        type: "assessment",
                        sections: [this.remotivate_stelara_pso]
                    }

            }
        },
        choosenCollapse(type) {

            switch (type) {

                case 'BIOLOGIC - NAÏVE PsA PATIENT':
                    this.biological_name_psa_patient.text = "BIOLOGIC - NAÏVE PsA PATIENT"
                    //(this.biological_name_psa_patient_experienced)
                    return {
                        title: "BIOLOGIC - NAÏVE PsA PATIENT",
                        visible: false,
                        type: "assessment",
                        sections: [this.biological_name_psa_patient, this.why_prescribed]
                    }

                case 'BIOLOGIC - EXPERIENCED PsA PATIENT':
                    this.biological_name_psa_patient.text = 'BIOLOGIC - EXPERIENCED PsA PATIENT'

                    return {
                        title: "BIOLOGIC - EXPERIENCED PsA PATIENT",
                        visible: false,
                        type: "assessment",
                        sections: [this.biological_name_psa_patient_experienced, this.why_prescribed_as_alternative]
                    }

                case 'BIOLOGIC - RE-MOTIVATING FOR STELARA':
                    return {
                        title: "BIOLOGIC - RE-MOTIVATING FOR STELARA",
                        visible: false,
                        type: "assessment",
                        sections: [this.remotivate_stelara_psa]
                    }


            }

        },
        mergeArray(type, formName) {
            if (this.triggerWatch) {
                this.triggerWatch = false;
            } else {
                let tempCollapse
                if (formName == 'PSA') {

                    tempCollapse = this.choosenCollapse(type);
                } else {
                    tempCollapse = this.choosenCollapsePSO(type)
                }

                if (this.form.clinical_trial.collapses[0]) {
                    let check = false;
                    if (this.form.clinical_trial.collapses[0].title == 'BIOLOGIC - NAÏVE PsA PATIENT') {
                        check = true;
                    }
                    if (this.form.clinical_trial.collapses[0].title == 'BIOLOGIC - EXPERIENCED PsA PATIENT') {
                        check = true;
                    }
                    if (this.form.clinical_trial.collapses[0].title == 'BIOLOGIC - RE-MOTIVATING FOR STELARA') {
                        check = true;
                    }
                    if (this.form.clinical_trial.collapses[0].title == 'BIOLOGIC - NAÏVE PsO PATIENT') {
                        check = true;
                    }
                    if (this.form.clinical_trial.collapses[0].title == 'BIOLOGIC - EXPERIENCED PsO PATIENT') {
                        check = true;
                    }
                    if (this.form.clinical_trial.collapses[0].title == 'BIOLOGIC - RE-MOTIVATING FOR STELARA') {
                        check = true;
                    }

                    if (check == true) {
                        this.form.clinical_trial.collapses.shift();
                    }

                }
                //(tempCollapse)
                this.form.clinical_trial.collapses = [
                    tempCollapse,
                    ...this.form.clinical_trial.collapses
                ];
                //(this.form.clinical_trial)
                let tempArray = this.options.type_of_patient.filter(val => {
                    return val.value == type
                })
                if (tempArray[0]) {
                    this.addReference('type_of_patient_reference', tempArray[0].reference, false)
                }
            }
        },
    }
}