<template>
  <b-modal v-model="show" dialog-class="extend-modal" scrollable no-close-on-esc hide-header no-close-on-backdrop>
    <Basdai @response="returnResult($event)"></Basdai>
    <template v-slot:modal-footer="{ ok }">
      <b-button size="md" variant="primary" @click="actionClose()">
        Close
      </b-button>

    </template>
  </b-modal>
</template>

<script>
import Basdai from "./../../pages/Quiz/BASDAI";
export default {
  props: {
    show: Boolean
  },
  components: {
    Basdai: Basdai
  },
  methods:{
      actionClose(){
          this.$emit("changeValue", false);
      },
      returnResult(evt){
          //(evt)
          this.$emit("placementData",evt)
      }
  }
};
</script>

<style>
</style>