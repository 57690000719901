import Vue from "vue";
import CryptoJS from "crypto-js";
import axios from "axios";
import moment from "moment";
export const GeneralUsage = {
  data() {
    return {
      validateErrors: {
        hcp_full_name: {
          status: null,
          message: "",
        },
        date: {
          status: null,
          message: "",
        },
        patient_detail_full_name: {
          status: null,
          message: "",
        },
        date_of_birth: {
          status: null,
          message: "",
        },
      },
      selectedTab: null,
      alertMessage: {
        show: false,
        variant: "success",
        message: "",
      },
      previewModal: {
        data: null,
        show: false,
        name: "Preview",
        modalName: "PREVIEW",
        footer: true,
      },
      exportModal: {
        show: false,
        type: "Word document",
        name: "Export to Word",
        modalName: "Export to Word",
        footer: true,
      },
    };
  },
  methods: {
    /**
     * [setLocal Sets local storage and sets the alert message its saved]
     *
     * @param   {[String]}  name  [name name to be saved into localstorage as]
     * @param   {[Object]}  form  [form the user input data]
     *
     */
    setLocal(name, form) {
      let encrypted = this.encrypt(form);
      localStorage.setItem(name, encrypted);
      this.alertMessage = {
        show: true,
        variant: "success",
        message: "Successfully Saved!",
      };
    },
    /**
     * [showModalExports Used to first validate data then choose which modal to show else set validate errors]
     *
     * @param   {[String]}  type  [type Which modal to show]
     *
     */
    showModalExports(type, useCustom = false, reason = false) {
      let check;
      if (useCustom) {
        check = this.customMicroValidate(this.form);
      } else {
        check = this.microValidate(this.form);
      }
      if (this.form.reason_for_selecting_new_medication) {
        if (this.form?.reason_for_selecting_new_medication.includes("Other") && this.form?.reason_for_selecting_new_medication_other == "") {
          check.status = false;
        } else {
          check.data?.re;
        }
      }
      this.alertMessage.show = false;
      if (check.status === true) {
        this.validationReset();
        this.validateErrors.reason_for_selecting_new_medication_other = {
          status: null,
          message: "",
        };
        if (type == "Word") {
          this.exportModal = {
            show: true,
            type: "Word",
            name: "Export to Word",
            modalName: "Export to Word",
            footer: true,
          };
        } else if (type == "pdf") {
          this.exportModal = {
            show: true,
            type: "PDF",
            name: "Export to PDF",
            modalName: "Export to PDF",
            footer: true,
          };
        } else {
          this.exportModal = {
            show: false,
            type: "",
            name: "",
            modalName: "",
            footer: true,
          };
        }
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        if (useCustom) {
          this.validateErrors.initial_diagnosis = check.data.initial_diagnosis;
          this.validateErrors.initial_diagnosis_enter = check.data.initial_diagnosis_enter;
          this.validateErrors.psa_value = check.data.psa_value;
          this.validateErrors.psa_value_enter = check.data.psa_value_enter;
        }
        if (reason) {
          if (this.form.reason_for_selecting_new_medication.includes("Other") && this.form.reason_for_selecting_new_medication_other == "") {
            this.validateErrors.reason_for_selecting_new_medication_other = {
              status: false,
              message: "Other field is required",
            };
          }
        }
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    /**
     * [getLocal get data from local storage and return decrypted data]
     *
     * @param   {[String]}  name  [name key for local storage]
     *
     * @return  {[Object]}        [return the form back to requester if none then null]
     */
    getLocal(name) {
      let data = localStorage.getItem(name);
      if (data) {
        return this.decrypt(data);
      }
      return null;
    },
    /**
     * [encrypt Used to encrypt the data using cryptojs]
     *
     * @param   {[Object]}  form  [form the form to be encrypted]
     *
     * @return  {[String]}        [return string of encrypted data]
     */
    encrypt(form) {
      let convert = JSON.stringify(form);
      return CryptoJS.AES.encrypt(convert, process.env.VUE_APP_SECRET_CODE);
    },
    /**
     * [decrypt decrypt the localstorage]
     *
     * @param   {[String]}  form  [form string of the encrypted data]
     *
     * @return  {[Object]}        [return The form in JSON]
     */
    decrypt(form) {
      let convert = CryptoJS.AES.decrypt(form, process.env.VUE_APP_SECRET_CODE);

      return JSON.parse(convert.toString(CryptoJS.enc.Utf8));
    },
    /**
     * [previewUrl preview api call]
     *
     * @param   {[Object]}  form  [form formatted data for the backend to read easier]
     *
     * @return  {[String]}        [return string of html]
     */
    async previewUrl(form) {
      try {
        let response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/preview`, {
          document_name: "Preview",
          data: form,
        });

        return response;
      } catch (Exception) {
        return {
          error: Exception.data.message,
        };
      }
    },
    /**
     * [exportWordUrl export Word to send to api]
     *
     * @param   {[Object]}  form  [form data formatted to be sent to api]
     *
     * @return  {[String]}        [return url for download]
     */
    async exportWordUrl(form, name) {
      try {
        let response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/generate-word`, {
          document_name: `motivation letter ${name}`,
          data: form,
        });
        return response;
      } catch (Exception) {
        return {
          error: Exception.data.message,
        };
      }
    },
    /**
     * [exportPDFUrl export pdf to send to api]]
     *
     * @param   {[Object]}  form  [form data formatted to be sent to api]
     *
     * @return  {[String]}        [return url for download]
     */
    async exportPDFUrl(form, name) {
      try {
        let response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/generate-pdf`, {
          document_name: `motivation letter ${name}`,
          data: form,
        });
        return response;
      } catch (Exception) {
        return {
          error: Exception.data.message,
        };
      }
    },
    /**
     * [processType Type of document and how it should react]
     *
     * @param   {[String]}  docType   [docType String of the type of doc]
     * @param   {[type]}  reaction  [reaction whether it should be saved or reset]
     *
     */
    processType(docType, reaction) {
      this.showModalExports("other");

      if (docType == "Word") {
        this.exportWord(reaction);
      } else {
        this.exportPDF(reaction);
      }
    },
    /**
     * [resetStorage Reset localstorage also reload page]
     *
     * @param   {[String]}  name  [name key for localstorage ]
     *
     */
    resetStorage(name) {
      localStorage.removeItem(name);
      location.reload();
    },
    /**
     * [getCheckbox Method used for helping the data be structured for the processes]
     *
     * @param   {[Object]}  data  [data Object with the collapse information in it to be processed]
     *
     * @return  {[Array]}        [return Array of formatted data]
     */
    getCheckbox(data) {
      // Variable to be returned a sort of Reverse chronology
      let tempStructure = [];
      let referenceExists = false;


      data.collapses.forEach((collapse, collapseIndex) => {
        // collapse type of data
        let type = collapse.type;
        // collapse data group selected if radio
        let selected;
        if (type == "radio") {
          selected = collapse.group;
        }

        // If type assessment follow this path of deep diving
        if (type == "assessment") {


          collapse.sections.forEach((section, sectionIndex) => {

            let arrayOptions = [];
            // Section header

            let header;
            if (section.text) {
              header = section.text;
            } else {
              header = collapse.title;
            }
            if (section.customText) {
              header = section.customText;
            }
            let optionArray = [];

            section?.options?.forEach((option, optionIndex) => {


              if (option.value) {

                if (option.render) {
                  optionArray.push(option.render);
                }
                if (option.references) {
                  referenceExists = true;
                }
                if (option.description) {
                  let tempString = `${option.labelPrint ? option.labelPrint + " " : ""} ${option.value == true ? "" : ": " + option.value}`;
                  if (tempString) {
                    optionArray.push(tempString);

                  } else {
                    optionArray.push(`${$option.description}`);

                  }
                } else {
                  if (option.labelPrint && option.custom) {
                    optionArray.push({
                      headText: option.labelPrint,
                      linkArray: `${option.labelPrint}: ${option.value}`,
                    });

                  } else {
                    arrayOptions = option.value;
                    if (arrayOptions.length > 0 && !option.input?.value) {
                      //(option)
                      //("he", arrayOptions)
                      if (option.type == "radio") {
                        optionArray.push(`${option.labelPrint}: ${option.value?.toString() ?? ""}`)


                      } else {
                        optionArray.push({
                          headText: option.labelPrint,
                          linkArray: arrayOptions,
                        });

                      }
                    }
                  }
                }
              }
              if (option.input?.value) {
                // if (option.input.value && option.value) {
                optionArray.push(`${option.labelPrint}: ${option.value?.toString() ?? ""} ${option.input?.value?.toString() ?? ""}`)
                // } else {
                // optionArray.push(`${option.labelPrint}: ${option.input.value.toString()}`);
                // }
              }
            });

            // Check if not empty then add to the tempStructure
            if (optionArray.length > 0) {
              tempStructure.push({
                header: header,
                assessment: optionArray,
              });
            }

            // Check if references exists
            if (referenceExists) {


              if (collapse.sections[sectionIndex + 1]?.text == "References") {
                let tempArray = [];
                let tempName = "header";
                let tempObject = {};
                tempObject[tempName] = "Reference";

                collapse.sections[sectionIndex + 1].options[0].references.forEach((ref, refIndex) => {
                  tempArray.push(ref.text);
                });

                tempObject.reference = tempArray;

                tempStructure.push(tempObject);
              }
            }
          });
          // Successful leave this iteration

          return;
        }

        collapse.sections.forEach((section, sectionIndex) => {
          let referenceExists = false;
          let refName;
          let header;
          let headerUsed = false;

          section.options.forEach((option, optionIndex) => {
            // Statically written because so far only radio that exists is the xeplion form
            if (type == "radio") {
              if (selected && option.value) {
                if (selected == option.value) {
                  let tempName = "header";
                  let tempObject = {};
                  tempObject[tempName] = "CLINICAL DATA";
                  if (option.references) {
                    referenceExists = true;
                  }
                  tempObject.text = option.value ? option.value + " " + option.description : "";

                  tempStructure.push(tempObject);
                }
              }
            } else {
              // Selects what type of data it will be
              if (option.type == "model") {
                if (option.value == true) {
                  let tempName = "header";
                  let tempObject = {};
                  tempObject[tempName] = option.label;
                  if (option.references) {
                    referenceExists = true;
                  }
                  tempObject.link = option.link ? option.link : "";
                  tempStructure.push(tempObject);
                }
              }

              if (option.type == "basic") {
                if (option.value == true) {
                  let tempText = (option.special ? " " : option.label) + " " + option.description;
                  let tempName = "header";
                  let tempObject = {};
                  tempObject[tempName] = "";
                  let tempString;
                  if (option.labelPrint) {
                    tempString = option.labelPrint + " " + option.value;
                  } else {
                  }
                  tempObject.text = tempString;

                  if (option.references) {
                    referenceExists = true;
                  }
                  tempObject.text = tempText ? tempText : "";
                  if (headerUsed == false) {
                    tempStructure.push({
                      header: section.text,
                      text: "",
                    });
                    headerUsed = true;
                  }
                  tempStructure.push(tempObject);
                }
              }
            }
          });
          // Check if references exists
          if (referenceExists) {
            if (collapse.sections[sectionIndex + 1].text == "References") {
              let tempArray = [];
              let tempName = "header";
              let tempObject = {};
              tempObject[tempName] = "Reference";

              collapse.sections[sectionIndex + 1].options[0].references.forEach((ref, refIndex) => {
                tempArray.push(ref.text);
              });

              tempObject.reference = tempArray;

              tempStructure.push(tempObject);
            }
          }
        });
      });

      return tempStructure;
    },
    /**
     * [patientsMotivation Used for a patients motivation]
     *
     * @param   {[Array]}  data  [data form]
     *
     * @return  {[Array]}        [return array of values]
     */
    patientsMotivation(data) {
      let tempArray = [];
      data.forEach((patient, patientIndex) => {
        if (patient.type == "patient_motivation") {
          patient.sections.forEach((section, sectionIndex) => {
            if (section.choice == "input_yourself") {
              section.options.forEach((option, optionIndex) => {
                tempArray.push({
                  header: "PATIENTS OWN MOTIVATION",
                  text: option.value,
                });
              });
            }
          });
        }
      });
      return tempArray;
    },
    /**
     * [closeModal Closing preview modal]
     *
     * @return  {[type]}  [return description]
     */
    closeModal() {
      this.previewModal = {
        data: null,
        show: false,
        name: "Preview",
        modalName: "Preview",
        footer: true,
      };
    },
    /**
     * [validationReset Reset validation]
     *
     */
    validationReset() {
      if (this.validateErrors.initial_diagnosis != null) {
        this.validateErrors = {
          hcp_full_name: {
            status: null,
            message: "",
          },
          date: {
            status: null,
            message: "",
          },
          patient_detail_full_name: {
            status: null,
            message: "",
          },
          date_of_birth: {
            status: null,
            message: "",
          },
          initial_diagnosis: {
            status: null,
            message: "",
          },
          initial_diagnosis_enter: {
            status: null,
            message: "",
          },
          psa_value: {
            status: null,
            message: "",
          },
          psa_value_enter: {
            status: null,
            message: "",
          },
          reason_for_selecting_new_medication_other: {
            status: null,
            message: "",
          },
        };
      } else {
        this.validateErrors = {
          hcp_full_name: {
            status: null,
            message: "",
          },
          date: {
            status: null,
            message: "",
          },
          patient_detail_full_name: {
            status: null,
            message: "",
          },
          date_of_birth: {
            status: null,
            message: "",
          },
          reason_for_selecting_new_medication_other: {
            status: null,
            message: "",
          },
        };
      }
    },
    /**
     * [changeActive This method is used to update preview to which section finished]
     *
     * @param   {[Boolean]}  value  [value what has been touched]
     *
     */
    changeActive(value) {
      let newArray = this.options.preview.map((val) => {
        let tempObject = {
          value: val.value,
          text: val.text,
          relation: val.relation,
        };
        if (val.relation == value) {
          tempObject.value = true;
        }
        return tempObject;
      });

      this.options.preview = newArray;
    },
    /**
     * [flatData Final data being formatted for the final send to backend to processes]
     *
     * @param   {[Array]}  data      [data data prepped to be completed]
     * @param   {[Object]}  fileJson  [fileJson JSON file to be used as config]
     *
     * @return  {[type]}            [return final process data to be sent to end points]
     */
    flatData(data, fileJson) {
      let tempArray = [];
      let variableNames = Object.keys(fileJson);
      variableNames.forEach((jsonVal) => {
        let tempObject;

        if (fileJson[jsonVal].optional) {
          if (fileJson[jsonVal].iterate) {
            if (data[jsonVal]) {
              data[jsonVal].forEach((item, index) => {
                if (item.header) {
                  let headerObject = {
                    element: "p",
                    classes: "section-space",
                    text: item.header,
                  };
                  tempArray.push(headerObject);
                }

                if (item.link) {
                  let mainObject = {
                    classes: fileJson[jsonVal].classes,
                    element: fileJson[jsonVal].element,
                    text: item.link,
                  };
                  tempArray.push(mainObject);
                }
                if (item.text) {
                  let mainObject = {
                    classes: fileJson[jsonVal].classes,
                    element: fileJson[jsonVal].element,
                    text: item.text,
                  };
                  tempArray.push(mainObject);
                }
                if (item.reference) {
                  let refer = [...new Set(item.reference)];

                  refer.forEach((ref) => {
                    let mainObject = {
                      classes: fileJson[jsonVal].classes,
                      element: fileJson[jsonVal].element,
                      text: ref,
                    };
                    tempArray.push(mainObject);
                  });
                }
                if (item.assessment) {
                  item.assessment.forEach((value, ind) => {
                    let mainObject = {};
                    if (typeof value === "object") {
                      if (value.headText) {
                        tempArray.push({
                          classes: fileJson[jsonVal].classes,
                          element: fileJson[jsonVal].element,
                          text: value.headText,
                        });
                      }
                      if (Array.isArray(value)) {
                        mainObject = {
                          classes: "text-space",
                          element: "ul",
                          child_element: "li",
                          data: value.linkArray,
                        };
                      } else {
                        if (Array.isArray(value.linkArray)) {
                          mainObject = {
                            classes: "text-space",
                            element: "ul",
                            child_element: "li",
                            data: value.linkArray,
                          };
                        } else {
                          mainObject = {
                            classes: "text-space",
                            element: "p",
                            text: value?.linkArray,
                          };
                        }
                      }
                    } else {
                      let html = value.includes("<sup>") || value.includes("<p>");

                      mainObject = {
                        classes: fileJson[jsonVal].classes,
                        element: html ? "html" : fileJson[jsonVal].element,
                        text: value,
                      };
                    }

                    tempArray.push(mainObject);
                  });
                }
              });
            }
          } else {
            if (data[jsonVal]) {
              let text = data[jsonVal];
              if (fileJson[jsonVal].append) {
                text = fileJson[jsonVal].append + "" + data[jsonVal];
              }

              if (fileJson[jsonVal].list) {
                tempObject = {
                  classes: fileJson[jsonVal].classes,
                  element: fileJson[jsonVal].element,
                  child_element: "li",
                  data: data[jsonVal],
                };
              } else if (fileJson[jsonVal].element == "li") {
                tempObject = {
                  classes: fileJson[jsonVal].classes,
                  element: "ul",
                  child_element: "li",
                  data: [data[jsonVal]],
                };
              } else {
                tempObject = {
                  classes: fileJson[jsonVal].classes,
                  element: fileJson[jsonVal].element,
                  text: text,
                };
              }
            }
          }
        } else {
          tempObject = {
            classes: fileJson[jsonVal].classes,
            element: fileJson[jsonVal].element,
            text: fileJson[jsonVal].text,
          };
        }
        if (tempObject != undefined) tempArray.push(tempObject);
      });

      return tempArray;
    },

    /**
     * [downloadFile Download the file url so far used for checklist]
     *
     * @param   {[String]}  name  [name name of checklist]
     *
     */
    downloadFile(name) {
      window.open(`${process.env.VUE_APP_BACKEND_URL} /download/checklist - ${name}.pdf`);
    },
    /**
     * [microValidate small validation for required fields ]
     *
     * @param   {[Object]}  form  [form the form passed to it]
     *
     * @return  {[Object]}        [return status of boolean and validation response]
     */
    microValidate(form) {
      let array = {};
      if (form.hcp_full_name == null || form.hcp_full_name == "") {
        array = {
          hcp_full_name: {
            status: false,
            message: "HCP Name Required!",
          },
          ...array,
        };
      }
      if (form.date == null || form.date == "") {
        array = {
          date: {
            status: false,
            message: "Date Required!",
          },
          ...array,
        };
      }
      if (form.patient_detail_full_name == null || form.patient_detail_full_name == "") {
        array = {
          patient_detail_full_name: {
            status: false,
            message: "Patient Detail Full Name Required!",
          },
          ...array,
        };
      }
      if (form.date_of_birth == null || form.date_of_birth == "") {
        array = {
          date_of_birth: {
            status: false,
            message: "Date of Birth Required!",
          },
          ...array,
        };
      }

      if (Object.keys(array).length > 0) {
        return {
          status: false,
          data: array,
        };
      }
      return {
        status: true,
      };
    },
    /**
     * [date Formats data the way i want to  be displayed]
     *
     * @return  {[Date]}  [return date]
     */
    date() {
      return moment().format("DD/MM/YYYY");
    },
    referenceAdd(evnt, name) {
      let lengthForRef = 0;
      if (this.form[name].collapses[evnt.collapseIndex].sections.length > 0) {
        lengthForRef = this.form[name].collapses[evnt.collapseIndex].sections.length - 1;
      }
      if (evnt) {
        evnt.data.forEach((val, index) => {
          this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options[0].references.push(val);
        });
      }
    },

    referenceRemove(evnt, name) {
      let lengthForRef = 0;
      if (this.form[name].collapses[evnt.collapseIndex].sections.length > 0) {
        lengthForRef = this.form[name].collapses[evnt.collapseIndex].sections.length - 1;
      }

      let tempArray = this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options.filter((val, index) => {
        return val.type == "reference";
      });
      let tempRemoved = [];

      tempArray[0].references.forEach((val, index) => {
        evnt.data.forEach((data, dataIndex) => {
          if (Object.is(data, val)) {
          } else {
            tempRemoved.push(val);
          }
        });
      });
      this.form[name].collapses[evnt.collapseIndex].sections[lengthForRef].options[0].references = tempRemoved;
    },
    addReference(formName, input, spreadEnable) {
      if (spreadEnable) {
        this.form[formName].push(...input);
      } else {
        this.form[formName] = input;
      }
    },
  },
};
