<template>
  <Layout>
    <template v-slot:required-header>
      <b-row>
        <b-col md="2" sm="12">
          <h6>HCP Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input name="hcp_full_name" :required="validateErrors.hcp_full_name" type="text" :binding="form.hcp_full_name"
            placeholder="Enter Dr Name" label="HCP Name" @changeValue="
              form.hcp_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :binding="form.date" name="date" label="Date" :required="validateErrors.date"
            placeholder="Select Date" @changeValue="
              form.date = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
      <b-row class="mb-0">
        <b-col md="2" sm="12">
          <h6>Patient's Details</h6>
        </b-col>
        <b-col md="6" sm="12">
          <Input :required="validateErrors.patient_detail_full_name" type="text" name="patient_detail_full_name"
            :binding="form.patient_detail_full_name" placeholder="Enter Patient's full name" label="Full Name"
            @changeValue="
              form.patient_detail_full_name = $event;
            options.preview[0].value = true;
            " />
        </b-col>
        <b-col md="4" sm="12">
          <Input type="date" :binding="form.date_of_birth" name="date_of_birth" placeholder="Select Date of birth"
            label="Date of Birth" :required="validateErrors.date_of_birth" @changeValue="
              form.date_of_birth = $event;
            options.preview[0].value = true;
            " />
        </b-col>
      </b-row>
    </template>
    <template v-slot:additional-fields>
      <b-row class="mb-0">
        <b-col md="6" offset="2" sm="12">
          <Input type="text" name="medical_aid_name" :binding="form.medical_aid_name"
            placeholder="Enter Medical Aid Name " label="Medical Aid Name"
            @changeValue="form.medical_aid_name = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <Input name="plan_option" type="text" :binding="form.plan_option" placeholder="Enter option here"
            label="Plan Option" @changeValue="form.plan_option = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <Input name="medical_aid_membership_number" type="text" :binding="form.medical_aid_membership_number"
            placeholder="Enter Membership Number" label="Membership Number"
            @changeValue="form.medical_aid_membership_number = $event" />
        </b-col>
        <b-col md="4" sm="12">
          <SelectInput :binding="form.idc_10_code" @changeValue="
            form.idc_10_code = $event;
          changeTypeInput();
          " :options="options.idc_10_code" label="ICD 10 Codes" placeholder name="icd_10_codes"></SelectInput>

        </b-col>

      </b-row>

      <b-row v-if="form.idc_10_code == 'Other'">
        <b-col md="6" offset="2" sm="12"></b-col>
        <b-col md="4" sm="12">
          <Input name="idc_10_code_other" type="text" :binding="form.idc_10_code_other" placeholder="Other"
            label="Please type other code" @changeValue="form.idc_10_code_other = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" offset="2" sm="12">
          <p>PMB Code 910S</p>

        </b-col>
      </b-row>
      <b-row>
        <!-- <b-col md="6" offset="2" sm="12">
          <SelectInput :binding="form.type_of_patient" @changeValue="
            form.type_of_patient = $event;
          form.diagnosis_type = null;
          form.type_of_patient_type = null;
          changeICD();
          " :options="options.type_of_patient_options" label="Diagnosis" placeholder name="type_of_patient_codes">
          </SelectInput>
        </b-col> -->
        <b-col md="6" offset="2" sm="12">
          <SelectInput v-if="form.type_of_patient == 'CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)'"
            :binding="form.type_of_patient_type" @changeValue="
              form.type_of_patient_type = $event;
            form.diagnosis_type = null;
            " :options="options.type_of_patient_type_options" label="Patient Type" placeholder
            name="type_of_patient_type"></SelectInput>

        </b-col>
        <b-col md="4" sm="12">
          <SelectInput v-if="form.type_of_patient_type == 'frontline' || form.type_of_patient_type == 'high_risk'"
            :binding="form.diagnosis_type" @changeValue="form.diagnosis_type = $event"
            :options="form.type_of_patient_type == 'frontline' ? options.type_of_diagnosis_options_frontline : options.type_of_diagnosis_options_high_risk"
            label="Clinical Trial Data" placeholder name="diagnosis_setting"></SelectInput>

        </b-col>
      </b-row>

    </template>
    <template v-slot:motivation-field>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Motivation</h6>
        </b-col>
        <b-col md="10" sm="12">
          <p>
            <span class="red-text">
              Remember to include your patient’s clinical and treatment history as well as co-morbidity index when
              motivating. Please view below a checklist of all the relevant additional documentation you may need to
              attach with your motivational letter.
              Medical scheme information to support the motivation is also available.
            </span>
          </p>
          <Input type="textarea" :binding="form.motivation" placeholder="Motivate here" name="motivation"
            @changeValue="form.motivation = $event" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="12">
          <h6>Medical Scheme Information</h6>
        </b-col>
        <b-col md="10" sm="12">
          <p>
            <span class="red-text"> Text from medical scheme information, found under additional information can be pasted
              below should you require.</span>
          </p>
          <HtmlEditor :inputData="form.medical_scheme_info" @changeValue="form.medical_scheme_info = $event"></HtmlEditor>
        </b-col>
      </b-row>
    </template>
    <template v-slot:tabs>
      <TabButton :tabs="options.tabs" :selectedTab="selectedTab" @changeTab="
        selectedTab = $event;
      changeActive($event);
      "></TabButton>
    </template>
    <template v-slot:tab-selects>
      <b-row v-if="selectedTab == 'trials'">
        <b-col v-if="form.type_of_patient" md="12" sm="12">
          <Accordion v-if="form.type_of_patient == 'CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)' && form.type_of_patient_type"
            :info="form[form.diagnosis_type ? form.diagnosis_type : form.type_of_patient_type]" type="checkbox">
          </Accordion>
          <Accordion v-if="form.type_of_patient == 'WALDENSTRÖM’S MACROGLOBULINAEMIA (WM)'" :info="form.waldenstrom"
            type="checkbox"></Accordion>
          <Accordion v-if="form.type_of_patient == 'MANTLE CELL LYMPHOMA (MCL)'" :info="form.mantle_cell" type="checkbox">
          </Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'additional'">
        <b-col md="12" sm="12">
          <Accordion :info="additionalDetails" type="information"></Accordion>
        </b-col>
      </b-row>
      <b-row v-if="selectedTab == 'checklist'">
        <b-col md="12" sm="12">
          <Checklist>
            <template v-slot:pre-title>
              <b-alert variant="danger" show>
                <b-container fluid>
                  <b-row>
                    <b-col md="1" sm="1" class="pr-0 icon-col">
                      <img src="https://motivateforms.co.za/public
/img/frontend_assets/danger-info.png" alt />
                    </b-col>
                    <b-col md="11" sm="11">Please remember to add / attach the below checklist documentation when
                      submitting your motivational letter.</b-col>
                  </b-row>
                </b-container>
              </b-alert>
            </template>

            <template v-slot:title>
              <h4 class="section-title section-title-spacing">CHECKLIST OF DOCUMENTS TO INCLUDE IN YOUR MOTIVATION FOR
                IMBRUVICA</h4>
            </template>
            <template v-slot:content>
              <ul>
                <li>Relevant histological or laboratory results</li>
                <li>Medical scheme information to support the motivation.</li>
              </ul>
            </template>
          </Checklist>
        </b-col>
      </b-row>
    </template>
    <template v-slot:modal-preview>
      <ModalComponent :name="previewModal.data" @closing="closeModal()" :response="true"
        :modalName="previewModal.modalName" :showModal="previewModal.show"></ModalComponent>
    </template>
    <template v-slot:modal-type>
      <ModalComponent size="md" :defaultModal="true" @closing="exportModal.show = false" :response="true"
        :DocumentType="exportModal.type" :modalName="exportModal.modalName" :showModal="exportModal.show" :footer="false"
        @saveExport="processType($event, 'save')" @exportReset="processType($event, 'reset')"></ModalComponent>
    </template>
    <template v-slot:formControl>
      <FormControl :alert="alertMessage" @alertClose="alertMessage.show = false" @preview="preview()" @save="save()"
        @reset="reset()" @exportPDF="showModalExports('pdf')" @exportWord="showModalExports('Word')"></FormControl>
    </template>
  </Layout>
</template>

<script>
import Input from "@/components/Fields/Input";
import SelectInput from "@/components/Fields/SelectInput";
import TabButton from "@/components/TabsButton";
import Accordion from "@/components/Accordion";
import imbruvicaJSON from "@/assets/json/newImbruvica.json";
import { GeneralUsage } from "@/mixins/GeneralUsage.js";
import Checklist from "@/components/Checklist.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import { ModalInformation } from "@/mixins/modals.js";
import Layout from "./Layouts";
import moment from "moment";
import HtmlEditor from "../components/HtmlEditor.vue";
import FormControl from "../components/FormControl.vue";
export default {
  components: {
    Layout,
    Input,
    SelectInput,
    FormControl,
    TabButton,
    Accordion,
    Checklist,
    ModalComponent,
    HtmlEditor,
  },
  mixins: [GeneralUsage, ModalInformation],
  data() {
    return {
      additionalDetails: {
        collapses: [
          {
            title: "MEDICAL SCHEME INFORMATION",
            visible: false,
            header: "Medical scheme information",
            type: "documents",
            sections: [
              {
                text: "MEDICAL SCHEME INFORMATION",
                motivate: [
                  {
                    icon: true,
                    variant: "danger",
                    html: false,
                    details: "Please note the document is not auto populated kindly copy and paste any relevant sections into the free text region above",
                  },
                ],

                options: [
                  {
                    type: "parent",
                    value: "The legal framework for patient access",
                  },
                  {
                    type: "model",
                    value: false,
                    link: "imbruvica.legal_framework_pmb",
                    label: "- With PMB regulations",
                    modal: "regulations_2020111",
                    modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO IMBRUVICA",
                    footerAppend: "<p>CP-203735</p>",
                  },
                  {
                    type: "model",
                    value: false,
                    link: "imbruvica.legal_framework_co_payments",
                    label: "- Avoidance of co-payments",
                    modal: "legal_framework_co_payments",
                    modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO IMBRUVICA",
                    footerAppend: "<p>CP-203735</p>",
                  },
                  {
                    type: "parent",
                    value: "Rebuttals",
                  },
                  {
                    type: "model",
                    value: false,
                    link: "imbruvica.rebuttals_state",
                    label: "- Rebuttals vs state level of care ",
                    modal: "rebuttals_2020112",
                    modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO IMBRUVICA",
                    footerAppend: "<p>CP-203735</p>",
                  },
                  {
                    type: "model",
                    value: false,
                    link: "imbruvica.rebuttals_innapropriate_care",
                    label: "- Rebuttals vs suggestion of inappropriate care",
                    modal: "rebuttals_innapropriate_care",
                    modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO IMBRUVICA",
                    footerAppend: "<p>CP-318514</p>",
                  },
                  {
                    type: "model",
                    value: false,
                    link: "imbruvica.rebuttals_medicine_costs",
                    label: "- Rebuttals vs medicine cost exceeds available benefit",
                    modal: "rebuttals_medicine_costs",
                    modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO IMBRUVICA",
                    footerAppend: "<p>CP-318514</p>",
                  },
                  {
                    type: "model",
                    value: false,
                    link: "imbruvica.rebuttals_benefit",
                    label: "- Rebuttals vs benefit option",
                    modal: "rebuttals_benefit",
                    modalHeading: "THE LEGAL FRAMEWORK AROUND PATIENT ACCESS TO IMBRUVICA",
                    footerAppend: "<p>CP-203735</p>",
                  },
                ],
              },
            ],
          },
          {
            title: "INTERNATIONAL GUIDELINES",
            visible: false,
            header: "USEFUL LINKS FOR ADDITIONAL INFORMATION ",
            type: "information",
            sections: [
              {
                title: "ELN - European Leukemia Network",
                link: "https://www.leukemia-net.org/leukemias/",
              }, {
                title: "ESMO - European Society for Medical Oncology",
                link: "https://www.esmo.org/guidelines/haematological-malignancies",
              },
              {
                title: "NCCN - National Comprehensive Cancer Network",
                link: "https://www.nccn.org/professionals/physician_gls/default.aspx",
              },
              {
                title: "IWCLL - International Workshop for Chronic Lymphocytic Leukemia",
                link: "https://ashpublications.org/blood/article-lookup/doi/10.1182/blood-2017-09-806398",
              },
              {
                title: "South African Council for Medical Schemes: PMB Definition Guidelines",
                link: "https://www.medicalschemes.co.za/publications/#2009-3568-wpfd-haematology",
              },
            ],
          },
          {
            title: "MEDICAL INFORMATION",
            visible: false,
            header: "MEDICAL INFORMATION",
            type: "html",
            data: `
          <p><strong>
          Should you require any further clinical trials or medical information please use the below contact information
          </strong>
          </p>
          <p>
          Email: <a class="link-color" href="mailto:ra-medinfoemmarkets@its.jnj.com">ra-medinfoemmarkets@its.jnj.com</a>
          <br>Telephone: 0860 11 11 17
          </p>
          `,
          },
        ],
      },
      options: {
        preview: [
          { value: false, text: "Patient Details", relation: "relation" },
          { value: false, text: "Clinical Trials", relation: "trials" },
          {
            value: false,
            text: "Additional Information",
            relation: "additional",
          },
          {
            value: false,
            text: "Checklist",
            relation: "checklist",
          },
        ],
        type_of_diagnosis_options_frontline: [
          { value: null, text: "Please select one" },
          { value: "frontline_data", text: "Frontline Data" },
          {
            value: "frontline_data_patient_elderly",
            text: "Frontline Data: Elderly, less fit patient (1L mlGHV)",
          },
          { value: "frontline_data_patient_del17p", text: "High Risk: Patient with Del17p/TP53 Mutations" },
          { value: "high_risk_data_patient_ulGHV", text: "High Risk: Patient with ulGHV" },
        ],
        type_of_diagnosis_options_high_risk: [
          { value: null, text: "Please select one" },
          {
            value: "frontline_data_patient_del17p",
            text: "Frontline Data: Patient with Del17p/TP53 Mutations",
          },
          {
            value: "frontline_data_patient_ulGHV",
            text: "Frontline Data: Patient with ulGHV",
          },
          {
            value: "frontline_data_ml_ghv",
            text: "Frontline Data: mL GHV	",
          },
          { value: "previously_treated", text: "Previously Treated" },
        ],
        type_of_patient_options: [
          {
            value: null,
            text: "Select a diagnosis",
          },
          {
            value: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
            text: "CHRONIC LYMPHOCYTIC LEUKAEMIA (CLL)",
          },
          {
            value: "MANTLE CELL LYMPHOMA (MCL)",
            text: "MANTLE CELL LYMPHOMA (MCL)",
          },
          {
            value: "WALDENSTRÖM’S MACROGLOBULINAEMIA (WM)",
            text: "WALDENSTRÖM’S MACROGLOBULINAEMIA (WM)",
          },
        ],
        type_of_patient_type_options: [
          { value: null, text: "Please select one" },
          { value: "frontline", text: "Front line" },
          { value: "previously_treated", text: "Previously Treated" },
          { value: "immune_restoration", text: "Immune Restoration" },
          { value: "high_risk", text: "High Risk" },
          { value: "long_term_survival_data", text: "Long Term Survival Data" },
        ],
        idc_10_code: [
          { value: null, text: "Please select ICD Code" },
          {
            value: "C91.1 Chronic lymphocytic leukaemia of B-cell type",
            text: "C91.1 Chronic Lymphocytic Leukaemia",
          },
          {
            value: "C83.1 Mantle cell lymphoma",
            text: "C83.1 Mantle Cell Lymphoma",
          },
          {
            value: "C88.0 Waldenström macroglobulinaemia",
            text: "C88.0 Waldenström’s Macroglobulinaemia",
          },
          { value: "Other", text: "Other" },
        ],
        tabs: [
          { value: "trials", text: "Clinical Trials" },
          {
            value: "additional",
            text: "Additional Information",
          },
          {
            value: "checklist",
            text: "Checklist",
          },
        ],
      },
      form: {
        hcp_full_name: "",
        date: null,
        patient_detail_full_name: "",
        date_of_birth: null,
        medical_aid_name: "",
        plan_option: "",
        medical_aid_membership_number: "",
        idc_10_code: null,
        idc_10_code_other: "",
        type_of_patient: null,
        type_of_patient_type: null,
        motivation: "",
        medical_scheme_info: "<p></p>",
        diagnosis_type: null,
        frontline_data: {
          collapses: [
            {
              type: "checkbox",
              title: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "FRONTLINE PATIENTS",
                  options: [
                    {
                      name: "long_term_efficacy_7_year",
                      type: "model",
                      value: false,
                      label: "Long-term efficacy and safety of first-line ibrutinib treatment for patients with CLL/SLL (7 year follow-up from RESONATE-2)",
                      link: "imbruvica.long_term_efficacy_7_year",
                      modal: "long_term_efficacy_7_year",
                      modalHeading: "Long-term efficacy and safety of first-line ibrutinib treatment for patients with CLL/SLL (7 year follow-up from RESONATE-2)",
                      footerAppend: `<p>CP-286928</p>`,
                    },
                    {
                      name: "alliance",
                      type: "model",
                      value: false,
                      label: "Ibrutinib regimens versus chemoimmunotherapy in older patients with untreated CLL (ALLIANCE)",
                      link: "imbruvica.alliance",
                      modal: "alliance",
                      modalHeading: "Ibrutinib regimens versus chemoimmunotherapy in older patients with untreated CLL (ALLIANCE)",
                      footerAppend: `<p>CP-110649</p>`,
                    },
                    {
                      name: "illuminate",
                      type: "model",
                      value: false,
                      label: "Ibrutinib + obinutuzumab versus chlorambucil + obinutuzumab (iLLUMINATE)",
                      link: "imbruvica.illuminate",
                      modal: "illuminate",
                      modalHeading: "Ibrutinib + obinutuzumab versus chlorambucil + obinutuzumab (iLLUMINATE)",
                      footerAppend: `<p>CP-286925</p>`,
                    },
                    {
                      name: "combination_therapy",
                      type: "model",
                      value: false,
                      label: "Combination therapy: Ibrutinib + Rituximab vs FCR (E1912)",
                      link: "imbruvica.combination_therapy",
                      modal: "combination_therapy",
                      modalHeading: "Combination therapy: Ibrutinib + Rituximab vs FCR (E1912)",
                      footerAppend: `<p>CP-286923</p>`,
                    },
                    {
                      name: "pcyc1102",
                      type: "model",
                      value: false,
                      label: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      link: "imbruvica.pcyc-1102",
                      modal: "pcyc1102",
                      modalHeading: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      footerAppend: `<p>CP-188363</p>`,
                    },
                    {
                      name: "cross_trial_comparison",
                      type: "model",
                      value: false,
                      label: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (Robak et al)",
                      link: "imbruvica.cross_trial_comparison",
                      modal: "cross_trial_comparison",
                      modalHeading: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (Robak et al)",
                      footerAppend: `<p>CP-180340</p>`,
                    },
                    {
                      name: "cross_trial_comparison",
                      type: "model",
                      value: false,
                      label: "Real World Outcomes for Ibrutinib Monotherapy vs Chemotherapy in FL CLL (HUANG ET AL",
                      link: "imbruvica.huang_et_al",
                      modal: "huang_et_al",
                      modalHeading: "Real World Outcomes for Ibrutinib Monotherapy vs Chemotherapy in FL CLL (HUANG ET AL)",

                    },
                  ],
                },
              ],
            },
          ],
        },
        immune_restoration: {
          collapses: [
            {
              type: "checkbox",
              title: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "IMMUNE RESTORATION",
                  options: [
                    {
                      name: "immune_restoration_moreno",
                      type: "model",
                      value: false,
                      label: "Immune Function Restoration as a complementary strategy for effective treatment of CLL (Moreno)",
                      link: "imbruvica.immune_restoration_moreno",
                      modal: "immune_restoration_moreno",
                      modalHeading: "Immune Function Restoration as a complementary strategy for effective treatment of CLL (Moreno)",

                    },
                  ],
                },
              ],
            },
          ],
        },
        frontline_data_patient_elderly: {
          collapses: [
            {
              type: "checkbox",
              title: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "FRONTLINE ELDERLY, LESS FIT PATIENT (1L mlGHV)",
                  options: [
                    {
                      name: "alliance",
                      type: "model",
                      value: false,
                      label: "Ibrutinib regimens versus chemoimmunotherapy in older patients with untreated CLL (ALLIANCE)",
                      link: "imbruvica.alliance",
                      modal: "alliance",
                      modalHeading: "Ibrutinib regimens versus chemoimmunotherapy in older patients with untreated CLL (ALLIANCE)",
                      footerAppend: `<p>CP-110649</p>`,
                    },
                    {
                      name: "cross_trial_comparison",
                      type: "model",
                      value: false,
                      label: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (Robak et al)",
                      link: "imbruvica.cross_trial_comparison",
                      modal: "cross_trial_comparison",
                      modalHeading: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (Robak et al)",
                      footerAppend: `<p>CP-180340</p>`,
                    },
                    {
                      name: "single_agent",
                      type: "model",
                      value: false,
                      label: "Depth and durability of response to Ibrutinib in CLL: 5-year follow-up of a phase 2 study (Ahn et al)",
                      link: "imbruvica.single_agent",
                      modal: "single_agent",
                      modalHeading: "Depth and durability of response to Ibrutinib in CLL: 5-year follow-up of a phase 2 study (Ahn et al)",
                      footerAppend: `<p>CP-180342</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        frontline_data_patient_del17p: {
          collapses: [
            {
              type: "checkbox",
              title: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "FRONTLINE PATIENTS WITH DEL17p/TP53 MUTATIONS",
                  options: [
                    {
                      //TODO NEED TO SET TRIAL
                      name: "aleration",
                      type: "model",
                      value: false,
                      label: "Ibrutinib for CLL in TP53 alterations (Ahn et al)",
                      link: "imbruvica.alterations",
                      modal: "aleration",
                      modalHeading: "Ibrutinib for CLL in TP53 alterations (Ahn et al)",
                      footerAppend: `<p>CP-180332</p>`,
                    },
                    {
                      name: "single_agent",
                      type: "model",
                      value: false,
                      label: "Depth and durability of response to Ibrutinib in CLL: 5-year follow-up of a phase 2 study (Ahn et al)",
                      link: "imbruvica.single_agent",
                      modal: "single_agent",
                      modalHeading: "Depth and durability of response to Ibrutinib in CLL: 5-year follow-up of a phase 2 study (Ahn et al)",
                      footerAppend: `<p>CP-180342</p>`,
                    },
                    {
                      name: "general_manage",
                      type: "model",
                      value: false,
                      label: "Approaches to chronic lymphocytic leukemia therapy in the era of new agents (Jain et al)",
                      link: "imbruvica.general-manage",
                      modal: "general_manage",
                      modalHeading: "Approaches to chronic lymphocytic leukemia therapy in the era of new agents (Jain et al)",
                      footerAppend: `<p>CP-180335</p>`,
                    },
                    {
                      name: "illuminate",
                      type: "model",
                      value: false,
                      label: "Ibrutinib + obinutuzumab versus chlorambucil + obinutuzumab (iLLUMINATE)",
                      link: "imbruvica.illuminate",
                      modal: "illuminate",
                      modalHeading: "Ibrutinib + obinutuzumab versus chlorambucil + obinutuzumab (iLLUMINATE)",
                      footerAppend: `<p>CP-286925</p>`,
                    },
                    {
                      name: "cross_trial_comparison",
                      type: "model",
                      value: false,
                      label: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (Robak et al)",
                      link: "imbruvica.cross_trial_comparison",
                      modal: "cross_trial_comparison",
                      modalHeading: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (Robak et al)",
                      footerAppend: `<p>CP-180340</p>`,
                    },
                    {
                      name: "pcyc1102",
                      type: "model",
                      value: false,
                      label: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      link: "imbruvica.pcyc-1102",
                      modal: "pcyc1102",
                      modalHeading: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      footerAppend: `<p>CP-188363</p>`,
                    },
                    {
                      name: "long_term_efficacy_7_year",
                      type: "model",
                      value: false,
                      label: "Long-term efficacy and safety of first-line ibrutinib treatment for patients with CLL/SLL (7 year follow-up from RESONATE-2)",
                      link: "imbruvica.long_term_efficacy_7_year",
                      modal: "long_term_efficacy_7_year",
                      modalHeading: "Long-term efficacy and safety of first-line ibrutinib treatment for patients with CLL/SLL (7 year follow-up from RESONATE-2)",
                      footerAppend: `<p>CP-286928</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        high_risk_data_patient_ulGHV: {
          collapses: [
            {
              type: "checkbox",
              title: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "HIGH RISK PATIENTS WITH ulGHV",
                  options: [
                    {
                      name: "general_manage",
                      type: "model",
                      value: false,
                      label: "Approaches to chronic lymphocytic leukemia therapy in the era of new agents (Jain et al)",
                      link: "imbruvica.general-manage",
                      modal: "general_manage",
                      modalHeading: "Approaches to chronic lymphocytic leukemia therapy in the era of new agents (Jain et al)",
                      footerAppend: `<p>CP-180335</p>`,
                    },
                    {
                      name: "pcyc1102",
                      type: "model",
                      value: false,
                      label: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      link: "imbruvica.pcyc-1102",
                      modal: "pcyc1102",
                      modalHeading: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      footerAppend: `<p>CP-188363</p>`,
                    },
                    {
                      name: "cross_trial_comparison",
                      type: "model",
                      value: false,
                      label: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (Robak et al)",
                      link: "imbruvica.cross_trial_comparison",
                      modal: "cross_trial_comparison",
                      modalHeading: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (Robak et al)",
                      footerAppend: `<p>CP-180340</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        previously_treated: {
          collapses: [
            {
              type: "checkbox",
              title: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
              visible: false,
              sections: [
                {
                  text: "PREVIOUSLY TREATED PATIENTS",
                  options: [
                    {
                      name: "resonate_refractory_resonate",
                      type: "model",
                      value: false,
                      label: "Ibrutinib in patients with previously treated CLL or small cell lymphocytic lymphoma (Final analysis: 6 year follow-up from RESONATE)",
                      link: "imbruvica.resonate-refractory-resonate",
                      modal: "resonate_refractory_resonate",
                      modalHeading: "Ibrutinib in patients with previously treated CLL or small cell lymphocytic lymphoma (Final analysis: 6 year follow-up from RESONATE)",
                      footerAppend: `<p>CP-180324</p>`,
                    },
                    {
                      name: "helios",
                      type: "model",
                      value: false,
                      label: "Ibrutinib + Bendamustine + Rituximab (5 year follow-up from HELIOS)",
                      link: "imbruvica.helios",
                      modal: "helios",
                      modalHeading: "Ibrutinib + Bendamustine + Rituximab (5 year follow-up from HELIOS)",
                      footerAppend: `<p>CP-286927</p>`,
                    },
                    {
                      name: "resonate_17",
                      type: "model",
                      value: false,
                      label: "Ibrutinib for patients with relapsed or refractory CLL with 17p deletion (RESONATE-17)",
                      link: "imbruvica.resonate_17",
                      modal: "resonate_17",
                      modalHeading: "Ibrutinib for patients with relapsed or refractory CLL with 17p deletion (RESONATE-17)",
                      footerAppend: `<p>CP-180323</p>`,
                    },
                    {
                      name: "relapse_refactory",
                      type: "model",
                      value: false,
                      label: "Evaluation of 230 patients with relapsed/refractory deletion 17p CLL treated with ibrutinib from 3 clinical trials (JONES ET AL)",
                      link: "imbruvica.relapse-refactory",
                      modal: "relapse_refactory",
                      modalHeading: "Evaluation of 230 patients with relapsed/refractory deletion 17p CLL treated with ibrutinib from 3 clinical trials (JONES ET AL)",
                      footerAppend: `<p>CP-180336</p>`,
                    },
                    {
                      name: "pcyc1102",
                      type: "model",
                      value: false,
                      label: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      link: "imbruvica.pcyc-1102",
                      modal: "pcyc1102",
                      modalHeading: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      footerAppend: `<p>CP-188363</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        frontline_data_patient_ulGHV: {
          collapses: [
            {
              type: "checkbox",
              title: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "FRONTLINE PATIENTS WITH ulGHV",
                  options: [
                    {
                      name: "relapse_resonate",
                      type: "model",
                      value: false,
                      link: "imbruvica.resonate",
                      label: "Extended follow-up and impact of high-risk prognostic factors in patients with previously treated CLL (RESONATE)",
                      modal: "RESONATE CLINICAL TRIAL",
                      modalHeading: "Extended follow-up and impact of high-risk prognostic factors in patients with previously treated CLL (RESONATE)",
                      footerAppend: `<p>CP-109541</p>`,
                    },
                    {
                      name: "pcyc1102",
                      type: "model",
                      value: false,
                      label: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      link: "imbruvica.pcyc-1102",
                      modal: "pcyc1102",
                      modalHeading: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      footerAppend: `<p>CP-188363</p>`,
                    },
                    {
                      name: "cross_trial_comparison",
                      type: "model",
                      value: false,
                      label: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (ROBAK ET AL)",
                      link: "imbruvica.cross_trial_comparison",
                      modal: "cross_trial_comparison",
                      modalHeading: "Single-agent ibrutinib versus chemoimmunotherapy regimens for treatment-naïve patients with CLL: a cross-trial comparison of phase 3 studies (ROBAK ET AL)",
                      footerAppend: `<p>CP-180340</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        frontline_data_ml_ghv: {
          collapses: [
            {
              type: "checkbox",
              title: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
              visible: false,
              sections: [
                {
                  motivate: [
                    {
                      icon: true,
                      variant: "danger",
                      html: false,
                      details: "You can view and select any clinical trial to be included in your motivation letter",
                    },
                  ],
                  text: "FRONTLINE mL GHV",
                  options: [
                    {
                      name: "alliance",
                      type: "model",
                      value: false,
                      label: "Ibrutinib regimens versus chemoimmunotherapy in older patients with untreated CLL (ALLIANCE)",
                      link: "imbruvica.alliance",
                      modal: "alliance",
                      modalHeading: "Ibrutinib regimens versus chemoimmunotherapy in older patients with untreated CLL (ALLIANCE)",
                      footerAppend: `<p>CP-110649</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        long_term_survival_data: {
          collapses: [
            {
              type: "checkbox",
              title: "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)",
              visible: false,
              sections: [
                {
                  text: "LONG TERM SURVIVAL DATA",
                  options: [
                    {
                      name: "pcyc1102",
                      type: "model",
                      value: false,
                      label: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      link: "imbruvica.pcyc-1102",
                      modal: "pcyc1102",
                      modalHeading: "Ibrutinib treatment for first-line and relapsed-refractory CLL (PCYC-1102)",
                      footerAppend: `<p>CP-188363</p>`,
                    },
                    {
                      name: "long_term_efficacy_7_year",
                      type: "model",
                      value: false,
                      label: "Long-term efficacy and safety of first-line ibrutinib treatment for patients with CLL/SLL (7 year follow-up from RESONATE-2)",
                      link: "imbruvica.long_term_efficacy_7_year",
                      modal: "long_term_efficacy_7_year",
                      modalHeading: "Long-term efficacy and safety of first-line ibrutinib treatment for patients with CLL/SLL (7 year follow-up from RESONATE-2)",
                      footerAppend: `<p>CP-286928</p>`,
                    },
                    {
                      name: "helios",
                      type: "model",
                      value: false,
                      label: "Ibrunitib + Bendamustine + Rituximab (5 year follow-up from HELIOS)",
                      link: "imbruvica.helios",
                      modal: "helios",
                      modalHeading: "Ibrunitib + Bendamustine + Rituximab (5 year follow-up from HELIOS)",
                      footerAppend: `<p>CP-286927</p>`,
                    },
                    {
                      name: "resonate_refractory_resonate",
                      type: "model",
                      value: false,
                      label: "Ibrutinib in patients with previously treated CLL or small cell lymphocytic lymphoma (Final analysis: 6 year follow-up from RESONATE)",
                      link: "imbruvica.resonate-refractory-resonate",
                      modal: "resonate_refractory_resonate",
                      modalHeading: "Ibrutinib in patients with previously treated CLL or small cell lymphocytic lymphoma (Final analysis: 6 year follow-up from RESONATE)",
                      footerAppend: `<p>CP-180324</p>`,
                    },
                    {
                      name: "GHIA-2022",
                      type: "model",
                      value: false,
                      label: "CLL patients who initiate ibrutinib have similar survival estimates as age-matched patients in the general population (GHIA 2022)",
                      link: "imbruvica.GHIA-2022",
                      modal: "GHIA-2022",
                      modalHeading: "CLL patients who initiate ibrutinib have similar survival estimates as age-matched patients in the general population (GHIA 2022)",
                      footerAppend: `<p>CP-454647</p>`,
                    },
                  ],
                },
              ],
            },
          ],
        },
        waldenstrom: {
          collapses: [
            {
              type: "checkbox",
              title: "WALDENSTRÖM’S  MACROGLOBULINAEMIA (WM) ",
              visible: false,
              sections: [
                {
                  text: "WALDENSTRÖM’S  MACROGLOBULINAEMIA (WM) PATIENTS",
                  options: [
                    {
                      name: "castillo",
                      type: "model",
                      value: false,
                      link: "imbruvica.castillo",
                      label: "Ibrutinib Monotherapy in FL WM with long term follow up (CASTILLO)",
                      modal: "castillo",
                      modalHeading: "Ibrutinib Monotherapy in FL WM with long term follow up (CASTILLO)",

                    },
                    {
                      name: "innovate",
                      type: "model",
                      value: false,
                      link: "imbruvica.innovate",
                      label: "Front-line and Previously treated - Ibr + Rituximab (iNNOVATE)",
                      modal: "innovate",
                      modalHeading: "Innovate: Front-line and Previously treated - Ibr + Rituximab",
                      footerAppend: `<p>CP-286926</p>`,
                    },
                    {
                      name: "PCYC-118e",
                      type: "model",
                      value: false,
                      link: "imbruvica.PCYC-118e",
                      label: "Ibrutinib in previously treated Waldenström's Macroglobulinaemia, with long-term follow up (PCYC-118e)",
                      modal: "PCYC-118e CLINICAL TRIAL",
                      modalHeading: "Ibrutinib in previously treated Waldenström's macroglobulinaemia, with long-term follow up (PCYC-118e)",
                      footerAppend: `<p>CP-330015</p>`,
                    },

                  ],
                },
              ],
            },
          ],
        },
        mantle_cell: {
          collapses: [
            {
              type: "checkbox",
              title: "MANTLE CELL LYMPHOMA (MCL)",
              visible: false,
              sections: [
                {
                  text: "MANTLE CELL LYMPHOMA (MCL) PATIENTS",
                  options: [
                    {
                      name: "mantle_pcyc_4",
                      type: "model",
                      value: false,
                      link: "imbruvica.pcyc-1104",
                      label: "Targeting BTK with ibrutinib in relapsed or refractory mantle cell lymphoma, with long-term follow up (PCYC-1104)",
                      modal: "PCYC-1104 CLINICAL TRIAL",
                      modalHeading: "Targeting BTK with ibrutinib in relapsed or refractory mantle cell lymphoma, with long-term follow up (PCYC-1104)",
                      footerAppend: `<p>CP-330006</p>`,
                    },
                    {
                      name: "dreyling",
                      type: "model",
                      value: false,
                      link: "imbruvica.dreyling",
                      label: "Ibrutinib monotherapy in RR MCL: Pooled analysis of 3 trials (DREYLING)",
                      modal: "dreyling",
                      modalHeading: "Ibrutinib monotherapy in RR MCL: Pooled analysis of 3 trials (DREYLING)",
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    };
  },
  methods: {
    prepImbruvica(form) {
      let checkbox;

      let returnObject = {
        hcp_full_name: form.hcp_full_name ? form.hcp_full_name : "",
        date: form.date ? moment(form.date).format("DD/MM/YYYY") : "",
        patient_detail_full_name: form.patient_detail_full_name ? form.patient_detail_full_name : "",
        date_of_birth: form.date_of_birth ? moment(form.date_of_birth).format("DD/MM/YYYY") : "",
        medical_aid_name: form.medical_aid_name ? form.medical_aid_name : "",
        plan_option: form.plan_option ? form.plan_option : "",
        medical_aid_membership_number: form.medical_aid_membership_number ? form.medical_aid_membership_number : "",
        idc_10_code: form.idc_10_code ? form.idc_10_code : "",
        pmb_code: "910S",

        medical_scheme_info: form.medical_scheme_info
          ? form.medical_scheme_info
            .replace(new RegExp("font-family: Poppins;", "g"), "")
            .replace(new RegExp("font-size: 20px;", "g"), "font-size: 15px;")
            .replace(new RegExp("list-style-type: none !important; counter-reset: item 0 !important;", "g"), "")
            .replace(new RegExp('<li style="box-sizing: border-box; display: table !important;', "g"), '<li style="box-sizing: border-box; display: list-item !important;')
            .replace(new RegExp('<ol style="box-sizing: border-box; color: #212529;  font-size: 12px; background-color: #ffffff; margin: 0px !important;  padding: 0px !important;">', "g"), "<ol>")
            .replace(new RegExp('<ol style="box-sizing: border-box; margin: 0px !important;  padding: 0px !important;">', "g"), "<ol>")
          : "",
      };
      if (form.idc_10_code == "Other") {
        returnObject["idc_10_code_other"] = form.idc_10_code_other;
      }
      returnObject["type_of_patient"] = form.type_of_patient ? form.type_of_patient : "";

      if (form.type_of_patient_type == "high_risk") {
        returnObject["diagnosis_type"] = form.diagnosis_type ? this.options.type_of_diagnosis_options_high_risk.filter((e) => e.value == form.diagnosis_type)[0].text : "";
      }
      if (form.type_of_patient_type == "frontline") {
        returnObject["diagnosis_type"] = form.diagnosis_type ? this.options.type_of_diagnosis_options_frontline.filter((e) => e.value == form.diagnosis_type)[0].text : "";
      }

      if (form.motivation) {
        returnObject["motivation_header"] = "MOTIVATION";
        returnObject["motivation"] = form.motivation ? form.motivation : "";
      }
      if (form.medical_scheme_info) {
        returnObject["medical_scheme_info_header"] = "MEDICAL SCHEME INFORMATION";
      }
      if (form.type_of_patient == "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)") {
        if (form.type_of_patient_type) {
          returnObject["clinical_trial_header"] = "CLINICAL TRIALS";
          let trials = form.diagnosis_type ? form.diagnosis_type : form.type_of_patient_type;
          switch (trials) {
            case "frontline_data":
              returnObject["frontline_header"] = "FRONTLINE PATIENTS";
              checkbox = this.getCheckbox(form.frontline_data);

              returnObject["frontline_trials"] = checkbox;
              break;
            case "immune_restoration":
              returnObject["frontline_data"] = "IMMUNE RESTORAION";
              checkbox = this.getCheckbox(form.immune_restoration);

              returnObject["frontline_trials"] = checkbox;
              break;
            case "frontline_data_patient_del17p":
              returnObject["frontline_header"] = "Frontline Data: Patient with Del17p/TP53 Mutations";
              checkbox = this.getCheckbox(form.frontline_data_patient_del17p);

              returnObject["frontline_data_patient_del17p_trial"] = checkbox;
              break;
            case "frontline_data_patient_ulGHV":
              returnObject["frontline_header"] = "Frontline Data: Patient with ulGHV";
              checkbox = this.getCheckbox(form.frontline_data_patient_ulGHV);

              returnObject["frontline_data_patient_ulGHV_trial"] = checkbox;
              break;
            case "frontline_data_patient_elderly":
              returnObject["frontline_header"] = "Frontline Data: Elderly, less fit patient (1L mlGHV)";
              checkbox = this.getCheckbox(form.frontline_data_patient_elderly);

              returnObject["frontline_data_patient_elderly_trial"] = checkbox;
              break;
            case "high_risk_data_patient_ulGHV":
              returnObject["frontline_header"] = "High Risk: Patient with ulGHV";
              checkbox = this.getCheckbox(form.high_risk_data_patient_ulGHV);

              returnObject["high_risk_data_patient_ulGHV_trial"] = checkbox;
              break;
            case "frontline_data_ml_ghv":
              returnObject["frontline_header"] = "Frontline Data: mL GHV";
              checkbox = this.getCheckbox(form.frontline_data_ml_ghv);

              returnObject["frontline_data_ml_ghv_trial"] = checkbox;
              break;
            case "previously_treated":
              returnObject["previously_treated_header"] = "PREVIOUSLY TREATED PATIENTS";

              checkbox = this.getCheckbox(form.previously_treated);
              returnObject["previously_treated"] = checkbox;
              break;
            case "long_term_survival_data":
              returnObject["long_term_survival_data_header"] = "LONG TERM SURVIVAL DATA";
              checkbox = this.getCheckbox(form.long_term_survival_data);
              returnObject["long_term_survival_data"] = checkbox;
              break;
          }
        }
      }

      if (form.type_of_patient == "WALDENSTRÖM’S MACROGLOBULINAEMIA (WM)") {
        if (this.checkCheckboxesSelected(form.waldenstrom).includes(true)) {
          returnObject["clinical_trial_header"] = "CLINICAL TRIALS";
          returnObject["waldenstrom_header"] = "WALDENSTRÖM’S MACROGLOBULINAEMIA (WM)";
        }
        checkbox = this.getCheckbox(form.waldenstrom);
        returnObject["waldenstom_trials"] = checkbox;
      }
      if (form.type_of_patient == "MANTLE CELL LYMPHOMA (MCL)") {
        console.log(this.checkCheckboxesSelected(form.mantle_cell).includes(true));
        if (this.checkCheckboxesSelected(form.mantle_cell).includes(true)) {
          returnObject["clinical_trial_header"] = "CLINICAL TRIALS";
          returnObject["mantle_cell_header"] = "MANTLE CELL LYMPHOMA (MCL)";
        }

        checkbox = this.getCheckbox(form.mantle_cell);
        returnObject["mantle_cell"] = checkbox;
      }

      return returnObject;
    },
    checkCheckboxesSelected(val) {
      let checkInclude = [];
      val.collapses.forEach((collapse) => {
        collapse.sections.forEach((section) => {
          section.options.forEach((option) => {
            if (option.value) {
              checkInclude.push(true);
            }
          });
        });
      });
      return checkInclude;
    },
    // Preview data
    async preview() {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      if (check.status === true) {
        let prepImbruv = this.prepImbruvica(this.form);
        let processed = this.flatData(prepImbruv, imbruvicaJSON);
        let response = await this.previewUrl(processed);
        this.previewModal.data = response.data;
        this.previewModal.show = true;
        this.validationReset();
      } else {
        this.validateErrors.hcp_full_name = check.data.hcp_full_name;
        this.validateErrors.date = check.data.date;
        this.validateErrors.patient_detail_full_name = check.data.patient_detail_full_name;
        this.validateErrors.date_of_birth = check.data.date_of_birth;
        this.alertMessage.message = "Please complete the required fields";
        this.alertMessage.show = true;
        this.alertMessage.variant = "danger";
        window.scrollTo(0, 0);
      }
    },
    changeTypeInput() {
      switch (this.form.idc_10_code) {
        case "C91.1 Chronic lymphocytic leukaemia of B-cell type":
          this.form.type_of_patient = "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)";
          break;
        case "C83.1 Mantle cell lymphoma":
          this.form.type_of_patient = "MANTLE CELL LYMPHOMA (MCL)";
          break;
        case "C88.0 Waldenström macroglobulinaemia":
          this.form.type_of_patient = "WALDENSTRÖM’S MACROGLOBULINAEMIA (WM)";
          break;
        case "Other":
          this.form.type_of_patient = "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)";
          break;
      }
    },
    changeICD() {
      switch (this.form.type_of_patient) {
        case "CHRONIC LYMPHOCYTIC LEUKEMIA (CLL)":
          this.form.idc_10_code = "C91.1 Chronic lymphocytic leukaemia of B-cell type";
          break;
        case "MANTLE CELL LYMPHOMA (MCL)":
          this.form.idc_10_code = "C83.1 Mantle cell lymphoma";
          break;
        case "WALDENSTRÖM’S  MACROGLOBULINAEMIA (WM)":
          this.form.idc_10_code = "C88.0 Waldenström macroglobulinaemia";
          break;
      }
    },
    // Saved data
    save() {
      this.setLocal("imbruvica", this.form);
    },
    // Show modal exports with two options

    // Export pdf function
    async exportPDF(reaction) {
      let check = this.microValidate(this.form);
      this.alertMessage.show = false;
      let response;
      if (check.status === true) {
        let prepImbruv = this.prepImbruvica(this.form);
        let processed = this.flatData(prepImbruv, imbruvicaJSON);
        response = await this.exportPDFUrl(processed, this.form.patient_detail_full_name);
      }
      if (response.error) {
        this.alertMessage = {
          show: true,
          variant: "error",
          message: response.error.message,
        };
      } else {
        window.open(response.data.data.url, "_blank");
        if (reaction == "save") {
          this.save();
        } else {
          this.reset();
        }
      }
    },
    // Export to word function
    async exportWord(reaction) {
      let prepImbruv = this.prepImbruvica(this.form);
      let x = new RegExp('<p class="indentity"', "g");
      let m = prepImbruv.medical_scheme_info.replace(x, '<br><br><p class="indentity"');
      prepImbruv.medical_scheme_info = m;
      let processed = this.flatData(prepImbruv, imbruvicaJSON);
      let response = await this.exportWordUrl(processed, this.form.patient_detail_full_name);
      if (response.error) {
        this.alertMessage = {
          show: true,
          variant: "error",
          message: response.error.message,
        };
      } else {
        window.open(response.data.data.url, "_blank");
        if (reaction == "save") {
          this.save();
        } else {
          this.reset();
        }
      }
    },
    // Reset data
    reset() {
      this.resetStorage("imbruvica");
    },
  },
  created() {
    let data = this.getLocal("imbruvica");
    if (data) {
      this.form = data;
    }
  },
};
</script>
