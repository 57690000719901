<template>
  <span>
    <label :for="name" v-if="label && html == false">
      <b>{{ label }}</b>
    </label>
    <b><label :for="name" v-if="label && html" v-html="label"></label></b>
    <template v-if="type == 'text'">
      <b-form-input
        :name="name"
        v-model="valueProp"
        :placeholder="placeholder"
        :class="['input-field', classes]"
        @blur="$emit('finishedWithInput')"
        :state="required.status"
      ></b-form-input>
      <b-form-invalid-feedback>{{ required.message }}</b-form-invalid-feedback>
    </template>
    <template v-if="type == 'date'">
      <b-form-datepicker
        v-model="valueProp"
        class="override-classes"
        locale="en-GB"
        :state="required.status"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        show-decade-nav
        aria-controls="example-input"
      ></b-form-datepicker>
      <b-form-invalid-feedback>{{ required.message }}</b-form-invalid-feedback>
    </template>
    <template v-if="type == 'textarea'">
      <b-form-textarea
        :name="name"
        v-model="valueProp"
        :placeholder="placeholder"
        :class="['textarea-field', classes]"
        @blur="$emit('finishedWithInput')"
        :rows="rows"
        max-rows="8"
      ></b-form-textarea>
    </template>
  </span>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  props: {
    name: String,
    type: String,
    rows: String,
    binding: String,
    placeholder: String,
    label: String,
    html: { type: Boolean, default: false },
    required: {
      type: Object,
      default() {
        return { status: null, message: "" };
      },
    },
    classes: { type: String, default: "" },
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      valueProp: this.$props.binding,
    };
  },
  watch: {
    valueProp() {
      this.$emit("changeValue", this.valueProp);
    },
  },
};
</script>

<style scoped></style>
