<template>
  <b-form-select v-model="details.value" class="white-background-input">
    <b-form-select-option
      v-for="(count,index) in optionGenerator(details.max,details)"
      :value="count"
      :key="index"
    >{{count}}</b-form-select-option>
  </b-form-select>
</template>

<script>
export default {
  props: {
    details: Object
  },
  methods: {
    optionGenerator(max, objectIn) {
      let returnArray = [];

      if (this.details.increment) {
        for (let x = 0; x <= max; x = x + objectIn.increment) {
          returnArray.push(x);
        }
      } else {
        for (let x = 0; x <= max; x++) {
          returnArray.push(x);
        }
      }
      return returnArray;
    }
  }
};
</script>

<style>
</style>