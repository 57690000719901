<template>
  <b-row id="tabs">
    <b-col v-for="(tab,index) in tabs" :key="index" :md="12/tabs.length" sm="12">
      <b-container
        @click="$emit('changeTab',tab.value)"
        :class="['tab',{'tab-selected':tab.value==selectedTab}]"
      >
        
          <div class="tab-item center">
            <div>{{tab.text}}</div>
          </div>
        
      </b-container>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    tabs: Array,
    selectedTab: String
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables";
.tab {
  float: left;
  padding: 1.5rem 10px;
  height: 100%;
  border: 0.1rem solid $primary-color;
  border-radius: 1rem;
  text-align: center;
  cursor: pointer;
  background-color: white;
  color: $primary-color;
}
.tab-selected {
  border: 0.1rem solid $secondary-color;
  background-color: $secondary-color;
  cursor: default;
  color: white;
}
.col {
  padding-right: 0;
}
.plus-sign{
  font-size:1.5rem;
}
</style>