<template>
  <div class="">
    <b-nav tabs>
      <b-nav-item v-for="form in forms" :key="form"><router-link :to="form.toLowerCase()">{{form}}</router-link></b-nav-item>
    </b-nav>  
  </div>

</template>

<script>
export default {
  data() {
    return {
      forms: [
        'Velcade',
        'Xeplion',
        'Imbruvica',
        'Darzalex',
        'Erleada',
        'Tremfya',
        'Trivecta',
        'Zytiga',
        'Stelara/PSO',
        'Stelara/PSA',
        'Stelara/IBD',
        'Quiz/basdai',
        'Quiz/dlqi',
        'Pah',
        'Spravato',
      ]
    };
  },
}
</script>

<style>

</style>